import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CaraosalPopupComponent } from 'src/app/common/templets/caraosal-popup/caraosal-popup.component';
import { PinPublishViewerDilog } from 'src/app/common/templets/pin-publish-popup/pin-publish';
import { Brand } from 'src/app/models/brand';
import { Snapshot } from 'src/app/models/snapshot';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { PinterestService } from 'src/app/services/pinterest-service';
import { environment } from 'src/environments/environment';
import isEqual from 'lodash/isEqual';
import { DripsComponent } from 'src/app/common/drips/drips.component';
import { AvatarPanelComponent } from 'src/app/common/panel-templete/avatar-panel/avatar-panel.component';


@Component({
  selector: 'app-avatar-page',
  templateUrl: './avatar-page.component.html',
  styleUrls: ['./avatar-page.component.css']
})

export class AvatarPageComponent {
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userdetails = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  animationList = this.firestore.collection('Animations').valueChanges({ idField: 'id' }) as Observable<any[]>;
  avatarData = this.firestore.collection('UserAvatar').valueChanges({ idField: 'id' }) as Observable<any[]>;
  pinterestBoardPinsStatus = this.firestore.collection('pinterest-board-pins-status').valueChanges({ idField: 'id' }) as Observable<Snapshot[]>;



  @ViewChild('userFeeds', { read: ElementRef }) public userFeeds: ElementRef<any>;
  @ViewChild('childrip') child: DripsComponent;
  @ViewChild('childavatarPanel') childavatar_Panel: AvatarPanelComponent;



  @Input() rootFlag: string | undefined;
  @Input() brandPageFlag: string | undefined;
  @Input() routLogInFlag: boolean | undefined;

  showBrand_Useravatar_Slider: boolean;
  showAvatar_Useravatar_Slider: boolean;
  showCloth_Useravatar_Slider: boolean;

  savedStoriesMap: { [tab: string]: any[] } = {};
  activeTab: string = 'Story'; // default


  showCrd_Set: boolean
  firstTimeStory_flag = false

  show_User_Story = false

  defaultEnvironment: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/Environments%2FAdobeStock_209580496.jpeg?alt=media&token=34dad92c-ec1f-4b7d-a293-5443ab42fd78';
  pageScroll: number;
  currentlySelected;
  saveFlage
  storyid: any
  userId: string;
  userSnapShotList: any;
  profileName: any
  userName: any = []
  userDetails: any
  filterSnapShotFileDetails: any = []
  SnapShotFileDetails: any = []
  id: any;
  avatarID: any;
  isLoadingforUserDetails = true
  mainMenuFlag: boolean = false;
  snapShotFileList: any = []
  avatarIdshide: boolean = true
  scrollFlag: boolean = false;
  avatarpanel: boolean = true;
  hide: boolean = true
  hideStoryCard: boolean = true
  StorySelectByUserpanel: boolean
  StorySelectByAvatarpanel: boolean
  usersplitted: any = []
  allUserSnapDetails: any = []
  filterSnapShotFileDetailsall: any = []
  profileDetails: any = {};
  userSnapShotDetailsCollection = {};
  counter: number = 0;
  allDetails: any = []
  userProfile: any = []
  pageNumber: any = 1
  allUserDetails: any = []
  userDetail: any = []
  filterSnap: any = []
  findSnap: any
  userFiledetails: any = []
  userUploadedFileList: any = []
  filelist: any = []
  snaploader: boolean = true
  snaploader2D: boolean = true
  callAvatrUserPanel: boolean = false

  intiateavatarpanel = false
  intiateuserpanel = false
  profileloader: boolean = true
  showpanel: boolean = true
  avatar: any
  user: any
  userData: any;
  pagecount: number;
  selectedStoryId: any;
  tab: string;
  avatarFlag: string;
  selectedTab: any = ''

  storyCardflag: boolean = true
  storyLoader: boolean = true


  brandStoryScrollerHeight: number;
  sideNavHeight: number;
  closeDiv: boolean = false
  wholeDiv: boolean = false
  garmentData: any = [];
  garmentDataCache = {};
  garmentDataCacheOrder = [];
  isStatsVisible: boolean = false
  envpanel = true
  newEntry = {
    AvatarID: "",
    BottomwearID: "",
    DateTime: { _seconds: 0, _nanoseconds: 0 },
    DisplayName: "",
    Environment: "",
    FootwearID: "",
    HeadwearID: "",
    SnapshotFileUrl: "",
    SnapshotFileUrlMicro: "",
    SnapshotFileUrlMini: "",
    StoryID: "",
    StoryType: "",
    UpperwearID: "",
    UserID: "",
    avatarName: "",
    home: "",
    id: ""
  };

  private lastSnapData: any = null; // Stores the last processed snapshot


  images = [
    { "image": "./assets/user-upload-dummy/new-img-1.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-5.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-6.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-7.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-8.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-9.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-10.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-11.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-12.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-13.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-14.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-15.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-16.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-17.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-18.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-19.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-20.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-21.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-22.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-23.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-24.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-25.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-26.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-27.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-28.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-29.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-30.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-31.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-32.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-33.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-34.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-35.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-36.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-37.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-38.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-39.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-40.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-41.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-42.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-43.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-44.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-45.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-46.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-47.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-48.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-49.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-50.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-51.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-52.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-53.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-54.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-55.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-56.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-57.jpg" },
  ]
  userProfileData: any;
  sticky: boolean;
  logInFlag: boolean;
  windowScroll: number;
  secteTab: any = 'Story';
  userAvatarData: any = {};
  AvatarSwichDetails: any

  envUrl: any = "";
  avatraUrls: any = "";
  garmenturl: any = "";
  garmentbottom: any = "";
  vrmAvtarUrl: any = "";
  genders: any = "";
  headgear: any = "";
  footwear: any = "";
  passId: any = "";
  avtarUrl: any = "";

  flagForShowThreeJs: boolean;
  functionflagValue: string;
  garmentLink: any;
  garmentsCollection: any = [];
  GarmentsGender: any;
  newArray: any = [];
  brandsCollection: any = [];
  brandCollapseFlag: boolean;
  garmentPanelFlag: boolean;
  brandheartFlag: boolean;
  newbrandUrl: string;
  selectedBrandId: any;
  brandboardCollection: any = [];
  storyPinterestPinsList: any = [];
  brandData: any = [];
  brandDataCache = {};
  brandDataCacheOrder = [];
  germentCollapseFlag: boolean;
  selectedGarmentName: string;
  brandUrl: string;
  realType: string;
  selecteGarmentId: any;
  garmentTypeID: any;
  typeOfGarment: any;
  clothDetails: any;
  fullBodyurl: any;
  UpperWearfinalUrl: any;
  loading: boolean;
  displayProgressSpinner: boolean;
  pinImg: any;
  snapshot: any;
  //previous-next button variables
  nextPage: boolean = false;
  callpageIndexFlag: boolean = true;
  callpageIndexFlagNextButton: boolean = true;
  callpageNumer: number;
  loadedPages = {};
  pageiIndex: any;
  loadedPagesBackWord = {};
  index: any;

  windowWidth: number;
  windowHeight: number;
  isMobile: boolean;
  newStoryID: string;
  avatarname: any;
  animationCollection: any = [];
  openAvatarmainPANLE: boolean = true;

  flag_v2: boolean = true;
  saveAvatarData: any;
  StoryID: string;
  private hasCalledStoryOnPanel = false;
  avatarPage: boolean = true;
  selectedAnimation: string;
  animationListShow: boolean;
  errorMessage: string;
  loadedCompoent: string;
  isCheckedFocus: boolean = true;

  showAvatarPanel: boolean = true;
  showSearchPanel: boolean = false;
  showSearchIcon: boolean = true;
  flagFunction: boolean = false;
  showUserPanel: boolean; PageType: string;

  SetGarmentId: string;
  SetBrandId: string;
  SetAvatarId: string;

  brandPageNumber: any
  avatarPageNumber: any
  garmentPageNumber: any
  avatargarmentPageNumber: any
  avatarBrandPageNumber: any
  storyText: any;
  showCrd: boolean = false;
  temp_StoryId: any;
  updatdeIndex: any;
  scrollSide: any;
  flagPassByStory: boolean = true;
  avatar_by_slider: boolean = false
  search_Temp_Flag: boolean;
  tempAvatarPanelData: any;
  ResetPanel: boolean;
  potraiFlag: boolean = false;
  firstLoadedData: any = [];
  firstLoadedFlage: boolean = false;
  initData: any = [];
  tempSnapList: any = [];
  PageTabSelection: any
  PageTabSelectionnew
  usrPageAvatarFlag: any
  showAvatarPotraite: any
  saveAvatarID_temp: any;
  UserIamge: any;
  callDripUserPanel: boolean = false;
  saveUserData: any;
  saveId: string;
  setimage: boolean = true;
  setTabSelection: any;
  tempBrandID: any;
  tempGarmentID: any;
  otherUserID: any;
  tempUserId: string;
  temptabselection: string = ''
  passTabSelection: boolean = false;
  passTabSelectionStyle: boolean = false;
  storycardhidePnel: boolean;
  sameUser: boolean = false;
  newIndex: boolean;
  saveAllSnapShots: any = [];
  crossButton: boolean = false
  singleSnap: any;
  StorySelectBySearchpanel = false;
  threadsPage_avatar_flag: boolean = true;

  selectedBrand_UserPanel
  selectedAvatar_UserPanel: any
  selectedCloth_UserPanel

  selectedBrand_AvatarPanel
  selectedCloth_AvatarPanel: any;
  showCloth_avatar_Slider: boolean;
  showBrand_avatar_Slider: boolean;
  selected_story: boolean;
  AvatarData: any;
  openSpacePanle: boolean = false;
  Set_UserPanelData: any;
  changeTab: string;
  avatarPageBrandSelection: any
  tempChangeAvatarFlag = false;
  temp_StoryId_new: any;




  @HostListener('window:scroll', ["$event"]) onScroll(event: Event) {
    let window = event.currentTarget as Window;
    this.sticky = window.scrollY >= 40;
    this.windowScroll = window.scrollY
  }

  // onResize(event) {
  //   this.updateWindowDimensions();
  // }


  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private apiHttpService: ApiHttpService,
    public router: Router,
    private commonService: CommonService,
    private firestore: AngularFirestore,
    private modal: NgbModal,
    private _httpClient: HttpClient,
    private storage: AngularFireStorage,
    private http: HttpClient,
    private pinterestAPI: PinterestService,
    public dialog: MatDialog,

  ) {
    this.route.paramMap.subscribe(paramMap => {
      this.userId = paramMap.get('id');
      this.tempUserId = paramMap.get('id');
      this.avatar = paramMap.get('avatar');
      this.storyid = paramMap.get('storyId');
      this.avatarID = paramMap.get('avatarID');
      this.user = paramMap.get('user');
      this.tab = paramMap.get('pagetab');
      this.avatarFlag = paramMap.get('avatarFlag');
      this.profileloader = true
      this.selectedStoryId = this.storyid
      this.index = paramMap.get('index');
      this.pageiIndex = paramMap.get('pageNumber');
      this.newStoryID = paramMap.get('Storyid');
      this.StoryID = paramMap.get('StoryID');
      this.PageType = paramMap.get('PageType');
      this.PageTabSelection = paramMap.get('TabSelection');
      this.PageTabSelectionnew = paramMap.get('TabSelection');
     
      this.SetBrandId = paramMap.get('SetBrandId');
      this.SetGarmentId = paramMap.get('SetGarmentId');
      this.SetAvatarId = paramMap.get('SetAvatarId');

      this.brandPageNumber = paramMap.get('brandPageNumber');
      this.avatarPageNumber = paramMap.get('avatarPageNumber');
      this.garmentPageNumber = paramMap.get('garmentPageNumber');

      this.avatargarmentPageNumber = paramMap.get('avatargarmentPageNumber');
      this.avatarBrandPageNumber = paramMap.get('avatarBrandPageNumber');

      












    });
    this.hideAvatar()
    this.closePopUp()

    this.logInFlag = this.commonService.isLoggedIn()

    this.garmentsList.subscribe((garments) => {
      this.garmentsCollection = garments;
    });

    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollection = brands;
    })

    this.brandBoardList.subscribe((brandboard) => {
      this.brandboardCollection = brandboard;
    })

    this.animationList.subscribe((animation) => {
      this.animationCollection = animation;
    })

    this.userdetails.subscribe(userdetails => {
      if (userdetails) {
        this.userProfile = userdetails;
        this.userProfileData = this.userProfile?.find((user) => user.UserID == this.userId);
      }
    });

    this.pinterestBoardPinsStatus.subscribe((response) => {
      this.storyPinterestPinsList = response;
    })
  }




  ngOnInit(): void {
    this.updateWindowDimensions();
    this.openInitiateAvatarpanel()


    if (this.PageType == 'Avatar') {
      this.intiateavatarpanel = true
      this.intiateuserpanel = false
      this.StorySelectByAvatarpanel = true
      // this.showAvatarPanel = true
      // this.closeDiv = true

    } else if (this.PageType == 'User') {
      this.intiateavatarpanel = false
      this.intiateuserpanel = true
      this.StorySelectByUserpanel = true
      // this.showUserPanel = true
      // this.closeDiv = true
    }
    console.log("this.PageType line 490",this.PageType)


    let title = 'Yologram - user'
    this.commonService.changeTitle(title);
    this.isStatsVisible = true

    // this.commonService.getUserAvatars().subscribe(data => {
    //   let avatars = data;  // Store the data when it changes
    // });

    this.snaploader2D = true;

    setTimeout(() => {
      this.snaploader2D = false;

    }, 2000);


    setTimeout(() => {
      this.storyText = ""
      this.functionflagValue = 'skybox';
      this.isStatsVisible = false
    }, 20);

    if (this.PageType) {
      this.panelswichHandle()
    }

    console.log("this.PageType line 519",this.PageType)


    this.storycardhidePnel = false

    setTimeout(() => {
      if (this.PageType == 'Avatar') {
        this.openAvatarPanel('avatar')
      } else if (this.PageType == 'User') {
        this.openAvatarPanel('user')
      }
      this.storycardhidePnel = true
    }, 10000);

    console.log("this.PageType line 533",this.PageType)

    this.getDocumentDetails()

    this.userdetails.subscribe(userdetails => {
      if (userdetails) {
        this.userProfile = userdetails;
        this.userProfileData = this.userProfile?.find((user) => user.UserID == this.userId);
        // if (this.userProfileData) {
        //   // this.UserIamge = this.userProfileData;
        // }
      }
    });

    this.selectedTab = this.tab
    this.dataSharingService.userDataObservable.subscribe((userData) => {
      this.userData = userData;
      if (this.userData != undefined && this.userData.uid != undefined) {
        this.userId = this.userData.uid
      } else {
        this.commonService.getIdentifier(this.userId).subscribe(async (response: any) => {
          this.userData = response;
          this.userData.profileName = this.userData.displayName != undefined ? this.userData.displayName : this.userData.uid;
        })
      }

      this.commonService.getAllUser().subscribe(async (allUsers: any) => {
        this.userDetail = allUsers.users;
      })

      this.userFilesList.subscribe((userFile) => {
        this.userUploadedFileList = userFile
        this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads')
        let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.userId)
        this.userFiledetails = filelist
      });
    })

    if (this.avatarID != undefined) {
      let title = 'Yologram Avatar'
      this.commonService.changeTitle(title);
    }

    if (this.index < 12) {
      this.callAvatrUserPanel = true
    }


  }

  closePopUp() {
    this.modal.dismissAll(CaraosalPopupComponent)
  }
  updateWindowDimensions() {
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
    this.isMobile = window.innerWidth <= 768; // You can adjust the width threshold for mobile mode
    // You can also use these dimensions to set styles or perform other logic
  }

  scrollHandler() {
    this.scrollFlag = true
    this.pageScroll = this.userFeeds.nativeElement.scrollTop
    this.selectedStoryId = ''
    setTimeout(() => {
      if ((this.scrollFlag == true && this.pageScroll > 100) || this.selectedStoryId != undefined) {
        this.selectedStoryId = ''
      }
    },
      0);
    let page = window.scrollY
  }

  getProfileName(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
    let profileName = userObjet.displayName != undefined ? userObjet.displayName : uuid;
    return profileName;
  }

  getProfilePhoto(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
    let profilephoto = userObjet.photoURL != undefined ? userObjet.photoURL : uuid;
    return profilephoto;
  }

  setPageNumber(pageNumber) {
    this.pageNumber = pageNumber
  }

  scrollToStory(snap) {
    this.selectedStoryId = snap.StoryID
    this.selectedTab = 'user-story'
  }

  selectSummeryView(tab) {
    this.selectedTab = 'story'
    this.selectTab(tab)
  }

  getSelectSnapshoAvatartFeed(userSnap) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.AvatarID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['public/public-userStory-feed', { 'id': userId, 'storyId': storyid }])
  }


  hideAvatar() {
    if (this.avatarID != undefined) {
      this.hide = false
    }
  }

  snapInIt() {
    this.pageNumber = 1;
    if (this.avatarID != undefined && this.userId != undefined) {
      let reqInputObjectAvatar = { id: this.avatarID, pageNumber: 1 }
      this.commonService.yologramSnapshotDetailsByAvatar(reqInputObjectAvatar).subscribe(async (response: any) => {
        // this.snapshot = this.filterSnap.find((snap)=>snap.StoryID == this.storyid)
        this.pagecount = response.count
        this.snaploader = false;
        if (this.index != undefined) {
          this.nextByCall('count')
          return
        }
        // this.filterSnap = response.object;
        this.filterSnap.forEach(async (snap: any, index) => {
          let userObjet = this.userDetail.find(userObject => userObject.uid == snap.UserID)
          snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
          userObjet.profileName = snap.profileName;
          snap.userData = userObjet
          snap.firstName = snap.profileName.split(" ", 1);
          snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
        });
      })

    }
  }

  getSnapShotListByUserID() {
    if (this.avatarID != undefined) {
      let reqInputObject = { id: this.avatarID, pageNumber: 1 }
      this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
        // this.userSnapShotList = response.object;
      })
    }
  }

  nextButtonForAvatar(c) {
    this.pageNumber++
    this.counter = this.pageNumber;
    let reqInputObject = { id: this.avatarID, pageNumber: this.counter }
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
      // this.filterSnap.push(...response.object);
    })
  }


  selectTab(tab: any) {
    this.secteTab = tab
  }

  openInitiateAvatarpanel() {
    this.selected_story = true

    this.commonService.getSnapshots().subscribe(async (snapshots: any) => {
      if (snapshots?.object && snapshots?.object.length > 0) {
        this.snapshot = snapshots?.object.find((s) => s.id == this.id)
        this.initData = snapshots?.object
        this.avatarpanel = true
        this.closeDiv = false
        this.wholeDiv = true
        this.germentCollapseFlag = false
        this.brandCollapseFlag = false
        this.avatarname = this.userAvatarData?.AvatarName
        this.functionflagValue = 'windowExpand';
        this.showCrd = false
        this.temp_StoryId = 0
        this.mainMenuFlag = false
        setTimeout(() => {
          if (this.tab == 'user-story' && this.storyid) {
            this.showCrd = true
            this.mainMenuFlag = true
            this.temp_StoryId = this.storyid
            this.hideStoryCard = false
          }
        }, 0);
        setTimeout(() => {
          if (this.tab == 'user-story' && this.storyid) {
            this.show_User_Story = true
          }
          console.log("this.temp_StoryId", this.temp_StoryId)
        }, 0);

      }
    })



  }

  handleStoryOnPanel(data) {

    this.setimage = true
    this.StorySelectByAvatarpanel = true
    this.StorySelectByUserpanel = false
    this.StorySelectBySearchpanel = false
    this.intiateavatarpanel = true
    this.intiateuserpanel = false
    this.passTabSelection = false

    setTimeout(() => {

      if (this.showUserPanel) {
        this.functionflagValue = null
        this.intiateuserpanel = true
        this.PageType == 'User'
        setTimeout(() => {
          this.functionflagValue = 'hideavatar'
        }, 10)
      }

      if (this.showAvatarPanel) {
        this.functionflagValue = null
        this.intiateavatarpanel = true
        this.potraiFlag = false
        this.PageType == 'Avatar'
        setTimeout(() => {
          this.functionflagValue = 'showAvatar'
        }, 10)
      }


      console.log("this.PageType line 768",this.PageType)


      this.selectedTab = 'user-story'
      // this.temp_StoryId = "0"
      this.mainMenuFlag = false
      this.storyLoader = false

      setTimeout(() => {
        this.temp_StoryId = data
        this.hideStoryCard = false
        this.showCrd = true
        this.mainMenuFlag = true
      }, 1)

      setTimeout(() => { this.storyLoader = true }, 500)
      setTimeout(() => { this.showSearchPanel = false }, 10)
      console.log('data', data)

      if (this.PageTabSelection == 'defaultStoryID') {
      }

      this.hideStoryCard = true
      this.closePanel()
    }, 500)



    setTimeout(() => {
      this.child.closePanel()
    }, 600)


  }

  handleStoryOnPaneluser(data) {
    this.setimage = true
    this.StorySelectByAvatarpanel = false
    this.StorySelectByUserpanel = true
    this.StorySelectBySearchpanel = false
    if (this.showUserPanel) {
      this.functionflagValue = null
      this.intiateuserpanel = true
      this.PageType == 'User'
      setTimeout(() => {
        this.functionflagValue = 'hideavatar'
      }, 10)
    }
    if (this.showAvatarPanel) {
      this.functionflagValue = null
      setTimeout(() => {
        this.functionflagValue = 'showAvatar'
      }, 10)
    }

    this.selectedTab = 'user-story'
    this.mainMenuFlag = false
    this.storyLoader = false

    if (this.PageTabSelection == 'Story') {
      this.temptabselection = ''
    }
    setTimeout(() => {
      this.temp_StoryId = data
      this.hideStoryCard = false
      this.showCrd = true
      this.mainMenuFlag = true
    }, 10)

    setTimeout(() => { this.storyLoader = true }, 500)
    setTimeout(() => { this.showSearchPanel = false }, 10)

    // this.findSnap = this.filterSnap.find((s) => s.StoryID == data)
    console.log("this.PageType line 841",this.PageType)


    setTimeout(() => {
      this.storycardhidePnel = true
    }, 1500)

    // this.closePanel()

    if (this.tempChangeAvatarFlag) {
      this.commonService.getSnapshots().subscribe(async (snapshots: any) => {
        if (snapshots?.object && snapshots?.object.length > 0) {
          let snap = snapshots?.object
          let findSnap = snap.find((s) => s.StoryID == data)
          this.handlesliderAvatar(findSnap)
          this.StorySelectByAvatarpanel = true
          // this.openAvatarPanel('avatar')
        }
      })
      this.tempChangeAvatarFlag = false
    }




  }


  async getDocumentDetails() {
    // console.log("id data",this.userId,this.avatarID)
    // Use provided data or fallback to class properties
    const userId = this.AvatarSwichDetails?.UserID || this.userId;
    const avatarID = this.AvatarSwichDetails?.AtarID || this.avatarID;
    if (avatarID == this.saveAvatarID_temp) {
      return
    }
    this.saveAvatarID_temp = avatarID
    await this.commonService
      .getDocumentsFromSubcollection(userId, avatarID)
      .then((documents) => {
        if (documents && documents.length > 0) {
          const firstDocument = documents[0]; // Assuming there is only one document
          this.userAvatarData = firstDocument;
          this.avatarname = this.userAvatarData?.AvatarName;
          this.openNews3DPassValue();
          this.showAvatarPotraite = this.userAvatarData?.AvatarPreviewPortraitUrl
        } else {
          console.warn("No documents found for given UserID and AvatarID.");
        }
      })
      .catch((error) => {
        console.error("Error in component:", error);
        throw error; // Optionally re-throw the error
      });
  }


  handlesliderUser(snapshot) {
    this.Set_UserPanelData = snapshot
    // this.child.resetUserPanel(snapshot)
  }


  async handlesliderAvatar(data) {
    console.log("let call it.................")

    let snapshot = data
    let miss = data

    let AvatarSwichDetails
    AvatarSwichDetails = AvatarSwichDetails || {};
    AvatarSwichDetails['UserID'] = miss.UserID;
    AvatarSwichDetails['AtarID'] = miss.AvatarID;

    // this.handUserIDAndAvatrID(AvatarSwichDetails)
    this.AvatarSwichDetails = AvatarSwichDetails
    console.log(this.AvatarSwichDetails)
    this.functionflagValue = null
    this.getDocumentDetails(); // Pass the datssa to getDocumentDetails
    // this.findSnap = this.filterSnap[0]
    console.log(this.filterSnap)
    this.closePanel()
    this.closecard()



    const emitData = {
      selectedUserName: '',
      selectedAvatarName: snapshot.avatarName,
      AvatarData: this.AvatarData = await this.avatarPanelDataFunction(snapshot.UserID, snapshot.AvatarID), // Use cached data if available
      snapshot: snapshot,
    };
    this.tempAvatarPanelData = null
    setTimeout(() => {
      // this.PageType =='Avatar' 
      this.avatar_by_slider = false
      this.tempAvatarPanelData = emitData
      this.PageTabSelection = 'Story'
      this.selectedBrand_AvatarPanel = null
      this.selectedCloth_AvatarPanel = null
      // this.avatar_by_slider = false
      // this.openAvatarPanel('avatar')
      // 
    }, 0)
    // this.handleAvatarPnel(emitData)

  }

  async avatarPanelDataFunction(userId, avatarId) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getAvatarPanelDetails";
    return this.http.post<any>(functionUrl, { userId: userId, avatarId: avatarId }).toPromise();
  }

  handUserIDAndAvatrID(data) {
    this.AvatarSwichDetails = data
    console.log(this.AvatarSwichDetails)
    this.functionflagValue = null
    this.getDocumentDetails(); // Pass the datssa to getDocumentDetails
    // this.findSnap = this.filterSnap[0]
    console.log(this.filterSnap)
    this.StorySelectBySearchpanel = false
    this.StorySelectByAvatarpanel = true
    this.StorySelectByUserpanel = false
    console.log("data------------------------------------------------------->", data)
    this.closePanel()
    this.closecard()
  }


  async openNews3DPassValue() {
    if (this.userAvatarData) {
      if (this.saveAvatarData?.dataBlobUrl) {
        this.saveAvatarData.dataBlobUrl = null
        this.saveAvatarData.envUrl = null
      }
      this.avtarUrl = this.userAvatarData.AvatarURL;
      this.garmenturl = await this.copyTextToClipGarment(this.userAvatarData.cUpperwearID);
      this.fullBodyurl = await this.copyTextToClipGarment(this.userAvatarData.cFullBodyID);

      // Assign the first non-undefined value
      // this.UpperWearfinalUrl = (this.garmenturl !== null || this.garmenturl !== null || this.garmenturl !== ''  ) ? this.garmenturl : this.fullBodyurl;
      this.UpperWearfinalUrl = this.garmenturl == null ? this.fullBodyurl : this.garmenturl
      this.garmentbottom = await this.copyTextToClipGarment(this.userAvatarData.cBottomwearID);;
      this.genders = await this.userAvatarData.Gender == 'MALE' ? 'M' : 'F';
      this.headgear = await this.copyTextToClipGarment(this.userAvatarData.cHeadgearID);
      this.footwear = await this.copyTextToClipGarment(this.userAvatarData.cFootwearID);
      this.flagForShowThreeJs = true;
      console.log('called....')
      this.functionflagValue = 'LoadOnlyAvatar_' + this.avtarUrl;
      // this.downloadprocessPackZip(this.avtarUrl)
      await this.zipExtractAndSaveFunction(this.avtarUrl)
      if(this.showAvatarPanel && this.closeDiv){
        this.usrPageAvatarFlag = this.avatarID
      }
    }
    // this.envUrl = envUrl;
  }

  
  // this.functionflagValue ='LoadAvatar'

  copyTextToClipGarment(text: string) {
    let Garments = this.garmentsCollection.find(
      (garmentList) => garmentList.id === text);
    if (Garments) {
      let brandId = Garments.BrandID;
      let gender = Garments.Gender;
      this.GarmentsGender = Garments.Gender;
      let garmentType = Garments.GarmentType;
      let zipfilename = Garments.id;
      // alert(zipfilename)
      let packlocation =
        'AvatarBlenderClothes%2F' +
        brandId +
        '_' +
        gender +
        '%2F' +
        garmentType +
        '%2F' +
        zipfilename;
      this.garmentLink =
        'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
        packlocation +
        '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';

      return this.garmentLink;
    } else {
      // Handle the case when Garments is not found
      // console.error('Garments not found for id: ' + text);
      return null; // or return an appropriate value or throw an error
    }
  }

  calledVlueIntilized(event) {
    let value = event
    if (value == "Initialized") {

    }
  }

  closeThreeDBUTTON() {
    this.selectedTab = 'user-story'
    this.temp_StoryId = "0"
    setTimeout(() => {
      this.hideStoryCard = false
      this.showCrd = true
      this.showSearchPanel = true;
      console.log("filterSnap 1", this.filterSnap)
    }, 10)
    setTimeout(() => {
      this.filterSnap = this.tempAvatarPanelData.snapshotsData.object
      this.hideStoryCard = true
      console.log("filterSnap 2", this.filterSnap)
    }, 20)
    setTimeout(() => {
      this.hideStoryCard = false
      console.log("filterSnap 3", this.filterSnap)
    }, 100)
    setTimeout(() => {
      this.showSearchPanel = false;
    }, 100)

  }





  handleAnimationList() {
    this.animationListShow = !this.animationListShow
  }

  zipExtractAndSaveFunction(url) {
    this.newArray = this.processAvatarUrls()
    if (this.userAvatarData.AvatarPreviewFullBodyUrl != undefined && this.userAvatarData.AvatarPreviewPortraitUrl != undefined) {
      return
    }
    let reqInputObject = {
      url: url,
      userId: this.userId,
      avatarId: this.avatarID
    }
    this.commonService.avatarPageExtractZip(reqInputObject).subscribe(async (response: any) => {
      // this.filterSnap = response.object;
    })
  }

  processAvatarUrls(): { fileurl: string }[] {
    const urls = [];
    if (this.userAvatarData?.AvatarPreviewFullBodyUrl) {
      urls.push({ fileurlMini: this.userAvatarData.AvatarPreviewFullBodyUrl, fileurl: this.userAvatarData.AvatarPreviewFullBodyUrl, });
    }
    if (this.userAvatarData?.AvatarPreviewPortraitUrl) {
      urls.push({ fileurlMini: this.userAvatarData.AvatarPreviewPortraitUrl, fileurl: this.userAvatarData.AvatarPreviewPortraitUrl, });
    }
    return urls;
  }


  getGarmentrealtype(garmentId: string): string {
    return this.garmentsCollection.find((b: any) => b.id === garmentId)?.RealType ?? '';
  }

  //showing Brandlogo With Garment Id
  getBrandImageByGarmetnID(garmentId: string): string {
    const imgURL = encodeURI(this.getBrandLogoURL(garmentId)).replace('/', '%2F');
    return `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${imgURL}?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c`;
  }

  //For Finding Brand Logo BrandLogoUrl 
  getBrandLogoURL(garmentId: string): string {
    const garment = this.garmentsCollection.find((b: any) => b.id === garmentId);
    const brandId = garment?.BrandID;
    const brand = this.brandsCollection.find((b: any) => b.id === brandId);
    return brand?.LogoURL ?? '';
  }

  getGarmentName(garmentId: string): string {
    const garment = this.garmentsCollection.find((b: any) => b.id === garmentId);
    return garment?.Name ?? '';
  }


  async openBrandPanel(garmentId: any, event: any) {
    // this.wholeDiv = false;
    // event.stopPropagation();
    // this.brandCollapseFlag = true;
    // this.garmentPanelFlag = false;
    // this.germentCollapseFlag = false;

    // this.closeDiv = true;
    // this.brandheartFlag = true;
    // this.newbrandUrl = this.getBrandImageByGarmetnID(garmentId);
    // console.log("this.brandUrl", this.newbrandUrl);
    // this.brandData = [];

    // // Find the selectedBrandId based on garmentId
    // let garmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
    // this.selectedBrandId = garmentObject.BrandID;

    // // Check if brandData for this selectedBrandId is already cached
    // if (this.brandDataCache[this.selectedBrandId]) {
    //   this.brandData = this.brandDataCache[this.selectedBrandId];
    //   console.log("when already cache", this.brandData);
    //   // Reorder the cache to keep the latest accessed item at the end
    //   const index = this.brandDataCacheOrder.indexOf(this.selectedBrandId);
    //   if (index !== -1) {
    //     this.brandDataCacheOrder.splice(index, 1); // Remove existing entry
    //   }
    //   this.brandDataCacheOrder.push(this.selectedBrandId); // Add at the end to maintain order
    // } else {
    //   // Fetch brandData if not already cached
    //   this.brandData = await this.brandPanelDataFunction(this.selectedBrandId);
    //   // Cache the fetched brandData
    //   if (this.brandDataCacheOrder.length >= 5) {
    //     const oldestCacheId = this.brandDataCacheOrder.shift(); // Remove the oldest cached entry
    //     delete this.brandDataCache[oldestCacheId];
    //   }
    //   this.brandDataCache[this.selectedBrandId] = this.brandData;
    //   this.brandDataCacheOrder.push(this.selectedBrandId); // Add at the end to maintain order

    //   console.log("this.brandDataCache", this.brandDataCache[this.selectedBrandId]);
    //   console.log("this.brandDataCache", this.brandDataCache);

    // }


    // // this.getBrnadStoryImage(this.selectedBrandId);
    // // this.getBrandData(garmentId);
    // // this.emitDataToParent()
    // // Adjust sidebar width
    // const sidebarWidth = window.innerWidth > 768 ? '249px' : '181px';
    // const sidebar = document.getElementById('mySidenav');
    // if (sidebar) {
    //   sidebar.style.width = sidebarWidth;
    // }

  }

  setBrandStoryScrollerHeight() {
    this.sideNavHeight = window.innerHeight
    this.brandStoryScrollerHeight = window.innerHeight - 270;
  }

  clickOnPanel(event) {
    event.stopPropagation()
  }


  closePanel() {
    this.wholeDiv = true;
    this.closeDiv = false;
    this.brandCollapseFlag = false;
    this.germentCollapseFlag = false;
    this.openAvatarmainPANLE = false;
    this.showSearchPanel = false;
    this.showpanel = false;

    // ✅ Use === for proper comparison
    if (this.PageType === 'Avatar') {
        this.showAvatarPanel = true;
        this.showUserPanel = false;
    } else {
        this.showAvatarPanel = false;
    }

    if (this.PageType === 'User') {
        this.showUserPanel = true;
        this.showAvatarPanel = false;
    } else {
        this.showUserPanel = false;
    }

    console.log("this.PageType", this.PageType);

    setTimeout(() => {
        this.showpanel = true;
    }, 100);

    setTimeout(() => {
        this.tempAvatarPanelData = null;
    }, 100);

    this.child.CloseAvatarButton();
}




  openAvatarPanel(flag) {

    this.wholeDiv = false;
    this.closeDiv = true;
    this.search_Temp_Flag = false
    
    if (this.PageTabSelection == 'Story') {
      this.openSpacePanle = false
    }

    if (this.PageTabSelection == 'Brands' ||
      this.PageTabSelection == 'Style' &&
      !this.openSpacePanle) {
      this.openSpacePanle = true
    }

    if (flag === 'search') {
      this.showCrd = true
      this.showSearchPanel = true;
      this.showAvatarPanel = false;
      this.showUserPanel = false
      // this.hideStoryCard = true
      if (!this.hideStoryCard) {
        this.hideStoryCard = false
      } else {
        this.hideStoryCard = true
      }
      this.search_Temp_Flag = true

    } else if (flag === 'avatar') {
      this.tempAvatarPanelData = null;
      this.showSearchPanel = false;
      this.showUserPanel = false
      this.ResetPanel = false
      this.wholeDiv = false;
      this.closeDiv = true;
      //new flage added
      this.showAvatarPanel = true;

      // this.PageType = 'Avatar'
      this.intiateavatarpanel = true
      this.intiateuserpanel = false
      this.passTabSelection = false
      // setTimeout(() => { { this.showAvatarPanel = true; this.ResetPanel = true } }, 500)
      this.passTabSelection = false
      this.passTabSelectionStyle = false
      setTimeout(() => {
        if (this.sameUser = true) {
          if (this.PageTabSelection == 'Brands' || this.temptabselection == 'Brands') {
            this.passTabSelection = true
          }
          if (this.PageTabSelection == 'Style' || this.temptabselection == 'Style') {
            this.passTabSelectionStyle = true
          }
        }
      }, 0)
    } else if (flag === 'user') {
      this.showUserPanel = true
      this.showAvatarPanel = false;
      this.showSearchPanel = false;
      this.saveUserData = this.userData
      this.callAvatrUserPanel = false
      this.intiateuserpanel = true
      this.intiateavatarpanel = false
      if(this.showUserPanel && this.closeDiv){
        this.usrPageAvatarFlag = null
      }
      setTimeout(() => {
        this.userId = this.tempUserId
        if (this.sameUser = true) {
          if (this.temptabselection == 'Brands') {
            this.PageTabSelection = this.temptabselection
          }
          if (this.temptabselection == 'Style') {
            this.PageTabSelection = this.temptabselection
          }
        }
      }, 10)
      console.log("UserIamge", this.UserIamge);
    }
    this.tempAvatarPanelData = null
  }

  async brandPanelDataFunction(brandId) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/brandPanelDetailswithLogoUrl";
    return this.http.post<any>(functionUrl, { brandId: brandId }).toPromise();
  }

  async garmentPanelDataFunction(garmentId, typeWear) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getGarmentPanelData_v2";
    return this.http.post<any>(functionUrl, { garmentId: garmentId, fileType: typeWear }).toPromise();
  }

  async openGarmentNav(snapshot: any, typerId: any, garmentId: any, type: any, event: any) {
    event.stopPropagation();
    this.germentCollapseFlag = true;
    this.closeDiv = true;
    this.wholeDiv = false;
    this.brandCollapseFlag = false;
    this.closeDiv = true;
    this.selecteGarmentId = garmentId;
    this.typeOfGarment = type;
    this.garmentTypeID = typerId;
    this.garmentData = [];
    this.selectedGarmentName = this.getGarmentName(garmentId);
    this.brandUrl = this.getBrandImageByGarmetnID(garmentId);
    this.realType = this.getGarmentrealtype(garmentId);

    if (this.garmentDataCache[garmentId]) {
      this.garmentData = this.garmentDataCache[garmentId];
      console.log("when already cache ", this.garmentData);
      const index = this.garmentDataCacheOrder.indexOf(garmentId);
      if (index !== -1) {
        this.garmentDataCacheOrder.splice(index, 1); // Remove existing entry
      }
      this.garmentDataCacheOrder.push(garmentId); // Add at the end to maintain order
    } else {
      this.garmentData = await this.garmentPanelDataFunction(garmentId, typerId);
      if (this.garmentDataCacheOrder.length >= 5) {
        const oldestCacheId = this.garmentDataCacheOrder.shift(); // Remove the oldest cached entry
        delete this.garmentDataCache[oldestCacheId];
      }
      this.garmentDataCache[garmentId] = this.garmentData;
      this.garmentDataCacheOrder.push(garmentId); // Add at the end to maintain order
      let filePathNewTemp = this.garmentData.userFiles.find((file) => file.link == this.selecteGarmentId && file.fileType == 'Garment360Cache');

      this.clothDetails = this.garmentData.garment
      if (!filePathNewTemp) {
        console.error("filePathNewTemp is undefined or null");
        await this.fileUrlUpadate(this.clothDetails);
      } else {
        if (filePathNewTemp.filepath) {
          const storageRef = this.storage.ref(filePathNewTemp.filepath);
          try {
            const downloadUrl = await storageRef.getDownloadURL().toPromise();
          } catch (error) {
            if (error.code === 'storage/object-not-found') {
              console.error("File not found:", filePathNewTemp.filepath);
              await this.fileUrlUpadate(this.clothDetails);
            } else {
              console.error("Error fetching download URL:", error);
            }
          }
        } else {
          console.error("filePathNewTemp.filepath is undefined");
          await this.fileUrlUpadate(this.clothDetails);
        }
      }


      console.log("this.selecteGarmentId", this.selecteGarmentId, this.typeOfGarment, this.garmentTypeID,
        this.selectedGarmentName, this.brandUrl, this.realType);
    }

  }

  async fileUrlUpadate(data) {
    let garment = data
    let zipNmae = 'pack0';
    let packlocation =
      'AvatarBlenderClothes%2F' +
      garment.BrandID +
      '_' +
      garment.Gender +
      '%2F' +
      zipNmae;
    let garmentLink =
      'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
      packlocation +
      '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
    const url = garmentLink;
    const cloth = garment.id;

    await this.commonService.downloadAndProcessPack(url, cloth).subscribe({
      next: (response) => console.log('Success:', response),
      error: (error) => console.error('Error:', error)
    });

  }


  showPintrestIcon(clothId: any) {
    let flag = false;
    let grandmentObject = this.garmentsCollection.find(
      (b: any) => b.id === clothId
    );
    let brandBoardObject;
    if (grandmentObject != undefined && grandmentObject.BrandID != undefined) {
      brandBoardObject = this.brandboardCollection.find(
        (b: any) => b.brandId == grandmentObject.BrandID
      );
      //let show = './assets/Pinterest-logo.png'
    }
    if (brandBoardObject != undefined) {
      flag = true;
    }
    return flag;
  }

  showSharedStory(
    garmentId: any,
    type: any,
    snapshotFileUrl: any,
    storyID: any
  ): boolean {
    let brandId: any;
    let boardId: any;

    if (this.garmentsCollection.length > 0) {
      let garmentObject = this.garmentsCollection.find(
        (b: any) => b.id === garmentId
      );
      if (garmentObject && garmentObject['Name'] != undefined) {
        brandId = garmentObject['BrandID'];
        let brandBoardObject = this.brandboardCollection.find(
          (b: any) => b.brandId == brandId
        );
        boardId =
          brandBoardObject && brandBoardObject['boardId'] != undefined
            ? brandBoardObject['boardId']
            : undefined;
      }
    }

    let storyObject = this.storyPinterestPinsList.find(
      (b: any) => b.StoryID == storyID
    );

    // Check if the story is already shared
    if (storyObject && storyObject[type] != undefined) {
      return true;
    }

    return false;
  }



  shareOnPinterest(
    garmentId: any,
    type: any,
    snapshotFileUrl: any,
    storyID: any
  ) {
    setTimeout(() => {
      this.loading = false;
    }, 9000);
    this.loading = true;
    let brandId: any;
    let boardId: any;
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find(
        (b: any) => b.id === garmentId
      );
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        brandId = grandmentObject['BrandID'];
        let brandBoardObject = this.brandboardCollection.find(
          (b: any) => b.brandId == brandId
        );
        boardId =
          brandBoardObject && brandBoardObject['boardId'] != undefined
            ? brandBoardObject['boardId']
            : undefined;
      }
    }
    if (boardId == undefined || brandId == '') {
      this.loading = false;
      alert('This brand is not linked with Pinterest board.');
      return true;
    }

    let jsonObject = {
      avatarURL: snapshotFileUrl,
      boardId: boardId,
    };
    // let jsonInput = this.pinterstJsonMap;
    let storyObject = this.storyPinterestPinsList.find(
      (b: any) => b.StoryID == storyID
    );

    if (storyObject && storyObject[type] != undefined) {
      let jsonInpt = { pinId: storyObject[type] };
      this.pinterestAPI.getPinterestPin(jsonInpt).subscribe((data) => {
        this.displayProgressSpinner = false;
        this.pinImg = data.media.images['400x300'].url;
        this.openModleForPublishedPin();
        this.loading = false;
      });
      return true;
    }

    this.pinterestAPI.createPinterestPin(jsonObject).subscribe((result) => {
      if (storyObject == undefined) {
        let pinsMappingObject: any = {
          StoryID: storyID,
        };
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id;
        if (type == 'footwear') pinsMappingObject['footwear'] = result.id;
        if (type == 'upperwear') pinsMappingObject['upperwear'] = result.id;
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id;
        this.firestore
          .collection('pinterest-board-pins-status')
          .add(pinsMappingObject);
      } else if (storyObject && storyObject[type] == undefined) {
        if (type == 'headwear') storyObject['headwear'] = result.id;
        if (type == 'footwear') storyObject['footwear'] = result.id;
        if (type == 'upperwear') storyObject['upperwear'] = result.id;
        if (type == 'headwear') storyObject['headwear'] = result.id;
        this.firestore
          .collection('pinterest-board-pins-status')
          .doc(storyObject['id'])
          .update(storyObject);
      }

      this.loading = false;
      alert('Pin published successfully.');
      return true;
    });
    return false;
  }
  openModleForPublishedPin() {
    this.loading = false;
    const dialogRef = this.dialog.open(PinPublishViewerDilog);
    dialogRef.componentInstance.pinImgSrc = this.pinImg;
    dialogRef.afterClosed().subscribe((result) => {
      this.loading = false;
    });
  }


  handleStoryCall(data) {
    this.nextPage = data
    if (this.nextPage == true) {
      let count = 1
      this.nextButton(count)
    }
  }

  nextByCall(counts) {
    const count = 12;
    for (let i = 0; i < this.pagecount; i++) {
      this.filterSnap.push(this.newEntry);
    }

    const totalPages = Math.ceil(this.pagecount / count);
    const currentPage = Math.floor(this.index / count) + 1;
    const startIndex = (currentPage - 1) * count;
    this.counter = count;
    let reqInputObject = { id: this.avatarID, pageNumber: currentPage }
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
      const newItems = response.object;
      for (let i = 0; i < newItems.length; i++) {
        this.filterSnap[startIndex + i] = newItems[i];
      }
    })




  }
  nextButton(counts) {
    // Increment the page number
    if (this.callpageIndexFlagNextButton) {
      this.pageNumber = this.pageiIndex;
      this.callpageIndexFlagNextButton = false
    }
    this.pageNumber++;

    const count = 12; // Number of items per page
    const totalPages = Math.ceil(this.pagecount / count);

    // Ensure the current page number does not exceed total pages
    if (this.pageNumber > totalPages) {
      this.pageNumber = totalPages;
    }

    // Calculate the current page and start index
    const currentPage = this.pageNumber;
    const startIndex = (currentPage - 1) * count;
    this.counter = this.pageNumber;

    // Check if this page has already been loaded
    if (this.loadedPages[this.pageNumber]) {
      return;
    }

    // Prepare the request object with the updated page number
    let reqInputObject = { id: this.avatarID, pageNumber: this.counter };

    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
      const newItems = response.object;
      this.nextPage = true;

      // Update filterSnap with the new items at the correct start index
      for (let i = 0; i < newItems.length; i++) {
        this.filterSnap[startIndex + i] = newItems[i];
      }

      // Mark this page as loaded
      this.loadedPages[this.pageNumber] = true;

      this.nextPage = false;
    });
  }
  previousbutton() {
    let count = 12;
    if (this.callpageIndexFlag) {
      this.callpageNumer = this.pageiIndex - 1;
      this.callpageIndexFlag = false;
    }

    if (this.callpageNumer > 0) {
      let cllpagecount = (this.callpageNumer * count) - 1;
      let startIndex = cllpagecount - (count - 1);
      // Check if the page has already been loaded
      if (this.loadedPagesBackWord[cllpagecount]) {
        this.callpageNumer--;  // Decrease callpage by 1
        return;
      }

      // Prepare the request object with the updated page number
      let reqInputObject = { id: this.avatarID, pageNumber: this.callpageNumer };

      // Make the API call to load the data for the previous page
      this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
        const newItems = response.object;
        this.nextPage = true;
        // Update filterSnap with the new items at the correct start index
        for (let i = 0; i < newItems.length; i++) {
          this.filterSnap[startIndex + i] = newItems[i];
        }
        // Mark this page as loaded to prevent future duplicate calls
        this.loadedPagesBackWord[cllpagecount] = true;
        this.nextPage = false;
        // Decrease callpage by 1 after the API call
        this.callpageNumer--;
      });
    }
  }

  async handlePreviousPage(data) {
    this.updatdeIndex = data
    let count = 12;
    if (this.callpageIndexFlag) {
      this.callpageNumer = this.pageiIndex - 1;
      this.callpageIndexFlag = false;
    }

    while (this.callpageNumer > 0) {
      let cllpagecount = (this.callpageNumer * count) - 1;
      let startIndex = cllpagecount - (count - 1);

      if (this.loadedPagesBackWord[cllpagecount]) {
        return;
      }

      if (cllpagecount === data) {
        try {
          let reqInputObject = { id: this.avatarID, pageNumber: this.callpageNumer };
          this.callpageNumer--;  // Decrease callpage by 1

          const response = await this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).toPromise();
          const newItems = response['object'];
          this.nextPage = true;
          // Update filterSnap with the new items at the correct start index
          newItems.forEach((item, index) => {
            this.filterSnap[startIndex + index] = item;
          });
          this.loadedPagesBackWord[cllpagecount] = true;
        } catch (error) {
          console.error("Error fetching page data:", error);
        }
      } else {
        break;  // Exit loop if no match is found
      }
    }
  }

  closecard() {
    this.selectedTab = ''
    this.showCrd = false
    this.showSearchIcon = false
    this.hideStoryCard = true
    this.showCrd_Set = false

    this.mainMenuFlag = false
    // this.filterSnap =  this.firstLoadedData 

    setTimeout(() => {
      this.mainMenuFlag = true
      // this.temp_StoryId = this.findSnap.StoryID
    }, 10)


    this.crossButton = false
    setTimeout(() => {
      this.showCrd = false
      this.crossButton = true
    }, 100)

    // this.findSnap = this.saveAllSnapShots[this.updatdeIndex];
    setTimeout(() => {
      this.openSelectedPanel()

    }, 10)



  }

  handleAvatarEmitData(data) {
    // Initialize saveAvatarData if it's undefined

    if (!this.saveAvatarData) {
      this.saveAvatarData = {};
    }

    if (this.saveAvatarData) {
      this.saveAvatarData = {};
    }

    if (data.avatraUrls && data.avatraUrls !== this.saveAvatarData.avatraUrls) {
      this.saveAvatarData.avatraUrls = data.avatraUrls;
    }

    // Only update properties that have changed
    if (data.envUrl && data.envUrl !== this.saveAvatarData.envUrl) {
      this.saveAvatarData.envUrl = data.envUrl;
    }
    if (data.functionflagValue && data.functionflagValue !== this.saveAvatarData.functionflagValue) {
      this.saveAvatarData.functionflagValue = data.functionflagValue;
    }
    if (data.garmenturl && data.garmenturl !== this.saveAvatarData.garmenturl) {
      this.saveAvatarData.garmenturl = data.garmenturl;
    }
    if (data.garmentbottom && data.garmentbottom !== this.saveAvatarData.garmentbottom) {
      this.saveAvatarData.garmentbottom = data.garmentbottom;
    }
    if (data.headgear && data.headgear !== this.saveAvatarData.headgear) {
      this.saveAvatarData.headgear = data.headgear;
    }
    if (data.footwear && data.footwear !== this.saveAvatarData.footwear) {
      this.saveAvatarData.footwear = data.footwear;
    }
    if (data.dataBlobUrl && data.dataBlobUrl !== this.saveAvatarData.dataBlobUrl) {
      this.saveAvatarData.dataBlobUrl = data.dataBlobUrl;
    }
    if (data.storyID && data.storyID !== this.saveAvatarData.storyID) {
      this.saveAvatarData.storyID = data.storyID;
    }

    this.functionflagValue = 'skybox';
    this.storyCardflag = true


    this.closecard()
    setTimeout(() => {
      this.storyCardflag = false
    }, 100)

  }

  handleStoryId(data) {
    if (!this.hasCalledStoryOnPanel) {
      this.hasCalledStoryOnPanel = true; // Set the flag to prevent further calls
      setTimeout((this.selectedStoryId = data), 50)
    }
  }


  handleAvatarPnel(data) {
    this.passTabSelection = false
    this.passTabSelectionStyle = false
    // this.PageTabSelection = 'Story'
    this.sameUser = false

    if (data) {
      let AvatarID = data.snapshot
      if (this.avatarID == AvatarID.AvatarID) {
        this.sameUser = true
        this.openAvatarPanel("avatar")
      }
      if (!this.avatarID) {
        this.tempAvatarPanelData = null
        this.openAvatarPanel("avatar")
        this.showAvatarPanel = true;
        this.showSearchPanel = false;
        this.showUserPanel = false
        this.wholeDiv = false;
        this.closeDiv = true;
        this.avatarname = data.selectedAvatarName
        setTimeout(() => {
          this.ResetPanel = false
          this.tempAvatarPanelData = data
          this.PageTabSelection = 'Story'
        }, 1); // Optional delay
        setTimeout(() => {
          this.PageTabSelection = 'Story'
        }, 1000); // Optional delay
      }

      if (this.avatarID != AvatarID.AvatarID) {
        if (this.childavatar_Panel) {  // ✅ Check if childavatar_Panel exists
          this.childavatar_Panel.selectTab('Story');
      } else {
          console.warn("childavatar_Panel is undefined!");
      }
        this.sameUser = false
        this.tempAvatarPanelData = null
        this.showAvatarPanel = true;
        this.showSearchPanel = false;
        this.showUserPanel = false  
        this.wholeDiv = false;
        this.closeDiv = true;
        this.avatarname = data.selectedAvatarName
        setTimeout(() => {
          this.ResetPanel = false
          this.tempAvatarPanelData = data
          this.PageTabSelection = 'Story'
        }, 1); // Optional delay
        setTimeout(() => {
          this.PageTabSelection = 'Story'
        }, 1000); // Optional delay
      }

    }
    //  this.ResetPanel = false
    //   this.showAvatarPanel = true;
    //   this.showSearchPanel = false;
    //   this.showUserPanel = false
    //   this.wholeDiv = false;
    //   this.closeDiv = true;
    //   this.avatarname = data.selectedAvatarName
    //   this.tempAvatarPanelData = data

    console.log('data', data)

    // if (this.intiateavatarpanel) {
    //   this.intiateavatarpanel = true
    //   this.intiateuserpanel = false
    // } else if (this.intiateuserpanel) {
    //   this.intiateavatarpanel = false
    //   this.intiateuserpanel = true
    // }

    console.log('intiateavatarpanel', this.intiateavatarpanel)
    console.log('intiateuserpanel', this.intiateuserpanel)

  }

  handleUserPnl(data) {
    console.log(data)
    // this.PageTabSelection = 'Story'
    this.sameUser = false
    if (this.userId == data.UserID) {
      setTimeout(() => {
        this.sameUser = true
      }, 0);
    }


    this.openAvatarPanel('user') 


    if (this.userId == data.UserID) {
      return
    }

    this.intiateavatarpanel = false
    this.intiateuserpanel = false
    this.callDripUserPanel = false

    setTimeout(() => {
      this.userId = data.UserID
      this.PageTabSelection = 'Story'
      this.intiateuserpanel = true
      this.intiateavatarpanel = false
      this.callDripUserPanel = true
    }, 100);
  }

  onSelectAnimation(data) {
    let url = data;
    let animationEncodedUrl = encodeURI(url).replace(/\//g, '%2F');
    let storageUrl =
      'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
      animationEncodedUrl +
      '?alt=media&token=c6ec5912-f818-429b-81a3-d44b8f92657c';
    this.selectedAnimation = storageUrl;
    this.functionflagValue = 'bvhAnimationUrl' + '_' + this.selectedAnimation;
    this.animationListShow = false
  }

  switchFlagforFocus() {
    // this.isCheckedFocus = !this.isCheckedFocus;
    if (this.isCheckedFocus) {
      this.functionflagValue = 'FocusOnAvatar';
      this.isCheckedFocus = false
    } else if (!this.isCheckedFocus) {
      this.functionflagValue = 'FocusOnStory';
      this.isCheckedFocus = true
    }
  }

  handleClosepanleFlag(data) {
    if (data) {
      // this.closePanel()
      // this.snapInIt()
    }
  }

  handleSlectedSnapList(data: any) {
    // this.findSnap = this.filterSnap[0]

    // this.mainMenuFlag = false

    if (this.lastSnapData && isEqual(data, this.lastSnapData)) {
      return; // Exit early if data is unchanged
    }

    // Update the last processed snapshot
    this.lastSnapData = data;
    this.filterSnap = []

    if (data && Object.keys(data).length > 0) { // Check if data is valid
      this.filterSnap = data;
      if (this.filterSnap.length > 0) {
       
        if (this.tab != 'story' && !this.storyid) {
          return
        }
        if (this.tab == 'user-story' && this.storyid) {
          setTimeout(() => {

          }, 100); // Optional delay
        }
      }
    } else {
      console.log('Invalid data:', data); // Log for invalid or empty data
    }
  }





  handleOpenpanleFlag(data) {
    if (data) {
      // this.showSearchIcon = true
    }
  }

  panelswichHandle() {

    if (this.PageType == 'User') {
      this.showUserPanel = true
      this.showAvatarPanel = false
      this.selectedTab = 'user-story'

    }
    if (this.PageType == 'Avatar') {
      this.showUserPanel = false
      this.showAvatarPanel = true
    }

    console.log("this.PageType line 2001",this.PageType)


  }

  handleStorySelectedIncard(data) {
    this.selectedStoryId = ''
    this.selectedStoryId = data
  }

  scrollToId(id: string) {
    if (id != '') this.commonService.scrollToElementById(id);
  }


  handleDomView(data) {
    // setTimeout(() => {
    //   this.showCrd = false
    //   let id = this.filterSnap[0].StoryID;
    //   this.temp_StoryId = id;

    //   if (data ) {
    //     this.temp_StoryId = this.storyid; // Set dynamically
    //     console.log(data, this.temp_StoryId);
    //   }
    // }, 2000); // Delay of 200ms
  }

  handleScrollSide(data) {
    // console.log(data)
    if (data) {
      this.scrollSide = data
    }
  }



  async TestPassValue() {
    if (this.userAvatarData) {

      if (this.saveAvatarData?.dataBlobUrl) {
        this.saveAvatarData.dataBlobUrl = null
        this.saveAvatarData.envUrl = null
      }

      this.avtarUrl = this.userAvatarData.AvatarURL;
      this.garmenturl = await this.copyTextToClipGarment(this.userAvatarData.cUpperwearID);
      this.fullBodyurl = await this.copyTextToClipGarment(this.userAvatarData.cFullBodyID);


      // Assign the first non-undefined value
      // this.UpperWearfinalUrl = (this.garmenturl !== null || this.garmenturl !== null || this.garmenturl !== ''  ) ? this.garmenturl : this.fullBodyurl;
      this.UpperWearfinalUrl = this.garmenturl == null ? this.fullBodyurl : this.garmenturl
      this.garmentbottom = await this.copyTextToClipGarment(this.userAvatarData.cBottomwearID);;
      this.genders = await this.userAvatarData.Gender == 'MALE' ? 'M' : 'F';
      this.headgear = await this.copyTextToClipGarment(this.userAvatarData.cHeadgearID);
      this.footwear = await this.copyTextToClipGarment(this.userAvatarData.cFootwearID);
      this.flagForShowThreeJs = true;
      this.functionflagValue = 'LoadOnlyAvatar_' + this.avtarUrl;
      // this.downloadprocessPackZip(this.avtarUrl)
      await this.zipExtractAndSaveFunction(this.avtarUrl)
      if(this.showAvatarPanel && this.closeDiv){
        this.usrPageAvatarFlag = this.avatarID
      }
    }
    // this.envUrl = envUrl;

  }

  handlePotrateImage(data) {
    if (!this.potraiFlag) {
      this.showAvatarPotraite = data[1].fileurlMini
      this.potraiFlag = true

    }

  }


  handlehomeData(data) {

  }

  handlecloseSearch(data) {
    if (data) {
      this.closePanel()
    }
  }

  handleloadedData(data) {
    this.storyLoader = data
  }

  handleCloseAvatrPanel(data: any) {
    setTimeout(() => {
      let findData

      if (data && data.AvatarID === undefined) {
        findData = this.initData.find((s) => s.AvatarID === data);
        // this.findSnap = this.filterSnap[0]
        data = findData
        this.closePanel();
        this.closecard()
      }

      // if (this.PageType == 'User') {
      //   this.AvatarSwichDetails = this.AvatarSwichDetails || {};
      //   this.AvatarSwichDetails['UserID'] = data.UserID;
      //   this.AvatarSwichDetails['AtarID'] = data.AvatarID;
      //   console.log('AvatarSwichDetails',this.AvatarSwichDetails)
      //   this.getDocumentDetails();
      // }



      let obj = this.filterSnap[0]
      if (obj.AvatarID == (data.AvatarID || data)) {
        return
      }

      this.AvatarSwichDetails = this.AvatarSwichDetails || {};
      this.AvatarSwichDetails['UserID'] = data.UserID;
      this.AvatarSwichDetails['AtarID'] = data.AvatarID;
      this.openInitiateAvatarpanel();
      this.getDocumentDetails();
      this.snapshot = null
      findData = this.initData.find((s) => s.AvatarID == data)
      // this.findSnap = findData
      this.ResetPanel = true
      this.closePanel();
      this.closecard()
    }, 50); // Delay for 0 milliseconds


  }


  profilerCheck() {
    this.isStatsVisible = !this.isStatsVisible
  }

  handleUserDetails_Data(data) {
    if (data) {
      this.UserIamge = data.photoURL
      // this.userId = data.UserID
    }
  }


  handlecloPanel(data) {
    if (data == 'story') {
      // this.findSnap = this.filterSnap[0]
      if (this.showUserPanel) {
        this.functionflagValue = null
        this.intiateuserpanel = true
        // this.PageType == 'User'
        setTimeout(() => {
          this.functionflagValue = 'hideavatar'
          // this.temp_StoryId = this.findSnap.StoryID
        }, 10)
      }
      this.closecard()
      this.closePanel()
    }
  }

  handleuserphotoPanel(data) {
    this.UserIamge = data.ProfilePicture
    // this.userId = data.UserID
  }

  // need to change in singel variable to set the tab
  handlesetTabSelection(data) {
    this.setTabSelection = data
    this.PageTabSelection = this.setTabSelection
  }


  handlesetTabUserSelection(data) {
    this.setTabSelection = data
    this.PageTabSelection = this.setTabSelection
  }

  handlesetTabBrandIdSelection(data) {
    if (this.setTabSelection == 'Brands') {
      this.tempBrandID = data
      this.temptabselection = 'Brands'
    }
  }

  handlesetTabGarmentSelection(data) {
    if (this.setTabSelection == 'Style') {
      this.tempGarmentID = data
      this.temptabselection = 'Style'
    }
  }




  handlestoryselctionFlag(data) {
    // this.storycardhidePnel = data
    this.mainMenuFlag = null
    this.temp_StoryId = this.storyid
    this.child.closePanel()
    this.mainMenuFlag = true
    this.hideStoryCard = false
    this.openSpacePanle = true


  }





  handlenew_even(data) {
    this.defaultEnvironment = data
    this.functionflagValue = null;
    console.log(this.envUrl)
    setTimeout(() => {
      this.functionflagValue = 'skybox';
    }, 10);
  }


  handleAllstory(data) {
    // this.saveAllSnapShots = data
    // this.temp_StoryId = 0
    // setTimeout(() => {
    //   this.temp_StoryId = this.storyid
    // }, 100);
    // console.log("this.saveAllSnapShots", this.saveAllSnapShots[0])
  }

  handleMinyCard(data) {
    this.singleSnap = data
    this.findSnap = this.singleSnap
    this.hideStoryCard = false
    setTimeout(() => { this.hideStoryCard = true }, 0);
  }

  handleSetsearch(data) {
    this.StorySelectBySearchpanel = data
    setTimeout(() => {
      this.search_Temp_Flag = false
    }, 1000);
  }

  openSelectedPanel() {
    setTimeout(() => {
      this.openSpacePanle = true
      if (this.StorySelectBySearchpanel) {
        this.openAvatarPanel('search')
        this.closeDiv = true
        this.wholeDiv = false
        this.StorySelectByAvatarpanel = false
        this.StorySelectByUserpanel = false
      }
      if (this.StorySelectByAvatarpanel) {
        this.openAvatarPanel("avatar")
        this.StorySelectByUserpanel = false
        this.StorySelectBySearchpanel = false
        this.child.closePanel()
      }

      if (this.StorySelectByUserpanel) {
        this.openAvatarPanel('user')
        this.StorySelectBySearchpanel = false
        this.StorySelectByAvatarpanel = false
        this.child.closePanel()
      }
    }, 10);

    setTimeout(() => {
      this.child.closePanel()
    }, 20);

    this.selected_story = false
  }


  handlechangeAvatarButton(data) {
    this.child.handlechangeAvatarButton(data)
    this.threadsPage_avatar_flag = true
    this.showCrd = true
  }

  handleNewAvatrUserData(data) {
    this.child.handleNewAvatrUserData(data)
  }

  handleChangeuser(data) {
    this.showCrd = true
    this.child.handleChangeAvatar(data)
  }

  handleAvatarTab(data) {
    this.child.handleAvatarTab(data)
  }

  handleCross_BrandTag(data) {
    this.showCrd = true
    this.child.handleCross_BrandTag(data)
    this.showBrand_avatar_Slider = data
    this.tempBrandID = null
    this.selectedBrand_AvatarPanel = null
  }

  handleCross_GarmentTag(data) {
    this.showCrd = true
    this.showCloth_avatar_Slider = true
    this.child.handleCross_GarmentTag(data)
    this.tempGarmentID = null
    this.selectedCloth_AvatarPanel = null
  }

  handleSelectedBrand(data) {
    this.selectedBrand_AvatarPanel = null
    this.intiateavatarpanel = true
    this.selectedBrand_AvatarPanel = data
    setTimeout(() => {
      this.childavatar_Panel.selectBrand(data)
      // this.SetBrandId = null
    }, 500);
    // this.handlesetTabBrandIdSelection(data.id)
  }

  handleSelectedGarment(data) {
    this.intiateavatarpanel = true
    this.showCloth_avatar_Slider = false
    this.selectedCloth_AvatarPanel = data
    setTimeout(() => {
      this.childavatar_Panel.selectCacheImage(data)
      // this.SetGarmentId = null;
    }, 500);
  }

  handleGarmentSlider(data) {
    this.showCloth_avatar_Slider = data

  }

  handleBrandSlider(data) {
    this.showBrand_avatar_Slider = data
  }

  handle_FornewBrand_closeSider_and_show_AllBrand(data) {
    // this.SetBrandId = null
  }






  // #region UserPanel_handleSlider

  handle_cross_UserPanel_Avatar(data) {
    this.showCrd = true
    console.log("user Avatar", data)
    this.child.handle_userAvatarTag_cross(data)
    this.showAvatar_Useravatar_Slider = true;
    this.selectedAvatar_UserPanel = null
  }

  handle_cross_UserPanel_Brand(data) {
    console.log("user Brand", data)
    this.showCrd = true
    this.child.handle_userBrandTag_cross(data)
    this.showBrand_Useravatar_Slider = true;
    this.selectedBrand_UserPanel = null
  }

  handle_cross_UserPanel_Cloth(data) {
    this.showCrd = true
    this.child.handle_userStyleTag_cross(data)
    this.showCloth_Useravatar_Slider = true;
    this.selectedCloth_UserPanel = null
    console.log("user cloh", data)
  }

  handle_UserTab(data) {
    // this.showCrd = true
    console.log("handle_UserTab", data)
    this.child.handle_UserTab(data)
  }

  handle_userData(data) {
    this.child.handle_userData(data)
    console.log("handle_userData", data)
  }

  changeUser_Avatar(data) {
    this.selectedAvatar_UserPanel = data
    this.showAvatar_Useravatar_Slider = false;
  }

  changeUser_Brand(data) {
    this.selectedBrand_UserPanel = data
    this.showBrand_Useravatar_Slider = false;
    console.log("this.selectedBrand_UserPanel", this.selectedBrand_UserPanel);
  }

  changeUser_Cloth(data) {
    this.selectedCloth_UserPanel = data
    this.showCloth_Useravatar_Slider = false;
  }

  closeAvatarSlider(event: boolean) {
    console.log("Avatar slider closed:", event);
    this.showAvatar_Useravatar_Slider = false;
  }

  closeBrandSlider(event: boolean) {
    console.log("Brand slider closed:", event);
    this.showBrand_Useravatar_Slider = false;
  }

  closeClothSlider(event: boolean) {
    console.log("Cloth slider closed:", event);
    this.showCloth_Useravatar_Slider = false;
  }


  userAavatarChange(data) {
    console.log("slider avatar :", data);
    this.tempChangeAvatarFlag = data;
  }

  // #endregion UserPanel_handleSlider

  handleStoryCard_loading(data) {
    if (!this.firstTimeStory_flag) {
      // this.temp_StoryId = '52s2s2'
      this.temp_StoryId_new = null;
      setTimeout(() => {
        this.temp_StoryId_new = this.storyid;
        this.show_User_Story = data;
        console.log("this.show_User_Story", this.show_User_Story);
        this.firstTimeStory_flag = true;
        if (this.filterSnap?.length > 0) {
          console.log("SnapshotFileUrl", this.filterSnap[0].SnapshotFileUrl);
        } else {
          console.warn("filterSnap is undefined or empty");
        }

      }, 1300); // 3 seconds delay

      console.log("SnapshotFileUrl", this.filterSnap);
      console.log("show_User_Story", this.show_User_Story);
      console.log("temp_StoryId_new", this.temp_StoryId_new);
    }
  }


  handleUserSnapStories(stories: any[]) {
    console.log('Stories for tab',this.setTabSelection, stories);
    this.savedStoriesMap[this.setTabSelection] = stories;
    console.log('this.savedStoriesMap', this.savedStoriesMap);
  }

}
