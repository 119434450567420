<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<app-public *ngIf="!logInFlag"></app-public>
<div>
    <button class="btn btn-primary" style=" z-index: 100000; position: fixed; top: 40px; right: 0;" (click)="toggle()">
        <i class="bi bi-arrow-right-circle" *ngIf="isShowStats"></i>
        <i class="bi bi-arrow-left-circle" *ngIf="!isShowStats"></i>
    </button>

    <div class="fixed-container">
        <div (click)="closeButtonForBrand()" (scroll)="onScroll()" #brandStoriyScroll class="div-margin roksan box1"
            [ngClass]="this.changeTabFlag ? 'blur-side' : ''">
            <div class="brand-profile-color" *ngIf="portpfolioName == undefined">
                <div #brandStoriyScrollA [ngClass]="scrollFlag ? 'd-none' : ''" class="slider-css brand-profile-color"
                    (scroll)="selectByScroll()">
                    <div class="" *ngFor="let brand of filterBrandList"
                        [ngClass]="byScroll != true && !portfolioFLAGnew  ? 'prt-Evt' :''">
                        <div *ngIf="selectedBrand == brand ">
                            <div
                                [ngClass]="selectedBrand == brand ? 'shadow-brand-slide' : (selectedBrand != undefined && !byScroll ? 'd-none' :'')">
                                <div class="text-end me-1 mt-1" style="pointer-events: auto;">
                                    <div>
                                        <img (click)="clickOnHeart()" *ngIf="brandheartFlag" class="heart"
                                            style="width:18px !important; color:#000000"
                                            src="./assets/storystyle/newStoryIcon/heart.png">
                                    </div>
                                    <div><img (click)="clickOnHeart()" *ngIf="!brandheartFlag" class="heart"
                                            style="width:18px !important; color:black"
                                            src="./assets/storystyle/newStoryIcon/heartGray.png">
                                    </div>
                                    <div class="second-share"><img (click)="shareBrand(brand.id)"
                                            style="width:20px !important;" src="./assets/share_copy.png"></div>
                                </div>
                            </div>
                        </div>

                        <img *ngIf="this.exclosiveMode == 'N' || this.exclosiveMode == null " [id]="brand.id"
                            class="m-1 " style="height:60px !important; width:80px !important; background: white;"
                            [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                            (click)="selectBrandForStory(brand.id,brand)" [src]="brand.Brandurl" />

                        <img *ngIf="this.exclosiveMode == 'Y'" [id]="brand.id" class="m-1"
                            style="height:60px !important; width:80px !important; background: white;"
                            [ngClass]="selectedBrand == brand ? '' : 'd-none'"
                            (click)="selectBrandForStory(brand.id,brand)" [src]="brand.Brandurl" />

                    </div>

                    <div *ngIf="loading" class="loading">Loading&#8230;</div>
                </div>

                <div [ngClass]="!scrollFlag ? 'd-none' : ''" class="slider-css brand-profile-color">
                    <div class="" *ngFor="let brand of filterBrandListNew" [ngClass]="byScroll != true ? 'prt-Evt' :''">
                        <img [id]="brand.id" class="m-1"
                            style="height:60px !important; width:80px !important; background: white;"
                            [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                            (click)="selectBrandBySearch(brand.id,brand)" [src]="brand.Brandurl" />
                    </div>
                </div>



                <div class="input-wrapper">
                    <input [disabled]="exclosiveMode == 'Y'" id="BrandName" type="text"
                        class="form-control custom-input no-rounded" (input)="suggest($event)"
                        (click)="blankplaceHolder()">
                    <label for="BrandName" class="placeholder-start">{{brandNameSelected}}</label>
                    <label for="BrandName" class="placeholder-end">


                        <h6 class="color-class-board m-2 "
                            *ngIf="boardDetails != undefined  && boardDetails.name != undefined">
                            <img style="width:12px !important" src="./assets/pinterest-512.png">:{{boardDetails.name}}
                        </h6>
                        <h6 class="color-class-board m-2"
                            *ngIf="boardDetails == undefined || boardDetails.name == undefined">
                            <img style="width:12px !important" src="./assets/pinterest-512.png">:Not Linked
                        </h6>


                    </label>
                </div>
            </div>

            <div class="brand-profile-color" *ngIf="portpfolioName != undefined">
                <div #brandSliderPortFolio [ngClass]="scrollFlag ? 'd-none' : ''" class="slider-css brand-profile-color"
                    (scroll)="selectByScroll()">
                    <div (click)="portfolioBrandSelection()" class="" *ngFor="let brand of filterBrandList"
                        [ngClass]="byScroll != true  ? 'prt-Evt' :''">
                        <div *ngIf="selectedBrand == brand ">
                            <div
                                [ngClass]="selectedBrand == brand ? 'shadow-brand-slide' : (selectedBrand != undefined && !byScroll ? 'd-none' :'')">
                                <div class="text-end me-1 mt-1" style="pointer-events: auto;">
                                    <div>
                                        <img (click)="clickOnHeart()" *ngIf="brandheartFlag" class="heart"
                                            style="width:18px !important; color:#000000"
                                            src="./assets/storystyle/newStoryIcon/heart.png">
                                    </div>
                                    <div><img (click)="clickOnHeart()" *ngIf="!brandheartFlag" class="heart"
                                            style="width:18px !important; color:black"
                                            src="./assets/storystyle/newStoryIcon/heartGray.png">
                                    </div>
                                    <div class="second-share"><img (click)="shareBrand(brand.id)"
                                            style="width:20px !important;" src="./assets/share_copy.png"></div>
                                </div>
                            </div>
                        </div>
                        <img *ngIf="this.exclosiveMode == 'N' || this.exclosiveMode == null " [id]="brand.id"
                            class="m-1" style="height:60px !important; width:80px !important;"
                            [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                            (click)="selectBrandForStory(brand.id,brand)" [src]="brand.Brandurl" />

                        <img *ngIf="this.exclosiveMode == 'Y'" [id]="brand.id" class="m-1"
                            style="height:60px !important; width:80px !important;"
                            [ngClass]="selectedBrand == brand ? '' : 'd-none'"
                            (click)="selectBrandForStory(brand.id,brand)" [src]="brand.Brandurl" />
                    </div>
                    <div *ngIf="loading" class="loading">Loading&#8230;</div>
                </div>
                <div [ngClass]="!scrollFlag ? 'd-none' : ''" class="slider-css brand-profile-color">
                    <div class="" *ngFor="let brand of filterBrandListNew" [ngClass]="byScroll != true ? 'prt-Evt' :''">
                        <img [id]="brand.id" class="m-1" style="height:60px !important; width:80px !important;"
                            [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                            (click)="selectBrandBySearch(brand.id,brand)" [src]="getBrandImage(brand.LogoURL)" />
                    </div>
                </div>
                <div class="input-wrapper">
                    <input [disabled]="exclosiveMode == 'Y'" id="BrandName" type="text"
                        class="form-control custom-input no-rounded" (input)="suggest($event)"
                        (click)="blankplaceHolder()">
                    <label for="BrandName" class="placeholder-start">{{brandNameSelected}}</label>
                    <label for="BrandName" class="placeholder-end">
                        <h6 class="color-class-board m-2 "
                            *ngIf="boardDetails != undefined  && boardDetails.name != undefined">
                            <img style="width:12px !important" src="./assets/pinterest-512.png">:{{boardDetails.name}}
                        </h6>
                        <h6 class="color-class-board m-2"
                            *ngIf="boardDetails == undefined || boardDetails.name == undefined">
                            <img style="width:12px !important" src="./assets/pinterest-512.png">:Not Linked
                        </h6>
                    </label>
                </div>
            </div>
        </div>
        <!-- <button class="btn btn-primary"(click)="clearThreadsPage()">clear</button> -->

    </div>





    <div class="scrollable-container" *ngIf="clearTab">
        <app-threads-child id="target" [tab]="selectedTab" [onChangeFlag]="onChangeFlag"
            (outnewStoryIndeX)="newindex($event)" (changeSelectedTab)="changeTab($event)"
            [brandsIDdetailsAll]="brandsIDdetailsAll" [mode]="mode" (changeSelctedCloth)="changeCloth($event)"
            [selectBrandGender]="genderID || SelectedGender" [selectedBrand]="selectedBrand"
            [brandId]="brandsIDdetailsAll" [pageNumber]="pageNumber" [clickEventFlag]="clickEventFlag"
            [stylePageFlag]="rootFlag" [stylePage]="stylePage" [routLogInFlag]="routLogInFlag"
            [isShowBrandBand]="isShowBrandBand" (changeSelctedState)="changeLoadingState($event)"
            (receivePackData)="receveidPackdata($event)" (receivePackZip)="receveidZipList($event)"
            (changeCloseBanner)="changeClose($event)" [BrandSelection]="brandSelectionFlag" [byScroll]="byScroll"
            [pageTrueFlage]="pageTrueFlage" [portFolioFlag]="portFolioFlag" [showBrandFlag]="showBrandFlag"
            [setTabFlag]="modeInsatnceFlageVlaue" [StoryId]="this.storyId" [index]="index" [pageiIndex]="pageiIndex"
            [pageNumberByDrips]=" this.pageNumberByDrips" [garmentId]="newGarmentId"
            (receiveAvatarPanelDetails)="receveidPanelDetails($event)" [compressUrl]="compressUrl"
            (receiveAvatarbrandPanel)="receveidPanelbrand($event)" (receveidPanelEven)="receveidPanelEven($event)"
            (receveidPanelUser)="receveidPanelUser($event)" (receveidPanelGarment)="receveidPanelGarment($event)"
            [isShowStats]="isShowStats" (opentheSearchPanel)="handleSearchPanlOnThreads($event)"
            [selectedSnapData]="selectedSnapData" (receveidSearchData)="receveidSearchData($event)" [panel]="panelFlag"
            [Drape]="Drape" [searchFilterData]="nonsSelectedBrand" [StoryId]="selectedStory"
            [showBrandAvatarslider]="showBrandAvatarslider" [showBrandslider]="showBrandslider"
            [selectSpaceTab]="selectSpaceTab" (sameSliderBrandEmitData)="handleBrandPanel($event)"
            [change_btn]="change_btn"

            [user_change_btn]="user_change_btn"
             [userData_avatar]="userData_avatar"
            (showAvatarMinSlider_emitData)="handleChangeAvatarButton($event)"
            (userAvatarData_emitData)="handleUserAvatarData($event)"
            (showUserSlider_emitData)="handleUserSlider($event)"


            (showUserSlider_emit_AllData)="getUSerData($event)"
            >
        </app-threads-child>
    </div>

</div>

<div class="drips-panel" *ngIf="avatarflagPanel">
    <div *ngIf="this.dripsPageAvatarData">
        <app-avatar-panel (closeAvatarEmitter)="closeAvatarPalen($event)" [userData]="dripsPageAvatarData"
            [closebuttonFlag]="closebuttonFlag" (emitOpneChangeAvatar)="handlechangeAvatarButton($event)"

            [AvatarID]="AvatarID"
            
            (newEmitUserData)="handleNewAvatrUserData($event)" 
            [SetAvatar_page]="SetAvatar_page"

            (show_brandslider_avatarEmit)="handleCross_BrandTag($event)"
            (show_clothlider_avatarEmit)="handleCross_GarmentTag($event)"
            (avatrTabselection)="handleAvatarTab($event)"

            [showCloth_avatar_Slider]="showCloth_avatar_Slider"
            [showBrand_avatar_Slider]="showBrand_avatar_Slider"
            [selectedCloth_AvatarPanel]="selectedCloth_AvatarPanel"
            [selectedBrand_AvatarPanel]="selectedBrand_AvatarPanel"
            >
        </app-avatar-panel>
    </div>
</div>


<div class="drips-panel" *ngIf="userflagPanel">
    <div *ngIf="dripsPageUserData">
        <app-user-panel (closeAvatarEmitter)="closeUserPanel($event)" [userData]="dripsPageUserData"
            [closebuttonFlag]="closebuttonFlag"
            (emitOpneChangeAvatar)="handlechangeAvatarButton($event)"
            (change_button)="handleChangeAvatar($event)" 
            [Set_UserPanelData]="Set_UserPanelData"

            (userBrandTag_cross)="handle_userBrandTag_cross($event)"
            (userStyleTag_cross)="handle_userStyleTag_cross($event)"
            (userAvatarTag_cross)="handle_userAvatarTag_cross($event)"

            [showAvatar_Useravatar_Slider]="showAvatar_Useravatar_Slider"
            [showBrand_Useravatar_Slider]="showBrand_Useravatar_Slider"
            [showCloth_Useravatar_Slider]="showCloth_Useravatar_Slider"

            (newEmitUser_Data)="handle_userData($event)"

            [selectedAvatar_UserPanel]="selectedAvatar_UserPanel"
            [selectedBrand_UserPanel]="selectedBrand_UserPanel"
            [selectedCloth_UserPanel]="selectedCloth_UserPanel"

            (setTabSelection)="handle_UserTab($event)"
            [change_avatar_flage]="change_avatar_flage"

            
            >
        </app-user-panel>
    </div>
</div>


<div class="drips-panel" *ngIf="brandflagPanel">
    <div *ngIf="this.dripsPageBrandData">
        <app-brand-panel (closePanelEmitter)="closeBrandPanel($event)" [brandObjectId]="this.dripsPageAvatarData"
            [brandObject]="this.dripsPageBrandData" [closebuttonFlag]="closebuttonFlag">
        </app-brand-panel>
    </div>
</div>

<div class="drips-panel" *ngIf="evenpanelFlag">
    <div>
        <app-environment-panel (closeEvenEmitter)="closeEnvironmentPanel($event)"
            (evnCompressUrl)="handleevnCompressUrl($event)" [evndata]="evndata" [skyBoXall]="evndata.skyBoXall"
            [twoDprivew]="evndata.twoDprivew" [evsname]="evndata.evsname" [evsdiplaynameALL]="evndata.evsdiplaynameALL"
            [environmentFileName]="evndata.environmentFileName" [closebuttonFlag]="closebuttonFlag"
            [hideFlag]="evndata.hideFlag" [brandStoryScrollerHeight]="evndata.brandStoryScrollerHeight"
            [evnProfilePageFlag]="evnProfilePageFlag" [snapEnv]="snapEnv" [selectednewAvatar]="selectednewAvatar"
            [selectedBrand]="selecteSpacedBrand" [brandobject]="selecteSpacedBrand" [spaceName_Page]="spaceName"
            (emitSliderArrow)="handleSlider($event)" (showbrandsliderEmit)="handleShowSlider($event)"
            (emitAvatarNmaeList)="handleShowAvatarSlider($event)"
            (showAvatarsliderEmit_Therds)="handleAvatarTag($event)"
            (showBrandsliderEmit_Therds)="handleBrandTag($event)" [showBrandslider]="showBrandslider"
            [showBrandAvatarslider]="showBrandAvatarslider" (setTabEmit)="handleTabData($event)"
            (emitBrandList)="handleBlistData($event)"
            (emitAvatarNmaeList)="handleShowSAvatarslider($event)"></app-environment-panel>
    </div>
</div>



<div class="drips-panel" *ngIf="garmentflagPanel">
    <div>
        <app-garment-panel (closeGarmentEmitter)="closeGarmentPanel($event)" [brandObject]="garmetData"
            [closebuttonFlag]="closebuttonFlag">
        </app-garment-panel>
    </div>
</div>

<div class="drips-panel" *ngIf="searchPanel" [style.width.px]="sideNavWidth_Fixed"
    [ngClass]="{ 'expand': sideNavWidth_Fixed == 380}">

    <!-- <div>
        <div class="menu-arrow text-end">
            <button   (click)="setSearchNavBar()" class="yologram-btn rounded-pill text-white"></button>
        </div>
    </div> -->

    <div [ngClass]="{ 'd-block': searchNavBarFlag, 'd-none':!searchNavBarFlag}">
        <app-search-tag-panel (emitrightPanelFlag)="handleSideNavSize($event)"
            (closePanelEmitter)="closeSearchPanel($event)" (serchedStory)="handleSerchedStory($event)"
            (selectedSnap)="handleSelectedSnap($event)" [exclosiveMode]="exclosiveMode" [snapData]="singleSnap"
            [snapshots]="tempSnapShot" [selectedBrand]="selectedBrand" [page]="pageName" [nonBrand]="nonsSelectedBrand"
            (snapListEmit)="handlesnapListEmit($event)"
            (selectTheStory)="handlesnapSelectedStory($event)"></app-search-tag-panel>
    </div>

</div>


<!-- <div class="loader-overlay" *ngIf="!garmentflagPanel">
    <div class="loader"></div>
</div>  -->


<!-- this is seprete slider for the spaces -->
<div *ngIf="evenpanelFlag">

    <button class="brand-remove" *ngIf="showBrandslider && this.selectSpaceTab === 'Brands'" (click)="closeSlider()">
        X
    </button>

    <button class="brand-remove" *ngIf="showBrandAvatarslider && this.selectSpaceTab === 'Style'"
        (click)="closeSliderAvatar()">
        X
    </button>

    <!-- Brands Div -->
    <div class="image-slider-brand" *ngIf="showBrandslider && this.selectSpaceTab === 'Brands'">
        <div class="image-slider-inner-brand " *ngFor="let brand of filterbrandLsist">
            <div class="image-wrapper" (click)="cahngeBrand(brand)">
                <img class="image-slide-brand" [src]="getBrandImageByLogo(brand?.LogoURL)" />
                <div class="image-overlay-brand">
                    {{ brand?.BrandName.length > 10 ? (brand.BrandName | slice: 0:10) + '...' : brand?.BrandName }}
                </div>
            </div>
        </div>
    </div>

    <!-- Style Div -->
    <div class="image-slider-brand" *ngIf="showBrandAvatarslider && this.selectSpaceTab === 'Style'">
        <div class="image-slider-inner-brand " *ngFor="let avtr of avatrlist"
            [ngClass]="avtr?.avatarName == undefined ? 'd-none' : ''">
            <div class="image-wrapper" *ngIf="avtr?.details != undefined && avtr?.avatarName != undefined "
                (click)="cahngeAvatar(avtr)">
                <img class="image-slide-brand" *ngIf="avtr?.details" [src]="avtr?.details" />
                <div class="image-overlay-brand" *ngIf="avtr?.details">
                    {{ avtr?.avatarName.length > 10 ? (avtr.avatarName | slice: 0:10) + '...' : avtr?.avatarName }}
                </div>
            </div>
        </div>
    </div>

    <!-- Fallback Content -->

</div>


<div *ngIf="showSpaceSlider && evenpanelFlag" style="position: fixed; top: 130px; left: 190px; z-index: 101;">
    <button id="above-model-button" (click)="cahngeButton()">Change</button>
</div>

<model-viewer *ngIf="showSpaceSlider && evenpanelFlag" id="poster" camera-controls touch-action="pan-y"
    data-js-focus-visible="false" [attr.skybox-image]="newGltf" alt="A 3D model of a sphere reflecting a sunrise"
    [attr.src]="newSkybox">
</model-viewer>

<div class="image-slider" *ngIf="showSpaceSlider && showSpacenNewSlider">
    <div class="image-slider-inner" *ngFor="let even of evnFileList">
        <div class="image-wrapper" *ngIf="even?.displayName != 'default'" (click)="reset(even)">
            <img class="image-slide" [src]="even?.evnCompressUrl" />
            <div class="image-overlay">
                {{ even?.displayName.length > 10 ? (even.displayName | slice:0:10) + '...' : even?.displayName }}
            </div>
        </div>
    </div>
</div>


<div *ngIf="(avatarflagPanel)">

    <button  class="brand-remove"
        *ngIf="showBrand_avatar_Slider && selecteAvatarPanel_Tab === 'Brands'" (click)="closeAvatarBrandSlider()">
        X
    </button>

    <button  class="brand-remove"
        *ngIf="showCloth_avatar_Slider && selecteAvatarPanel_Tab === 'Style'" (click)="closeAvatarClothSlider()">
        X
    </button>
    <!-- Brands Div -->
    <div class="image-slider-brand" *ngIf="showBrand_avatar_Slider && selecteAvatarPanel_Tab === 'Brands'"
        >
        <div class="image-slider-inner-brand " *ngFor="let brand of temp_BrandAvatar_List">
            <div class="image-wrapper" (click)="cahngeAvatrBrand(brand)">
                <img class="image-slide-brand" [src]="brand.Brandurl" />
                <div class="image-overlay-brand">
                    {{ brand?.BrandName.length > 10 ? (brand.BrandName | slice: 0:10) + '...' : brand?.BrandName }}
                </div>
            </div>
        </div>
    </div>

    <!-- Style Div -->
    <div class="image-slider-brand" *ngIf="showCloth_avatar_Slider && selecteAvatarPanel_Tab === 'Style'"
       >
        <div class="image-slider-inner-brand " *ngFor="let chache of temp_CacheImageAvatar_List">
            <div class="image-wrapper" *ngIf="chache"
                (click)="cahngeAvatarCloth(chache)">
                <div class="image-set">
                    <img class="image-set-cache"
                        [src]="chache.cacheUrl != undefined || chache.cacheUrl != null ? chache.cacheUrl : cacheReGenrate(chache)"
                        alt="Avatar Image" />
                </div>                <div class="image-overlay-brand" *ngIf="chache">
                    {{ chache?.Name?.length > 10 ? (chache?.Name | slice: 0:10) + '...' : chache?.Name }}
                </div>
            </div>
        </div>
    </div>

    <!-- Fallback Content -->

</div>
<div class="image-slider" *ngIf="showAvatarSlider && this.showAvatarNewSlider">
    <div class="image-slider-inner" *ngFor="let avatar of temp_AvatarList">
        <div class="image-wrapper" >
            <img class="image-slide" (click)="resetAvatarPanel(avatar,$event)"
                [src]="avatar.AvatarPreviewPortraitUrl != undefined || avatar.AvatarPreviewPortraitUrl != null ? avatar.AvatarPreviewPortraitUrl : avatar.AvatarPreviewPortraitUrl" />
            <div class="image-overlay" *ngIf="avatar?.AvatarName">
                {{ avatar.AvatarName.length > 10 ? (avatar.AvatarName | slice:0:10) + '...' : avatar?.AvatarName }}
            </div>
        </div>
    </div>
</div>


<div class="image-slider" *ngIf="showUserSlider && this.showUserSlider_selected"
   >
    <div class="image-slider-inner" *ngFor="let user of userFilter">
        <div class="image-wrapper">
            <img class="image-slide"  (click)="resetUserPanel(user)"
                [src]="user.ProfilePicture  != undefined || user.ProfilePicture  != null ?user.ProfilePicture  : user.ProfilePicture "  (error)="onImageError($event)" />
            <div class="image-overlay" *ngIf=" user.DisplayName">
                {{ user.DisplayName.length > 10 ? ( user.DisplayName | slice:0:10) + '...' :  user.DisplayName }}
            </div>
        </div>
    </div>
</div>


<div *ngIf="userflagPanel" class="slider-class-container">

    <button  class="brand-remove"
        *ngIf="showBrand_Useravatar_Slider && UerTab === 'Brands'" (click)="closeUser_BrandSlider()">
        X
    </button>

    <button class="brand-remove"
        *ngIf="showCloth_Useravatar_Slider && UerTab === 'Style'" (click)="closeUser_ClothSlider()">
        X
    </button>

    <button  class="brand-remove"
        *ngIf="showAvatar_Useravatar_Slider &&  UerTab === 'Story'" (click)="closeUser_AvatarSlider()">
        X
    </button>



    <div class="image-slider-brand" *ngIf="showAvatar_Useravatar_Slider && UerTab === 'Story'">
        <div class="image-slider-inner-brand " *ngFor="let avatar of temp_Avatar_List">
            <div class="image-wrapper" (click)="cacahngeUser_Avatar(avatar)">
                <img class="image-slide-brand" [src]="avatar?.AvatarPreviewPortraitUrl" />
                <div class="image-overlay-brand">
                    {{ avatar?.AvatarName?.length > 10 ? (avatar.AvatarName | slice: 0:10) + '...' : avatar?.AvatarName }}
                </div>
            </div>
        </div>
    </div>




    <!-- Brands Div -->
    <div class="image-slider-brand" *ngIf="showBrand_Useravatar_Slider && UerTab === 'Brands'">
        <div class="image-slider-inner-brand " *ngFor="let brand of temp_BrandAvatar_List">
            <div class="image-wrapper" (click)="cahngeUserBrand(brand,$event)">
                <img class="image-slide-brand" [src]="brand.Brandurl" />
                <div class="image-overlay-brand">
                    {{ brand?.BrandName?.length > 10 ? (brand.BrandName | slice: 0:10) + '...' : brand?.BrandName }}
                </div>
            </div>
        </div>
    </div>

    <!-- Style Div -->
    <div class="image-slider-brand" *ngIf="showCloth_Useravatar_Slider && UerTab === 'Style'" >
        <div class="image-slider-inner-brand " *ngFor="let chache of temp_CacheImageAvatar_List">
            <div class="image-wrapper" *ngIf="chache"
                (click)="cahngeUserCloth(chache)">
                <div class="image-set">
                    <img class="image-set-cache"
                        [src]="chache.cacheUrl != undefined || chache.cacheUrl != null ? chache.cacheUrl : cacheReGenrate(chache)"
                        alt="Avatar Image" />
                </div>                <div class="image-overlay-brand" *ngIf="chache">
                    {{ chache?.Name?.length > 10 ? (chache?.Name | slice: 0:10) + '...' : chache?.Name }}
                </div>
            </div>
        </div>
    </div>


<!-- <div class="image-slider" *ngIf="showAvatarSlider && this.showAvatarNewSlider"
    [ngStyle]="{ 'width.px': windowWidth - 175 }">
    <div class="image-slider-inner" *ngFor="let avatar of temp_AvatarList">
        <div class="image-wrapper" (click)="resetAvatarPanel(avatar,$event)">
            <img class="image-slide"
                [src]="avatar.AvatarPreviewPortraitUrl != undefined || avatar.AvatarPreviewPortraitUrl != null ? avatar.AvatarPreviewPortraitUrl : avatar.AvatarPreviewPortraitUrl" />
            <div class="image-overlay" *ngIf="avatar?.AvatarName">
                {{ avatar.AvatarName.length > 10 ? (avatar.AvatarName | slice:0:10) + '...' : avatar?.AvatarName }}
            </div>
        </div>
    </div>
</div> -->