import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { generateUUID } from 'three/src/math/MathUtils';
import { DatePipe } from '@angular/common';
import { UserPanelDataMapping } from 'src/app/public/avatar-page-redesign/avatar-page-redesign.component';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.css']
})
export class UserPanelComponent {

  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;

  // Emit the data to set each tab object list in PARENT userPanelTabDataObjectMap object map
  // Input object to consider the parent dataset, and show with data availablity


  @Output() emitTabDataObjectList = new EventEmitter<any>();
  @Input() childUserPanelDataMapping: UserPanelDataMapping = {} as UserPanelDataMapping;


  @Output() emitSlectedSnapList = new EventEmitter<any>();



  @Output() showStoryList_emit = new EventEmitter<any>();
  @Output() closeAvatarEmitter = new EventEmitter<any>();
  @Output() avatralist_emit = new EventEmitter<any>();
  @Output() userAvatarTag_cross = new EventEmitter<any>();
  @Output() userBrandTag_cross = new EventEmitter<any>();
  @Output() userStyleTag_cross = new EventEmitter<any>();
  @Output() emitOpneChangeUser = new EventEmitter<any>();
  @Output() newEmitUser_Data = new EventEmitter<any>();
  @Output() SelectedStoryID = new EventEmitter<any>();
  @Output() emitSlectedUSER_And_Avatar = new EventEmitter<any>();
  @Output() sendStoryId = new EventEmitter<any>();
  @Output() UserDetails_Data = new EventEmitter<any>();
  @Output() pageid = new EventEmitter<any>();
  @Output() userDetailsObjectemit = new EventEmitter<any>();
  @Output() setTabSelection = new EventEmitter<any>();
  @Output() setTabSelectionBrandId = new EventEmitter<any>();
  @Output() setTabSelectionGarment = new EventEmitter<any>();
  @Output() change_button = new EventEmitter<any>();


  private isFetchingGarment: boolean = true;


  currentBrandIndex = 12;
  currentGarmentIndex = 12;
  lastBrandID: any;
  lastUserID: any;
  lastcacheObjecID: any
  temp_cacheObject

  flagbrandNextBatch = false
  flagbrandPrevBatch = false

  flagGarmentdPrevBatch = false
  flagGarmentNextBatch = false

  saveBrandCacheStories_temp: any = []
  saveGarmentCacheStories_temp: any = []

  nextGarmentStories: any = []
  prevGarmentStories: any = []

  garmentCache_story


  @Input() UserShapShotObject: any = {};
  @Input() AvatarID: string;
  @Input() scrollSide: any;
  @Input() Set_UserPanelData: any;
  @Input() avatarPageNumber: number = 1;
  @Input() brandPageNumber: number = 1;
  @Input() garmentPageNumber: number = 1;
  currentAvatarIndex = 12;
  nextAvatarStories: any = [];
  snapshotsAvatarFilter: any = [];
  @Input() showAvatar_Useravatar_Slider: boolean;
  @Input() showBrand_Useravatar_Slider: boolean;
  @Input() showCloth_Useravatar_Slider: boolean;
  @Input() change_avatar_flage: boolean;
  @Input() selectedAvatar_UserPanel: any;
  @Input() selectedBrand_UserPanel: any;
  @Input() selectedCloth_UserPanel: any;
  First_snapshotsBrandFilter: any = [];
  @Input() changeTab: any;
  @Input() Index: number;
  @Input() myProfile: boolean;
  @Input() newIndex: boolean;
  @Input() callDripUserPanel: boolean;
  @Input() callAvatrUserPanel: boolean;
  @Input() UserID: any;
  @Input() userData: any;
  @Input() rootFlag: string;
  @Input() userProfile: string;
  @Input() brandStoryScrollerHeight: any;
  @Input() closebuttonFlag: boolean;
  @Input() selectedUserName: any;
  @Input() brandObject: any;
  @Input() usrPageAvatarFlag: any;
  @Input() UserData: any;
  @Input() TabSelection: any;
  @Input() SetBrandId: any;
  @Input() SetGarmentId: any;
  @Input() SetAvatarId: any;
  @Input() updatdeIndex: any;

  showDetailes = false;
  @Input() show_UserBrand_user_Slider: boolean | undefined;
  @Input() show_UserCloth_user_Slider: boolean | undefined;
  @Input() show_UserAvatar_user_Slider: boolean | undefined;

  showChild: boolean = true;
  snapInitCall: boolean;
  dropdownOpen: boolean;
  showStoryList: boolean = true;
  loadedPagesBackWord = {};
  currentIndex = 0;
  nextBrandStories: any = [];
  previousBrandPageStories: any = [];
  save_first_BrandNavigate_Data: any = [];
  allUserSnapDetails: any = [];
  save_firstNavigate_Data: any = [];
  snapshotsGarmentFilter: any = [];
  garmentCacheList: any = [];
  AllDetails: any = [];
  userImfo: any = [];
  selectedSanp: any = [];
  snapshotsBrandFilter: any = [];
  allUserDetails: any = [];
  userFiledetails: any = [];
  SnapShotFileDetails: any = [];
  filelist: any = [];
  userDetails: any = [];
  userUploadedFileList: any = [];
  userDetail: any = [];
  userAllDetails: any = [];
  userDetailsObject: any = {};
  avatarFlag = 'avatar';
  userFlag = 'user';

  logInFlag = '';
  filterSnapShotFileDetailsall: any = [];
  userDetailsloading: boolean = false;
  brandsnaploadingSnap: boolean = true;
  selectedTab: any;
  secteTab: any = 'Story';
  pageNumber: number = 1;
  pagecount: number = 1;
  brandList: any;
  cacheObject: any;
  temp_brandObject

  pageLoadingFlag = true;
  pageVersionV2 = true;

  images = [
    { "image": "./assets/user-upload-dummy/new-img-1.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-5.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-6.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-7.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-8.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-9.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-10.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-11.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-12.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-13.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-14.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-15.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-16.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-17.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-18.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-19.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-20.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-21.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-22.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-23.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-24.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-25.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-26.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-27.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-28.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-29.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-30.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-31.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-32.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-33.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-34.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-35.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-36.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-37.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-38.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-39.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-40.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-41.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-42.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-43.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-44.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-45.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-46.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-47.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-48.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-49.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-50.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-51.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-52.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-53.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-54.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-55.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-56.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-57.jpg" },
  ];
  page: number = 1;
  dismiss: boolean = true;
  avatarObject: any;
  newAvatarfilterSnap: any = [];
  newAvatarList: any = [];
  filterSnap: any = [];
  selectedavatarId: any = '';
  avatarMoreFlag: boolean;
  showList: boolean;
  tempSnapList: any = [];
  cachedUserId: any;
  isBrandLoading: boolean;
  uploadFileName: any;
  task!: AngularFireUploadTask;
  percentage!: Observable<number | undefined>;
  loading: boolean;
  delete: boolean;
  previousFlage: boolean;
  callpageIndexFlag: any;
  callpageNumer: number = 0;
  nextPage: boolean;
  pageiIndex: number;
  indexCache: any;
  showpanelView: boolean = true;
  pageFlagRout: string;
  previousPageStories: any = [];
  nextStories: any = [];
  prevGarmentPageStories: null;
  showPrevisousButton: boolean;
  show_StylePrevisousButton = true;

  constructor(
    public router: Router,
    private dataSharingService: DataSharingService,
    private commonService: CommonService,
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore,
    private http: HttpClient,
    private storage: AngularFireStorage,
    public datepipe: DatePipe,
    private activatedRoute: ActivatedRoute
  ) {
    console.log('UserPanelComponent constructor called at line 522');
    this.checkCurrentRoute();

    this.user.subscribe(userdetails => {
      console.log('User details subscription triggered at line 526');
      this.userDetails = userdetails;
      let userId = this.UserShapShotObject?.UserID != undefined ? this.UserShapShotObject?.UserID : (this.userData?.uid || this.UserID);
      // this.userDetailsObject = this.userDetails.find((user) => user.UserID == userId)
    });
  }

  ngOnInit(): void {
    console.log('ngOnInit called at line 534');
    // this need to be remove
    this.filterBrandListAndAvatarList();
    //this.selectTab(this.childUserPanelDataMapping.selectedTab);
  }

  async ngOnChanges(changes: SimpleChanges) {

    console.log("changes >>>>>> ", changes)

    console.log("ngOnChanges", this.childUserPanelDataMapping)

    this.brandStoryScrollerHeight = window.innerHeight - 270;
    console.log(changes['childUserPanelDataMapping'])

    if (this.childUserPanelDataMapping) {

      if (this.childUserPanelDataMapping && this.childUserPanelDataMapping.selectedTab == 'Brands'
        && this.childUserPanelDataMapping.userPanelTabDataObjectMap
        && this.childUserPanelDataMapping.userPanelTabDataObjectMap['Brands']['selectedTagDetails'] != undefined

      ) {
        console.log("Loadng select tag")
        this.selectTab(this.childUserPanelDataMapping.selectedTab);
      } else if (this.childUserPanelDataMapping && this.childUserPanelDataMapping.selectedTab == 'Style'
      ) {
        this.selectTab(this.childUserPanelDataMapping.selectedTab);
      } else if (this.childUserPanelDataMapping && this.childUserPanelDataMapping.selectedTab == 'Story'
      ) {
        this.selectTab(this.childUserPanelDataMapping.selectedTab);
      }
    }




    // if(this.childUserPanelDataMapping.selectedTab == 'Brands'){
    //   if(this.childUserPanelDataMapping.userPanelTabDataObjectMap['Brands']['selectedTagDetails'].object){
    //      let brand = this.childUserPanelDataMapping.userPanelTabDataObjectMap['Brands']['selectedTagDetails'].object
    //      this.selectBrand(brand)
    //   }
    // }

    // if(this.childUserPanelDataMapping.selectedTab == 'Style'){
    //   if(this.childUserPanelDataMapping.userPanelTabDataObjectMap['Style']['selectedTagDetails'].object){
    //      let garment = this.childUserPanelDataMapping.userPanelTabDataObjectMap['Style']['selectedTagDetails'].object
    //      this.selectCacheImage(garment)
    //   }
    // }





    if (this.pageFlagRout == 'drsassip') {

      if (changes['SetGarmentId'] && changes['SetGarmentId'].previousValue !== changes['SetGarmentId'].currentValue) {
        console.log('SetGarmentId changed:', this.SetGarmentId);
      }
      if (changes['SetBrandId'] && changes['SetBrandId'].previousValue !== changes['SetBrandId'].currentValue) {
        console.log('SetBrandId changed:', this.SetBrandId);
      }

      if (changes['myProfile'] && this.myProfile) {
        console.log('myProfile changed at line 541');
        this.delete = true;
      }

      if (changes['changeTab'] && this.changeTab) {
        console.log('changeTab changed at line 546');
        this.TabSelection = null;
        this.selectTab('Story');
      }

      if (changes['brandPageNumber'] && this.brandPageNumber) {
        console.log('brandPageNumber changed at line 552');
      }

      if (changes['callAvatrUserPanel'] && this.callAvatrUserPanel) {
        console.log('callAvatrUserPanel changed at line 556');
        this.showpanelView = false;
        this.UserData = await this.userPanelDataFunction(this.UserID);
        if (this.UserData) {
          this.userFiledetails = this.UserData.userFiles;
          this.userDetailsObject = this.UserData.userDetails;
          this.selectedUserName = this.UserData.userDetails.DisplayName;
          this.pagecount = this.UserData.snapshotsData.count;
          this.brandsnaploadingSnap = false;

          if (this.Index && !this.garmentPageNumber && !this.brandPageNumber) {
            this.fetchSnapshotByIndex(this.Index);
          }


          // if(this.garmentPageNumber && this.brandPageNumber){
          //   if(this.secteTab == 'Style'){
          //     this.selectTab('Style')
          //   }
          // }

          this.emitSlectedSnapList.emit(this.allUserSnapDetails);
          console.log('emitSlectedSnapList callAvatrUserPanel 1', this.allUserSnapDetails);
          this.showStoryList = true
          this.showpanelView = true;
          this.selectTab(this.TabSelection);
        }
      }

      if (changes['callDripUserPanel'] && this.callDripUserPanel) {
        console.log('callDripUserPanel changed at line 576');
        this.UserData = await this.userPanelDataFunction(this.UserID);
        if (this.UserData) {
          this.userFiledetails = this.UserData.userFiles;
          this.userDetailsObject = this.UserData.userDetails;
          this.selectedUserName = this.UserData.userDetails.DisplayName;
          this.pagecount = this.UserData.snapshotsData.count;
          this.brandsnaploadingSnap = false;

          if (this.Index && !this.garmentPageNumber && !this.brandPageNumber) {
            this.fetchSnapshotByIndex(this.Index);
          }

          this.emitSlectedSnapList.emit(this.allUserSnapDetails);
          console.log('emitSlectedSnapList callDripUserPanel 2', this.allUserSnapDetails);

          this.selectTab(this.TabSelection);
        }
      }

      if (changes['UserID'] && this.UserID) {
        console.log('UserID changed at line 595');
        this.loadUserData();
        if (changes['TabSelection'] && this.TabSelection) {
          this.selectTab(this.TabSelection);
        }
        console.log('UserID 1', this.UserID);
      }

      if (changes['usrPageAvatarFlag'] && this.usrPageAvatarFlag) {
        console.log('usrPageAvatarFlag changed at line 603');
        if (this.usrPageAvatarFlag) {
          this.SetAvatarId = this.usrPageAvatarFlag;
        }
      }


      if (changes['userData'] && this.userData) {
        console.log('userData changed at line 611');
        this.UserDetails_Data.emit(this.userData);

        if (this.userData.userWholeObject) {
          this.UserData = this.userData.userWholeObject;
          if (this.UserData.userDetails) {
            this.selectedUserName = this.UserData.userDetails.DisplayName;
          } else {
            console.warn('userDetails is undefined in UserData at line 618');
            this.selectedUserName = '';
          }
          this.loadUserData();
        } else {
          console.warn('userWholeObject is undefined in userData at line 623');
        }
      }

      if (changes['Index'] && this.Index) {
        console.log('Index changed at line 628');
        let numb = this.Index;
      }

      if (changes['updatdeIndex'] && this.updatdeIndex) {
        console.log('updatdeIndex changed at line 633');
        let numb = this.updatdeIndex;
        this.loadStoryByScroll(numb);
      }

      if (this.scrollSide) {
        console.log('scrollSide changed at line 638');
        let numb = this.scrollSide;
      }

      if (changes['Set_UserPanelData'] && this.Set_UserPanelData && changes['Set_UserPanelData'].previousValue !== changes['Set_UserPanelData'].currentValue) {
        console.log('Set_UserPanelData changed at line 643');
        this.getUserSnapShotDashboard('story', this.Set_UserPanelData, '');
      }

      // 
      if (changes['showAvatar_Useravatar_Slider'] && changes['showAvatar_Useravatar_Slider'].previousValue !== changes['showAvatar_Useravatar_Slider'].currentValue) {
        if (!this.showAvatar_Useravatar_Slider && !this.selectedAvatar_UserPanel && this.secteTab == 'Story') {
          if (this.UserData && this.UserData.snapshotsData) {
            this.allUserSnapDetails = this.UserData.snapshotsData.object;
          } else {
            console.warn("UserData or snapshotsData is undefined");
          }
        }

      }

      if (changes['showBrand_Useravatar_Slider'] && changes['showBrand_Useravatar_Slider'].previousValue !== changes['showBrand_Useravatar_Slider'].currentValue) {
        this.change_avatar_flage = false
        if (!this.showBrand_Useravatar_Slider && !this.selectedBrand_UserPanel && this.secteTab == 'Brands') {
          this.allUserSnapDetails = this.UserData.snapshotsData.object;
          this.showStoryList = true;
        }

      }

      if (changes['showCloth_Useravatar_Slider'] && changes['showCloth_Useravatar_Slider'].previousValue !== changes['showCloth_Useravatar_Slider'].currentValue) {
        console.log('showCloth_Useravatar_Slider changed at line 663');
        this.change_avatar_flage = false

        if (!this.showCloth_Useravatar_Slider && !this.selectedCloth_UserPanel && this.secteTab == 'Style') {
          //this.UserData.snapshotsData.object.length
          // await this.initUserData()
          // this.allUserSnapDetails = this.UserData.snapshotsData.object;
        }

      }

      if (changes['selectedAvatar_UserPanel'] && JSON.stringify(changes['selectedAvatar_UserPanel'].previousValue) !== JSON.stringify(changes['selectedAvatar_UserPanel'].currentValue)) {
        console.log('selectedAvatar_UserPanel changed at line 670');
        // await this.select_set_Avatar(this.selectedAvatar_UserPanel);
        this.change_avatar_flage = true;
      }

      if (changes['selectedBrand_UserPanel'] && JSON.stringify(changes['selectedBrand_UserPanel'].previousValue) !== JSON.stringify(changes['selectedBrand_UserPanel'].currentValue)) {
        console.log('selectedBrand_UserPanel changed at line 676');
        this.selectBrand(this.selectedBrand_UserPanel);
        // this.flagbrandPrevBatch = false
        // this.flagbrandNextBatch = false
        this.nextStories = null
        this.previousPageStories = null
        this.brandPageNumber = 0
        console.log('this.brandObject line 678', this.brandObject);
      }

      if (changes['selectedCloth_UserPanel'] && JSON.stringify(changes['selectedCloth_UserPanel'].previousValue) !== JSON.stringify(changes['selectedCloth_UserPanel'].currentValue)) {
        console.log('selectedCloth_UserPanel changed at line 683');
        console.log('selectedCloth_UserPanel', this.selectedCloth_UserPanel);
        this.selectCacheImage(this.selectedCloth_UserPanel);
        this.nextGarmentStories = null
        this.prevGarmentPageStories = null
        this.garmentPageNumber = 0
        this.show_StylePrevisousButton = false
      }

      if (changes['change_avatar_flage'] && changes['change_avatar_flage'].previousValue !== changes['change_avatar_flage'].currentValue) {
        console.log('change_avatar_flage changed at line 688');
      }

      if (changes['TabSelection'] && changes['TabSelection'].previousValue !== this.TabSelection) {
        console.log('TabSelection changed at line 692');
        if (this.TabSelection == 'Style') {
          console.log('change_avatar_flage changed at line 688',);

          console.log('Style Tab :-', this.TabSelection);
          console.log('Style Tab SetGarmentId :-', this.SetGarmentId);
          console.log('Style Tab selectedCloth_UserPanel :-', this.selectedCloth_UserPanel);

        }

        if (this.TabSelection == 'Brands') {
          if (this.SetBrandId) {
            const userId = this.UserID || this.userDetailsObject.UserID;
            const response = await this.commonService.getUniqueBrandListByUserId(userId).toPromise();
            this.brandList = response.brands;
            let setbend = this.brandList.find((b) => b.id == this.SetBrandId);
            this.selectedBrand_UserPanel = setbend;
            this.selectBrand(setbend);
            console.log('this.brandObject line 702', this.brandObject);
            const itemsPerPage = 12;
            const pageNumbedr = Math.floor(this.Index / itemsPerPage) + 1;
            const pageNumber = pageNumbedr;
            const startIndex = (pageNumber - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            this.First_snapshotsBrandFilter = this.allUserSnapDetails;
            const filteredStories = this.First_snapshotsBrandFilter.slice(startIndex, endIndex);
            this.allUserSnapDetails = filteredStories;
            console.log('Filtered Stories for Page', pageNumber, filteredStories);
          }
        }
      }
    }
  }

  async initUserData() {
    if (this.UserID && !this.UserData) {
      this.UserData = await this.userPanelDataFunction(this.UserID);
      this.tempSnapList = this.UserData.snapshotsData.object;
    }
  }


  async loadUserData() {
    if (this.SetGarmentId || this.SetBrandId) {
      return
    }
    console.log('loadUserData called at line 719');
    this.initUserData()

    if (this.UserData?.length != 0 && this.UserData) {
      this.userFiledetails = this.UserData.userFiles;
      this.userDetailsObject = this.UserData.userDetails;
      this.pagecount = this.UserData.snapshotsData.count;
      this.brandsnaploadingSnap = false;

      if (this.Index == undefined || this.Index < 12) {
        this.allUserSnapDetails = this.UserData.snapshotsData.object;
      }

      if (this.Index && !this.garmentPageNumber && !this.brandPageNumber) {
        this.fetchSnapshotByIndex(this.Index);
      }

      console.log('Index :-', this.Index);

    } else {
      this.UserData = await this.userPanelDataFunction(this.UserID);
      if (this.UserData) {
        this.userFiledetails = this.UserData.userFiles;
        this.userDetailsObject = this.UserData.userDetails;

        if (this.Index == undefined || this.Index < 12) {
          this.allUserSnapDetails = this.UserData.snapshotsData.object;
        }

        this.pagecount = this.UserData.snapshotsData.count;
        this.brandsnaploadingSnap = false;
        if (this.Index && !this.garmentPageNumber && !this.brandPageNumber) {
          this.fetchSnapshotByIndex(this.Index);
        }

        console.log('this.UserData--------------->');
      }
    }

    // this.tempSnapList = this.allUserSnapDetails;


    console.log('this.garmentPageNumber', this.garmentPageNumber);
    console.log('this.brandPageNumber)', this.brandPageNumber);

    if (!this.garmentPageNumber && !this.brandPageNumber) {
      this.emitSlectedSnapList.emit(this.allUserSnapDetails);
      this.showStoryList_emit.emit(this.showStoryList)
      console.log('emitSlectedSnapList loadUserData 3', this.allUserSnapDetails);
    }

  }

  update() {
    console.log('update called at line 760');
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.AllDetails = allUsers.users;
      this.AllDetails.forEach(object => {
        let filterSnapShots = this.SnapShotFileDetails.filter((snapShot) => snapShot.UserID == object.uid);
        this.selectedSanp = filterSnapShots;
        if (filterSnapShots.length > 0) {
          this.userImfo.push(object);
        }
      });

      const userIDsInUserData = this.userDetails.map(user => user.UserID);
      this.userImfo.forEach(object => {
        let details = object;
        let userDetail = details.providerData[0];
        let saveUserDetails = {
          "DisplayName": userDetail.displayName != undefined ? userDetail.displayName : '',
          "Email": userDetail.email != undefined ? userDetail.email : '',
          "FirstName": '',
          "Identifier": '',
          "LastName": '',
          "MiddleName": '',
          "ProfilePicture": userDetail.photoURL != undefined ? userDetail.photoURL : '',
          "UserID": details.uid != undefined ? details.uid : '',
          "PageName": 'userpopup'
        };

        if (!userIDsInUserData.includes(saveUserDetails.UserID)) {
          this.firestore.collection('user-profile', ref =>
            ref.where('UserID', '==', saveUserDetails.UserID))
            .get()
            .subscribe(querySnapshot => {
              if (querySnapshot.empty) {
                this.firestore.collection('user-profile').add(saveUserDetails).then((docRef) => {
                  console.log('User profile added at line 790');
                });
              } else {
                console.log('User profile already exists in Firestore at line 792');
              }
            });
        } else {
          console.log(`User profile for UserID ${saveUserDetails.UserID} already exists in userData, not adding to Firestore at line 795.`);
        }
      });
    });
  }

  userSnapShots() {
    console.log('userSnapShots called at line 800');
    this.closePopUp();
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
    this.dataSharingService.sendUserData(this.userData);
  }

  async getUserSnapShotDashboard(page, snapshot, index) {

    console.log("index index  index index", index)
    console.log('getUserSnapShotDashboard called at line 806');
    let tabFalg = false;
    let count = 12;

    let currentPage = Math.floor(index / count) + 1;
    if (this.secteTab == 'Brands' || this.secteTab == 'Style') {
      tabFalg = true;
    }

    let storyId = snapshot.StoryID != undefined ? snapshot.StoryID : "BlanckStoryID";
    let params;


    console.log("change_avatar_flage", this.change_avatar_flage)

    if (this.change_avatar_flage) {
      params = {
        avatarID: snapshot.AvatarID,
        pagetab: page,
        id: snapshot.UserID || this.UserID,
        storyId: storyId,
        index: (this.secteTab === 'Brands' || this.secteTab === 'Style') ? 1 : index,
        pageNumber: (this.secteTab == 'Brands' || this.secteTab == 'Style') ? 1 : currentPage,
        PageType: 'Avatar'
      };
    } else {
      params = {
        id: snapshot.UserID || this.UserID,
        pagetab: page,
        storyId: storyId,
        index: (this.secteTab === 'Brands' || this.secteTab === 'Style') ? 1 : (this.secteTab === 'Story' ? index : 1),
        pageNumber: (this.secteTab == 'Brands' || this.secteTab == 'Style') ? 1 : currentPage,
        PageType: 'User',
      };
    }

    if (tabFalg) {
      params['TabSelection'] = this.secteTab;
      if (this.secteTab == 'Brands') { params['SetBrandId'] = this.brandObject?.id; }
      if (this.secteTab == 'Story') { params['SetAvatarId'] = this.avatarObject?.AvatarID; }
      if (this.secteTab == 'Style') { params['SetGarmentId'] = this.cacheObject?.id; }
    }

    if (index !== undefined && index !== null && index >= 0) {
      console.log("index index index index", index);
      if (this.secteTab === 'Brands') {
        if (this.pageFlagRout == 'avatar-v2') {
          let newSelectedSnap_BrandIndex = this.snapshotsBrandFilter.findIndex(
            (s) => s.StoryID === snapshot.StoryID);
          let filteredIndex = Math.floor(newSelectedSnap_BrandIndex / count) + 1;
          console.log("Index of the story in snapshotsGarmentFilter:", newSelectedSnap_BrandIndex);
          currentPage = filteredIndex
        }

        console.log("index index index Brands", index);
        params['brandPageNumber'] = currentPage;
        params['pageNumber'] = '';
        params['index'] = '';
      }

      if (this.secteTab === 'Style') {
        if (this.pageFlagRout == 'avatar-v2') {
          let newSelectedSnap_GarmentIndex = this.snapshotsGarmentFilter.findIndex(
            (s) => s.StoryID === snapshot.StoryID
          );
          let filteredIndex = Math.floor(newSelectedSnap_GarmentIndex / count) + 1;
          console.log("Index of the story in snapshotsGarmentFilter:", newSelectedSnap_GarmentIndex);
          currentPage = filteredIndex;
        }
        params['garmentPageNumber'] = currentPage;
        params['pageNumber'] = '';
        params['index'] = '';
      }
    }

    if (this.secteTab === 'Story' && this.change_avatar_flage) {
      params['avatarPageNumber'] = currentPage;
    }

    if (this.secteTab == 'Story' || storyId == 'BlanckStoryID') {
      params['TabSelection'] = this.secteTab;
      params['index'] = currentPage;
    }

    if (this.secteTab === 'Story' && !this.change_avatar_flage) {
      params['index'] = index;
    }

    if (page == 'user-story') {
      this.SelectedStoryID.emit(storyId);
      this.sendStoryId.emit(storyId);
    }

    console.log('params------------>', params);
    console.log('this.change_avatar_flage------------>', this.change_avatar_flage);

    this.pageid.emit(page);

    this.userDetailsObjectemit.emit(this.userDetailsObject);

    if (this.myProfile && storyId) {
      return;
    }

    this.router.navigate(['/avatar-v2', params]);
  }

  getUserSnapShotByAvatarId(page, snap) {
    console.log('getUserSnapShotByAvatarId called at line 860');
    let sToryId = snap.StoryID != undefined ? snap.StoryID : '';
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/user')) {
          window.location.reload();
        }
      }
    });

    this.closePopUp();
    this.router.navigate(['/user', { 'avatarID': snap.AvatarID || this.AvatarID, 'pagetab': page, 'id': (this.userDetailsObject.UserID || this.UserID) }]);
  }

  grtProfile(page) {
    console.log('grtProfile called at line 874');
    this.closePopUp();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/user')) {
          window.location.reload();
        }
      }
    });
    this.router.navigate(['user', { 'id': this.UserID, 'pagetab': page }]);
  }

  selectSummeryView(tab) {
    console.log('selectSummeryView called at line 885');
    this.selectedTab = 'story';
    this.selectTab(tab);
  }

  async selectTab(tab: any) {

    console.log("selectTab allUserSnapDetails", this.allUserSnapDetails, tab)
    if (!this.pageLoadingFlag && event != undefined) {
      //!this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab]['selectedTagDetails'].key.includes('All') ) {
      event.stopPropagation();
    }// prevent bubbling
    this.pageLoadingFlag = false
    console.log('this.pageLoadingFlag', this.pageLoadingFlag);

    this.secteTab = tab;
    if (!this.UserData) {
      await this.initUserData()
      console.log('initUserData');
    }

    this.setTabSelection.emit(tab);

    console.log("Check blank condition", this.childUserPanelDataMapping.userPanelTabDataObjectMap)

    if (this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab] != undefined &&
      !this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab]['selectedTagDetails'].key.includes('All')) {
      // alert("Jai Ho");
      this.allUserSnapDetails = [];
      if (tab == 'Story')
        this.selectedAvatar_UserPanel = this.childUserPanelDataMapping.userPanelTabDataObjectMap['Story']['selectedTagDetails'].object
      if (tab == 'Brands')
        this.selectedBrand_UserPanel = this.childUserPanelDataMapping.userPanelTabDataObjectMap['Brands']['selectedTagDetails'].object
      if (tab == 'Style')
        this.selectedCloth_UserPanel = this.childUserPanelDataMapping.userPanelTabDataObjectMap['Style']['selectedTagDetails'].object
      console.log("allUserSnapDetails after clearing:", this.allUserSnapDetails);
    }

    // this.userPanelDataMapping.userPanelTabDataObjectMap['Style']['selectedTagDetails'] = 
    // {key:data.id , object:data};

    let userChecked = false
    // code for the different user
    if (this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab]?.data?.length > 0) {
      const user = this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab].data[0];
      if (user && user.UserID !== undefined) {
        userChecked = user.UserID === this.UserID
      }
    }



    if (this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab] != undefined &&
      this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab].data != undefined &&
      this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab].data.length > 0 && userChecked) {

      console.log("childUserPanelTabDataObjectMap", this.childUserPanelDataMapping)
      console.log("tab", tab)

      this.allUserSnapDetails = this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab].data;


      this.emitTabDataObjectList.emit({
        tab: tab
      });

    } else if (tab == 'Story') {
      // Call this if ALL avatart
      await this.selectAllAvatars();
      // else -- Get stories of that avartart
      if (this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab] != undefined && this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab].selectedTagDetails.key != 'All Avatar') {
        let avatar = this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab].selectedTagDetails.object
        this.select_set_Avatar(avatar)
      } else {
        console.log("Set empty condition", this.UserData?.snapshotsData?.object)

        this.allUserSnapDetails = this.UserData != undefined ? this.UserData?.snapshotsData?.object : null;
        this.emitTabDataObjectList.emit({
          tab: tab,
          dataList: this.UserData != undefined ? this.UserData?.snapshotsData?.object : this.allUserSnapDetails,
          selectedTagDetails: {
            key: this.selectedAvatar_UserPanel ? this.selectedAvatar_UserPanel.AvatarID : 'All Avatar',
            object: this.selectedAvatar_UserPanel ? this.selectedAvatar_UserPanel : null
          }
        });

      }

      this.showStoryList = true;
      this.showpanelView = true;

      // if (this.selectedAvatar_UserPanel && this.snapshotsAvatarFilter) {
      //   this.allUserSnapDetails = this.snapshotsAvatarFilter
      // }

      this.showStoryList_emit.emit(this.showStoryList)
      this.emitSlectedSnapList.emit(this.allUserSnapDetails);


    } else if (tab == 'Brands') {

      console.log("allUserSnapDetails", this.allUserSnapDetails)
      this.lastUserID = this.UserID;
      this.showStoryList = false;

      // if (this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab] != undefined &&
      //   this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab].data != undefined
      // ) {
      //   console.log("childUserPanelTabDataObjectMap", this.childUserPanelDataMapping)
      //   console.seta("tab", tab)
      //   this.allUserSnapDetails = this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab].data;
      //   return;
      // }

      if (this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab] == undefined
        || (this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab] && this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab].selectedTagDetails.key == 'All Brands')
      ) {


        this.allUserSnapDetails = this.UserData != undefined ? this.UserData?.snapshotsData?.object : null;
        console.log("this.childUserPanelDataMapping", this.childUserPanelDataMapping)


        this.emitTabDataObjectList.emit({
          tab: tab,
          dataList: this.UserData != undefined ? this.UserData?.snapshotsData?.object : null,
          selectedTagDetails: {
            key: 'All Brands',
            object: null
          }
        });


      }


      //-------------------------------- this need to be drip---------------------------------//

      // if (this.lastBrandID === this.temp_brandObject?.id && this.temp_brandObject && this.selectedBrand_UserPanel) {
      //   let type = 'b'; 
      //   const url = await this.commonService.compressImage(this.temp_brandObject.LogoURL, type);
      //   this.selectedBrand_UserPanel.Brandurl = url.downloadUrl
      //   console.log(this.saveBrandCacheStories_temp);


      //   // this.allUserSnapDetails = this.saveBrandCacheStories_temp;

      //   if (this.nextBrandStories && this.nextBrandStories.length > 0 && this.flagbrandNextBatch) {
      //     this.allUserSnapDetails = this.nextBrandStories;
      //   }

      //   if (this.previousBrandPageStories && this.previousBrandPageStories.length > 0 && this.flagbrandPrevBatch) {
      //     this.allUserSnapDetails = this.previousBrandPageStories;
      //   }

      //   this.showStoryList = true;
      //   this.showpanelView = true
      //   this.showStoryList_emit.emit(this.showStoryList)
      //   return
      // }


      if (!this.showBrand_Useravatar_Slider && !this.selectedBrand_UserPanel && this.secteTab == 'Brands') {
        // this.allUserSnapDetails = this.UserData.snapshotsData.object;
        this.showStoryList = true;
      }


      await this.switchBrandTab();
      // this.showStoryList = true;
      this.showpanelView = true;
      this.showStoryList = true


      this.showStoryList_emit.emit(this.showStoryList)

    } else if (tab == 'Style') {



      // if (this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab] == undefined) {
      //   this.emitTabDataObjectList.emit({
      //     tab: tab,
      //     dataList: this.UserData.snapshotsData.object,
      //     selectedTagDetails: {
      //       key: this.selectedCloth_UserPanel ? this.selectedCloth_UserPanel.id : 'All Garment',
      //       object: this.selectedCloth_UserPanel ? this.selectedCloth_UserPanel : null
      //     }
      //   });
      // }


      if (this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab] == undefined
        || (this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab] && this.childUserPanelDataMapping.userPanelTabDataObjectMap[tab].selectedTagDetails.key == 'All Garment')
      ) {


        this.allUserSnapDetails = this.UserData.snapshotsData.object;
        console.log("this.childUserPanelDataMapping", this.childUserPanelDataMapping)


        this.emitTabDataObjectList.emit({
          tab: tab,
          dataList: this.UserData.snapshotsData.object,
          selectedTagDetails: {
            key: 'All Garment',
            object: null
          }
        });
      }


      //if (this.SetGarmentId != undefined && this.UserID ) {
      // if (this.SetGarmentId !== undefined && this.selectedCloth_UserPanel == null && this.UserID && this.isFetchingGarment) {
      //   // this.isFetchingGarment = true; // Set flag
      //   const response = await this.commonService.getUniqueCacheImageList(this.UserID).toPromise();
      //   this.garmentCacheList = response.garments;
      //   let setcloth = this.garmentCacheList.find((b) => b.id === this.SetGarmentId);
      //   console.log('setcloth:-', setcloth, this.SetGarmentId, this.UserID);
      //   this.selectedCloth_UserPanel = setcloth;
      //   console.log('selectedCloth_UserPanel:-', this.selectedCloth_UserPanel);
      //   this.SetGarmentId = null;
      //   this.isFetchingGarment = false; // Reset flag
      //   this.showStoryList = true;
      // }

      this.showStoryList = false;

      // if (this.lastcacheObjecID === this.temp_cacheObject?.id && this.temp_cacheObject && this.selectedCloth_UserPanel) {
      //   // this.allUserSnapDetails = this.saveGarmentCacheStories_temp;

      //   if (this.nextGarmentStories && this.nextGarmentStories.length > 0 && this.flagGarmentNextBatch) {
      //     this.allUserSnapDetails = this.nextGarmentStories;
      //   }

      //   if (this.prevGarmentStories && this.prevGarmentStories.length > 0 && this.flagGarmentdPrevBatch) {
      //     this.allUserSnapDetails = this.previousBrandPageStories;
      //   }
      //   this.showStoryList = true;
      //   this.showpanelView = true
      //   this.showStoryList_emit.emit(this.showStoryList)

      //   return
      // }

      this.showStoryList = false;
      if (!this.showCloth_Useravatar_Slider && !this.selectedCloth_UserPanel && this.secteTab == 'Style') {
        // this.allUserSnapDetails = this.UserData.snapshotsData.object;
        this.showStoryList = true;
      }

      await this.switchStyleTab();
      this.showpanelView = true;
      this.showStoryList = true;
      this.showStoryList_emit.emit(this.showStoryList)
    }

  }

  getHeight(length: number): number {
    console.log('getHeight called at line 950');
    if (length === 2) {
      return 150;
    } else if (length === 3 || length === 4) {
      return 225;
    } else if (length >= 5) {
      return 300;
    }
    return 350;
  }




  async switchStyleTab() {
    console.log('switchStyleTab called at line 960');
    const userId = this.UserID || this.userDetailsObject.UserID;
    this.selectedCloth_UserPanel =
      this.childUserPanelDataMapping?.userPanelTabDataObjectMap?.['Style']?.['selectedTagDetails']?.object || null;

    try {
      const response = await this.commonService.getUniqueCacheImageList(userId).toPromise();
      this.garmentCacheList = response.garments;
      this.cachedUserId = userId;
    } catch (error) {
      console.error('Error fetching brand list:', error);
    } finally {

      console.log('switchStyleTab called at line 960', this.selectedCloth_UserPanel, this.temp_cacheObject?.id, this.SetGarmentId);
      if (this.selectedCloth_UserPanel || this.temp_cacheObject?.id) {
        let setbend = this.garmentCacheList.find((b) => b.id == this.selectedCloth_UserPanel?.id || this.temp_cacheObject?.id);
        this.selectedCloth_UserPanel = setbend;
        await this.selectCacheImage(setbend);
        this.SetGarmentId = null
      }

      console.log("this.selectedCloth_UserPanel", this.selectedCloth_UserPanel)
      this.isBrandLoading = false;
      this.garmentCacheList.forEach(async element => {
        if (element.cacheUrl == null) {
          let image = await this.cacheReGenrate(element);
          element.cacheUrl = image.publicUrl;
        }
      });


    }
  }


  async filterBrandListAndAvatarList() {
    this.UserID
    const Garmentresponse = await this.commonService.getUniqueCacheImageList(this.UserID).toPromise();
    this.garmentCacheList = Garmentresponse.garments;
    const response = await this.commonService.getUniqueBrandListByUserId(this.UserID).toPromise();
    this.brandList = response.brands;
    for (const brand of this.brandList) {
      let type = 'b';
      const url = await this.commonService.compressImage(brand.LogoURL, type);
      brand.Brandurl = url.downloadUrl;
    }
    this.filterAvatarDetails()
    let data = {
      user: this.userDetailsObject,
      UserName: this.selectedUserName,
      AvatarList: this.newAvatarfilterSnap,
      BrandList: this.brandList,
      GarmentCacheList: this.garmentCacheList
    };
    this.newEmitUser_Data.emit(data);
  }

  async switchBrandTab() {
    // This new to remove
    this.selectedBrand_UserPanel =
      this.childUserPanelDataMapping?.userPanelTabDataObjectMap?.['Brands']?.['selectedTagDetails']?.object || null; console.log('switchBrandTab selectedBrand_UserPanel', this.selectedBrand_UserPanel);
    console.log('switchBrandTab called at line 995');
    const userId = this.UserID || this.userDetailsObject.UserID;
    this.isBrandLoading = true;
    try {
      // const response = await this.commonService.getUniqueBrandListByUserId(userId).toPromise();
      // this.brandList = response.brands;
      // for (const brand of this.brandList) {
      //   let type = 'b';
      //   const url = await this.commonService.compressImage(brand.LogoURL, type);
      //   brand.Brandurl = url.downloadUrl;
      // }
      this.cachedUserId = userId;
    } catch (error) {
      console.error('Error fetching brand list:', error);
    } finally {
      if (this.selectedBrand_UserPanel) {
        await this.selectBrand(this.selectedBrand_UserPanel);
        // let setbend = this.brandList.find((b) => b.id == this.selectedBrand_UserPanel?.id);
        // this.selectedBrand_UserPanel = setbend;

        console.log('this.brandObject line 1012', this.brandObject);
      }

    }
    this.isBrandLoading = false;
    // this.showStoryList = true
  }

  getSelectSnapshotFeed(userSnap) {
    console.log('getSelectSnapshotFeed called at line 1025');
    this.closePopUp();
    let avatar = this.AvatarID;
    let userId = userSnap.UserID;
    let storyid = userSnap.StoryID;
    let userSnapShotList = this.filterSnapShotFileDetailsall.filter((snapshot) => snapshot.UserID == userId);
    userSnapShotList.forEach(async (snap: any, index) => {
      let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID);
      snap.profileName = userObjet && userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
      snap.firstName = snap.profileName.split(" ", 1);
      snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
    });

    let storyObject = { 'id': userId, 'storyId': storyid, 'pageNamuber': this.page };
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    if (this.AvatarID != undefined) storyObject['avatarID'] = this.AvatarID;
    this.router.navigate(['public/public-userStory-feed', storyObject]);
  }

  closePopUp() {
    console.log('closePopUp called at line 1045');
    this.activeModal.close();
  }

  calculatePageNumber(index: number, itemsPerPage: number = 12): number {
    console.log('calculatePageNumber called at line 1050');
    const calculatedPageNumber = Math.floor(index / itemsPerPage) + 1;
    return calculatedPageNumber;
  }

  private loadedPagesNumber = new Set<number>();

  fetchSnapshotByIndex(index: number) {
    console.log('fetchSnapshotByIndex called at line 1058');
    this.previousFlage = true;

    const itemsPerPage = 12;
    const pageNumber = this.calculatePageNumber(index, itemsPerPage);

    if (this.loadedPagesNumber.has(pageNumber)) {
      return;
    }

    this.loadedPagesNumber.add(pageNumber);

    this.pageiIndex = pageNumber;
    this.callpageNumer = pageNumber - 1;
    this.pageNumber = pageNumber;

    this.functNavigate(pageNumber);
  }

  loadStoryByScroll(number) {
    console.log('loadStoryByScroll called at line 1077');
    if (number % 5 === 0) {
      this.indexCache = number;
      if (this.indexCache === 5 && this.scrollSide === 'up') {
        this.previousUserbutton();
      } else if (this.indexCache === 5 && this.scrollSide === 'down') {
        this.incrementImgCounter();
      }
    }
  }

  previousUserbutton() {
    console.log('previousUserbutton called at line 1088');
    const count = 12;
    if (this.callpageIndexFlag) {
      this.pageNumber = this.pageiIndex - 1;
      this.callpageIndexFlag = false;
    }

    if (this.callpageNumer > 0) {
      const cllpagecount = this.callpageNumer * count;
      const startIndex = cllpagecount - count;

      if (this.loadedPagesBackWord[cllpagecount]) {
        this.callpageNumer--;
        return;
      }
      if (this.loadedPagesNumber.has(this.callpageNumer)) {
        return;
      }

      const reqInputObject = {
        id: this.UserID || this.userDetailsObject.UserID,
        pageNumber: this.callpageNumer
      };

      const currentIndex = this.updatdeIndex;

      this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
        const newItems = response.object;
        this.nextPage = true;

        this.allUserSnapDetails.unshift(...newItems);
        this.loadedPagesBackWord[cllpagecount] = true;
        this.nextPage = false;

        const adjustedIndex = currentIndex + newItems.length;
        this.setSliderToIndex(adjustedIndex);

        this.emitSlectedSnapList.emit(this.allUserSnapDetails);
        console.log('emitSlectedSnapList Prev 5', this.allUserSnapDetails);

        this.previousPageStories = this.allUserSnapDetails;
        this.callpageNumer--;
      });
    } else {
      console.log('No more pages to load backward.');
    }
  }

  setSliderToIndex(adjustedIndex: any) {
    console.log('setSliderToIndex called at line 1130');

    let snap = this.allUserSnapDetails[adjustedIndex];
    let tab = 'user-story';
  }

  incrementImgCounter() {
    event.stopPropagation(); // prevent bubbling
    console.log('incrementImgCounter called at line 1136');
    this.pageNumber++;
    const reqInputObject = { id: this.UserID, pageNumber: this.pageNumber };
    if (this.loadedPagesNumber.has(this.pageNumber)) {
      return;
    }
    this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(
      async (response: any) => {
        this.pagecount = response.count;

        if (this.pagecount > this.allUserSnapDetails.length) {
          this.allUserSnapDetails.push(...response.object);
          this.nextStories = this.allUserSnapDetails;
        } else {
          console.log('No new snapshots added. Current pagecount:', this.pagecount);
        }
      },
      (error: any) => {
        console.error('Error occurred while fetching snapshots:', error);
      }
    );
    this.tempSnapList = this.allUserSnapDetails;
    this.emitSlectedSnapList.emit(this.allUserSnapDetails);
    console.log('emitSlectedSnapList Incre 5', this.allUserSnapDetails);

  }

  functNavigate(pageNumber) {
    console.log('functNavigate called at line 1161');
    const reqInputObject = { id: this.UserID, pageNumber: pageNumber };
    this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(
      async (response: any) => {
        this.pagecount = response.count;
        this.allUserSnapDetails = response.object;
        this.save_firstNavigate_Data = this.allUserSnapDetails;
        this.showpanelView = true;
        this.emitSlectedSnapList.emit(this.allUserSnapDetails);
        console.log('emitSlectedSnapList functNavigate 6', this.allUserSnapDetails);

        if (this.pagecount > this.allUserSnapDetails.length) {
        } else {
          console.log('No new snapshots added. Current pagecount:', this.pagecount);
        }
      },
      (error: any) => {
        console.error('Error occurred while fetching snapshots:', error);
      }
    );
    this.tempSnapList = this.allUserSnapDetails;
  }

  toggleChild() {
    console.log('toggleChild called at line 1184');
    this.showChild = !this.showChild;
    this.closeAvatarEmitter.emit(this.showChild);
  }

  async userPanelDataFunction(userId) {
    console.log('userPanelDataFunction called at line 1190');
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getUserPanelDetails";
    return this.http.post<any>(functionUrl, { userId: userId }).toPromise();
  }

  async filterAvatarDetails() {
    // this.showStoryList = false
    console.log('filterAvatarDetails called at line 1196');
    let reqInputObject = { userID: this.UserID || this.userDetailsObject.UserID };
    await this.commonService.yologramPublicByAvatar(reqInputObject).subscribe(async (response: any) => {
      this.newAvatarfilterSnap = response.object;
      this.newAvatarList = this.newAvatarfilterSnap;
      await Promise.all(
        this.newAvatarList.map(async (entry) => {
          const avatarName = await this.getAvatarData(entry.UserID, entry.AvatarID);
          if (avatarName) {
            entry.AvatarName = avatarName.AvatarName;
            entry.AvatarPreviewPortraitUrl = avatarName.AvatarPreviewPortraitUrl;
          }
        })
      );

      if (this.SetAvatarId) {
        let avatarStory = this.newAvatarList.find((a) => a.AvatarID == this.SetAvatarId);
        this.selectAvatarId(avatarStory);
      }

      let data = {
        user: this.userDetailsObject,
        UserName: this.selectedUserName,
        AvatarList: this.newAvatarfilterSnap,
        BrandList: this.brandList,
        GarmentCacheList: this.garmentCacheList
      };

      this.newEmitUser_Data.emit(data);
      this.avatralist_emit.emit(this.newAvatarList);
      // this.showStoryList = true
    });
  }

  toggleDropdown() {
    console.log('toggleDropdown called at line 1227');
    this.dropdownOpen = !this.dropdownOpen;
  }

  closeDropdown() {
    console.log('closeDropdown called at line 1232');
    this.dropdownOpen = false;
  }

  async getAvatarData(id, avatar) {
    console.log('getAvatarData called at line 1237');
    try {
      const documents = await this.commonService.getDocumentsFromSubcollection(id, avatar);
      if (documents && documents.length > 0) {
        const firstDocument = documents[0];
        return firstDocument;
      }
    } catch (error) {
      console.error('Error getting avatar data:', error);
      return null;
    }
  }

  selectAvatarId(id) {
    console.log('selectAvatarId called at line 1249');
    this.avatarMoreFlag = true;
    this.avatarObject = id;
    this.dropdownOpen = false;
    this.selectedavatarId = id.AvatarID;
    let reqInputObject = { id: this.selectedavatarId, pageNumber: 1 };
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
      this.filterSnap = response.object;
      this.allUserSnapDetails = this.filterSnap;
    });
  }

  // async selectBrand(brand) {
  //   console.log('selectBrand called at line 1261');
  //   this.showStoryList = false;
  //   console.log('brand Selection line 1263 :-', brand);
  //   this.brandObject = brand;
  //   this.temp_brandObject = brand
  //   this.lastBrandID = this.temp_brandObject?.id;
  //   this.isBrandLoading = true;
  //   await this.commonService.getSnapshotsByUserAndBrand(this.UserID || this.userDetailsObject.UserID, this.brandObject.id).subscribe({
  //     next: (response) => {
  //       this.brandPageNumber = this.brandPageNumber || 1;
  //       this.snapshotsBrandFilter = response.snapshots;
  //       const startIndex = (this.brandPageNumber - 1) * this.currentBrandIndex;
  //       const endIndex = startIndex + this.currentBrandIndex;

  //       if (Array.isArray(this.snapshotsBrandFilter) && this.snapshotsBrandFilter.length > 0) {
  //         this.allUserSnapDetails = this.snapshotsBrandFilter.slice(startIndex, endIndex);
  //       } else {
  //         console.warn('snapshotsBrandFilter is empty or not an array');
  //       }

  //       this.showStoryList = true;
  //       this.dropdownOpen = false;
  //     },
  //     error: (error) => {
  //       console.error('Error fetching snapshots:', error);
  //     },
  //     complete: () => {
  //       this.isBrandLoading = false;
  //       this.emitSlectedSnapList.emit(this.snapshotsBrandFilter);
  //     }
  //   });
  // }
  async selectBrand(brand) {
    console.log('selectBrand called at line 1261');
    console.log('brand Selection line 1263 :-', brand);
    let type = 'b';
    const url = await this.commonService.compressImage(brand.LogoURL, type);
    brand.Brandurl = url.downloadUrl;
    this.brandObject = brand;
    this.temp_brandObject = brand;
    this.lastBrandID = this.temp_brandObject?.id;
    this.isBrandLoading = true;
    this.showStoryList = false;

    //temp adding user panel
    this.selectedBrand_UserPanel = brand

    await this.commonService.getSnapshotsByUserAndBrand(this.UserID || this.userDetailsObject?.UserID, this.brandObject?.id).subscribe({
      next: (response) => {
        this.brandPageNumber = this.brandPageNumber || 1;
        this.snapshotsBrandFilter = response.snapshots;
        const startIndex = (this.brandPageNumber - 1) * this.currentBrandIndex;
        const endIndex = startIndex + this.currentBrandIndex;

        if (Array.isArray(this.snapshotsBrandFilter) && this.snapshotsBrandFilter.length > 0) {
          this.allUserSnapDetails = this.snapshotsBrandFilter.slice(startIndex, endIndex);
          this.saveBrandCacheStories_temp = this.allUserSnapDetails
        } else {
          console.warn('snapshotsBrandFilter is empty or not an array');
        }

        this.showStoryList = true;
        this.dropdownOpen = false;
      },
      error: (error) => {
        console.error('Error fetching snapshots:', error);
      },
      complete: () => {
        this.isBrandLoading = false;
        this.emitSlectedSnapList.emit(this.snapshotsBrandFilter);
        this.emitTabDataObjectList.emit({
          tab: 'Brands',
          dataList: this.allUserSnapDetails,

        });

        console.log('emitSlectedSnapList selectBran 6', this.allUserSnapDetails);

      }
    });
  }


  async selectCacheImage(cache) {
    console.log('selectCacheImage called at line 1295');
    this.cacheObject = cache;
    // this.temp_cacheObject = cache;
    this.lastcacheObjecID = this.temp_cacheObject?.id;
    this.showStoryList = false;
    this.selectedCloth_UserPanel = cache
    console.log('selectCacheImage cache', cache);
    this.showStoryList = false;
    this.commonService.getSnapshotsByUserAndGarment(this.UserID || this.userDetailsObject?.UserID, this.cacheObject?.id).subscribe({
      next: (response) => {
        this.snapshotsGarmentFilter = response.snapshots;
        this.garmentPageNumber = this.garmentPageNumber || 1;
        const startIndex = (this.garmentPageNumber - 1) * this.currentGarmentIndex;
        const endIndex = startIndex + this.currentGarmentIndex;
        if (this.snapshotsGarmentFilter) {
          this.allUserSnapDetails = this.snapshotsGarmentFilter.slice(startIndex, endIndex);
          this.saveGarmentCacheStories_temp = this.allUserSnapDetails
        }
        console.log('selectCacheImage cache', cache);
        this.showStoryList = true;
        this.dropdownOpen = false;
      },
      error: (error) => {
        console.error('Error fetching snapshots:', error);
      },
      complete: () => {
        let data = this.allUserSnapDetails
        this.emitSlectedSnapList.emit(data);
        // this.emitTabDataObjectList.emit({tab:this.secteTab, dataList:this.allUserSnapDetails});
        this.emitTabDataObjectList.emit({
          tab: 'Style',
          dataList: this.allUserSnapDetails,
          selectedTagDetails: {
            key: this.selectedCloth_UserPanel ? this.selectedCloth_UserPanel.id : 'All Garment',
            object: this.selectedCloth_UserPanel ? this.selectedCloth_UserPanel : null
          }
        });
        console.log('emitSlectedSnapList selectGarment 6', this.allUserSnapDetails);
      }
    });
  }

  selectAllAvatars() {
    console.log('selectAllAvatars called at line 1321');
    this.brandObject = null;
    this.cacheObject = null;
    this.avatarObject = null;
    this.allUserSnapDetails = this.tempSnapList;
    this.dropdownOpen = false;
    this.secteTab = 'Story';
    this.emitSlectedSnapList.emit(this.allUserSnapDetails);
    console.log('emitSlectedSnapList selectAllAvatars 6', this.allUserSnapDetails);

  }

  async cacheReGenrate(chache): Promise<any> {
    console.log('cacheReGenrate called at line 1331');
    try {
      const response = await firstValueFrom(this.commonService.downloadAndProcessPackUserReference(chache));
      return response;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }

  navigateToAvatarPage() {
    console.log('navigateToAvatarPage called at line 1341');
    let data = { UserID: this.UserID, AtarID: this.selectedavatarId };

    if (this.myProfile) { return; }
    const avatarId = this.selectedavatarId;
    const userId = this.UserID;
    const storyId = 'defaultStoryID';

    const params: any = {
      avatarID: avatarId,
      pagetab: 'story',
      id: userId,
      storyId: storyId,
      index: '',
      pageNumber: '',
      PageType: 'Avatar'
    };

    this.router.navigate(['/avatar', params]);
    this.emitSlectedUSER_And_Avatar.emit(data);
  }

  selectavatarOprtion() {
    console.log('selectavatarOprtion called at line 1363');
    this.dropdownOpen = !this.dropdownOpen;
  }

  getGarmentTypeLogo(option) {
    switch (option) {
      case 0:
        return 'assets/newsfeed/headgear-new.png';
      case 1:
        return 'assets/realtype/upperwear.png';
      case 2:
        return 'assets/newsfeed/bottomwear-new.png';
      case 3:
        return 'assets/realtype/footwear.png';
      case 4:
        return 'assets/realtype/fullbody.png';
      case 5:
        return './assets/realtype/fullbody.png';
      default:
        return '';
    }
  }

  userFileUpload() {
    console.log('userFileUpload called at line 1386');
    const fileInput = document.getElementById("file") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  async start2DFileUpload($event: any) {
    console.log('start2DFileUpload called at line 1393');
    let userID = this.userDetailsObject.UserID || this.UserID;
    let uploadFile = $event.target.files[0];
    this.uploadFileName = uploadFile.name;
    const path = `Uploads/${userID}/${this.uploadFileName}`;
    const ref = this.storage.ref(path);
    this.task = this.storage.upload(path, uploadFile);
    this.percentage = this.task.percentageChanges();
    let new_date = new Date();
    const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
    const uid = generateUUID();
    let uploadObject: any = {
      id: uid,
      filename: this.uploadFileName,
      fileurl: '',
      fileurlMini: '',
      filepath: path,
      date: date,
      fileType: 'UserUploads',
      userId: userID,
      link: '',
    };

    this.task.then(async snapshot => {
      let downloadURLTest = ref.getDownloadURL();
      await downloadURLTest.subscribe(async url => {
        uploadObject.fileurl = url;
        const modifiedFileName = this.uploadFileName.replace(/\.[^/.]+$/, "_128x128$&");
        const compressedFilePath = `Uploads/${userID}/${modifiedFileName}`;
        let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/compressUploadsFiles";
        try {
          const response = await this.http.post(functionUrl, {
            imageUrl: url,
            path: compressedFilePath
          }).toPromise();
          uploadObject.fileurlMini = response['fileurlMini'];

          if (uploadObject.fileurlMini) {
            await this.firestore.collection('userfiles').add(uploadObject);
            this.userFiledetails.push(uploadObject);
            alert('User file uploaded successfully.');
          } else {
            console.error('File URL Mini is undefined');
          }
        } catch (error) {
          console.error('Error compressing file:', error);
        }
      });
    });
  }

  setuserDetails(userDetails) {
    console.log('setuserDetails called at line 1441');
    let userID = this.userDetailsObject.UserID || this.UserID;
    this.loading = true;
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
      let userDetail = this.allUserDetails.find((user) => user.uid == userID);
      if (!userDetail) {
        this.loading = false;
        return;
      }
      let displayDetails = userDetail.providerData[0];
      let saveUserDetails = {
        "DisplayName": displayDetails.displayName != undefined ? displayDetails.displayName : '',
        "Email": displayDetails.email != undefined ? displayDetails.email : '',
        "FirstName": '',
        "Identifier": '',
        "LastName": '',
        "MiddleName": '',
        "ProfilePicture": displayDetails.photoURL != undefined ? displayDetails.photoURL : '',
        "UserID": userDetail.uid,
        "PageName": 'myuserprofilefeed'
      };
      const userProfileRef = this.firestore.collection('user-profile').doc(userDetails.id);
      userProfileRef.get().subscribe((docSnapshot) => {
        if (docSnapshot.exists) {
          userProfileRef.update(saveUserDetails);
        } else {
          console.log('Create a new user profile if it doesn\'t exist');
        }
        this.loading = false;
      });
    });
  }

  colapsAvatarPanel(event) {
    console.log('colapsAvatarPanel called at line 1473');
    event.stopPropagation();
    this.showDetailes = !this.showDetailes;
  }

  onImageError(event: any) {
    console.log('onImageError called at line 1479');
    event.target.src = 'assets/profilepic.png';
  }

  handleCrossButton_Avatar() {
    event.stopPropagation();
    console.log('handleCrossButton_Avatar called at line 1484');
    let data = true;
    this.showAvatar_Useravatar_Slider = true;
    this.selectedAvatar_UserPanel = null;
    this.userAvatarTag_cross.emit(this.showAvatar_Useravatar_Slider);
    console.log('handleCrossButton_Brand called at line 1491', this.secteTab);
    this.setTabSelection.emit(this.secteTab);
  }

  handleCrossButton_Brand() {
    event.stopPropagation();
    console.log('handleCrossButton_Brand called at line 1491');
    let data = true;
    this.showBrand_Useravatar_Slider = true;
    this.selectedBrand_UserPanel = null;
    this.userBrandTag_cross.emit(this.showBrand_Useravatar_Slider);
    // this.childUserPanelDataMapping.userPanelTabDataObjectMap['Brands'].selectedTagDetails.key = undefined;
    console.log('handleCrossButton_Brand called at line 1491', this.secteTab);
    // this.setTabSelection.emit(this.secteTab);
  }

  handleCrossButton_Garment() {
    event.stopPropagation();
    console.log('handleCrossButton_Garment called at line 1498');
    let data = true;
    this.showCloth_Useravatar_Slider = true;
    this.selectedCloth_UserPanel = null;
    this.userStyleTag_cross.emit(this.showCloth_Useravatar_Slider);
    console.log('handleCrossButton_Brand called at line 1491', this.secteTab);
    this.setTabSelection.emit(this.secteTab);
  }

  changeUser() {
    console.log('changeUser called at line 1505');
    let flage = true;
    this.change_button.emit(flage);
    this.emitOpneChangeUser.emit(true);
    let data = {
      user: this.userDetailsObject,
      UserName: this.selectedUserName,
      AvatarList: this.newAvatarfilterSnap,
      BrandList: this.brandList,
      GarmentCacheList: this.garmentCacheList
    };
    this.newEmitUser_Data.emit(data);
  }

  async select_set_Avatar(data) {
    this.selectedAvatar_UserPanel = this.childUserPanelDataMapping.userPanelTabDataObjectMap['Story'].selectedTagDetails.object
    console.log('select_set_Avatar called at line 1518', data);
    // Ensure `data` is valid before proceeding
    if (!data || !data.AvatarID) {
      console.error('Invalid data provided to select_set_Avatar:', data);
      return;
    }

    let dataSet = [];
    this.showStoryList = false;

    await this.commonService.getSnapshots().subscribe(async (snapshots: any) => {
      if (snapshots?.object && snapshots?.object.length > 0) {
        dataSet = snapshots?.object;

        // Ensure `dataSet` is an array before filtering
        if (!Array.isArray(dataSet)) {
          console.error('Expected dataSet to be an array but got:', dataSet);
          return;
        }

        this.snapshotsAvatarFilter = dataSet.filter((s) => s?.AvatarID === data.AvatarID);

        this.avatarPageNumber = this.avatarPageNumber || 1;
        const startIndex = (this.avatarPageNumber - 1) * this.currentAvatarIndex;
        const endIndex = startIndex + this.currentAvatarIndex;
        if (Array.isArray(this.snapshotsAvatarFilter) && this.snapshotsAvatarFilter.length > 0) {
          this.allUserSnapDetails = this.snapshotsAvatarFilter.slice(startIndex, endIndex);

          this.emitTabDataObjectList.emit({
            tab: 'Story',
            dataList: this.allUserSnapDetails,
            selectedTagDetails: {
              key: this.selectedAvatar_UserPanel ? this.selectedAvatar_UserPanel.AvatarID : 'All Avatar',
              object: this.selectedAvatar_UserPanel ? this.selectedAvatar_UserPanel : null
            }
          });
        } else {
          console.warn('snapshotsAvatarFilter is empty or not an array');
        }
        this.showStoryList = true;
      }
    });
  }


  checkCurrentRoute(): void {
    console.log('checkCurrentRoute called at line 1540');
    this.activatedRoute.url.subscribe(url => {
      this.pageFlagRout = url[0].path;
      if (this.pageFlagRout == 'avatar') {
      }
    });
  }

  nextBrandPage() {
    console.log('nextBrandPage called at line 1548');
    if ((this.brandPageNumber * this.currentBrandIndex) < this.snapshotsBrandFilter.length) {
      const startIndex = this.brandPageNumber * this.currentBrandIndex;
      const endIndex = startIndex + this.currentBrandIndex;
      const nextBatch = this.snapshotsBrandFilter.slice(startIndex, endIndex);
      this.allUserSnapDetails.push(...nextBatch);
      this.nextBrandStories = this.allUserSnapDetails;
      this.brandPageNumber++;
      this.flagbrandPrevBatch = false
      this.flagbrandNextBatch = true
    }
  }

  prevBrandPage() {
    console.log('prevBrandPage called at line 1559');
    if (this.brandPageNumber > 1) {
      const startIndex = (this.brandPageNumber - 2) * this.currentBrandIndex;
      const endIndex = startIndex + this.currentBrandIndex;
      const prevBatch = this.snapshotsBrandFilter.slice(startIndex, endIndex);
      this.allUserSnapDetails.unshift(...prevBatch);
      this.previousBrandPageStories = this.allUserSnapDetails;
      this.brandPageNumber--;
      this.flagbrandPrevBatch = true
      this.flagbrandNextBatch = false
    }
    this.router.navigate([], {
      queryParams: { brandPageNumber: this.brandPageNumber },
      queryParamsHandling: 'merge' // Keeps existing query parameters
    });
  }


  nextGarmentPage() {
    console.log('nextGarmentPage called at line 1570');
    if ((this.garmentPageNumber * this.currentGarmentIndex) < this.snapshotsGarmentFilter.length) {
      const startIndex = this.garmentPageNumber * this.currentGarmentIndex;
      const endIndex = startIndex + this.currentGarmentIndex;
      const nextBatch = this.snapshotsGarmentFilter.slice(startIndex, endIndex);
      this.allUserSnapDetails.push(...nextBatch);
      this.nextGarmentStories = this.allUserSnapDetails
      this.garmentPageNumber++;
      this.flagGarmentdPrevBatch = false
      this.flagGarmentNextBatch = true
    }
  }

  prevGarmentPage() {
    console.log('prevGarmentPage called at line 1580');
    if (this.garmentPageNumber > 1) {
      const startIndex = (this.garmentPageNumber - 2) * this.currentGarmentIndex;
      const endIndex = startIndex + this.currentGarmentIndex;
      const prevBatch = this.snapshotsGarmentFilter.slice(startIndex, endIndex);
      this.allUserSnapDetails.unshift(...prevBatch);
      this.prevGarmentStories = this.allUserSnapDetails
      this.garmentPageNumber--;
      this.flagGarmentdPrevBatch = true
      this.flagGarmentNextBatch = false
    }
    this.router.navigate([], {
      queryParams: { garmentPageNumber: this.garmentPageNumber },
      queryParamsHandling: 'merge' // Keeps existing query parameters
    });

    this.show_StylePrevisousButton = this.garmentPageNumber > 1;
  }

  nextAvatarPage() {
    console.log('nextAvatarPage called at line 1590');
    if ((this.avatarPageNumber * this.currentAvatarIndex) < this.snapshotsAvatarFilter.length) {
      const startIndex = this.avatarPageNumber * this.currentAvatarIndex;
      const endIndex = startIndex + this.currentAvatarIndex;
      const nextBatch = this.snapshotsAvatarFilter.slice(startIndex, endIndex);
      this.allUserSnapDetails.push(...nextBatch);
      this.nextAvatarStories = this.allUserSnapDetails;
      this.avatarPageNumber++;
    }
  }

  prevAvatarPage() {
    console.log('prevAvatarPage called at line 1601');
    if (this.brandPageNumber > 1) {
      const startIndex = (this.brandPageNumber - 2) * this.currentBrandIndex;
      const endIndex = startIndex + this.currentBrandIndex;
      const prevBatch = this.snapshotsBrandFilter.slice(startIndex, endIndex);
      this.allUserSnapDetails.unshift(...prevBatch);
      this.previousBrandPageStories = this.allUserSnapDetails;
      this.brandPageNumber--;
    }
  }


}