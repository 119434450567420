import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CaraosalPopupComponent } from 'src/app/common/templets/caraosal-popup/caraosal-popup.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-news-user',
  templateUrl: './news-user.component.html',
  styleUrls: ['./news-user.component.css']
})

export class NewsUserComponent implements OnInit {

  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userdetails = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  @ViewChild('userFeeds', { read: ElementRef }) public userFeeds: ElementRef<any>;

  @Input() rootFlag: string | undefined;
  @Input() brandPageFlag: string | undefined;
  @Input() routLogInFlag: boolean | undefined;



  windowWidth: number;
  windowHeight: number;
  isMobile: boolean;


  avatarMoreFlag: boolean = false
  mainMenuFlag: boolean = true
  showpanel: boolean = true
  pageScroll: number;
  currentlySelected;
  storyid: any
  userId: string;
  userSnapShotList: any;
  profileName: any
  userName: any = []
  userDetails: any
  filterSnapShotFileDetails: any = []
  SnapShotFileDetails: any = []
  id: any;
  avatarID: any;
  isLoadingforUserDetails = true
  snapShotFileList: any = []
  avatarIdshide: boolean = true
  scrollFlag: boolean = false;
  hide: boolean = true
  usersplitted: any = []
  allUserSnapDetails: any = []
  filterSnapShotFileDetailsall: any = []
  profileDetails: any = {};
  userSnapShotDetailsCollection = {};
  counter: number = 0;
  allDetails: any = []
  userProfile: any = []
  pageNumber: any = 1
  allUserDetails: any = []
  userDetail: any = []
  filterSnap: any = []
  userFiledetails: any = []
  userUploadedFileList: any = []
  filelist: any = []
  snaploader: boolean = true
  snaploader2D: boolean = true
  profileloader: boolean = true
  avatar: any
  user: any
  userData: any;
  pagecount: number;
  selectedStoryId: any;
  tab: string;
  avatarFlag: string;
  selectedTab: any

  newEntry = {
    AvatarID: "",
    BottomwearID: "",
    DateTime: { _seconds: 0, _nanoseconds: 0 },
    DisplayName: "",
    Environment: "",
    FootwearID: "",
    HeadwearID: "",
    SnapshotFileUrl: "",
    SnapshotFileUrlMicro: "",
    SnapshotFileUrlMini: "",
    StoryID: "",
    StoryType: "",
    UpperwearID: "",
    UserID: "",
    avatarName: "",
    home: "",
    id: ""
  };
  images = [
    { "image": "./assets/user-upload-dummy/new-img-1.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-5.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-6.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-7.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-8.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-9.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-10.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-11.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-12.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-13.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-14.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-15.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-16.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-17.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-18.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-19.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-20.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-21.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-22.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-23.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-24.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-25.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-26.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-27.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-28.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-29.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-30.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-31.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-32.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-33.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-34.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-35.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-36.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-37.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-38.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-39.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-40.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-41.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-42.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-43.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-44.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-45.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-46.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-47.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-48.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-49.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-50.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-51.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-52.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-53.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-54.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-55.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-56.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-57.jpg" },
  ]
  userProfileData: any;
  sticky: boolean;
  logInFlag: boolean;
  windowScroll: number;
  secteTab: any = 'Story';
  newAvatarList: any = [];
  selectedavatarId: any;
  avatarObject: any;
  newAvatarfilterSnap: any = [];
  showDripPage: boolean;
  
  nextPage: boolean = false;
  index: any;
  pageiIndex: any;
  itemsPerPage: number = 12;

  callpageIndexFlag: boolean = true;
  callpageNumer: any;
  callpageIndexFlagNextButton: boolean = true;
  snapInitCall: boolean;
  loadedPages = {};  // Object to track loaded pages
  loadedPagesBackWord = {};
  sideNavWidth_Fixed: any = 181;
  sideNavHeight: number = 700;
  closeDiv: boolean;
  wholeDiv: boolean;

  showUserPanel: boolean = true;
  showSearchPanel: boolean = false;
  showSearchIcon: boolean = true;
  flagFunction: boolean = false;;
;

  @HostListener('window:scroll', ["$event"]) onScroll(event: Event) {
    let window = event.currentTarget as Window;
    this.sticky = window.scrollY >= 40;
    this.windowScroll = window.scrollY

  }
  onResize(event) {
    this.updateWindowDimensions();
  }



  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private apiHttpService: ApiHttpService,
    public router: Router,
    private commonService: CommonService,
    private firestore: AngularFirestore,
    private modal: NgbModal,
    private cdr: ChangeDetectorRef


  ) {
    this.hideAvatar()
    this.closePopUp()





    this.logInFlag = this.commonService.isLoggedIn()


    this.closeDiv = true;
    this.wholeDiv = false;

    this.sideNavHeight = window.innerHeight
    

  }
  ngOnInit(): void {

    this.updateWindowDimensions()

    this.route.paramMap.subscribe(paramMap => {
      this.userId = paramMap.get('id');
      this.storyid = paramMap.get('storyId');
      this.avatarID = paramMap.get('avatarID');
      this.avatar = paramMap.get('avatar');
      this.user = paramMap.get('user');
      this.tab = paramMap.get('pagetab');
      this.index = paramMap.get('index');
      this.pageiIndex = paramMap.get('pageNumber');
      this.avatarFlag = paramMap.get('avatarFlag');
      this.profileloader = true
      this.selectedStoryId = this.storyid
    });

    console.log(this.brandPageFlag)
    console.log(this.rootFlag)
    console.log('this.index', this.index)

    this.snaploader2D = true;
    setTimeout(() => {
      this.snaploader2D = false;
    }, 2000);
    this.userdetails.subscribe(userdetails => {
      this.userProfile = userdetails
      this.userProfileData = this.userProfile.find((user) => user.UserID == this.userId);
      let title = 'Yologram' + ' - ' + this.userProfileData?.DisplayName ?? "User"
      this.commonService.changeTitle(title);
    });
    this.selectedTab = this.tab
    this.snapInIt()
    this.dataSharingService.userDataObservable.subscribe((userData) => {
      this.userData = userData;
      if (this.userData != undefined && this.userData.uid != undefined) {
        this.userId = this.userData.uid
      } else {
        this.commonService.getIdentifier(this.userId).subscribe(async (response: any) => {
          this.userData = response;
          this.userData.profileName = this.userData.displayName != undefined ? this.userData.displayName : this.userData.uid;
        })
      }
      this.commonService.getAllUser().subscribe(async (allUsers: any) => {
        this.userDetail = allUsers.users;
      })
      this.getNewFilterAvatr()




      this.userFilesList.subscribe((userFile) => {
        this.userUploadedFileList = userFile
        this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads')
        let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.userId)
        this.userFiledetails = filelist
      });
    })


   
  }
  closePopUp() {
    this.modal.dismissAll(CaraosalPopupComponent)
  }

  scrollHandler() {
    this.scrollFlag = true
    this.pageScroll = this.userFeeds.nativeElement.scrollTop
    this.selectedStoryId = ''
    setTimeout(() => {
      if ((this.scrollFlag == true && this.pageScroll > 100) || this.selectedStoryId != undefined) {
        this.selectedStoryId = ''
      }
    },
      0);
    let page = window.scrollY

  }
  getProfileName(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
    let profileName = userObjet.displayName != undefined ? userObjet.displayName : uuid;
    return profileName;
  }
  getProfilePhoto(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
    let profilephoto = userObjet.photoURL != undefined ? userObjet.photoURL : uuid;
    return profilephoto;
  }

  setPageNumber(pageNumber) {
    this.pageNumber = pageNumber
  }

  getSnapShotListByUser(userId) {
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['public/public-userStory-feed', { 'id': userId }])
  }
  getSelectSnapshotFeed(userSnap) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    if (this.avatarID != undefined) {
      this.router.navigate(['public/public-userStory-feed', { 'id': userId, 'storyId': storyid, 'avatarID': this.avatarID, 'pagecount': this.pagecount }])
    } else {
      this.router.navigate(['public/public-userStory-feed', { 'id': userId, 'storyId': storyid, 'pagecount': this.pagecount }])

    }
  }

  scrollToStory(snap) {
    this.selectedStoryId = snap.StoryID
    this.selectedTab = 'user-story'
  }

  selectSummeryView(tab) {
    this.selectedTab = 'story'
    // this.snapInIt()
    this.selectTab(tab)
  }
  getSelectSnapshoAvatartFeed(userSnap) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.AvatarID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['public/public-userStory-feed', { 'id': userId, 'storyId': storyid }])
  }
  hideAvatar() {
    if (this.avatarID != undefined) {
      this.hide = false
    }
  }

  snapInIt() {
    this.showDripPage = false
    this.pageNumber = 1;
    let reqInputObject = { id: this.userId, pageNumber: this.pageNumber }
    this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
      this.pagecount = response.count
      this.showDripPage = true
      this.snaploader = false;
      if (this.index != undefined) {
        this.nextByCall('count')
        return
      }
      this.filterSnap = response.object
      this.filterSnap.forEach(async (snap: any, index) => {
        let userObjet = this.userDetail.find(userObject => userObject.uid == snap.UserID)
        snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
        userObjet.profileName = snap.profileName;
        snap.userData = userObjet
        snap.firstName = snap.profileName.split(" ", 1);
        snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
      });
    })

    console.log(' this.filterSnap ', this.filterSnap);
    // if (this.avatarID != undefined && this.userId != undefined) {
    //   let reqInputObjectAvatar = { id: this.avatarID, pageNumber: 1 }
    //   this.commonService.yologramSnapshotDetailsByAvatar(reqInputObjectAvatar).subscribe(async (response: any) => {
    //     this.filterSnap = response.object;
    //     this.pagecount = response.count
    //     this.snaploader = false;
    //     this.filterSnap.forEach(async (snap: any, index) => {
    //       let userObjet = this.userDetail.find(userObject => userObject.uid == snap.UserID)
    //       snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
    //       userObjet.profileName = snap.profileName;
    //       snap.userData = userObjet
    //       snap.firstName = snap.profileName.split(" ", 1);
    //       snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
    //     });
    //   })
    // } else {
    //   let reqInputObject = { id: this.userId, pageNumber: this.pageNumber }
    //   this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
    //     this.filterSnap = response.object
    //     this.pagecount = response.count
    //     this.snaploader = false;
    //     this.filterSnap.forEach(async (snap: any, index) => {
    //       let userObjet = this.userDetail.find(userObject => userObject.uid == snap.UserID)
    //       snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
    //       userObjet.profileName = snap.profileName;
    //       snap.userData = userObjet
    //       snap.firstName = snap.profileName.split(" ", 1);
    //       snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
    //     });
    //     // this.filterAvatraId()
    //   })
    // }

  }
  getSnapShotListByUserID() {
    if (this.avatarID != undefined) {
      let reqInputObject = { id: this.avatarID, pageNumber: 1 }
      this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
        this.userSnapShotList = response.object;
      })
    } else {
      let reqInputObject = { id: this.userData.uid, pageNumber: this.counter }
      this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
        this.allDetails = response.object
        let allAvatarDetails = this.allDetails.filter((snap) => snap.UserID == this.userId)
        this.userSnapShotList = response.object;
      })
    }
  }

  getSnapShotListavtarid(avatar) {
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.AvatarID == avatar);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    if (this.avatarID != undefined) {
      this.router.navigate(['public/public-userStory-feed', { 'id': this.userId, 'storyId': this.storyid, 'avatarID': this.avatarID }])
    } else {
      this.router.navigate(['public/public-userStory-feed', { 'id': this.userId, 'storyId': this.storyid }])
    }
  }

  // nextButton(count) {
  //   this.pageNumber++
  //   console.log("pageNumber---------------------------------------->",this.pageNumber)
  //   this.counter = this.pageNumber;
  //   let reqInputObject = { id: this.userData.uid, pageNumber: this.counter }
  //   this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
  //     console.log("this.filterSnap before push", this.filterSnap)
  //     this.nextPage = true
  //     this.filterSnap.push(...response.object);
  //     this.nextPage = false
  //     console.log("this.filterSnap after push", this.filterSnap)
  //   })
  // }

  nextButtonForAvatar(c) {
    this.pageNumber++
    this.counter = this.pageNumber;
    let reqInputObject = { id: this.avatarID, pageNumber: this.counter }
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
      this.filterSnap.push(...response.object);
    })

  }
  selectTab(tab: any) {
    this.secteTab = tab
  }



  selectAvatarId(id) {
    this.avatarMoreFlag = true
    this.avatarObject = id
    this.selectedavatarId = id.AvatarID
    let reqInputObject = { id: this.selectedavatarId, pageNumber: 1 }
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
      this.filterSnap = response.object;
    })

  }

  // async getAvatarData(id, avatar) {
  //   let newData; // Initialize newData here
  //   newData = await this.commonService.getDocumentsFromSubcollection(id, avatar)
  //     .then((documents) => {
  //       if (documents && documents.length > 0) {
  //         const firstDocument = documents[0]; // Assuming there is only one document
  //         return firstDocument.AvatarName // Return the value to be assigned to newData
  //       }
  //     });
  // }
  // filterAvatraId(){
  //   console.log("snap:-", this.filterSnap);
  //   const uniqueAvatarIds = {};
  //   this.newAvatarList = this.filterSnap.filter((entry) => {
  //     if (!uniqueAvatarIds[entry.AvatarID]) {
  //       uniqueAvatarIds[entry.AvatarID] = true;
  //       return true;
  //     }
  //     return false;
  //   });

  // }

  async filterAvatraId() {
    // const uniqueAvatarIds = {};
    // this.newAvatarList = await this.newAvatarfilterSnap.filter((entry) => {
    //   if (!uniqueAvatarIds[entry.AvatarID]) {
    //     uniqueAvatarIds[entry.AvatarID] = true;
    //     return true;
    //   }
    //   return false;
    // });

    this.newAvatarList = this.newAvatarfilterSnap

    // Now that duplicates are removed, we can fetch AvatarName for each entry
    for (const entry of this.newAvatarList) {
      const avatarName = await this.getAvatarData(entry.UserID, entry.AvatarID);
      if (avatarName) {
        entry.AvatarName = avatarName.AvatarName;
        entry.AvatarPreviewPortraitUrl = avatarName.AvatarPreviewPortraitUrl;
      }
    }

  }


  async getAvatarData(id, avatar) {
    try {
      const documents = await this.commonService.getDocumentsFromSubcollection(id, avatar);
      if (documents && documents.length > 0) {
        const firstDocument = documents[0]; // Assuming there is only one document
        return firstDocument;
      }
    } catch (error) {
      console.error('Error getting avatar data:', error);
      return null; // Return null if there's an error fetching the data
    }
  }

  async getNewFilterAvatr() {
    let reqInputObject = { userID: this.userId }
    await this.commonService.yologramPublicByAvatar(reqInputObject).subscribe(async (response: any) => {
      this.newAvatarfilterSnap = response.object;
      this.filterAvatraId()
    })
  }

  navigateToAvatarPage() {
    this.router.navigate(['/avatar', { 'id': this.userId, 'avatarID': this.selectedavatarId, 'pagetab': "story" }])
  }




  handleStoryCall(data) {
    this.nextPage = data
    if (this.nextPage == true) {
      let count = 1
      this.nextButton(count)
    }
  }
  nextByCall(counts) {
    const count = 12;
    for (let i = 0; i < this.pagecount; i++) {
      this.filterSnap.push(this.newEntry);
    }
    const totalPages = Math.ceil(this.pagecount / count);
    const currentPage = Math.floor(this.index / count) + 1;
    const startIndex = (currentPage - 1) * count;
    console.log("currentPage", currentPage)
    this.counter = count;
    let reqInputObject = { id: this.userData.uid, pageNumber: currentPage }
    this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
      const newItems = response.object;
      for (let i = 0; i < newItems.length; i++) {
        this.filterSnap[startIndex + i] = newItems[i];
      }
      this.snapInitCall = true
    })
  }
  nextButton(counts) {
    // Increment the page number
    // this.pageNumber = this.pageiIndex;
    if (this.callpageIndexFlagNextButton) {
      this.pageNumber = this.pageiIndex;
      this.callpageIndexFlagNextButton = false
    }
    this.pageNumber++;

    const count = 12; // Number of items per page
    const totalPages = Math.ceil(this.pagecount / count);

    // Ensure the current page number does not exceed total pages
    if (this.pageNumber > totalPages) {
      this.pageNumber = totalPages;
    }

    // Calculate the current page and start index
    const currentPage = this.pageNumber;
    const startIndex = (currentPage - 1) * count;

    console.log("pageNumber---------------------------------------->", this.pageNumber);

    this.counter = this.pageNumber;

    // Check if this page has already been loaded
    if (this.loadedPages[this.pageNumber]) {
      console.log(`Page ${this.pageNumber} is already loaded. Skipping API call.`);
      return;
    }

    // Prepare the request object with the updated page number
    let reqInputObject = { id: this.userData.uid, pageNumber: this.counter };

    this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
      console.log("this.filterSnap before push", this.filterSnap);

      const newItems = response.object;
      this.nextPage = true;

      // Update filterSnap with the new items at the correct start index
      for (let i = 0; i < newItems.length; i++) {
        this.filterSnap[startIndex + i] = newItems[i];
      }

      // Mark this page as loaded
      this.loadedPages[this.pageNumber] = true;

      this.nextPage = false;
      console.log("this.filterSnap after push", this.filterSnap);
    });
  }
  previousbutton() {
    let count = 12;

    if (this.callpageIndexFlag) {
      this.callpageNumer = this.pageiIndex - 1;
      this.callpageIndexFlag = false;
    }

    if (this.callpageNumer > 0) {
      let cllpagecount = (this.callpageNumer * count) - 1;
      let startIndex = cllpagecount - (count - 1);

      console.log('cllpagecount', cllpagecount);
      console.log(`Alert for index ${cllpagecount} on page ${this.callpageNumer + 1}!`);

      // Check if the page has already been loaded
      if (this.loadedPagesBackWord[cllpagecount]) {
        console.log(`Page ${this.callpageNumer + 1} is already loaded. Skipping API call.`);
        this.callpageNumer--;  // Decrease callpage by 1
        return;
      }

      // Prepare the request object with the updated page number
      let reqInputObject = { id: this.userData.uid, pageNumber: this.callpageNumer };

      // Make the API call to load the data for the previous page
      this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
        console.log("this.filterSnap before push", this.filterSnap);
        const newItems = response.object;
        this.nextPage = true;

        // Update filterSnap with the new items at the correct start index
        for (let i = 0; i < newItems.length; i++) {
          this.filterSnap[startIndex + i] = newItems[i];
        }

        // Mark this page as loaded to prevent future duplicate calls
        this.loadedPagesBackWord[cllpagecount] = true;

        this.nextPage = false;
        console.log("this.filterSnap after push", this.filterSnap);
        console.log("loadedPages", this.loadedPagesBackWord);

        // Decrease callpage by 1 after the API call
        this.callpageNumer--;
      });
    }
  }
  async handlePreviousPage(data) {
    console.log("index number :-", data);
    let count = 12;
    if (this.callpageIndexFlag) {
      this.callpageNumer = this.pageiIndex - 1;
      this.callpageIndexFlag = false;
    }
    while (this.callpageNumer > 0) {
      let cllpagecount = (this.callpageNumer * count) - 1;
      let startIndex = cllpagecount - (count - 1);
      console.log('cllpagecount', cllpagecount);

      if (this.loadedPagesBackWord[cllpagecount]) {
        console.log(`Page ${cllpagecount} is already loaded. Skipping API call.`);
        return;
      }

      if (cllpagecount === data) {
        console.log(`Alert for index ${cllpagecount} on page ${this.callpageNumer + 1}!`);

        try {
          let reqInputObject = { id: this.userData.uid, pageNumber: this.callpageNumer };
          this.callpageNumer--;  // Decrease callpage by 1

          const response = await this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).toPromise();
          const newItems = response['object'];
          this.nextPage = true;
          // Update filterSnap with the new items at the correct start index
          newItems.forEach((item, index) => {
            this.filterSnap[startIndex + index] = item;
          });
          this.loadedPagesBackWord[cllpagecount] = true;
          console.log("loadedPages", this.loadedPagesBackWord);
        } catch (error) {
          console.error("Error fetching page data:", error);
        }
      } else {
        break;  // Exit loop if no match is found
      }
    }
  }



  // handlePreviousPage(data) {
  //   console.log("index number :-", data);
  //   let count = 12;
  //   if (this.callpageIndexFlag) {
  //     this.callpageNumer = this.pageiIndex - 1;
  //     this.callpageIndexFlag = false
  //   }
  //   while (this.callpageNumer > 0) {
  //     let cllpagecount = (this.callpageNumer * count) - 1;
  //     let startIndex = cllpagecount - (count - 1);
  //     console.log('cllpagecount', cllpagecount);
  //     if (this.loadedPagesBackWord[cllpagecount]) {
  //       console.log(`Page ${this.loadedPagesBackWord} is already loaded. Skipping API call.`);
  //       return;
  //     }
  //     if (cllpagecount === data) {
  //       console.log(`Alert for index ${cllpagecount} on page ${this.callpageNumer + 1}!`);
  //       let reqInputObject = { id: this.userData.uid, pageNumber: this.callpageNumer };
  //       this.callpageNumer--;  // Decrease callpage by 1
  //       this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
  //         const newItems = response.object;
  //         this.nextPage = true;
  //         // Update filterSnap with the new items at the correct start index
  //         for (let i = 0; i < newItems.length; i++) {
  //           this.filterSnap[startIndex + i] = newItems[i];
  //         }
  //         this.loadedPagesBackWord[cllpagecount] = true;
  //         console.log("loadedPages", this.loadedPagesBackWord);
  //       });
  //     } else {
  //       break;  // Exit loop if no match is found
  //     }
  //   }
  // }



  updateWindowDimensions() {
    this.windowHeight = window.innerHeight;
     this.windowWidth = window.innerWidth;
     this.isMobile = window.innerWidth <= 768; // You can adjust the width threshold for mobile mode

    console.log('Window Height:',   this.windowHeight);
    console.log('Window Width:',  this.windowWidth);

    // You can also use these dimensions to set styles or perform other logic
  }
  openUserPanel(flag) {
    this.wholeDiv = false;
    this.closeDiv = true;
    this.showSearchPanel = true;
    this.showUserPanel = true;
    
    if (flag === 'search') {
      this.showSearchPanel = true;
      this.showUserPanel = false;
    } else if (flag === 'user') {
      this.showUserPanel = true;
      this.showSearchPanel = false;
    }
  
    }

    closePanel() {
      this.wholeDiv = true;
      this.closeDiv = false;
      this.showUserPanel = false;
      this.showSearchPanel = false;
    }
  
    handleClosepanleFlag(data){
      if(data){
        this.closePanel()
        this.snapInIt()
      }
    }

    handleSlectedSnapList(data: any) {
      if (data) {
        this.filterSnap = data;
        this.flagFunction = true;
        console.log('this.filterSnap', this.filterSnap);
      }
    
      // Set a timeout to reset flagFunction to false
      setTimeout(() => {
        this.flagFunction = false;
      }, 1000); // Timeout set to 1 second (1000 ms)
    }
    
  
    handleOpenpanleFlag(data){
      if(data){
        // this.showSearchIcon = true
      }
    }
}
