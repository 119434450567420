<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<app-public *ngIf="!logInFlag"></app-public>
<div class="loader-overlay" *ngIf="isLoading">
    <div class="loader"></div>
</div>
<div (click)="closePanel()">
<table class="table" >
    <thead>
        <tr>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of filteredUserProfile ;let i=index">
            <td>
                <img style="width: 40px; height:40px !important;" class="rounded-circle m-1 p-1" data-bs-dismiss="modal"
                    [src]="item.ProfilePicture" (click)="openUserPanel(item,$event)" />
                <a class="clickFeed" (click)="openUserPanel(item,$event)"><b>{{item.DisplayName }}</b></a>
            </td>
            <td><button class="btn btn-sm btn-outline-info m-2">Follow</button></td>
        </tr>
    </tbody>
</table>

<div class="justify-content-center text-center">
    <span *ngFor="let pageNumber of paginationObject['pageObject']">
        <span (click)="nextButton(pageNumber)"><b
                [ngClass]=" pageNumber == selectedNumber ? 'border p-1 border-dark ' : ''"
                class="m-1">{{pageNumber}}</b></span>
    </span>
</div>

</div>

<div class="modal fade imp-bg" id="user-profile" tabindex="-1" aria-labelledby="user-profile" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog  ">
        <div class="modal-content imp-block" *ngIf="filterSnapShotFileDetails">
            <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
            <div class="container">
                <div class="row text-center">
                    <div class="row text-start p-2">
                        <div class="col-12 m-3">
                            <div *ngIf="popupLoadingIMg" class="spinner-border m-2 p-2" role="status">
                                <span class="sr-only"></span>
                            </div>
                            <img *ngIf="!popupLoadingIMg" style="width: 70px; height:70px !important;"
                                class="rounded-circle" data-bs-dismiss="modal"
                                (click)="getSnapShotListBySorted(profileDetails.uid)"
                                src="{{profileDetails.photoURL}}" />
                            <h2 class="w-100" data-bs-dismiss="modal"
                                (click)="getSnapShotListBySorted(profileDetails.uid)"
                                (click)="getSnapShotListBySorted(profileDetails.uid)">
                                {{profileDetails.displayName}}
                            </h2>
                            <div><button type="button" class="btn btn-outline-info m-1 follow">Follow</button></div>
                            <!-- <div><button type="button" class="btn btn-outline-info m-1 follow"
                            (click)="getUserSnapShotDashboar(profileDetails.uid)"     data-bs-dismiss="modal">User Feed</button></div> -->
                        </div>
                        <div>
                            <div *ngIf="popupLoadingFor2D" class="d-flex justify-content-center">
                                <div class="spinner-border m-5 p-3" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </div>
                            <div *ngIf="!popupLoadingFor2D" class="row d-flex">
                                <div data-bs-dismiss="modal" class="text-center col-6">
                                    <span>
                                        <div>
                                            <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                " src="./assets/imgplaceholder.jpg">
                                        </div>
                                    </span>
                                </div>
                                <div data-bs-dismiss="modal" class="text-center col-6">
                                    <span>
                                        <div>
                                            <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                " src="./assets/imgplaceholder1.jpg">
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="row w-100 m-2"
                                *ngIf="!popupLoading && filterSnapShotFileDetails.length >= 0  && filterSnapShotFileDetails != undefined && filterSnapShotFileDetails.length >= 0 ">
                                <div class="column d-block m-1"
                                    *ngFor="let snapshot of filterSnapShotFileDetails  ; let i=index;">
                                    <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                        (click)="getUserSnapShotDashboar(profileDetails.uid)"
                                        [src]="snapshot.SnapshotFileUrl">
                                </div>
                                <div class="columnImg d-block m-1">
                                    <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                </div>
                                <div class="columnImg d-block m-1">
                                    <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                </div>
                                <div class="columnImg d-block m-1"
                                    *ngIf="filterSnapShotFileDetails.length > 2 && profileDetails.uid != undefined && !popupLoading"
                                    (click)="getUserSnapShotDashboar(profileDetails.uid)">
                                    <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                        data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                                </div>
                            </div>
                        </div>
                        <div *ngIf="popupLoading" class="d-flex justify-content-center">
                            <div class="spinner-border m-5 p-4" role="status">
                                <span class="sr-only"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="brand-close" id="closePannel" *ngIf="userCollapseFlag" (click)=" clickOnPanel($event)">
    <div id="mySidenav" class="sidenav" [style.height.px]="sideNavHeight" *ngIf="closeDiv && !wholeDiv">
        <div *ngIf="userCollapseFlag">
            <app-user-panel [UserShapShotObject]="this.snapshot" [UserID]="this.snapshot['UserID']"
                [brandStoryScrollerHeight]="brandStoryScrollerHeight" [userData]="userData" [UserData]="UserData"
                [selectedUserName]="selectedUserName"
                
                [Set_UserPanelData]="Set_UserPanelData"
                (change_button)="handleChangeuser($event)" 
                (newEmitUser_Data)="handle_userData($event)"




                (userBrandTag_cross)="handle_userBrandTag_cross($event)"
                (userStyleTag_cross)="handle_userStyleTag_cross($event)"
                (userAvatarTag_cross)="handle_userAvatarTag_cross($event)"


                [showBrand_Useravatar_Slider]="showBrand_Useravatar_Slider"
                [showCloth_Useravatar_Slider]="showCloth_Useravatar_Slider"
                [showAvatar_Useravatar_Slider]="showAvatar_Useravatar_Slider"

                [selectedAvatar_UserPanel]="selectedAvatar_UserPanel"
                [selectedBrand_UserPanel]="selectedBrand_UserPanel"
                [selectedCloth_UserPanel]="selectedCloth_UserPanel"

                (setTabSelection)="handle_UserTab($event)"

                [change_avatar_flage]="change_avatar_flage"

                >
            </app-user-panel>
        </div>
    </div>
</div>


<div *ngIf="showUserSlider && userCollapseFlag  " style="position: fixed; top: 130px; left: 190px; z-index: 51;">
    <button id="above-model-button"   class="text-black"  (click)=" this.cahngeUserButton()">Change User</button>
    <button  class="brand-remove-avatar text-black"  (click)=" this.cahngeUserButton()"> X </button>
</div>

<div *ngIf="showUserSlider && userCollapseFlag " class="avatar-creator">
    <div *ngIf="userFiledetails?.length != 0" class="twoDimages">
        <app-image-slide [images]="userFiledetails"></app-image-slide>
    </div>
    <div *ngIf="userFiledetails.length == 0 " class=" col-12 band">
        <app-image-slide [images]="images"></app-image-slide>
    </div>
</div>

<div class="image-slider" *ngIf="showUserSlider && this.showUserSlider_selected "
   >
    <div class="image-slider-inner" *ngFor="let user of userFilter">
        <div class="image-wrapper" (click)="resetUserPanel(user)" >
            <img class="image-slide"
                [src]="user.ProfilePicture  != undefined || user.ProfilePicture  != null ?user.ProfilePicture  : user.ProfilePicture "   (error)="onImageError($event)"
                />
            <div class="image-overlay" *ngIf=" user.DisplayName">
                {{ user.DisplayName.length > 10 ? ( user.DisplayName | slice:0:10) + '...' :  user.DisplayName }}
            </div>
        </div>
    </div>
</div>


<div *ngIf="(userCollapseFlag)">

    <button  class="brand-remove"
        *ngIf="showBrand_Useravatar_Slider && UerTab === 'Brands'" (click)="closeUser_BrandSlider()">
        X
    </button>

    <button class="brand-remove"
        *ngIf="showCloth_Useravatar_Slider && UerTab === 'Style'" (click)="closeUser_ClothSlider()">
        X
    </button>

    <button  class="brand-remove"
        *ngIf="showAvatar_Useravatar_Slider &&  UerTab === 'Story'" (click)="closeUser_AvatarSlider()">
        X
    </button>



    <div class="image-slider-brand" *ngIf="showAvatar_Useravatar_Slider && UerTab === 'Story'">
        <div class="image-slider-inner-brand " *ngFor="let avatar of temp_Avatar_List">
            <div class="image-wrapper" (click)="cacahngeUser_Avatar(avatar)">
                <img class="image-slide-brand" [src]="avatar?.AvatarPreviewPortraitUrl" />
                <div class="image-overlay-brand">
                    {{ avatar?.AvatarName?.length > 10 ? (avatar.AvatarName | slice: 0:10) + '...' : avatar?.AvatarName }}
                </div>
            </div>
        </div>
    </div>




    <!-- Brands Div -->
    <div class="image-slider-brand" *ngIf="showBrand_Useravatar_Slider && UerTab === 'Brands'">
        <div class="image-slider-inner-brand " *ngFor="let brand of temp_BrandAvatar_List">
            <div class="image-wrapper" (click)="cahngeUserBrand(brand,$event)">
                <img class="image-slide-brand" [src]="brand.Brandurl" />
                <div class="image-overlay-brand">
                    {{ brand?.BrandName?.length > 10 ? (brand.BrandName | slice: 0:10) + '...' : brand?.BrandName }}
                </div>
            </div>
        </div>
    </div>

    <!-- Style Div -->
    <div class="image-slider-brand" *ngIf="showCloth_Useravatar_Slider && UerTab === 'Style'"
        >
        <div class="image-slider-inner-brand " *ngFor="let chache of temp_CacheImageAvatar_List">
            <div class="image-wrapper" *ngIf="chache.cacheUrl"
                (click)="cahngeUserCloth(chache)">
                <div class="image-set">
                    <img *ngIf="chache.cacheUrl" class="image-set-cache"
                        [src]="chache.cacheUrl != undefined || chache.cacheUrl != null ? chache.cacheUrl : cacheReGenrate(chache)"
                        alt="Avatar Image" />
                </div>                <div class="image-overlay-brand" *ngIf="chache">
                    {{ chache?.Name?.length > 10 ? (chache?.Name | slice: 0:10) + '...' : chache?.Name }}
                </div>
            </div>
        </div>
    </div>

    <!-- Fallback Content -->

</div>