<div class="modal-content imp-block">
    <div class="container">
        <button type="button" *ngIf="closebuttonFlag" class="btn-close main-close-button" aria-label="Close"
            (click)="toggleChild()" style="color: white;"></button>
        <div class="row text-center">
            <div class="container setImage">
                <div class="text-center">
                    <div class="row text-start p-2">
                        <div *ngIf="userDetailsloading" class="text-center">
                            <div class="spinner-border m-5" role="status">
                                <span class="sr-only"></span>
                            </div>
                        </div>
                        <div class=" col-lg-12 band">
                            <div class="static-band" style="margin-left: -18px  !important;">
                                <div (click)="getUserSnapShotDashboard('story','','')"
                                    class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal">
                                    <img *ngIf="userDetailsObject.ProfilePicture != undefined"
                                        class="img-thumbnail rounded-circle" style="
                                          width: 40px !important;
                                          height: 40px !important;
                                          margin-top: -36px;
                                      " [src]="userDetailsObject.ProfilePicture">
                                    <img *ngIf="userDetailsObject.ProfilePicture == undefined"
                                        class="img-thumbnail rounded-circle" style="
                                          width: 40px !important;
                                          height: 40px !important;
                                          margin-top: -36px;
                                      " [src]="userDetailsObject.ProfilePicture">
                                </div>
                                <div class="w-100 col-12 d-flex ">
                                    <div class="col-9 text-start">
                                        <h5 *ngIf="selectedUserName" class="m-1 text-start text-black profile-name"
                                            (click)="getUserSnapShotDashboard('story','','')">{{selectedUserName}}</h5>
                                        <!-- <h5 *ngIf="userDetailsObject.DisplayName != undefined"
                                            class="m-1 text-black profile-name"
                                            (click)="getUserSnapShotDashboard('story','')">
                                            {{userDetailsObject.DisplayName}}
                                        </h5> -->
                                    </div>
                                    <div class="col-3 "><button type="button" style="font-size: 8px;"
                                            class="btn btn-outline-info follow-btn">Follow</button>
                                    </div>
                                </div>

                                <div *ngIf="newArray.length != 0" class="text-start capsule-container"
                                    style="height: 35px; width: 100%; ">
                                    <div class="d-flex align-items-center justify-content-center"
                                        (click)="getUserSnapShotByAvatar('story', this.AvatarID,'')"
                                        *ngIf="newArray.length != 0">
                                        <img class="avatar-image" style="width: 25px; height: 25px;"
                                            [src]="userAvatarData?.AvatarPreviewPortraitUrl">
                                        <span class="avatar-name"><span class="avatar-details">{{selectedAvatarName}}
                                                ({{userAvatarData?.Gender =='MALE' ? 'M': 'F'}})</span></span>
                                    </div>
                                </div>
                                <div *ngIf="newArray.length == 0" class="text-start capsule-container"
                                    style="height: 35px; width: 100%; ">
                                    <div class="d-flex align-items-center justify-content-center"
                                        (click)="getUserSnapShotByAvatar('story', this.AvatarID,'')">
                                        <img class="avatar-image" style="width: 25px; height: 25px;"
                                            [src]="getAvatarImageUrl(userAvatarData)">
                                        <span class="avatar-name"><span class="avatar-details">{{selectedAvatarName }}
                                                ({{userAvatarData?.Gender =='MALE' ? 'M': 'F'}})</span></span>
                                    </div>
                                </div>



                                <div class="centered-container" *ngIf="userAvatarData">
                                    <p class="centered-text">Size : {{userAvatarData?.FatBlendshapeValue}}</p>
                                    <p class="centered-text">Height  : {{userAvatarData?.HeightScale}}</p>
                                  
                                </div>
                                  

                                <div>

                                    <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                                        <div class="brandbtn">
                                            <h6 class="m-1" (click)="selectSummeryView('Story')"
                                                [ngClass]="secteTab == 'Story'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                                <a *ngIf="this.AvatarID == undefined">
                                                    <img (click)="getUserSnapShotByAvatar('story', this.AvatarID,'')"
                                                        *ngIf="secteTab  == 'Story'" class="story-icon"
                                                        src="  ./assets/storystyle/story-white.png">
                                                    <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                                        src=" ./assets/storystyle/storyblack.png">
                                                </a>
                                                <a *ngIf="this.AvatarID != undefined">
                                                    <img (click)="getUserSnapShotByAvatar('story',this.AvatarID,'')"
                                                        *ngIf="secteTab  == 'Story'" style="width:15px !important"
                                                        src="  ./assets/storystyle/story-white.png">
                                                    <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                                        src=" ./assets/storystyle/storyblack.png">
                                                </a>

                                            </h6>
                                            <h6 *ngIf="secteTab  == 'Story'" class="ms-2 font-class">Story</h6>
                                        </div>
                                        <div class="brandbtn" (click)="selectTab('Brands')">
                                            <h6 class="m-1 p-0"
                                                [ngClass]="secteTab == 'Brands'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                                <a>
                                                    <img *ngIf="secteTab  == 'Brands'" style="width:15px !important"
                                                        src="./assets/brand_white.png">
                                                    <img *ngIf="secteTab  != 'Brands'" style="width:15px !important"
                                                        src="./assets/brand_black.png">

                                                </a>
                                            </h6>
                                            <h6 *ngIf="secteTab  == 'Brands'" class="m-1 font-class">Brands </h6>
                                        </div>
                                        <div class="brandbtn" (click)="selectTab('Style')">
                                            <h6 class="m-1 p-0"
                                                [ngClass]="secteTab == 'Style'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                                <a>
                                                    <img *ngIf="secteTab  == 'Style'" style="width:15px !important"
                                                        src="./assets/t_shirt_white.png">
                                                    <img *ngIf="secteTab  != 'Style'" style="width:15px !important"
                                                        src="./assets/t_shirt_black.png">
                                                </a>
                                            </h6>
                                            <h6 *ngIf="secteTab  == 'Style'" class="m-1 font-class">Style</h6>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div style="height: 80px !important; width: 100% !important;">
                                <div class="slider-scroll band">
                                    <div *ngIf="newArray.length != 0" class="twoDimages">
                                        <!-- <app-slider-two-d [images]="userFiledetails" [UserId]="this.UserID"
                                            [UserProfile]="userProfile" [dismmis]="dismiss"></app-slider-two-d> -->
                                        <app-image-slide [images]="newArray" [UserId]="this.UserID"></app-image-slide>
                                    </div>

                                    <div *ngIf="newArray.length == 0" class="d-flex " data-bs-dismiss="modal">
                                        <div class="twoDimages">
                                            <app-image-slide [images]="images"></app-image-slide>
                                        </div>
                                    </div>

                                    <!-- <div class="d-flex " *ngIf="this.AvatarID != undefined"
                                        (click)="getUserSnapShotByAvatarId('story',this.AvatarID)"
                                        data-bs-dismiss="modal">
                                        <div *ngIf="userFiledetails.length == 0 " class=" col-12 band">
                                            <app-image-slide [images]="images"></app-image-slide>
                                        </div>
                                        <div *ngIf="userFiledetails.length != 0" class=" col-12  band">
                                      
                                            <app-image-slide [images]="userFiledetails" [UserId]="this.UserID"
                                                [UserProfile]="userProfile" [dismmis]="dismiss"></app-image-slide>
                                        </div>
                                    </div> -->


                                </div>
                            </div>
                            <div class="large-box">
                                <div class="col-12 col-sm-12  d-flex">
                                    <div class="col-2 col-sm-2 m-1">
                                        <img class="m-1" style="width:30px; height:30px !important; color:black;"
                                           
                                            *ngIf="getGarmentrealtype(userAvatarData.cHeadgearID) == '0' "
                                            class="rounded profileImg-brand"
                                            [src]="getBrandImageByGarmetnID(userAvatarData.cHeadgearID)" />
                                    </div>
                                    <div class="col-8 col-sm-8 clothName p-1"
                                        
                                        *ngIf="userAvatarData.cHeadgearID != undefined && userAvatarData.cHeadgearID!= ''">
                                        <img class="gar-icon" *ngIf="getGarmentrealtype(userAvatarData.cHeadgearID) == '0' "
                                            style="width:15px !important; color:black ; opacity: 0.4;"
                                            src="./assets/newsfeed/headgear-new.png">
                                        <span class="caption-values"
                                            *ngIf="userAvatarData.cHeadgearID != undefined && userAvatarData.cHeadgearID != ''">
                                            <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                                title="Cloth Details">
                                                {{getGarmentName(userAvatarData.cHeadgearID).substring(0,15)}}
                                            </span>&nbsp;
                                        </span>
                                      
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12  d-flex">
                                    <div class="col-2 col-sm-2 m-1 ">
                                        <img class="m-1" 
                                            style="width:30px; height:30px !important; color:black ; "
                                            *ngIf="getGarmentrealtype(userAvatarData.cUpperwearID) == '1' "
                                            class="rounded profileImg-brand"
                                            [src]="getBrandImageByGarmetnID(userAvatarData.cUpperwearID)" />
                                        <img class="m-1" 
                                            style="width:30px; height:30px !important; color:black ;"
                                            *ngIf="getGarmentrealtype(userAvatarData.cFullBodyID) == '5' "
                                            class="rounded profileImg-brand"
                                            [src]="getBrandImageByGarmetnID(userAvatarData.cFullBodyID)" />
                                    </div>
                                    <div class="col-8 col-sm-8 clothName p-1"
                                        *ngIf="userAvatarData.cUpperwearID != undefined &&userAvatarData.cUpperwearID != ''">
                                        <span
                                          >
                                            <img class="gar-icon"
                                                *ngIf="getGarmentrealtype(userAvatarData.cUpperwearID) == '1' "
                                                style="width:15px !important; color:black; opacity: 0.4;"
                                                src="./assets/realtype/upperwear.png">
                                            <img class="gar-icon"
                                                *ngIf="getGarmentrealtype(userAvatarData.cUpperwearID) == '5' "
                                                style="width:15px !important; color:black ; opacity: 0.4;"
                                                src="./assets/realtype/fullbody.png">
                                            <span class="caption-values"
                                                *ngIf="userAvatarData.cUpperwearID != undefined && userAvatarData.cUpperwearID != ''">
                                                <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                                    title="Cloth Details">
                                                    {{getGarmentName(userAvatarData.cUpperwearID).substring(0,15)}}
                                                </span>&nbsp;
                                            </span>
                                        </span>
                                      
                                    </div>
                                    <div class="col-8 col-sm-8 clothName p-1"
                                        *ngIf="userAvatarData.cFullBodyID != undefined &&userAvatarData.cFullBodyID != ''">
                                        <span
                                            >
                                            <img class="gar-icon" *ngIf="getGarmentrealtype(userAvatarData.cFullBodyID) == '5' "
                                                style="width:15px !important; color:black ; opacity: 0.4;"
                                                src="./assets/realtype/fullbody.png">
                                            <span class="caption-values"
                                                *ngIf="userAvatarData.cFullBodyID != undefined && userAvatarData.cFullBodyID != ''">
                                                <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                                    title="Cloth Details">
                                                    {{getGarmentName(userAvatarData.cFullBodyID).substring(0,15)}}
                                                </span>&nbsp;
                                            </span>
                                        </span>
                                     
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 d-flex ">
                                    <div class="col-2 col-sm-2  m-1">
                                        <img 
                                            style="width:30px; height:30px !important; color:black ; "
                                            *ngIf="getGarmentrealtype(userAvatarData.cBottomwearID) == '2'"
                                            class="rounded profileImg-brand"
                                            [src]="getBrandImageByGarmetnID(userAvatarData.cBottomwearID)" />
            
                                    </div>
                                    <div class="col-8 col-sm-8 clothName p-1"
                                        *ngIf="userAvatarData.cBottomwearID != undefined && userAvatarData.cBottomwearID != ''">
                                        <span
                                            >
            
            
                                            <img class="gar-icon"
                                                *ngIf="getGarmentrealtype(userAvatarData.cBottomwearID) == '2'"
                                                style="width:15px !important; color:black ; opacity: 0.4;"
                                                src="./assets/newsfeed/bottomwear-new.png">
                                            <span class="caption-values"
                                                *ngIf="userAvatarData.cBottomwearID != undefined &&userAvatarData.cBottomwearID!= ''">
                                                <span class="garment-name" data-toggle="popover" data-placement="left"
                                                    title="Cloth Details">{{getGarmentName(userAvatarData.cBottomwearID).substring(0,15)}}
                                                </span>&nbsp;
                                            </span>
                                        </span>
            
                                       
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12  d-flex">
                                    <div class="col-2 col-sm-2 ">
                                        <img class="m-2" 
                                            style="width:30px; height:30px !important; color:black ;"
                                            *ngIf="getGarmentrealtype(userAvatarData.cFootwearID) == '3'"
                                            class="rounded profileImg-brand"
                                            [src]="getBrandImageByGarmetnID(userAvatarData.cFootwearID)" />
                                    </div>
            
                                    <div class="col-8 col-sm-8 clothName p-1"
                                        *ngIf="userAvatarData.cFootwearID != undefined && userAvatarData.cFootwearID != ''">
                                        <img class="gar-icon" *ngIf="getGarmentrealtype(userAvatarData.cFootwearID) == '3' "
                                            
                                            style="width:15px !important; color:black ; opacity: 0.4;"
                                            src="./assets/realtype/footwear.png">

                                        <span class="caption-values"
                                            *ngIf="userAvatarData.cFootwearID != undefined && userAvatarData.cFootwearID != ''">
                                            <span class="garment-name" data-toggle="popover" data-placement="left"
                                                title="Cloth Details">
                                                {{getGarmentName(userAvatarData.cFootwearID).substring(0,15)}}
                                            </span>&nbsp;
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 div-center" style="margin-top: -45px;">

                            <!-- <div class="row  image-margin" [style.height.px]="brandStoryScrollerHeight - 62">
                                <div class="col-6 p-0" *ngFor="let snapshot of allUserSnapDetails; let i=index;">
                                    <div class="d-flex ">
                                        <img class="shadow-1-strong rounded m-1 story-image"
                                            (click)="getUserSnapShotByAvatar('user-story',snapshot)"
                                            [src]="snapshot.SnapshotFileUrl">
                                    </div>
                                </div>
                            </div> -->


                            
                            <div class="row image-margin"
                                [ngStyle]="allUserSnapDetails.length < 12 ? {} : { 'height.px': brandStoryScrollerHeight - 62 }">
                                <div class="col-6 p-0" *ngFor="let snapshot of allUserSnapDetails; let i=index;">
                                    <div class="d-flex">
                                        <img class="shadow-1-strong rounded m-1 story-image"
                                            (click)="getUserSnapShotByAvatar('user-story',snapshot,i)"
                                            [src]="snapshot.SnapshotFileUrlMini">
                                    </div>
                                </div>
                            </div>



                            <div *ngIf="brandsnaploadingSnap" class="text-center">
                                <div class="spinner-border m-5 p-5" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <div class="col-12 text-center text-black justify-content-center p-3">
        <h6 (click)="incrementImgCounter()">
            <b>More</b>
        </h6>
    </div>
</div>