import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { DripsComponent } from 'src/app/common/drips/drips.component';
import { CaraosalPopupComponent } from 'src/app/common/templets/caraosal-popup/caraosal-popup.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { environment } from 'src/environments/environment';
import { generateUUID } from 'three/src/math/MathUtils';

@Component({
  selector: 'app-user-my-profile',
  templateUrl: './user-my-profile.component.html',
  styleUrls: ['./user-my-profile.component.css']
})

export class UserMyProfileComponent {
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userDetailsList = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;

  @ViewChild('userFeeds', { read: ElementRef }) public userFeeds: ElementRef<any>;

    @ViewChild('childrip') newchild: DripsComponent;

  threadsPage_avatar_flag = true
  task!: AngularFireUploadTask;
  percentage!: Observable<number | undefined>;
  currentlySelected;
  storyid: any
  userId: string;
  userSnapShotList: any;
  profileName: any
  userName: any = []
  userDetails: any
  userProfileData: any = {}
  filterSnapShotFileDetails: any = []
  SnapShotFileDetails: any = []
  id: any;
  avatarID: any;
  isLoadingforUserDetails = true
  snapShotFileList: any = []
  avatarIdshide: boolean = true
  myProfile: boolean = true
  hide: boolean = true
  usersplitted: any = []
  allUserSnapDetails: any = []
  filterSnapShotFileDetailsall: any = []
  profileDetails: any = {};
  UserList: any = {};
  userSnapShotDetailsCollection = {};
  counter: number = 0;
  allDetails: any = []
  pageNumber: any = 1
  allUserDetails: any = []
  userDetail: any = []
  filterSnap: any = []
  snaploader: boolean = true
  snaploader2D: boolean = true
  profileloader: boolean = true
  deleteflag: boolean = true
  avatar: any
  user: any
  file: any
  userData: any;
  pagecount: number;
  pageScroll: number;
  selectedStoryId: any;
  tab: string;
  avatarFlag: string;
  selectedTab: any = 'user-story'
  deleteFlag: any = true
  userUploadedFileList: any = []
  filelist: any = []
  userFiledetails: any = []
  backObject: any = {}
  loading: boolean = false;

  routLogInFlag: boolean = true

  closeDiv: boolean;
  wholeDiv: boolean;

  showUserPanel: boolean = true;
  showSearchPanel: boolean = false;
  showSearchIcon: boolean = true;
  flagFunction: boolean = false;
  showAvatarPanel: boolean = true;
  openAvatarmainPANLE: boolean = true;
  hasCalledStoryOnPanel: boolean = true;
  avatarpanel: boolean = true;


  PageType: string;

  SetGarmentId: string;
  SetBrandId: string;
  SetAvatarId: string;
  saveAvatarData: any
  functionflagValue: any = ''
  mainMenuFlag: boolean = true
  showpanel: boolean = true
  avatarPage: boolean = true;
  defaultEnvironment: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/Environments%2FAdobeStock_209580496.jpeg?alt=media&token=34dad92c-ec1f-4b7d-a293-5443ab42fd78';





  storyText: any;
  nextPage: any;
  genders: any;
  flag_v2: boolean = true;
  sideNavHeight: any;
  StoryID: any;
  AvatarSwichDetails: any;
  avatarname: any;
  userAvatarData: any;
  avtarUrl: any;
  garmenturl: any;
  fullBodyurl: any;
  UpperWearfinalUrl: any;
  garmentbottom: any;
  headgear: any;
  footwear: any;
  flagForShowThreeJs: boolean;
  newArray:any[];garmentLink: any;
  GarmentsGender: any;
;
Set_UserPanelData
  tempSnapList: any = [];
  garmentsCollection: any = [];
  PageTabSelection: any

  usrPageAvatarFlag: any

  userPage: any = 'yologram-social/yologram-user-myprofile'
  brandFlag: any = '/yologram-social/yologram-brand-stories'
  images = [
    { "image": "./assets/user-upload-dummy/new-img-1.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-5.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-6.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-7.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-8.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-9.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-10.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-11.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-12.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-13.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-14.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-15.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-16.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-17.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-18.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-19.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-20.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-21.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-22.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-23.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-24.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-25.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-26.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-27.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-28.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-29.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-30.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-31.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-32.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-33.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-34.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-35.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-36.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-37.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-38.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-39.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-40.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-41.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-42.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-43.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-44.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-45.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-46.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-47.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-48.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-49.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-50.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-51.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-52.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-53.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-54.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-55.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-56.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-57.jpg" },
  ]
  uploadFileName: any;
  secteTab: any = 'Story';
  scrollFlag: boolean = false;
  email: any;
  logInFlag: boolean;


  showBrand_Useravatar_Slider: boolean;
  showAvatar_Useravatar_Slider: boolean;
  showCloth_Useravatar_Slider: boolean;

  selectedBrand_UserPanel
  selectedAvatar_UserPanel:any
  selectedCloth_UserPanel







  constructor(
    private dataSharingService: DataSharingService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private apiHttpService: ApiHttpService,
    public router: Router,
    private commonService: CommonService,
    private storage: AngularFireStorage,
    public datepipe: DatePipe,
    private db: AngularFirestore,
    private firestore: AngularFirestore,
    private modal: NgbModal,) {
    this.closePopUp()
    this.commonService.getUser().subscribe((user: any) => {
      this.email = user?.email;
      this.id = user?.uid;
      this.userId = this.backObject.UserId != undefined ? this.backObject.UserId : this.id
      this.tab = this.backObject.Pagetab != undefined ? this.backObject.Pagetab : 'story'
      console.log(this.userId)
    });

    this.hideAvatar()
    // this.backObject = this.router.getCurrentNavigation()?.extras.state;
    // console.log(this.backObject)
    // this.userId = this.backObject.UserId != undefined  ? this.backObject.UserId : this.id
    // this.tab = this.backObject.Pagetab != undefined ? this.backObject.Pagetab : 'story'
    // this.storyid =  this.backObject.StoryId
    this.backObject = this.dataSharingService.getNavigationData()
    console.log("back object", this.backObject)
    if (this.backObject) {
      this.userId = this.backObject.UserId !== undefined ? this.backObject.UserId : this.id;
      this.tab = this.backObject.Pagetab !== undefined ? this.backObject.Pagetab : 'story';
      console.log("this.userId", this.userId);
      console.log("this.tab", this.tab);
      this.storyid = this.backObject.StoryId !== undefined ? this.backObject.StoryId : null;
      console.log("this.storyid", this.storyid);
    }


  }

  ngOnInit(): void {
    let title = 'Yologram - me'
    this.commonService.changeTitle(title);
    this.logInFlag = this.commonService.isLoggedIn()
    this.snaploader2D = true;
    setInterval(() => {
      this.snaploader2D = false;
    }, 2000);

    setTimeout(() => {
      this.functionflagValue = 'skybox';
      this.storyText = ""
    }, 0);

    this.route.paramMap.subscribe(paramMap => {
      // this.userId = paramMap.get('id');
      // this.storyid = paramMap.get('storyId');
      this.avatarID = paramMap.get('avatarID');
      this.avatar = paramMap.get('avatar');
      this.user = paramMap.get('user');
      // this.tab = paramMap.get('pagetab');
      this.avatarFlag = paramMap.get('avatarFlag');
      this.deleteFlag = paramMap.get('flag');
      this.PageType = paramMap.get('PageType');
      this.PageTabSelection = paramMap.get('TabSelection');
      this.SetBrandId = paramMap.get('SetBrandId');
      this.profileloader = true
      this.selectedStoryId = this.storyid
    });

    this.closeDiv = true

    this.selectedTab = this.tab
    this.dataSharingService.userDataObservable.subscribe((userData) => {
      this.userData = userData;
      if (this.userData != undefined && this.userData.uid != undefined) {
        this.userId = this.userData.uid
      } else {
        this.commonService.getIdentifier(this.userId).subscribe(async (response: any) => {
          this.userData = response;
          this.userData.profileName = this.userData.displayName != undefined ? this.userData.displayName : this.userData.uid;
        })
      }
      this.commonService.getAllUser().subscribe(async (allUsers: any) => {
        this.userDetail = allUsers.users;
      })
      this.userFilesList.subscribe((userFile) => {
        this.userUploadedFileList = userFile
        this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads')
        let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.userId)
        this.userFiledetails = filelist

      })
    });




    this.userDetailsList.subscribe((userFile) => {
      this.UserList = userFile
      this.userProfileData = this.UserList.find((user) => user.UserID == this.userId)
    })

    this.garmentsList.subscribe((garments) => {
      this.garmentsCollection = garments;
    });

    this.snapInIt()
    this.flagFunction = true
    // this.dataSharingService.userSnapShotObservable.subscribe(userSnapShots => {
    //   if (userSnapShots == undefined || userSnapShots.length == 0) {
    //     if(this.avatarID == undefined) {
    //       let reqInputObjectUser = { id: this.userId  , pageNumber: 1 }
    //       this.commonService.yologramSnapshotDetailsByUserId(reqInputObjectUser).subscribe(async (response: any) => {
    //         this.snaploader = false;
    //         this.userSnapShotList= response.object;
    //         this.pagecount =  response.count
    //       })
    //     }else {
    //       let reqInputObjectAvatar = { id: this.avatarID, pageNumber: 1 }
    //       this.commonService.yologramSnapshotDetailsByAvatar(reqInputObjectAvatar).subscribe(async (response: any) => {
    //         this.userSnapShotList= response.object;
    //         this.pagecount =  response.count
    //         console.log("UserSnapShotList",this.userSnapShotList)
    //         this.snaploader = false;
    //       })
    //     }
    //   } else {
    //     this.snaploader = false;
    //     this.userSnapShotList = userSnapShots
    //     console.log("UserSnapShotList After Else",this.userSnapShotList)
    //   }
    // });
    if (this.avatarID != undefined) {
      let title = 'Yologram Avatar'
      this.commonService.changeTitle(title);
    } else {
      let title = 'Yologram' + ' - ' + this.userData?.profileName
      this.commonService.changeTitle(title);
    }


    // if(this.backObject == undefined){
    //   this.commonService.getUser().subscribe((user: any) => {
    //     this.email = user?.email;
    //     this.id = user?.uid;
    //     this.userId =  this.id
    //     this.tab =  'story'
    //      console.log(this.userId)
    //   });
    //  }
  }

  closePopUp() {
    this.modal.dismissAll(CaraosalPopupComponent)
  }

  userFileReload() {
    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads')
      let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.userId)
      this.userFiledetails = filelist
    });
  }
  userFileuploadsd() {
    let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.userId)
    this.userFiledetails = filelist
    this.userFileReload()
  }

  getProfileName(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
    let profileName = userObjet.displayName != undefined ? userObjet.displayName : uuid;
    return profileName;
  }
  getProfilePhoto(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
    let profilephoto = userObjet.photoURL != undefined ? userObjet.photoURL : uuid;
    return profilephoto;
  }

  setPageNumber(pageNumber) {
    this.pageNumber = pageNumber
  }

  getSnapShotListByUser(userId) {
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['public/public-userStory-feed', { 'id': userId }])
  }



  getSelectSnapshotFeed(userSnap) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    if (this.avatarID != undefined) {
      this.router.navigate(['public/public-userStory-feed', { 'id': userId, 'storyId': storyid, 'avatarID': this.avatarID, 'pagecount': this.pagecount }])
    } else {
      this.router.navigate(['public/public-userStory-feed', { 'id': userId, 'storyId': storyid, 'pagecount': this.pagecount }])

    }
  }

  scrollToStory(snap) {
    this.selectedStoryId = snap.StoryID
    this.selectedTab = 'user-story'
  }

  selectSummeryView(tab) {
    this.selectedTab = 'story'
    this.selectTab(tab)
  }


  getSelectSnapshoAvatartFeed(userSnap) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.AvatarID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['public/public-userStory-feed', { 'id': userId, 'storyId': storyid }])
  }


  hideAvatar() {
    if (this.avatarID != undefined) {
      this.hide = false
    }
  }

  snapInIt() {
    this.pageNumber = 1;
    if (this.avatarID != undefined && this.userId != undefined) {
      let reqInputObjectAvatar = { id: this.avatarID, pageNumber: 1 }
      this.commonService.yologramSnapshotDetailsByAvatar(reqInputObjectAvatar).subscribe(async (response: any) => {
        this.filterSnap = response.object;
        this.pagecount = response.count
        this.snaploader = false;
        this.filterSnap.forEach(async (snap: any, index) => {
          let userObjet = this.userDetail.find(userObject => userObject.uid == snap.UserID)
          snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
          userObjet.profileName = snap.profileName;
          snap.userData = userObjet
          snap.firstName = snap.profileName.split(" ", 1);
          snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
        });
      })
    } else {
      let reqInputObject = { id: this.userId, pageNumber: this.pageNumber }
      this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
        this.filterSnap = response.object
        this.pagecount = response.count
        this.snaploader = false;
        this.filterSnap.forEach(async (snap: any, index) => {
          let userObjet = this.userDetail.find(userObject => userObject.uid == snap.UserID)
          snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
          userObjet.profileName = snap.profileName;
          snap.userData = userObjet
          snap.firstName = snap.profileName.split(" ", 1);
          snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
        });
      })
    }
  }


  getSnapShotListByUserID() {
    if (this.avatarID != undefined) {
      let reqInputObject = { id: this.avatarID, pageNumber: 1 }
      this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
        this.userSnapShotList = response.object;
      })
    } else {
      let reqInputObject = { id: this.userData.uid, pageNumber: this.counter }
      this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
        this.allDetails = response.object
        let allAvatarDetails = this.allDetails.filter((snap) => snap.UserID == this.userId)
        this.userSnapShotList = response.object;
      })
    }
  }

  getSnapShotListavtarid(avatar) {
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.AvatarID == avatar);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    if (this.avatarID != undefined) {
      this.router.navigate(['public/public-userStory-feed', { 'id': this.userId, 'storyId': this.storyid, 'avatarID': this.avatarID }])
    } else {
      this.router.navigate(['public/public-userStory-feed', { 'id': this.userId, 'storyId': this.storyid }])

    }
  }


  nextButton(count) {
    this.pageNumber++
    this.counter = this.pageNumber;
    let reqInputObject = { id: this.userData.uid, pageNumber: this.counter }
    this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
      this.filterSnap.push(...response.object);
    })
  }
  nextButtonForAvatar(c) {
    this.pageNumber++
    this.counter = this.pageNumber;
    let reqInputObject = { id: this.avatarID, pageNumber: this.counter }
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
      this.filterSnap.push(...response.object);
    })
  }


  userFileUpload() {
    document.getElementById("file").click();
  }

  //  start2DFileUpload($event: any) {
  //   let uploadFile = $event.target.files[0];
  //   this.uploadFileName = uploadFile.name;
  //   const path = `Uploads/${this.userId}/${this.uploadFileName}`;
  //   const filename = `${this.file}`;
  //   const ref = this.storage.ref(path);
  //   this.task = this.storage.upload(path, uploadFile);
  //   this.percentage = this.task.percentageChanges();
  //   let new_date = new Date();
  //   const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
  //   const uid = generateUUID();
  //   let uploadObject = {
  //     id:uid,
  //     filename: this.uploadFileName,
  //     fileurl: '',
  //     fileurlMini: '',
  //     filepath: path,
  //     date: date,
  //     fileType: 'UserUploads',
  //     userId:   this.userId ,
  //     link: '',
  //   }
  //   this.task.then(async snapshot => {
  //     let downloadURLTest = ref.getDownloadURL();
  //     await downloadURLTest.subscribe(async url => {
  //       uploadObject.fileurl = url
  //       let functionUrl = "http://127.0.0.1:5001/yolomoves-fb435/us-central1/compressUploadsFiles"
  //       const response = await this.http.post(functionUrl, { imageUrl: url, path: `Uploads/${this.userId}`}).toPromise();
  //       uploadObject.fileurlMini = response['fileurlMini']
  //       console.log("uploadObject",uploadObject)
  //       this.db.collection('userfiles').add(uploadObject);
  //     });
  //   })
  // }

  //   async start2DFileUpload($event: any) {
  //     let uploadFile = $event.target.files[0];
  //     this.uploadFileName = uploadFile.name;
  //     const path = `Uploads/${this.userId}/${this.uploadFileName}`;
  //     const ref = this.storage.ref(path);
  //     this.task = this.storage.upload(path, uploadFile);
  //     this.percentage = this.task.percentageChanges();
  //     let new_date = new Date();
  //     const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
  //     const uid = generateUUID();
  //     let uploadObject: any = {
  //         id: uid,
  //         filename: this.uploadFileName,
  //         fileurl: '',
  //         fileurlMini: '',
  //         filepath: path,
  //         date: date,
  //         fileType: 'UserUploads',
  //         userId: this.userId,
  //         link: '',
  //     };

  //     this.task.then(async snapshot => {
  //         let downloadURLTest = ref.getDownloadURL();
  //         await downloadURLTest.subscribe(async url => {
  //             uploadObject.fileurl = url;
  //             let functionUrl = "http://127.0.0.1:5001/yolomoves-fb435/us-central1/compressUploadsFiles";
  //             try {
  //                 const response = await this.http.post(functionUrl, { 
  //                     imageUrl: url, 
  //                     path: `Uploads/${this.userId}/${this.uploadFileName}_128x128` 
  //                 }).toPromise();
  //                 uploadObject.fileurlMini = response['fileurlMini'];
  //                 console.log(" uploadObject.fileurlMini", uploadObject.fileurlMini)

  //                 // Ensure fileurlMini is defined before adding to Firestore
  //                 if (uploadObject.fileurlMini) {
  //                     console.log("uploadObject", uploadObject);
  //                     await this.db.collection('userfiles').add(uploadObject);
  //                 } else {
  //                     console.error('File URL Mini is undefined');
  //                 }
  //             } catch (error) {
  //                 console.error('Error compressing file:', error);
  //             }
  //         });
  //     });
  // }

  async start2DFileUpload($event: any) {
    let uploadFile = $event.target.files[0];
    this.uploadFileName = uploadFile.name;
    const path = `Uploads/${this.userId}/${this.uploadFileName}`;
    const ref = this.storage.ref(path);
    this.task = this.storage.upload(path, uploadFile);
    this.percentage = this.task.percentageChanges();
    let new_date = new Date();
    const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
    const uid = generateUUID();
    let uploadObject: any = {
      id: uid,
      filename: this.uploadFileName,
      fileurl: '',
      fileurlMini: '',
      filepath: path,
      date: date,
      fileType: 'UserUploads',
      userId: this.userId,
      link: '',
    };

    this.task.then(async snapshot => {
      let downloadURLTest = ref.getDownloadURL();
      await downloadURLTest.subscribe(async url => {
        uploadObject.fileurl = url;
        // Generate the modified filename
        const modifiedFileName = this.uploadFileName.replace(/\.[^/.]+$/, "_128x128$&");
        const compressedFilePath = `Uploads/${this.userId}/${modifiedFileName}`;
        let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/compressUploadsFiles";
        try {
          const response = await this.http.post(functionUrl, {
            imageUrl: url,
            path: compressedFilePath
          }).toPromise();
          uploadObject.fileurlMini = response['fileurlMini'];
          console.log(" uploadObject.fileurlMini", uploadObject.fileurlMini)

          // Ensure fileurlMini is defined before adding to Firestore
          if (uploadObject.fileurlMini) {
            console.log("uploadObject", uploadObject);
            await this.db.collection('userfiles').add(uploadObject);
          } else {
            console.error('File URL Mini is undefined');
          }
        } catch (error) {
          console.error('Error compressing file:', error);
        }
      });
    });
  }







  scrollHandler() {
    this.scrollFlag = true
    this.pageScroll = this.userFeeds.nativeElement.scrollTop
    this.selectedStoryId = ''
    setTimeout(() => {
      if ((this.scrollFlag == true && this.pageScroll > 100) || this.selectedStoryId != undefined) {
        this.selectedStoryId = ''
      }
    },
      0);
    let page = window.scrollY

  }

  selectTab(tab: any) {
    this.secteTab = tab
  }

  // setuserDetails(userDetails){
  //   this.loading = true
  //   this.commonService.getAllUser().subscribe(async (allUsers: any) => {
  //     this.allUserDetails = allUsers.users;
  //     let userDetail =  this.allUserDetails.find((user)=>user.uid == this.userId )
  //     let dispalydetails = userDetail.providerData[0]
  //     let saveUserDetails = {
  //       "DisplayName":dispalydetails.displayName != undefined ?  dispalydetails.displayName : '',
  //       "Email":dispalydetails.email != undefined ?  dispalydetails.email : '',
  //       "FirstName": '',
  //       "Identifier":'',
  //       "LastName":'',
  //       "MiddleName":'',
  //       "ProfilePicture":dispalydetails.photoURL != undefined ?  dispalydetails.photoURL : '',
  //       "UserID":userDetail.uid,
  //     }
  //     this.firestore.collection('user-profile').doc(userDetails.id).update(saveUserDetails);
  //     this.loading = false
  //   })
  // }

  setuserDetails(userDetails) {
    console.log('UserDetails', userDetails)
    this.loading = true;
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
      let userDetail = this.allUserDetails.find((user) => user.uid == this.userId);
      if (!userDetail) {
        // Handle the case where the user does not exist.
        this.loading = false;
        return;
      }
      let displayDetails = userDetail.providerData[0];
      let saveUserDetails = {
        "DisplayName": displayDetails.displayName != undefined ? displayDetails.displayName : '',
        "Email": displayDetails.email != undefined ? displayDetails.email : '',
        "FirstName": '',
        "Identifier": '',
        "LastName": '',
        "MiddleName": '',
        "ProfilePicture": displayDetails.photoURL != undefined ? displayDetails.photoURL : '',
        "UserID": userDetail.uid,
        "PageName": 'myuserprofilefeed'
      };
      // Check if the user-profile already has this user's information to avoid logical duplication
      const userProfileRef = this.firestore.collection('user-profile').doc(userDetails.id);
      console.log('userProfileRef', userProfileRef)
      userProfileRef.get().subscribe((docSnapshot) => {
        if (docSnapshot.exists) {
          // Update the existing user profile
          userProfileRef.update(saveUserDetails);
          console.log("Update the existing user profile")
        } else {
          // Create a new user profile if it doesn't exist
          console.log("Create a new user profile if it doesn't exist")
        }
        this.loading = false;
      });
    });
  }

  closePanel() {
    this.wholeDiv = true;
    this.closeDiv = false;
    this.openAvatarmainPANLE = false
    // this.showAvatarPanel = false;
    this.showSearchPanel = false;
    this.showUserPanel = false
  }

  closecard() {
    this.selectedTab = ''
    this.showSearchIcon = false
  }

  handleStoryCall(data) {
    this.nextPage = data
    if (this.nextPage == true) {
      let count = 1
      this.nextButton(count)
    }
  }

  handleStoryId(data) {
    if (!this.hasCalledStoryOnPanel) {
      this.hasCalledStoryOnPanel = true; // Set the flag to prevent further calls
      setTimeout((this.selectedStoryId = data), 50)
      console.log("this.selectedStoryId - data", data)
    }
  }


  //////////////////////////////////////////// new addition of the cod e with avatar////////////////////////////////////////////////////
  handleAvatarEmitData(data) {
    // Initialize saveAvatarData if it's undefined
    if (!this.saveAvatarData) {
      this.saveAvatarData = {};
    }

    // Only update properties that have changed
    if (data.envUrl && data.envUrl !== this.saveAvatarData.envUrl) {
      this.saveAvatarData.envUrl = data.envUrl;
    }
    if (data.functionflagValue && data.functionflagValue !== this.saveAvatarData.functionflagValue) {
      this.saveAvatarData.functionflagValue = data.functionflagValue;
    }
    if (data.garmenturl && data.garmenturl !== this.saveAvatarData.garmenturl) {
      this.saveAvatarData.garmenturl = data.garmenturl;
    }
    if (data.garmentbottom && data.garmentbottom !== this.saveAvatarData.garmentbottom) {
      this.saveAvatarData.garmentbottom = data.garmentbottom;
    }
    if (data.headgear && data.headgear !== this.saveAvatarData.headgear) {
      this.saveAvatarData.headgear = data.headgear;
    }
    if (data.footwear && data.footwear !== this.saveAvatarData.footwear) {
      this.saveAvatarData.footwear = data.footwear;
    }
    if (data.dataBlobUrl && data.dataBlobUrl !== this.saveAvatarData.dataBlobUrl) {
      this.saveAvatarData.dataBlobUrl = data.dataBlobUrl;
    }
    if (data.storyID && data.storyID !== this.saveAvatarData.storyID) {
      this.saveAvatarData.storyID = data.storyID;
    }

    console.log('Updated avatar data:', this.saveAvatarData);
    this.functionflagValue = 'skybox';
    this.closecard()
  }
  openAvatarPanel(flag) {
    this.wholeDiv = false;
    this.closeDiv = true;
    this.showSearchPanel = true;
    this.showAvatarPanel = true;
    this.showUserPanel = true
    if (flag === 'search') {
      this.showSearchPanel = true;
      this.showAvatarPanel = false;
      this.showUserPanel = false
    } else if (flag === 'avatar') {
      this.showAvatarPanel = true;
      this.showSearchPanel = false;
      this.showUserPanel = false
    } else if (flag === 'user') {
      this.showUserPanel = true
      this.showAvatarPanel = false;
      this.showSearchPanel = false;
    }
  }

  handleAvatarPnel
    (data) {
    if (data) {
      this.openAvatarPanel('avatar')
    }
  }

  handleClosepanleFlag(data) {
    if (data) {
      this.closePanel()
      this.snapInIt()
    }
  }

  handleOpenpanleFlag(data) {
    if (data) {
      // this.showSearchIcon = true
    }
  }

  calledVlueIntilized(event) {
    let value = event
    console.log(value)
    if (value == "Initialized") {

    }
  }

  clickOnPanel(event) {
    event.stopPropagation()
  }

  handleSlectedSnapList(data: any) {
    if (data) {
      this.filterSnap = data;
      this.flagFunction = true;
      console.log('this.filterSnap', this.filterSnap);
      // this.selectedTab = 'user-story'
    }
    // Set a timeout to reset flagFunction to false
    setTimeout(() => {
      this.selectedStoryId = ''
      this.flagFunction = false;
      setTimeout(() => {
        this.selectedStoryId = this.StoryID
      }, 1); // Timeout set to 1 second (1000 ms)
    }, 0); // Timeout set to 1 second (1000 ms)
  }

  handleStoryOnPanel(data) {
    this.selectedTab = 'user-story'
    this.selectedStoryId = data
  }

  handUserIDAndAvatrID(data) {
    console.log("Received data:", data);
    this.AvatarSwichDetails = data
    this.getDocumentDetails();
    // Pass the data to getDocumentDetails
  }

  async getDocumentDetails() {
    // Use provided data or fallback to class properties
    const userId =  this.AvatarSwichDetails?.UserID || this.userId;
    const avatarID =  this.AvatarSwichDetails?.AtarID || this.avatarID;
    console.log("UserID and AvatarID being used:", userId, avatarID);
  
    await this.commonService
      .getDocumentsFromSubcollection(userId, avatarID)
      .then((documents) => {
        if (documents && documents.length > 0) {
          const firstDocument = documents[0]; // Assuming there is only one document
          this.userAvatarData = firstDocument;
          this.avatarname = this.userAvatarData?.AvatarName;
          this.openNews3DPassValue();
        } else {
          console.warn("No documents found for given UserID and AvatarID.");
        }
      })
      .catch((error) => {
        console.error("Error in component:", error);
        throw error; // Optionally re-throw the error
      });
  }


  async openNews3DPassValue() {
    console.log("userAvatarData", this.userAvatarData)
    if (this.userAvatarData) {
      console.log('calleing....')

      if(this.saveAvatarData?.dataBlobUrl){
        this.saveAvatarData.dataBlobUrl = null 
        this.saveAvatarData.envUrl = null 
      }

      this.avtarUrl = this.userAvatarData.AvatarURL;
      this.garmenturl = await this.copyTextToClipGarment(this.userAvatarData.cUpperwearID);
      this.fullBodyurl = await this.copyTextToClipGarment(this.userAvatarData.cFullBodyID);


      // Assign the first non-undefined value
      // this.UpperWearfinalUrl = (this.garmenturl !== null || this.garmenturl !== null || this.garmenturl !== ''  ) ? this.garmenturl : this.fullBodyurl;
      this.UpperWearfinalUrl = this.garmenturl == null ? this.fullBodyurl : this.garmenturl

      console.log("garmenturl", this.garmenturl)
      console.log("fullBodyurl", this.fullBodyurl)
      console.log("UpperWearfinalUrl", this.UpperWearfinalUrl)
      this.garmentbottom = await this.copyTextToClipGarment(this.userAvatarData.cBottomwearID);;
      this.genders = await this.userAvatarData.Gender == 'MALE' ? 'M' : 'F';
      this.headgear = await this.copyTextToClipGarment(this.userAvatarData.cHeadgearID);
      this.footwear = await this.copyTextToClipGarment(this.userAvatarData.cFootwearID);
      this.flagForShowThreeJs = true;
      console.log('called....')
      this.functionflagValue = 'LoadOnlyAvatar_' + this.avtarUrl;
      // this.downloadprocessPackZip(this.avtarUrl)
       await this.zipExtractAndSaveFunction(this.avtarUrl)
      this.usrPageAvatarFlag = this.avatarID
      console.log("this.usrPageAvatarFlag - avatar page", this.usrPageAvatarFlag)
    }
    // this.envUrl = envUrl;

  }
  zipExtractAndSaveFunction(url) {
    this.newArray = this.processAvatarUrls()
    console.log()
    console.log("newArray", this.newArray)
    if (this.userAvatarData.AvatarPreviewFullBodyUrl != undefined && this.userAvatarData.AvatarPreviewPortraitUrl != undefined) {
      return
    }
    let reqInputObject = {
      url: url,
      userId: this.userId,
      avatarId: this.avatarID
    }
    this.commonService.avatarPageExtractZip(reqInputObject).subscribe(async (response: any) => {
      // this.filterSnap = response.object;
    })
  }

  processAvatarUrls(): { fileurl: string }[] {
    const urls = [];
    if (this.userAvatarData?.AvatarPreviewFullBodyUrl) {
      urls.push({ fileurlMini: this.userAvatarData.AvatarPreviewFullBodyUrl, fileurl: this.userAvatarData.AvatarPreviewFullBodyUrl, });
    }
    if (this.userAvatarData?.AvatarPreviewPortraitUrl) {
      urls.push({ fileurlMini: this.userAvatarData.AvatarPreviewPortraitUrl, fileurl: this.userAvatarData.AvatarPreviewPortraitUrl, });
    }
    return urls;
  }

  copyTextToClipGarment(text: string) {
    let Garments = this.garmentsCollection.find(
      (garmentList) => garmentList.id === text
    );
    if (Garments) {
      let brandId = Garments.BrandID;
      let gender = Garments.Gender;
      this.GarmentsGender = Garments.Gender;
      let garmentType = Garments.GarmentType;
      let zipfilename = Garments.id;
      // alert(zipfilename)
      let packlocation =
        'AvatarBlenderClothes%2F' +
        brandId +
        '_' +
        gender +
        '%2F' +
        garmentType +
        '%2F' +
        zipfilename;
      this.garmentLink =
        'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
        packlocation +
        '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
      console.log(' this.garmentLink', this.garmentLink)
      return this.garmentLink;
    } else {
      // Handle the case when Garments is not found
      // console.error('Garments not found for id: ' + text);
      return null; // or return an appropriate value or throw an error
    }
  }
  





  

// #region UserPanel_handleSlider


handleChangeuser(data){
  this.newchild.handleChangeAvatar(data)
}


handlesliderUser(snapshot){
  this.Set_UserPanelData = snapshot
  // this.child.resetUserPanel(snapshot)
}


handle_cross_UserPanel_Avatar(data){
  console.log("user Avatar",data)
  this.newchild.handle_userAvatarTag_cross(data)
  this.showAvatar_Useravatar_Slider = false;
}


handle_cross_UserPanel_Brand(data){
  console.log("user Brand",data)
  // this.showCrd = true
  this.newchild.handle_userBrandTag_cross(data)
  this.showBrand_Useravatar_Slider = true;
}

handle_cross_UserPanel_Cloth(data){
  this.newchild.handle_userStyleTag_cross(data)
  this.showCloth_Useravatar_Slider = true;
  console.log("user cloh",data)
}

handle_UserTab(data){
  console.log("handle_UserTab",data)
  this.newchild.handle_UserTab(data)
}

handle_userData(data){
  this.newchild.handle_userData(data)
  console.log("handle_userData",data)
}

changeUser_Avatar(data){
  this.selectedAvatar_UserPanel = data
  this.showCloth_Useravatar_Slider = false;
}

changeUser_Brand(data){
  this.selectedBrand_UserPanel = data
  this.showBrand_Useravatar_Slider = false;
}

changeUser_Cloth(data){
  this.selectedCloth_UserPanel = data
  this.showCloth_Useravatar_Slider = false;
}

closeAvatarSlider(event: boolean) {
  console.log("Avatar slider closed:", event);
  this.showAvatar_Useravatar_Slider = false;
}

closeBrandSlider(event: boolean) {
  console.log("Brand slider closed:", event);
  this.showBrand_Useravatar_Slider = false;
}

closeClothSlider(event: boolean) {
  console.log("Cloth slider closed:", event);
  this.showCloth_Useravatar_Slider = false;
}

// #endregion UserPanel_handleSlider

} 

