import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-search-tag-panel',
  templateUrl: './search-tag-panel.component.html',
  styleUrls: ['./search-tag-panel.component.css']
})
export class SearchTagPanelComponent {

  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;



  @Input() snapData: any;
  @Input() snapshots: any;
  @Input() page: any;
  @Input() selectedBrand: any;
  @Input() exclosiveMode: any;
  @Input() showCrossButton: boolean;
  

  @Input() nonBrand: any = {};

  

  

  @Output() emitrightPanelFlag: EventEmitter<any> = new EventEmitter();
  @Output() serchedStory: EventEmitter<any> = new EventEmitter();
  @Output() closeSearch: EventEmitter<any> = new EventEmitter();
  @Output() closePanelEmitter: EventEmitter<any> = new EventEmitter();
  @Output() selectTheStory: EventEmitter<any> = new EventEmitter();

  @Output() selectedSnap: EventEmitter<any> = new EventEmitter();

  @Output() snapListEmit: EventEmitter<any> = new EventEmitter();

  

  


  

  


  



  loadingSnap:boolean = true
  garmentsCollection: any = [];
  brandsCollection: any = [];
  evnFileList: any = [];
  userData: any = [];
  lastfeild: string;
  paginatedSnapshots: any = [];
  endIdx: number;
  next: number = 1;
  userSnap: any = [];
  showStoryCard: boolean;
  loading: boolean;
  allsnapObject: any = [];
  snapInitCall: boolean;
  rightPanelFlag: boolean;
  flowState: boolean;
  showSnapshots: boolean = false;
  allsnapshotsList: any = [];
  showChild: boolean;
  isOnlyEmpt: boolean;
  showsnapbysearch: boolean = false;
  newCloseEvent: boolean;









  constructor(private storage: AngularFireStorage,
    private router: Router,
    public commonService: CommonService,
    public dialog: MatDialog,
    private firestore: AngularFirestore,
    private http: HttpClient,) {


    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollection = brands;
    });

    this.environmentCollection.subscribe(
      (evnFile) => {
        this.evnFileList = evnFile;
      }
    );

    this.garmentsList.subscribe(
      garments => {
        this.garmentsCollection = garments;
      }
    );

    this.commonService.getSnapshots().subscribe((snapshots: any) => {
      if (snapshots?.object && snapshots?.object.length > 0) {
        this.allsnapshotsList = snapshots.object
        this.search()
      }
    })



    this.user.subscribe(userdetails => {
      this.userData = userdetails;
    });

    // this.environmentCollection.subscribe(
    //   (evnFile) => {
    //     this.evnFileList = evnFile
    //   });
    // this.garmentsList.subscribe(
    //   garments => {
    //     this.garmentsCollection = garments;
    //   });
    // this.brandsFilesList.subscribe((brands) => {
    //   this.brandsCollection = brands;
    // });
    // this.user.subscribe(userdetails => {
    //   this.userData = userdetails
    //   // this.userDetails()
    // });

    // this.commonService.getSnapshots().subscribe((snapshots: any) => {
    //   this.userSnap = snapshots?.object;

    //   // Use forEach to loop through each snapShot and call getAvatarDetails
    //   this.userSnap.forEach((snapShot) => {
    //     this.getAvatartDetails(snapShot);
    //   });

    //   this.loading = false;
    //   this.showStoryCard = true;
    //   this.allsnapObject = this.userSnap;
    // });

    // this.commonService.getSnapshots().subscribe(async (snapshots: any) => {
    //   this.userSnap = snapshots?.object
    //   await Promise.all(this.userSnap.map((snapShot) => this.getAvatartDetails(snapShot)));
    //   this.loading = false
    //   this.showStoryCard = true
    //   this.allsnapObject = this.userSnap
    //   console.log("allsnapObject",this.allsnapObject)
    // });
  }


  conditionsearchDetails: any
  searchFields = [
    { selectedOption: '', inputValue: '', isDropdownOpen: false, typeValue: '', id: '' }  // Initially show one dropdown and input
  ];
  searchTerms: any;
  brandOption: null;
  avatarOption: null;
  SaveAvatrGender: null;
  envOption: null;
  SaveGarmentGender: null;
  userOption: null;
  singleSnap: any;
  garmentOption: any;
  selectedOption: any;
  garment: null;
  User: null;
  Brand: null;
  snapFiltered: any = [];
  userSnafilter: any = [];
  inputValue: string;


  ngOnInit() {




  }


  // ngOnChanges(changes: SimpleChanges) {
  //   this.flowState = true
  //   if (changes['snapData']) {
  //     setTimeout(() => {
  //       console.log("snapData", this.snapData);
  //       this.selectStorySearch(this.snapData);
  //       this.flowState = false
  //     }, 1000); // 200ms delay
  //   }
  //   if (changes['snapshots']) {
  //     setTimeout(() => {
  //       console.log("snapshots", this.snapshots);
  //       this.userSnap = this.snapshots
  //       this.allsnapObject = this.userSnap
  //     }, 20); // 200ms delay
  //   }

  //   if (changes['selectedBrand']) {
  //     console.log("selectedBrand", this.selectedBrand);

  //   }

  //   if (changes['nonBrand']) {
  //     setTimeout(() => {
  //       this.flowState = false
  //       console.log("nonBrand", this.nonBrand);
  //       // this.selectednotExclosue(this.nonBrand)
  //     }, 50)

  //   }
  //   // this.search()
  // }


  ngOnChanges(changes: SimpleChanges) {
    this.flowState = true;
  
    if (changes['snapData']) {
      setTimeout(() => {
    
        console.log("snapData", this.snapData);
  
        // Check if snapData is defined before calling selectStorySearch
        if (this.snapData) {
          this.selectStorySearch(this.snapData);
        } else {
          console.warn('snapData is undefined');
        }
        
        this.flowState = false;
      }, 1000);
    }
  
    if (changes['snapshots']) {
      setTimeout(() => {
        console.log("snapshots", this.snapshots);
  
        if (this.snapshots) {
          this.userSnap = this.snapshots;
          this.allsnapObject = this.userSnap;
        } else {
          console.warn('snapshots is undefined');
        }
      }, 20);
    }
  
    if (changes['selectedBrand']) {
      console.log("selectedBrand", this.selectedBrand);
    }
  
    if (changes['nonBrand']) {
      setTimeout(() => {
        this.flowState = false;
        console.log("nonBrand", this.nonBrand);
  
        // Ensure nonBrand is defined before using it
        if (this.nonBrand) {
          // Uncomment if you want to use selectednotExclosue
          // this.selectednotExclosue(this.nonBrand);
        } else {
          console.warn('nonBrand is undefined');
        }
      }, 50);
    }

    if (this.showCrossButton) {
      this.newCloseEvent = this.showCrossButton
      console.log("showCrossButton", this.showCrossButton);
    }

    
  }
  getSearchedBrandImage(brandName) {
    let brand = this.brandsCollection.find((b) => b.BrandName == brandName)
    let brandUrl = this.getBrandImage(brand.LogoURL)
    return brandUrl || ""
  }

  getSearchedGarmentRealType(garmentName) {

    let garment = this.garmentsCollection.find((g) => g.Name == garmentName)
    let Url = this.getGarmentTypeLogo(garment.RealType)
    return Url || ""
  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }

  handlePlusClick() {
    const lastField = this.searchFields[this.searchFields.length - 1];

    // Only add a new set if the last one has a valid selected option
    if (lastField.selectedOption && (lastField.selectedOption === 'User' || lastField.selectedOption === 'Garment' || lastField.selectedOption === 'Brand'

      || lastField.selectedOption === 'Avatar' || lastField.selectedOption === 'Env'
    )) {
      this.searchFields.push({ selectedOption: '', inputValue: '', isDropdownOpen: false, typeValue: '', id: '' });
    } else {
      console.log('Please select a valid option before adding more.');
    }

    this.lastfeild = lastField.selectedOption
    this.openRightSearchPanel()

  }
  handleSearchClick() {
    this.search()
  }


  getGarmentTypeLogo(option) {
    switch (option) {
      case 0:
        return 'assets/newsfeed/headgear-new.png';
      case 1:
        return 'assets/realtype/upperwear.png'; // Replace with correct image path
      case 2:
        return 'assets/newsfeed/bottomwear-new.png'; // Replace with correct image path
      case 3:
        return 'assets/realtype/footwear.png'; // Replace with correct image path
      case 4:
        return 'assets/realtype/fullbody.png'; // Replace with correct image path
      case 5:
        return './assets/realtype/fullbody.png'; // Replace with correct image path

      default:
        return ''; // Default or placeholder image
    }
  }

  handleConditionSearch(data) {
    this.conditionsearchDetails = data
  }

  closeSechcondition() {
    this.conditionsearchDetails = null
  }
  getImageForOption(option) {
    switch (option) {
      case 'User':
        return 'assets/search/user.png';
      case 'Garment':
        return 'assets/search/garment.png'; // Replace with correct image path
      case 'Brand':
        return 'assets/search/brand.png'; // Replace with correct image path
      case 'Avatar':
        return 'assets/search/avatar.png'; // Replace with correct image path
      case 'Env':
        return 'assets/search/spaces.png'; // Replace with correct image path
      default:
        return ''; // Default or placeholder image
    }
  }


  selectectEmpty(){
    const isOnlyEmptyEntry = this.searchFields.length === 1 &&
                           !this.searchFields[0].selectedOption &&
                           !this.searchFields[0].inputValue &&
                           !this.searchFields[0].typeValue &&
                           !this.searchFields[0].id;

  // if (isOnlyEmptyEntry) {
  //   alert('Please make a selection.');
  // } else {
  //   console.log('Search fields cleared, retaining one dropdown with matching entry if available.');
  // }
  this.isOnlyEmpt = isOnlyEmptyEntry
  }

  removeField(field, index) {
    if (this.selectedBrand) {
      if (this.exclosiveMode  == 'Y' && (this.selectedBrand?.id == field.id)) {
        alert("You are not allowed to remove the selected Brand.");
        return;
      }
    }



    this.searchFields.splice(index, 1);
    if (field.selectedOption === 'User') {
      this.userOption = null;
    } else if (field.selectedOption === 'Garment') {
      console.log('Before removal, searchTerms:', this.searchTerms);

      this.SaveGarmentGender = null      // Find the index of the term that matches exactly with the field's input value
      const termIndex = this.searchTerms.findIndex(
        (term) => term.toLowerCase().trim() === field.inputValue.toLowerCase().trim()
      );

      if (termIndex > -1) {
        // Remove the term from searchTerms array
        this.searchTerms.splice(termIndex, 1);
        console.log('After removal, searchTerms:', this.searchTerms);
      } else {
        console.log('Term not found for removal:', field.inputValue);
      }
    } else if (field.selectedOption === 'Brand') {
      this.brandOption = null;
    } else if (field.selectedOption === 'Avatar') {
      this.avatarOption = null;
      this.SaveAvatrGender = null
    } else if (field.selectedOption === 'Env') {
      this.envOption = null;
    }
  }

  handleSearchClear() {
    this.inputValue = '';
    this.userSnafilter = [];
    this.snapFiltered = [];
    this.searchTerms = []
    this.Brand = null;
    this.User = null;
    this.garment = null;
    this.selectedOption = null
    this.userOption = null
    this.garmentOption = null
    this.brandOption = null
    // this.searchFields = [{ selectedOption: '', inputValue: '', isDropdownOpen: false, typeValue: '', id: '' }]; // Keep at least one dropdown with proper properties
    // console.log('Search fields cleared, retaining one dropdown.');

    const preservedEntry = this.searchFields.find(field => field.id === this.selectedBrand?.id);

  // Reset the search fields but retain the matching entry, if any
    if (this.exclosiveMode == 'Y') {
      this.searchFields = preservedEntry
        ? [{ ...preservedEntry }]
        : [{ selectedOption: '', inputValue: '', isDropdownOpen: false, typeValue: '', id: '' }];
      console.log('Search fields cleared, retaining the matching dropdown if present.');
    } else {
      this.searchFields = [{ selectedOption: '', inputValue: '', isDropdownOpen: false, typeValue: '', id: '' }];
    }
 
  }


  async selectStorySearch(snap) {
    let field = this.searchFields;
    this.handleSearchClear();
    let user = this.userData.find((u) => u?.UserID === snap.UserID);
    this.userOption = user?.DisplayName || 'debug';
    let AvatarID = snap.avatarName;
    let BottomwearID = snap.BottomwearID;
    let Environment = snap.Environment;
    let FootwearID = snap.FootwearID;
    let HeadwearID = snap.HeadwearID;
    let UpperwearID = snap.UpperwearID;
    let UserID = snap.DisplayName;
    let AId = snap.AvatarID;
    const getGarmentDetails = (garmentID) => {
      let garment = this.garmentsCollection.find((g) => g?.id === garmentID);
      if (garment) {
        return {
          name: garment.Name,
          gender: garment.Gender || 'Unknown', // Return 'Unknown' if gender is not available
          type: garment.GarmentType
        };
      } else {
        return {
          name: garmentID,
          gender: 'Unknown',
        };
      }
    };


    let bottomwearDetails = getGarmentDetails(BottomwearID);
    let footwearDetails = getGarmentDetails(FootwearID);
    let headwearDetails = getGarmentDetails(HeadwearID);
    let upperwearDetails = getGarmentDetails(UpperwearID);




    // Helper function to get the garment name by ID
    const getGarmentName = (garmentID) => {
      let garment = this.garmentsCollection.find((g) => g?.id === garmentID);
      return garment ? garment?.Name : garmentID; // Use garment.Name if found, otherwise use the ID
    };

    const getBrandName = (garmentID) => {
      let garment = this.garmentsCollection.find((g) => g?.id === garmentID);
      let brand = this.brandsCollection.find((b) => b?.id === garment.BrandID);
      return brand ? brand : garment?.BrandID; // Use garment.Name if found, otherwise use the ID
    };

    // Check each variable and add to searchFields if not empty
    if (UserID !== "") {
      this.searchFields.push({
        selectedOption: 'User',
        inputValue: this.userOption,
        typeValue: '', // Blank for non-garment fields
        isDropdownOpen: false,
        id: snap.UserID
      });
      this.selectedOption = 'User';
    }

    if (AvatarID !== "") {
      this.searchFields.push({
        selectedOption: 'Avatar',
        inputValue: AvatarID,
        typeValue: '', // Blank for non-garment fields
        isDropdownOpen: false, id: AId
      });
      this.avatarOption = AvatarID || 'Avatar';
    }

    if (Environment !== "") {
      let defaultValue = 'default';
      let envFileNames = '';
      let evenPath = 'Environments/' + Environment;
      if (defaultValue === Environment) {
        envFileNames = defaultValue;
      } else {
        let env = this.evnFileList.find((u) => u?.storageURL === evenPath);
        envFileNames = env?.displayName;
      }
      this.searchFields.push({
        selectedOption: 'Env',
        inputValue: envFileNames,
        typeValue: '', // Blank for environment
        isDropdownOpen: false,
        id: snap.Environment
      });
    }

    // Handle Headwear
    if (HeadwearID !== "") {
      this.searchFields.push({
        selectedOption: 'Brand',
        inputValue: getBrandName(HeadwearID).BrandName,
        typeValue: '', // Set typeValue for brands
        isDropdownOpen: false,
        id: getBrandName(HeadwearID).id
      });
      this.searchFields.push({
        selectedOption: 'Garment',
        inputValue: getGarmentName(HeadwearID),
        typeValue: 'Headwear', // Set typeValue for garments
        isDropdownOpen: false,
        id: HeadwearID
      });
    }
    // Handle Upperwear
    if (UpperwearID !== "") {
      this.searchFields.push({
        selectedOption: 'Brand',
        inputValue: getBrandName(UpperwearID).BrandName,
        typeValue: '', // Set typeValue for brands
        isDropdownOpen: false,
        id: getBrandName(UpperwearID).id

      });
      this.searchFields.push({
        selectedOption: 'Garment',
        inputValue: getGarmentName(UpperwearID),
        typeValue: 'Upperwear', // Set typeValue for garments
        isDropdownOpen: false,
        id: UpperwearID
      });
    }

    // Handle Bottomwear
    if (BottomwearID !== "") {
      this.searchFields.push({
        selectedOption: 'Brand',
        inputValue: getBrandName(BottomwearID).BrandName,
        typeValue: '', // Set typeValue for brands
        isDropdownOpen: false,
        id: getBrandName(BottomwearID).id
      });
      this.searchFields.push({
        selectedOption: 'Garment',
        inputValue: getGarmentName(BottomwearID),
        typeValue: 'Bottomwear', // Set typeValue for garments
        isDropdownOpen: false,
        id: BottomwearID
      });
    }

    // Handle Footwear
    if (FootwearID !== "") {
      this.searchFields.push({
        selectedOption: 'Brand',
        inputValue: getBrandName(FootwearID).BrandName,
        typeValue: '', // Set typeValue for brands
        isDropdownOpen: false,
        id: getBrandName(FootwearID).id
      });

      this.searchFields.push({
        selectedOption: 'Garment',
        inputValue: getGarmentName(FootwearID),
        typeValue: 'Footwear', // Set typeValue for garments
        isDropdownOpen: false,
        id: FootwearID
      });
    }
    this.searchFields = this.searchFields.filter((item, index, self) => {
      return (
        item.selectedOption !== "Brand" ||
        self.findIndex(
          (t) =>
            t.selectedOption === "Brand" &&
            t.id === item.id &&
            t.inputValue === item.inputValue
        ) === index
      );
    });
    this.removeField(field, 0);
    this.singleSnap = snap;
    // let obj = await this.getAvatartObject(snap)
    // this.SaveAvatrGender = obj.Gender
    this.SaveGarmentGender = bottomwearDetails.gender || footwearDetails.gender || headwearDetails.gender || upperwearDetails.gender

  }



  moreButtonForNext() {
    console.log('Initializing moreButtonForNext...');
    // this.snapInitCall = false;
    console.log('snapInitCall set to false');
    const itemsPerPage = 12;
    this.next += 1;
    console.log('Current page (next):', this.next);

    const startIdx = (this.next - 1) * itemsPerPage;
    const endIdx = this.next * itemsPerPage;
    console.log('Start index:', startIdx, 'End index:', endIdx);

    const newSnapshots = this.userSnafilter.slice(startIdx, endIdx);
    console.log('New snapshots to add:', newSnapshots);

    this.endIdx = endIdx;
    console.log('Updated endIdx:', this.endIdx);

    if (newSnapshots.length > 0) {
      this.snapFiltered.push(...newSnapshots);
      console.log('snapFiltered updated:', this.snapFiltered);

      let debounceTimeout;

      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        console.log('snapInitCall set to true after timeout');

        this.paginatedSnapshots.push(...newSnapshots);
        console.log('paginatedSnapshots updated:', this.paginatedSnapshots);

      }, 200); // Wait for 300ms after the last event

    } else {
      console.log('No new snapshots found, no update made to snapFiltered or paginatedSnapshots.');
    }
  }

  async search() {
    this.showsnapbysearch = true
    this.selectectEmpty()
    if (this.isOnlyEmpt) {
      return
    }



    this.snapInitCall = false;
    this.userSnafilter = [];
    const brandIds = new Set<string>();  // Use a Set to hold unique Brand IDs
    let userId = "";
    let avatarId = "";
    let envId = "";
    let garmentMap = {
      BottomwearID: "",
      UpperwearID: "",
      HeadwearID: "",
      FootwearID: ""
    };

    let mapUserIds
    let avatarName
    let mapSpaceIds
    let mapGarmentIds
    let mapBrandIds
    const defaultId = 'default'

    if (this.conditionsearchDetails) {
      const { selectedValue, searchInput } = this.conditionsearchDetails;
      if (selectedValue == 'User') {
        let users = this.userData.filter((u) => u?.DisplayName.toLowerCase().includes(searchInput?.toLowerCase()));
        let userIDs = users.map((u) => u.UserID);
        let displayName = users.map((u) => u.DisplayName);
        console.log("userIDs,", userIDs)
        console.log("displayName", displayName)
        mapUserIds = userIDs
      }

      if (selectedValue == 'avatar') {
        let avatar = this.userSnap.filter((u) =>
          u?.avatarName.toLowerCase().includes(searchInput?.toLowerCase()));
        console.log("avatar", avatar)
        avatarName = avatar.map((u) => u.avatarName);
      }

      if (selectedValue == 'space') {
        if (defaultId.toLowerCase().includes(searchInput?.toLowerCase())) {
          mapSpaceIds = [defaultId];
        } else {
          let env = this.evnFileList.filter((u) =>
            u?.displayName.toLowerCase().includes(searchInput?.toLowerCase())
          );
          mapSpaceIds = env.map((u) => u.storageURL.replace('Environments/', ''));
          console.log("mapSpaceIds", mapSpaceIds)
        }
      }

      if (selectedValue == 'Garment') {
        let filteredGarments = this.garmentsCollection.filter((garment) =>
          garment?.Name.toLowerCase().includes(searchInput?.toLowerCase())
        );
        mapGarmentIds = filteredGarments.map((u) => u.id);
        console.log("filteredGarments", filteredGarments)
      }

      if (selectedValue == 'brand') {

        let filteredBrand = this.brandsCollection.filter((brand) =>
          brand?.BrandName.toLowerCase().includes(searchInput?.toLowerCase())
        );

        mapBrandIds = filteredBrand.map((b) => b.id);
        console.log("filteredBrand", filteredBrand)
        console.log("brandIds", brandIds)
        mapBrandIds.forEach((id) => brandIds.add(id));
        console.log("brandIds", brandIds)



      }
    }

    // Loop through the search fields to extract User, Avatar, Environment, and Garment filters
    this.searchFields.forEach((field) => {
      if (field.selectedOption === 'User') {
        userId = field.id;  // Get the exact User ID
      } else if (field.selectedOption === 'Avatar') {
        avatarId = field.id;  // Get the exact Avatar ID
      } else if (field.selectedOption === 'Env') {
        envId = field.id;  // Get the exact Environment ID
      } else if (field.selectedOption === 'Garment') {
        // Match the garment type and store its corresponding ID
        if (field.typeValue === 'Upperwear') {
          garmentMap.UpperwearID = field.id;
        } else if (field.typeValue === 'Bottomwear') {
          garmentMap.BottomwearID = field.id;
        } else if (field.typeValue === 'Headwear') {
          garmentMap.HeadwearID = field.id;
        } else if (field.typeValue === 'Footwear') {
          garmentMap.FootwearID = field.id;
        }
      } else if (field.selectedOption === 'Brand') {
        brandIds.add(field.id);  // Add Brand ID to the set
      }
    });


    console.log("searchFields", this.searchFields)

    console.log("this.conditionsearchDetails", this.conditionsearchDetails)

    // Filter garments based on selected brand IDs
    const garments = this.garmentsCollection.filter((g) => brandIds.has(g.BrandID));
    const garmentIdS = Array.from(new Set(garments.map((g) => g.id))); // Ensure unique IDs

    console.log("garmentIdS", garmentIdS);
    console.log("garments", garments);

    mapGarmentIds = mapGarmentIds || [];

    // Merge garment IDs from both mapGarmentIds and garmentIdS
    const garmentMapValues = Object.values(garmentMap).filter(id => id !== '');
    // Merge mapGarmentIds and garmentMapValues into a Set

    console.log("garmentMapValues", garmentMapValues)
    const combinedGarmentIds = new Set([...mapGarmentIds, ...garmentMapValues]);

    console.log('combinedGarmentIds:', combinedGarmentIds); // Debug to check the combined set

    if (!this.userSnap || this.userSnap.length === 0) {
      this.showsnapbysearch = false
      this.userSnap = this.allsnapshotsList;
      this.allsnapObject =  this.userSnap
      console.log("this.userSnap 2", this.userSnap);
      this.loadingSnap = false
    }

    if (this.userSnap &&  this.userSnap.length > 0) {
      this.loadingSnap = true
    }

    // Filter the snapshots based on UserID, AvatarID, Environment, and Garment IDs
    this.userSnafilter = this.userSnap.filter((snap) => {

      const matchesUser = mapUserIds ? (mapUserIds?.length === 0 || mapUserIds.includes(snap.UserID)) : (!userId || snap.UserID === userId);
      const matchesAvatar = avatarName ? (avatarName.length === 0 || avatarName.includes(snap.AvatarName)) : (!avatarId || snap.AvatarID === avatarId);
      const matchesEnv = mapSpaceIds ? (mapSpaceIds.length === 0 || mapSpaceIds.includes(snap.Environment)) : (!envId || snap.Environment === envId);
      const garmentBrandMatches = garments.length == 0 || garments.some((g) =>
        (g.id === snap.UpperwearID || g.id === snap.BottomwearID || g.id == snap.HeadwearID || g.id == snap.FootwearID)
        && garmentIdS.includes(g.id)
      );

      const combinedGarmentMatchH = !garmentMap.HeadwearID || combinedGarmentIds.has(snap.HeadwearID);
      const combinedGarmentMatchU = !garmentMap.UpperwearID || combinedGarmentIds.has(snap.UpperwearID);
      const combinedGarmentMatchB = !garmentMap.BottomwearID || combinedGarmentIds.has(snap.BottomwearID);
      const combinedGarmentMatchF = !garmentMap.FootwearID || combinedGarmentIds.has(snap.FootwearID);
      // Return true only if the snap matches all non-empty filters and brand garments
      return (
        matchesUser &&
        matchesAvatar &&
        matchesEnv &&
        garmentBrandMatches &&
        combinedGarmentMatchB &&
        combinedGarmentMatchU &&
        combinedGarmentMatchH &&
        combinedGarmentMatchF
      );
    });



    // Now we need to filter out snapshots that do not match ALL brand requirements
    let removeSnapShot = [];
    let removeSnapShotGarment = [];
    let gSearchTerm = [];

    if (this.conditionsearchDetails?.selectedValue == 'Garment') {
      // Check and push BottomwearID if present
      if (garmentMap.BottomwearID != '') {
        let bottomwearGarment = this.garmentsCollection.find((x) => x.id === garmentMap.BottomwearID);
        if (bottomwearGarment) {
          gSearchTerm.push({ type: 'Bottomwear', name: bottomwearGarment.Name }); // Store with type and name
        }
      }
      // Check and push UpperwearID if present
      if (garmentMap.UpperwearID != '') {
        let upperwearGarment = this.garmentsCollection.find((x) => x.id === garmentMap.UpperwearID);
        if (upperwearGarment) {
          gSearchTerm.push({ type: 'Upperwear', name: upperwearGarment.Name }); // Store with type and name
        }
      }
      // Check and push HeadwearID if present
      if (garmentMap.HeadwearID != '') {
        let headwearGarment = this.garmentsCollection.find((x) => x.id === garmentMap.HeadwearID);
        if (headwearGarment) {
          gSearchTerm.push({ type: 'Headwear', name: headwearGarment.Name }); // Store with type and name
        }
      }
      // Check and push FootwearID if present
      if (garmentMap.FootwearID != '') {
        let footwearGarment = this.garmentsCollection.find((x) => x.id === garmentMap.FootwearID);
        if (footwearGarment) {
          gSearchTerm.push({ type: 'Footwear', name: footwearGarment.Name }); // Store with type and name
        }
      }
      // Handle search input: treat it as a global search term to match across all garments
      gSearchTerm.push({ type: 'searchInput', name: this.conditionsearchDetails.searchInput });
      console.log('gSearchTerm', gSearchTerm);
      // Filter userSnafilter snapshots
      this.userSnafilter.filter(async (snap) => {
        let filterKeyWordFlag = {};

        // Iterate over gSearchTerm and match each term with the respective garment ID in the snapshot
        gSearchTerm.forEach((term) => {
          filterKeyWordFlag[term.name] = false;

          // Check the correct garment type in the snapshot
          if (term.type === 'Headwear' && snap.HeadwearID) {
            filterKeyWordFlag[term.name] = this.garmentsCollection.find((x => x.id === snap.HeadwearID))?.Name.toLowerCase().includes(term.name.toLowerCase());
          }
          if (term.type === 'Upperwear' && snap.UpperwearID) {
            filterKeyWordFlag[term.name] = this.garmentsCollection.find((x => x.id === snap.UpperwearID))?.Name.toLowerCase().includes(term.name.toLowerCase());
          }
          if (term.type === 'Bottomwear' && snap.BottomwearID) {
            filterKeyWordFlag[term.name] = this.garmentsCollection.find((x => x.id === snap.BottomwearID))?.Name.toLowerCase().includes(term.name.toLowerCase());
          }
          if (term.type === 'Footwear' && snap.FootwearID) {
            filterKeyWordFlag[term.name] = this.garmentsCollection.find((x => x.id === snap.FootwearID))?.Name.toLowerCase().includes(term.name.toLowerCase());
          }

          // If it's the search input, check if it exists in any garment's name
          if (term.type === 'searchInput') {
            filterKeyWordFlag[term.name] = this.garmentsCollection.find((x => x.id === snap.HeadwearID || x.id === snap.UpperwearID || x.id === snap.BottomwearID || x.id === snap.FootwearID))?.Name.toLowerCase().includes(term.name.toLowerCase());
          }
        });
        console.log('filterKeyWordFlag:', filterKeyWordFlag);
        let allMatched = gSearchTerm.every(term => filterKeyWordFlag[term.name] === true);
        if (!allMatched) {
          removeSnapShotGarment.push(snap.id);
        }
        return allMatched;
      });

      this.userSnafilter = await this.userSnafilter.filter(
        (snap) => !removeSnapShotGarment.includes(snap.id)
      );
    }

    this.userSnafilter = this.userSnafilter.filter((snap) => {
      let filterKeywordFlag = false;
      // Check if all garment parts (Upperwear, Bottomwear, etc.) match one of the selected brands
      for (const brandId of brandIds) {
        const matchesBrand = garments.some((g) =>
          (g.id === snap.UpperwearID || g.id === snap.BottomwearID || g.id === snap.HeadwearID || g.id === snap.FootwearID)
          && g.BrandID === brandId
        );
        if (!matchesBrand) {
          removeSnapShot.push(snap.id);  // Mark this snap for removal if not all brands match
          filterKeywordFlag = true;  // Exit early once a mismatch is found
          break;
        }



      }
      return !filterKeywordFlag;  // If no mismatches, keep the snapshot
    });
    this.snapFiltered = this.userSnafilter.slice(0, 12);
    this.paginatedSnapshots = this.snapFiltered;
    this.showSnapshots = true; // Set to true to display content
    this.serchedStory.emit(this.paginatedSnapshots)
    console.log('Filtered snapshots:', this.snapFiltered);


    this.showsnapbysearch = true

    if (this.snapFiltered && this.snapFiltered?.length === 0) {
      alert('Filtered snapshots:'+this.snapFiltered?.length);
      this.showsnapbysearch = true
      this.showSnapshots = false; // Set to true to display content
    }

  }

  async getAvatartDetails(snapShots) {
    try {
      snapShots.AvatarName = ''
      snapShots.avatarName = ''

      // Check if UserID and AvatarID are available before proceeding
      if (!snapShots.UserID || !snapShots.AvatarID) {
        console.warn('Missing UserID or AvatarID:', snapShots);
        snapShots.AvatarName = snapShots.AvatarID || 'Unknown Avatar';
        snapShots.avatarName = snapShots.AvatarID || 'Unknown Avatar';
        return;
      }

      // Fetch avatar details from the common service
      const documents = await this.commonService.getUserAvatarDetails(snapShots.UserID, snapShots.AvatarID);
      if (documents?.length > 0) {
        const firstDocument = documents[0];
        snapShots.AvatarName = firstDocument.AvatarName || snapShots.AvatarID;
        snapShots.avatarName = snapShots.AvatarName
      } else {
        snapShots.AvatarName = snapShots.AvatarID;
        snapShots.avatarName = snapShots.AvatarID;

      }
    } catch (error) {
      console.error('Error in getAvatarDetails:', error);
      throw error; // Re-throw the error or handle it here as needed
    }
  }

  openRightSearchPanel() {
    this.rightPanelFlag = true
    this.emitrightPanelFlag.emit(this.rightPanelFlag)
  }

  closeRightSearchPanel() {
    this.rightPanelFlag = false
    this.emitrightPanelFlag.emit(this.rightPanelFlag)
  }


  handleUserDetails(data) {
    this.conditionsearchDetails = null
    // Find if there is already an entry with selectedOption 'User'
    const userFieldIndex = this.searchFields.findIndex(field => field.selectedOption === 'User');

    if (userFieldIndex !== -1) {
      // If 'User' is found, update the corresponding entry
      this.searchFields[userFieldIndex] = {
        ...this.searchFields[userFieldIndex],
        inputValue: data.DisplayName,
        id: data.UserID
      };
    } else {
      // If 'User' is not found, you can add a new entry or do nothing
      this.searchFields.push({
        selectedOption: 'User',
        inputValue: data.DisplayName,
        isDropdownOpen: false,
        typeValue: '',
        id: data.UserID
      });
    }
    // this.userSearchDetails = data
    console.log("searchfeid with User", this.searchFields)
  }
  handleGarmentDetails(data) {
    console.log("GarmentSelection", data);

    let garmentObj = this.garmentsCollection.find((g) =>
      g.id === this.singleSnap.UpperwearID ||
      g.id === this.singleSnap.BottomwearID ||
      g.id === this.singleSnap.HeadgearID ||
      g.id === this.singleSnap.FootwearID
    ); console.log("garmentObj", garmentObj);


    if (this.SaveGarmentGender) {
      if (this.SaveGarmentGender != data.Gender) {
        alert('The selected garment does not match the Gender.');
        return
      }
    }


    this.SaveGarmentGender = data.Gender

    const garment = this.searchFields.findIndex(field => field.selectedOption === 'Garment');
    if (garment) {
      // Define possible garment types
      const garmentTypes = ['headgear', 'upperwear', 'bottomwear', 'footwear'];
      // Iterate over garment types and check if they exist in the data
      garmentTypes.forEach((type) => {
        // Check if the garment type exists in the data
        if (data.GarmentType === type) {
          // Check if this garment type already exists in searchFields
          const existingFieldIndex = this.searchFields.findIndex(
            field => field.typeValue.toLowerCase() === type
          );

          const newField = {
            selectedOption: 'Garment',
            inputValue: data.Name, // The name of the garment
            isDropdownOpen: false,
            typeValue: this.capitalize(type), // Ensure the first letter is capitalized
            id: data.id
          };


          console.log("existingFieldIndex", existingFieldIndex)
          // If it exists, replace it
          if (existingFieldIndex > -1) {
            this.searchFields[existingFieldIndex] = newField;
          } else {
            // If it doesn't exist, push it to the array
            this.searchFields.push(newField);
          }
        }
      });
    }
    console.log("searchfeid with Garment", this.searchFields)

  }
  handleBrandDetails(data) {
    // Filter out all fields that are selectedOption === 'Brand'
    const brandFields = this.searchFields.filter(field => field.selectedOption === 'Brand');
    if (brandFields.length >= 4) {
      alert('You can only add up to 4 brands.');
      return; // Prevent further execution if the limit is reached
    }

    // Check if the same Brand (same id and inputValue) already exists
    const brandExists = brandFields.some(field => field.inputValue === data.BrandName && field.id === data.id);

    if (brandExists) {
      // If the brand already exists, show an alert and do not push
      alert('This brand is already added.');
    } else {
      // If not, push the new brand into the searchFields array
      this.searchFields.push({
        selectedOption: 'Brand',
        inputValue: data.BrandName,
        isDropdownOpen: false,
        typeValue: '', // Default value or customize as needed
        id: data.id
      });
    }





    // Check if any Brand fields exist
    //   if (brandFields.length > 0) {
    //     // Use the update count to determine which Brand field to update
    //     const targetIndex = this.brandUpdateCount % brandFields.length; // Wrap around when count exceeds number of Brand fields

    //     // Find the actual index in the searchFields array to update
    //     let currentBrandIndex = 0;
    //     for (let i = 0; i < this.searchFields.length; i++) {
    //       if (this.searchFields[i].selectedOption === 'Brand') {
    //         if (currentBrandIndex === targetIndex) {
    //           // Update the input value of the current brand field
    //           this.searchFields[i] = {
    //             ...this.searchFields[i],
    //             inputValue: data.BrandName // Update the BrandName
    //           };
    //           break;
    //         }
    //         currentBrandIndex++;
    //       }
    //     }

    //     // Increment the update count for next call
    //     this.brandUpdateCount++;
    //   } else {
    //     // If no Brand field exists, add a new one
    //     this.searchFields.push({
    //       selectedOption: 'Brand',
    //       inputValue: data.BrandName,
    //       isDropdownOpen: false,
    //       typeValue: 'Upperwear' ,// Default value or customize as needed
    //       id:data
    //     });
    //     this.brandUpdateCount = 1; // Reset count since we just added the first entry
    //   }
    // }
    console.log("searchfeid with Brand", this.searchFields)

  }

  async handleAvatarDetails(data) {
    this.conditionsearchDetails = null
    console.log("singleSnap", this.singleSnap)
    let avatarObj = await this.getAvatartObject(this.singleSnap)
    console.log("SaveAvatrGender", this.SaveAvatrGender)
    console.log("SaveAvatrGender", data.Gender)

    let SaveAvatrGender = data.Gender
    if (this.SaveAvatrGender) {
      if ((this.SaveAvatrGender != data.Gender)) {
        alert("Gender mismatch detected. You are not allowed to select a different gender.");
        return
      }
    }

    this.SaveAvatrGender = SaveAvatrGender


    // Find if there is already an entry with selectedOption 'User'
    const userFieldIndex = this.searchFields.findIndex(field => field.selectedOption === 'Avatar');

    if (userFieldIndex !== -1) {
      // If 'User' is found, update the corresponding entry

      this.searchFields[userFieldIndex] = {
        ...this.searchFields[userFieldIndex],
        inputValue: data.AvatarName,
        id: data.AvatarID
      };
    } else {
      // If 'User' is not found, you can add a new entry or do nothing
      this.searchFields.push({
        selectedOption: 'Avatar',
        inputValue: data.AvatarName,
        isDropdownOpen: false,
        typeValue: '',
        id: data.AvatarID
      });
    }
    console.log("searchfeid with Avatar", this.searchFields)

  }
  handleSpaceDetails(data) {
    // Find if there is already an entry with selectedOption 'User'
    this.conditionsearchDetails = null
    const userFieldIndex = this.searchFields.findIndex(field => field.selectedOption === 'Env');
    if (userFieldIndex !== -1) {
      // If 'User' is found, update the corresponding entry
      this.searchFields[userFieldIndex] = {
        ...this.searchFields[userFieldIndex],
        inputValue: data.displayName,
        id: data.storageURL.replace('Environments/', '') // Remove 'Environments/' and keep only the file name
      };
    } else {
      // If 'User' is not found, you can add a new entry or do nothing
      this.searchFields.push({
        selectedOption: 'Env',
        inputValue: data.displayName,
        isDropdownOpen: false,
        typeValue: '',
        id: data.storageURL.replace('Environments/', '') // Remove 'Environments/' and keep only the file name
      });
    }
    console.log("space with data", data)
    console.log("searchfeid with Space", this.searchFields)

  }
  capitalize(type: string): string {
    return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
  }

  async getAvatartObject(snapShots) {
    try {
      // Check if UserID and AvatarID are available before proceeding
      if (!snapShots.UserID || !snapShots.AvatarID) {
        snapShots.avatarName = snapShots.AvatarID || 'Unknown Avatar';
        return;
      }
      // Fetch avatar details from the common service
      const documents = await this.commonService.getUserAvatarDetails(snapShots.UserID, snapShots.AvatarID);
      if (documents?.length > 0) {
        const firstDocument = documents[0];
        return firstDocument
      }
    } catch (error) {
      console.error('Error in getAvatarDetails:', error);
      throw error; // Re-throw the error or handle it here as needed
    }
  }

  toggleChild() {
    this.showChild = false;
    this.closePanelEmitter.emit(this.showChild);

    // Add a delay before emitting and calling handleSearchClear
   
  }
  



selectImage(snapshot: any) {
  const storyId = snapshot.StoryID;
  this.selectTheStory.emit(storyId)
  // this.selectedSnap.emit(snapshot)
    const data = {
      storyId,
      snapshot,
      searchFields: this.searchFields != undefined ? this.searchFields : null ,
      conditionsearchDetails: this.conditionsearchDetails  != undefined ? this.conditionsearchDetails : null,
      userSnafilter  :this.paginatedSnapshots != undefined ? this.paginatedSnapshots : null
    };
    let dataSelection =  this.commonService.emitImageSelectionData(data);
    this.selectedSnap.emit(dataSelection)
  
}


selectednotExclosue(data){
  // console.log("newdata",data)
  // this.searchFields = data.searchFields
  // this.conditionsearchDetails = data.conditionsearchDetails
  this.search()
}
 


}
