import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AvatarModelViewComponent } from './yologram-social/3D-model-view/avatar-model-view.component';
import { AvatarUploaderComponent } from './yologram-social/avatar-uploader/avatar-uploader.component';
import { BrandBoardComponent } from './yologram-social/brand-board/brand-board.component';
// import { BrandsComponent } from './yologram-social/threads/threads.component';
import { NewsComponent } from './yologram-social/news/news.component';
import { YologramSocialComponent } from './yologram-social/yologram-social.component';
import { UnzipBrandPack } from './yologram-social/zip-brand-pack/zip-brand-pack.component'
import { ViewsFiles } from './yologram-social/view-files/view-files.component'
import { StyleComponent } from './public/style/style.component';
import { PublicNewsComponent } from './public/news/news.component';
import { PublicComponent } from './public/public.component';
import { MenuPageComponent } from './yologram-social/menu-page/menu-page.component';
import { ChatBoxComponent } from "./yologram-social/chat-box/chat-box.component";
import { GroupUserComponent } from "./yologram-social/group-user/group-user.component";
import { NotifyUserComponent } from "./yologram-social/notify-user/notify-user.component";
import { SecurityGroupComponent } from "./yologram-social/security-group/security-group.component";
import { WebPagesComponent } from "./yologram-social/web-pages/web-pages.component";
import { GroupRoleComponent } from "./yologram-social/group-role/group-role.component";
import { YologramNetworkComponent } from './public/yologram-network/yologram-network.component';
import { SecurityGroupChangeComponent } from './yologram-social/security-group-change/security-group-change.component';
import { UserNewsComponent } from './yologram-social/news/user-news/user-news.component';
import { NewsUserComponent } from './public/news-user/news-user.component';
import { UserFeedComponent } from './yologram-social/news/user-feed/user-feed.component';
import { ShareComponent } from './public/share/share.component';
import { NetworkComponent } from './yologram-social/network/network.component';
import { UserViewFilesComponent } from './yologram-social/view-files/user-view-files/user-view-files.component';
import { BrandStoriesComponent, } from './yologram-social/brand-stories/brand-stories.component';
import { BrandStoriesNewsComponent } from './yologram-social/brand-stories/brand-stories-news/brand-stories-news.component';
import { YologramPublicStyleComponent } from './yologram-social/yologram-public-style/yologram-public-style.component';
import { BrandProfileComponent } from './yologram-social/brand-profile/brand-profile.component';
import { PublicBrandProfileComponent, } from './public/public-brand-profile/public-brand-profile.component';
import { PublicBrandStoriesComponent } from './public/public-brand-stories/public-brand-stories.component';
import { BrandStyleComponent } from './yologram-social/brand-style/brand-style.component';
import { PublicBrandStyleComponent } from './public/public-brand-style/public-brand-style.component';
import { GarmentProfileComponent } from './yologram-social/garment-profile/garment-profile.component';
import { GarmentStyleComponent } from './yologram-social/garment-style/garment-style.component';
import { PublicGarmentProfileComponent } from './public/public-garment-profile/public-garment-profile.component';
import { PublicGarmentStyleComponent } from './public/public-garment-style/public-garment-style.component';
import { GarmentEditComponent } from './yologram-social/garment-edit/garment-edit.component';
import { EnvironmentProfileComponent } from './yologram-social/environment-profile/environment-profile.component';
import { PublicEvnProfileComponent } from './public/public-evn-profile/public-evn-profile.component';
import { PublicGarmentFeedComponent } from './public/public-garment-feed/public-garment-feed.component';
import { GarmentFeedComponent } from './yologram-social/garment-feed/garment-feed.component';
import { UserProfileFeedComponent } from './yologram-social/user-profile-feed/user-profile-feed.component';
import { PublicUserFeedComponent } from './public/public-user-feed/public-user-feed.component';
import { NewsCommonComponent } from './common/news-common/news-common.component';
import { SocialMedialStoryComponent } from './common/social-medial-story/social-medial-story.component';
import { GarmentCollectionComponent } from './yologram-social/garment-collection/garment-collection.component';
import { DummyPublicNewsComponent } from './common/dummy-public-news/dummy-public-news.component';
import { DummyPublicNewsUpdatedComponent } from './common/dummy-public-news-updated/dummy-public-news-updated.component';
import { NewDummyPageComponent } from './common/new-dummy-page/new-dummy-page.component';
import { UserEditComponent } from './yologram-social/user-edit/user-edit.component';
import { UserMyProfileComponent } from './yologram-social/user-my-profile/user-my-profile.component';
import { NewThreeDFileComponent } from './common/new-three-d-file/new-three-d-file.component';
import { NewsThreeDComponent } from './public/news-three-d/news-three-d.component';
import { Style3DComponent } from './common/style3-d/style3-d.component';
import { DripsComponent } from './common/drips/drips.component';
import { ThreadsComponent } from './common/threads/threads.component';
import { BrandsComponent } from './yologram-social/brands/brands.component';
import { DummyAnimationComponent } from './common/dummy-animation/dummy-animation.component';
import { GroupBrandsComponent } from './yologram-social/group-brands/group-brands.component';
import { AvatarPageComponent } from './public/avatar-page/avatar-page.component';
import { BrandAvatarComponent } from './yologram-social/brand-avatar/brand-avatar.component';
import { BrandPortfolioComponent } from './yologram-social/brand-portfolio/brand-portfolio.component';
import { TestLottieanimationComponent } from './common/test-lottieanimation/test-lottieanimation.component';
import { DefaultBrandClothesComponent } from './yologram-social/default-brand-clothes/default-brand-clothes.component';
import { GarmentCacheComponent } from './yologram-social/garment-cache/garment-cache.component';
import { DripsV2Component } from './common/drips-v2/drips-v2.component';
import { DripsSampleComponent } from './common/drips-sample/drips-sample.component';
import { StoreFrontConfigComponent } from './yologram-social/store-front-config/store-front-config.component';
import { BrandSpacesComponent } from './yologram-social/brand-spaces/brand-spaces.component';
import { TexturePageComponent } from './yologram-social/texture-page/texture-page.component';
import { BrandColorComponent } from './yologram-social/brand-color/brand-color.component';
import { AvatarPageRedesignComponent } from './public/avatar-page-redesign/avatar-page-redesign.component';






const routes: Routes = [
  // { path: '', redirectTo: '/public/news', pathMatch: 'full' },

  { path: '', redirectTo: '/drip', pathMatch: 'full' },
  { path: 'public', component: PublicComponent },
  { path: 'fam', component: YologramNetworkComponent },
  { path: 'style', component: StyleComponent },
  { path: 'spaces', component: PublicEvnProfileComponent},
  { path: 'user', component: NewsUserComponent },
  { path: 'garment', component: PublicGarmentFeedComponent},
  { path: 'brand', component: PublicBrandStoriesComponent},
  { path: 'brands',component: Style3DComponent},

  { path: 'drip', component: DripsComponent},
  // { path: 'drip', component: DripsV2Component},
  { path: 'drip_v2', component: DripsV2Component},
  { path: 'drip_Sample', component: DripsSampleComponent},
  { path: 'news', component: PublicNewsComponent },
  { path: 'group-user', component: GroupUserComponent },
  { path: 'view-files', component: ViewsFiles },
  { path: 'file-upload', component: AvatarUploaderComponent },
  { path: 'edit-news', component: NewsComponent },
  { path: 'security-group', component: SecurityGroupComponent },
  { path: 'garment-collection', component: GarmentCollectionComponent},
  { path: 'brand-board', component: BrandBoardComponent },      
  { path: 'group-role', component: GroupRoleComponent },
  { path: 'brand-info', component: BrandsComponent },
  { path: 'notify-user', component: NotifyUserComponent },
  { path: 'web-pages', component: WebPagesComponent },
  { path: 'menu-page', component: MenuPageComponent },
  { path: 'me', component: UserMyProfileComponent},
  { path: 'edit-user/:id/:pagetab/:storyId', component: UserEditComponent},
  { path: 'security-group-change', component: SecurityGroupChangeComponent },
  { path: 'edit-garment', component:GarmentEditComponent},
  { path: 'threads', component:ThreadsComponent},
  { path: 'story', component: SocialMedialStoryComponent},
  { path: 'dummy-animation', component: DummyAnimationComponent},
  { path: 'group-brands', component: GroupBrandsComponent},
  { path: 'avatar', component: AvatarPageComponent},
  { path: 'brand-avatar', component: BrandAvatarComponent},
  { path: 'brand-portfolio', component: BrandPortfolioComponent},
  { path: 'test-lottie-animation', component: TestLottieanimationComponent},
  { path: 'default-brand-garments', component: DefaultBrandClothesComponent},
  { path: 'garment-360-cache', component: GarmentCacheComponent},
  { path: 'store-front-config', component: StoreFrontConfigComponent},
  { path: 'brand-spaces', component: BrandSpacesComponent},
  { path: 'texture-library', component: TexturePageComponent},
  { path: 'brand-color', component: BrandColorComponent},
  { path: 'avatar-v2', component: AvatarPageRedesignComponent},


  


  
  



  


  



  


  
  
  














  
  





  
  // {
  //   path: '', component: PublicComponent,
  //   children: [
  //     { path: 'network', component: PublicNewsComponent },
  //     { path: 'style', component: StyleComponent },
  //   ] 
  // },
  
  // { path: 'public/news', component: PublicNewsComponent },
  {
    path: 'public', component: PublicComponent,
    children: [
      // { path: 'news', component: PublicNewsComponent },
      // { path: 'style', component: StyleComponent },
      // { path: 'network', component: YologramNetworkComponent },
      // { path: 'news-user', component: NewsUserComponent },
      // { path: 'share', component: ShareComponent },
      // { path: 'public-brand-profile', component: PublicBrandProfileComponent },
      // { path: 'public-brand-stories', component: PublicBrandStoriesComponent },
      // { path: 'public-brand-style', component: PublicBrandStyleComponent },
      // { path: 'public-garment-profile', component: PublicGarmentProfileComponent },
      // { path: 'public-garment-style', component: PublicGarmentStyleComponent},
      // { path: 'public-evn-profile', component: PublicEvnProfileComponent},
      // // { path: 'public-garment-feed', component: PublicGarmentFeedComponent},
      // { path: 'public-userStory-feed', component: PublicUserFeedComponent},
      // { path: 'public-news-common', component: NewsCommonComponent},
      // { path: 'story', component: SocialMedialStoryComponent},
    // { path: 'dummy-public-news', component: DummyPublicNewsComponent},
      // { path: 'dummy-public-news-v2', component: DummyPublicNewsUpdatedComponent},
      // { path: 'new-dummy-news-v3', component: NewDummyPageComponent},  
      // { path: 'external',component: NewThreeDFileComponent},
      // { path: 'news3D',component: NewsThreeDComponent},
      // { path: 'brands',component: Style3DComponent}
    ]
  },
  {
    
    path: 'yologram-social', component: YologramSocialComponent,
    children: [
      // { path: 'news-admin', component: NewsComponent },
      // { path: 'brands', component: BrandsComponent },
      // { path: 'file-upload', component: AvatarUploaderComponent },
      // { path: '3D-model-view', component: AvatarModelViewComponent },
      // { path: 'brand-board', component: BrandBoardComponent },
      // { path: 'zip-brand-pack', component: UnzipBrandPack },
      // { path: 'view-files', component: ViewsFiles },
      // { path: 'menu-page', component: MenuPageComponent },
      // { path: 'messaging/:desttoken', component: ChatBoxComponent },
      // { path: 'group-user', component: GroupUserComponent },
      // { path: 'notify-user', component: NotifyUserComponent },
      // { path: 'security-group', component: SecurityGroupComponent },
      // { path: 'web-pages', component: WebPagesComponent },
      // { path: 'group-role', component: GroupRoleComponent },
      // { path: 'security-group-change', component: SecurityGroupChangeComponent },
      // { path: 'yologram-public-news', component: UserNewsComponent },
      // { path: 'yologram-public-news-feed', component: UserFeedComponent },
      // { path: 'network', component: NetworkComponent },
      // { path: 'share', component: ShareComponent },
      // { path: 'yologram-public-view-file', component:UserViewFilesComponent  },
      // { path: 'yologram-brand-stories', component:BrandStoriesComponent  },
      // { path: 'yologram-brand-stories-news', component:BrandStoriesNewsComponent},
      // { path: 'yologram-public-style', component:YologramPublicStyleComponent,  },
      // { path: 'yologram-brand-profile', component:BrandProfileComponent},
      // { path: 'yologram-brand-style', component:BrandStyleComponent},
      // { path: 'yologram-garment-profile', component:GarmentProfileComponent},
      // { path: 'yologram-garment-style', component:GarmentStyleComponent},
      // // { path: 'yologram-garment-edit', component:GarmentEditComponent},
      // { path: 'yologram-env-profile', component:EnvironmentProfileComponent},
      // { path: 'yologram-garment-feed', component:GarmentFeedComponent},
      // { path: 'yologram-user-profile-feed/:id/:pagetab/:storyId', component:UserProfileFeedComponent},
      // { path: 'yologram-user-profile-feed/:avatarID/:pagetab/:id/:storyId', component:UserProfileFeedComponent},
      // { path: 'garment-collection', component: GarmentCollectionComponent},
      // { path: 'yologram-user-edit/:id/:pagetab/:storyId', component: UserEditComponent},
      // { path: 'yologram-user-myprofile', component: UserMyProfileComponent},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
