<div class="d-flex justify-content-center align-items-center" style="height: 100vh;" *ngIf="flowState">
    <div class="spinner-border" role="status">
        <span class="sr-only"></span>
    </div>
</div>



<div class="dropdown-container" *ngIf="!flowState">
    <div *ngIf="page == 'threads' || newCloseEvent"  style="padding-bottom: 30px;">
        <button type="button" class="btn-close main-close-button" aria-label="Close" (click)="toggleChild()"
            style="color: white;"></button>
    </div>
    <div *ngFor="let field of searchFields; let i = index" class="dropdown-row d-block">
        <!-- Display selected User tag -->
        <div class="col-12  user-tag" *ngIf="field.selectedOption == 'User'"
            style="background-color:transparent !important">
            <h6 style="margin: 5px;" class="d-flex">

                <img style="width: 25px; height: 25px;" [src]="getImageForOption(field.selectedOption)" alt="Image">

                <h6 *ngIf="!conditionsearchDetails || conditionsearchDetails?.selectedValue != 'User'">
                    {{field.inputValue}}
                </h6>
                <h6 *ngIf="conditionsearchDetails?.selectedValue == 'User'">Like:
                    {{conditionsearchDetails?.searchInput}}
                </h6>

            </h6>
            <button class="user-remove" (click)="removeField(field, i)">
                X
            </button>
        </div>
        <div class="col-12  user-tag" *ngIf="field.selectedOption == 'Avatar'">
            <h6 style="margin: 5px;" class="d-flex">
                <img style="width: 25px; height: 25px;" [src]="getImageForOption(field.selectedOption)" alt="Image">
                <h6 *ngIf="!conditionsearchDetails || conditionsearchDetails?.selectedValue != 'avatar'">
                    {{field.inputValue}}
                </h6>
                <h6 *ngIf="conditionsearchDetails?.selectedValue == 'avatar'">Like:
                    {{conditionsearchDetails?.searchInput}}
                </h6>

            </h6>
            <button class="user-remove" (click)="removeField(field, i)">
                X
            </button>
        </div>
        <div class="col-12 user-tag" *ngIf="field.selectedOption == 'Env'">
            <h6 style="margin: 5px;" class="d-flex">
                <img style="width: 25px; height: 25px;" [src]="getImageForOption(field.selectedOption)" alt="Image">
                <h6 *ngIf="!conditionsearchDetails ||  conditionsearchDetails?.selectedValue != 'space'">
                    {{field.inputValue}}
                </h6>
                <h6 *ngIf="conditionsearchDetails?.selectedValue == 'space'">Like:
                    {{conditionsearchDetails?.searchInput}}
                </h6>
            </h6>
            <button class="user-remove" (click)="removeField(field, i)">
                X
            </button>
        </div>
        <div class="col-12 brand-tag" *ngIf="field.selectedOption == 'Brand'">
            <h6 style="margin: 5px;" class="d-flex">
                <img style="width: 50px; height: 50px;" [src]="getSearchedBrandImage(field.inputValue)" alt="Image">
                <span class="m-1"> {{field.inputValue}}</span>
            </h6>
            <button class=" brand-remove" (click)="removeField(field, i)">
                X
            </button>
        </div>

        <div class="col-12 brand-tag" *ngIf="field.selectedOption == 'Garment'">
            <h6 style="margin: 5px;" class="d-flex">
                <img style="width: 25px; height: 25px;" [src]="getSearchedGarmentRealType(field.inputValue)"
                    alt="Image">
                <span class="m-1">{{ field.inputValue.slice(0, 14) }}</span>
            </h6>
            <button class=" brand-remove" (click)="removeField(field, i)">
                X
            </button>
        </div>
    </div>
    <div class="col-12 brand-tag" *ngIf="conditionsearchDetails?.selectedValue == 'Garment'">
        <h6 style="margin: 5px;" class="d-flex">
            <img style="width: 25px; height: 25px;" [src]="getImageForOption('Garment')" alt="Image">
            <h6 *ngIf="conditionsearchDetails?.selectedValue == 'Garment'">Like: {{conditionsearchDetails?.searchInput}}
            </h6>
        </h6>
        <button (click)="closeSechcondition()" class="brand-remove">
            X
        </button>
    </div>

    <div class="col-12 brand-tag" *ngIf="conditionsearchDetails?.selectedValue == 'brand'">
        <h6 style="margin: 5px;" class="d-flex">
            <img style="width: 25px; height: 25px;" [src]="getImageForOption('Brand')" alt="Image">
            <h6 *ngIf="conditionsearchDetails?.selectedValue == 'brand'">Like: {{conditionsearchDetails?.searchInput}}
            </h6>
        </h6>
        <button (click)="closeSechcondition()" class="brand-remove">
            X
        </button>
    </div>

    <div class="button-container">

        <!-- Search Button -->
        <button class="transparent-button rounded-plus-button" (click)="handleSearchClick()">
            <img style="width:15px !important;" src="./assets/brand-space/search-button.png"> </button>
        <!-- Rounded Plus Button -->
        <button class="transparent-button rounded-plus-button" (click)="handlePlusClick()">
            +
        </button>

        <!-- Clear Button -->
        <button class="transparent-button search-button clear-butn" (click)="handleSearchClear()">
            Clear
        </button>
    </div>

    <div class="div-center class-desktop" *ngIf="showSnapshots && !loadingSnap">
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class=""></span>
            </div>
        </div>
    </div>

    <div class="div-center class-desktop" *ngIf="showSnapshots && loadingSnap">
        <div class="row w-100 image-margin">
            <div *ngIf="snapFiltered && snapFiltered?.length < 0 && showsnapbysearch" class="alert alert-danger w-100 text-center">
                No stories available
            </div>
            <div class="col-6 p-0" *ngFor="let snapshot of snapFiltered; let i=index;"
                [ngClass]="{'d-none': snapshot.SnapshotFileUrl == ''}">
                <div class="d-flex align-items-center justify-content-center">
                    <img class="shadow-1-strong rounded m-1 story-img" (click)="selectImage(snapshot)" [src]="snapshot.SnapshotFileUrlMini">
                </div>
            </div>
        </div>
    </div>
    
    <div *ngIf="userSnafilter?.length > 12" (click)="moreButtonForNext()" class="text-center"> More </div>
</div>

<div class="brand-close" *ngIf="rightPanelFlag">
    <div id="mySidenav" class="searchNav">
        <div class="close-container" (click)="closeRightSearchPanel()"> <span class="cross-close">X</span></div>
        <app-search-selection-panel (selectedUserDetails)="handleUserDetails($event)"
            (selectedSpaceDetails)="handleSpaceDetails($event)" (selectedBrandDetails)="handleBrandDetails($event)"
            (selectedAvatarDetails)="handleAvatarDetails($event)"
            (selectedgGarmentDetails)="handleGarmentDetails($event)"
            (selectedgConditionSearch)="handleConditionSearch($event)" [allsnapObject]="allsnapObject">
        </app-search-selection-panel>
    </div>
</div>