import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { Brand } from 'src/app/models/brand';
@Component({
  selector: 'app-avatar-panel',
  templateUrl: './avatar-panel.component.html',
  styleUrls: ['./avatar-panel.component.css']
})
export class AvatarPanelComponent {

  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;



  @Input() UserShapShotObject: any = {};
  @Input() AvatarID: string;
  @Input() UserID: any | undefined;
  @Input() userData: any;
  @Input() rootFlag:  string | undefined;
  @Input() userProfile: string;
  @Input() brandStoryScrollerHeight: any;
  @Input() closebuttonFlag: boolean;
  @Input() selectedAvatarName: any;
  @Input() selectedUserName: any;
  @Input() AvatarData: any;

  

  @Input() UserShapShots: any =[];

  

  
  @Output() closeAvatarEmitter = new EventEmitter<any>();
  @Output() sendStoryId = new EventEmitter<any>();





  
  userAvatarData: any = {};
  allUserSnapDetails: any = []
  AllDetails: any = []
  userImfo: any = []
  selectedSanp: any = []
  allUserDetails: any = []
  userFiledetails: any = []
  SnapShotFileDetails: any = []
  filelist: any = []
  userDetails: any = []
  userUploadedFileList: any = []
  userDetail: any = []
  userAllDetails : any = []
  userDetailsObject: any = {}
  avatarFlag = 'avatar'
  userFlag = 'user'
  logInFlag = ''
  filterSnapShotFileDetailsall: any = []
  userDetailsloading: boolean = false
  brandsnaploadingSnap: boolean = true
  selectedTab: any
  secteTab: any = 'Story';
  pageNumber:  number = 1
  pagecount:  number = 1
  newArray: any = [];



  images = [
    { "image": "./assets/user-upload-dummy/new-img-1.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-5.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-6.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-7.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-8.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-9.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-10.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-11.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-12.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-13.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-14.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-15.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-16.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-17.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-18.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-19.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-20.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-21.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-22.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-23.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-24.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-25.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-26.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-27.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-28.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-29.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-30.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-31.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-32.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-33.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-34.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-35.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-36.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-37.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-38.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-39.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-40.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-41.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-42.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-43.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-44.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-45.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-46.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-47.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-48.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-49.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-50.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-51.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-52.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-53.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-54.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-55.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-56.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-57.jpg" },
  ]
  page: number = 1;
  dismiss:boolean = true;
  showChild: boolean = true ;
  garmentsCollection: any = [];
  brandsCollection: any = [];
  constructor(
    public router: Router,
    private dataSharingService: DataSharingService,
    private commonService: CommonService,
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore,
    private http: HttpClient,
  ) {


    this.garmentsList.subscribe((garments) => {
      this.garmentsCollection = garments;
    });
    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollection = brands;
    })

    // this.user.subscribe(userdetails => {
    //   this.userDetails = userdetails
    //   let userId = this.UserShapShotObject.UserID != undefined ? this.UserShapShotObject.UserID : (this.userData.uid || this.UserID);
    //   this.userDetailsObject = this.userDetails.find((user) => user.UserID == userId)
    // });


    // this.commonService.getAllUser().subscribe(async (allUsers: any) => {
    //   this.allUserDetails = allUsers.users;
    //   this.AllDetails = allUsers.users;
    // })

    // this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
    //   this.SnapShotFileDetails = snapshot;
    //   // this.update()  
    // })
  }

  ngOnInit(): void {
    //    this.getDocumentDetails()
     
    //   let reqInputObject = { id: this.UserShapShotObject.AvatarID, pageNumber: 1 }
    //   this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
    //     this.filterSnapShotFileDetailsall = response.object
    //     // this.allUserSnapDetails = response.object.slice(0, 3)
    //     this.allUserSnapDetails= this.filterSnapShotFileDetailsall
    //     this.brandsnaploadingSnap = false
    //     this.filterSnapShotFileDetailsall.forEach(async (snap: any, index) => {
    //     });
    //   })

    // this.userFilesList.subscribe((userFile) => {
    //   let userId = this.UserShapShotObject &&
    //   this.UserShapShotObject.UserID != undefined ? this.UserShapShotObject.UserID : (this.userData.uid || this.UserID);
    //   this.userUploadedFileList = userFile
    //   this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads')
    //   let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == userId)
    //   this.userFiledetails = filelist
    // });
    // this.setuserDetails()
  }


  // ngOnChanges(changes) {
  //   new window['bootstrap'].Carousel(document.getElementById('imageCarousel'), {
  //     interval: true // Disable automatic sliding if needed
  //   });    
  // }

  // async getDocumentDetails() {
  //   await this.commonService.getDocumentsFromSubcollection(this.UserShapShotObject.UserID, this.UserShapShotObject.AvatarID)
  //     .then((documents) => {
  //       if (documents && documents.length > 0) {
  //         const firstDocument = documents[0]; // Assuming there is only one document
  //         this.userAvatarData = null
  //         this.userAvatarData = firstDocument ;
  //         this.newArray = this.processAvatarUrls()
  //         console.log("this.userAvatarData", this.userAvatarData)
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error in component:', error);
  //       throw error; 
  //       // You can choose to re-throw the error or handle it here
  //     });
  // }
  
  async getDocumentDetails() {
    try {
      const documents = await this.commonService.getDocumentsFromSubcollection(this.UserShapShotObject.UserID, this.UserShapShotObject.AvatarID);
      let dummydata = {AvatarID: this.UserShapShotObject.AvatarID }
      if (documents && documents.length > 0) {
        const firstDocument = documents[0]; // Assuming there is only one document
        this.userAvatarData = null;
        this.userAvatarData = firstDocument;

        this.newArray = this.processAvatarUrls();
        console.log("this.userAvatarData", this.userAvatarData);
      } else {
        this.userAvatarData = { AvatarID: this.UserShapShotObject.AvatarID };
      }
    } catch (error) {
      console.error('Error in component:', error);
      throw error;
    }
  }
  
  getAvatarDisplayName(userAvatarData: any): string {
    return userAvatarData?.AvatarName !== undefined && userAvatarData?.AvatarName !== '' 
      ? userAvatarData.AvatarName 
      : userAvatarData.AvatarID;
  }
  
  getAvatarImageUrl(userAvatarData: any): string {
    return userAvatarData?.AvatarPreviewPortraitUrl !== undefined && userAvatarData?.AvatarPreviewPortraitUrl !== ''
      ? userAvatarData.AvatarPreviewPortraitUrl
      : './assets/peteravatar.png'; // Replace with the actual path to your dummy image
  }
  

  processAvatarUrls(): { fileurl: string }[] {
    const urls = [];
    if (this.userAvatarData?.AvatarPreviewFullBodyUrl) {
      urls.push({ fileurlMini: this.userAvatarData.AvatarPreviewFullBodyUrl,fileurl: this.userAvatarData.AvatarPreviewFullBodyUr });
    }
    if (this.userAvatarData?.AvatarPreviewPortraitUrl) {
      urls.push({ fileurlMini: this.userAvatarData.AvatarPreviewPortraitUrl,
        fileurl: this.userAvatarData.AvatarPreviewPortraitUrl });
    }




    
    return urls;
  }


  
    update() {
      this.commonService.getAllUser().subscribe(async (allUsers: any) => {
        this.AllDetails = allUsers.users;
        this.AllDetails.forEach(object => {
          let filterSnapShots = this.SnapShotFileDetails.filter((snapShot) => snapShot.UserID == object.uid);
          this.selectedSanp = filterSnapShots;
          if (filterSnapShots.length > 0) {
            this.userImfo.push(object);
          }
        });
    
        // Assuming this.userData is an array that holds the UserIDs that should not be added again
        const userIDsInUserData = this.userDetails.map(user => user.UserID);
        this.userImfo.forEach(object => {
          let details = object;
          let userDetail = details.providerData[0];
          let saveUserDetails = {
            "DisplayName": userDetail.displayName != undefined ? userDetail.displayName : '',
            "Email": userDetail.email != undefined ? userDetail.email : '',
            "FirstName": '',
            "Identifier": '',
            "LastName": '',
            "MiddleName": '',
            "ProfilePicture": userDetail.photoURL != undefined ? userDetail.photoURL : '',
            "UserID": details.uid != undefined ? details.uid : '',
            "PageName":'userpopup'
          };
    
          // Check if UserID is already in this.userData
          if (!userIDsInUserData.includes(saveUserDetails.UserID)) {
            // If not, then check Firestore
            this.firestore.collection('user-profile', ref =>
              ref.where('UserID', '==', saveUserDetails.UserID))
              .get()
              .subscribe(querySnapshot => {
                if (querySnapshot.empty) {
                  this.firestore.collection('user-profile').add(saveUserDetails).then((docRef) => {
                    console.log(`User profile added with ID: ${docRef.id}`);
                    // Optionally, you could add this ID to this.userData to keep the local array updated
                    // this.userData.push({ ...saveUserDetails, UserID: docRef.id });
                  });
                } else {
                  console.log("User profile already exists in Firestore");
                }
              });
          } else {
            console.log(`User profile for UserID ${saveUserDetails.UserID} already exists in userData, not adding to Firestore.`);
          }
        });
      });
    }


  // userSnapShots() {
  //   this.closePopUp();
  //   this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
  //   this.dataSharingService.sendUserData(this.userData)
  // }

  async getUserSnapShotDashboard(page, snapshot,index) {
    let id =  snapshot.UserID != undefined ?  snapshot.UserID : this.UserID
    let count = 12
    const currentPage = Math.floor(index / count) + 1;
    let  pagetab =  page
    let  storyId = snapshot.StoryID != undefined ?  snapshot.StoryID : "BlanckStoryID"
    this.router.navigate([ '/user' , { 'id':snapshot.UserID || this.UserID || this.UserShapShotObject.UserID, 'pagetab': page, 'storyId': storyId,'index':index,'pageNumber':currentPage}])

  
    // this.closePopUp();
          // this.router.navigate([ '/user' , { 'id':snapshot.UserID || this.UserID, 'pagetab': page, 'storyId': storyId }])

    // if( this.rootFlag ==  "/edit-user"){
    //   this.router.navigate([this.rootFlag,id,pagetab,storyId])
    // }
    // else if(this.rootFlag ==  "/me" )
    // {
    //   this.router.navigate([ this.rootFlag , { 'id':snapshot.UserID || this.UserID, 'pagetab': page, 'storyId': storyId }])
      
    // } else if(this.rootFlag ==  '/user'   )
    // {
    //   this.router.navigate([ '/user' , { 'id':snapshot.UserID || this.UserID, 'pagetab': page, 'storyId': storyId }])
    // }
  }

  getUserSnapShotByAvatarId(page, snap,index) {
    let sToryId  =  snap.StoryID != undefined ? snap.StoryID : ''
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/user')) {
          window.location.reload();
        }
      }
    });
    this.closePopUp();
    let count = 12
    const currentPage = Math.floor(index / count) + 1;
          this.router.navigate(['/user', { 'avatarID': snap.AvatarID || this.AvatarID, 'pagetab': page, 'id': (this.userDetailsObject.UserID || this.UserID),'Storyid':this.UserShapShotObject.id }])

  }

  getUserSnapShotByAvatar(page, snap, index) {
    this.closePopUp();

    // Set the number of items per page
    const count = 12;
    const currentPage = Math.floor(index / count) + 1;

    // Check if snap and snap.StoryID are defined before using them
    const storyId = snap?.StoryID || 'defaultStoryID';

    // Navigate with the safe storyId and other parameters
    this.router.navigate([
        '/avatar', 
        { 
            'avatarID': this.UserShapShotObject?.AvatarID || this.AvatarID, 
            'pagetab': page, 
            'id': this.userDetailsObject?.UserID || this.UserID, 
            'Storyid': this.UserShapShotObject?.id, 
            'StoryID': storyId 
        }
    ]);

    // Emit only if snap and StoryID are defined
    if (snap?.StoryID) {
        this.sendStoryId.emit(snap.StoryID);
    } else {
        console.warn('StoryID is undefined for the provided snap object.');
    }
}


  grtProfile(page) {
    this.closePopUp()
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/user')) {
          window.location.reload();
        }
      }
    });
          this.router.navigate(['user', { 'id':this.UserID, 'pagetab': page }])

    // if(this.rootFlag != undefined || this.UserID != undefined){
    //   this.router.navigate(['yologram-social/yologram-user-myprofile', { 'id':this.UserID, 'pagetab': page }])
    // }
    // else{
    //   this.router.navigate([ this.rootFlag, { 'id':this.userDetailsObject.UserID, 'pagetab': page }])
    // }
  }


  selectSummeryView(tab) {
    this.selectedTab = 'story'
    this.selectTab(tab)
  }

  selectTab(tab:any){
    this.secteTab = tab
  }

  getSelectSnapshotFeed(userSnap) {
    this.closePopUp()
    let avatar = this.AvatarID
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.filterSnapShotFileDetailsall.filter((snapshot) => snapshot.UserID == userId);
    userSnapShotList.forEach(async (snap: any, index) => {
      let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
      snap.profileName = userObjet && userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
      snap.firstName = snap.profileName.split(" ", 1);
      snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
    })

    let storyObject = { 'id': userId, 'storyId': storyid, 'pageNamuber': this.page, }
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    if (this.AvatarID != undefined) storyObject['avatarID'] = this.AvatarID
    this.router.navigate(['public/public-userStory-feed', storyObject])
  }


  closePopUp() {
    this.activeModal.close();
  }


  incrementImgCounter() {
    this.pageNumber++
    let avatarId = this.AvatarData.snapshotsData.object[0].AvatarID
    console.log("Avatar:-",this.AvatarData.snapshotsData.object[0].AvatarID)
    let reqInputObject = { id: avatarId, pageNumber: this.pageNumber }
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
        this.allUserSnapDetails.push(...response.object)
    })
  }

  toggleChild() {
    // this.showChild = !this.showChild;
    this.closeAvatarEmitter.emit(false)
  }



  async avatarPanelDataFunction(userId,avatarId){
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getAvatarPanelDetails";
    return this.http.post<any>(functionUrl, { userId: userId ,avatarId:avatarId}).toPromise();
  }

  async ngOnChanges(changes: SimpleChanges) {
    // Handle UserShapShotObject change
    if (changes['UserShapShotObject'] && this.UserShapShotObject) {
      this.AvatarData = await this.avatarPanelDataFunction(this.UserShapShotObject.UserID, this.UserShapShotObject.AvatarID);
      console.log("Updated AvatarData from UserShapShotObject", this.AvatarData);
      this.loadUserData();
    }
  
    // Handle userData change
    if (changes['userData'] && this.userData && this.userData.userData && this.userData.userData.userDetails) {
      this.AvatarData = this.userData.userData;
      this.selectedUserName = this.AvatarData.userDetails.DisplayName;
      this.selectedAvatarName = this.AvatarData.userDetails.DisplayName;
      this.loadUserData();
    } else {
      console.warn('User data or userDetails is missing');
    }
    
  
    // Initial load when UserShapShotObject is undefined
    if (!this.UserShapShotObject) {
      this.AvatarData = await this.avatarPanelDataFunction(this.UserID, this.AvatarID);
      console.log("Initial load AvatarData", this.AvatarData);
      this.loadUserData();
    }
  }
  
  // async loadUserData() {
  //   let data = await this.avatarPanelDataFunction(this.UserShapShotObject.UserID,this.UserShapShotObject.AvatarID)
  //   this.userAvatarData = data.avatarDetails[0]
  //   this.allUserSnapDetails = data.snapshotsData
  //   console.log("data",data)
    
  // }
  async loadUserData() {
    try { if(this.AvatarData.length != 0){      
      this.brandsnaploadingSnap = false;
      if (Array.isArray(this.AvatarData.avatarDetails) && this.AvatarData.avatarDetails.length > 0) {
        this.userAvatarData = this.AvatarData.avatarDetails[0]; // Assuming you only need the first avatar detail
        this.newArray = this.processAvatarUrls()
        console.log("this.newArray",this.newArray)
      } else {
        this.userAvatarData = null; // Handle case where no avatar details are found
      }
      if (Array.isArray(this.AvatarData.snapshotsData.object)) {
        this.allUserSnapDetails = this.AvatarData.snapshotsData.object; // Assuming snapshotsData.object is the array you want to assign
      } else {
        this.allUserSnapDetails = []; // Handle case where no snapshots data is found
      }
      this.userDetailsObject = this.AvatarData.userDetails; // Assuming user
      console.log("not call updation")
    }
      // Assuming avatarPanelDataFunction is a method that retrieves data asynchronously
      this.AvatarData = await this.avatarPanelDataFunction(this.UserShapShotObject.UserID, this.UserShapShotObject.AvatarID);
      console.log(" this.AvatarData", this.AvatarData)

      // Check and assign avatar details
      if (Array.isArray(this.AvatarData.avatarDetails) && this.AvatarData.avatarDetails.length > 0) {
        this.userAvatarData = this.AvatarData.avatarDetails[0]; // Assuming you only need the first avatar detail
        this.newArray = this.processAvatarUrls()
        console.log(" this.userAvatarData", this.userAvatarData)
        console.log("this.newArray",this.newArray)
      } else {
        this.userAvatarData = null; // Handle case where no avatar details are found
      }
  
      // Assign snapshots data
      if (Array.isArray(this.AvatarData.snapshotsData.object)) {
        this.allUserSnapDetails = this.AvatarData.snapshotsData.object; // Assuming snapshotsData.object is the array you want to assign
      } else {
        this.allUserSnapDetails = []; // Handle case where no snapshots data is found
      }
  
      // Assign user details
      this.userDetailsObject = this.AvatarData.userDetails; // Assuming userDetails is an object directly from the response
  
      console.log("Data loaded successfully:", this.AvatarData);
      this.brandsnaploadingSnap = false;
    } catch (error) {
      console.error("Error loading user data:", error);
      // Handle error gracefully
    }
  }

  getGarmentrealtype(garmentId: string): string {
    return this.garmentsCollection.find((b: any) => b.id === garmentId)?.RealType ?? '';
  }

  //showing Brandlogo With Garment Id
  getBrandImageByGarmetnID(garmentId: string): string {
    const imgURL = encodeURI(this.getBrandLogoURL(garmentId)).replace('/', '%2F');
    return `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${imgURL}?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c`;
  }

  //For Finding Brand Logo BrandLogoUrl 
  getBrandLogoURL(garmentId: string): string {
    const garment = this.garmentsCollection.find((b: any) => b.id === garmentId);
    const brandId = garment?.BrandID;
    const brand = this.brandsCollection.find((b: any) => b.id === brandId);
    return brand?.LogoURL ?? '';
  }

  getGarmentName(garmentId: string): string {
    const garment = this.garmentsCollection.find((b: any) => b.id === garmentId);
    return garment?.Name ?? '';
  }
}