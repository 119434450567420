<div *ngIf="loading" class="loading">Loading&#8230;</div>
<app-yologram-social *ngIf="logInFlag"></app-yologram-social>

<div (scroll)="scrollHandler()" #userFeeds class="roksan">
    <div class="col-lg-12  page-scroll sticky-band" *ngIf="pageScroll > 30">
        <span class="d-flex  justify-content-center ">
            <div class=" col-lg-12 band">
                <div class="static-band" >
                    <div class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal">
                        <img *ngIf="userProfileData.ProfilePicture != undefined" class="img-thumbnail rounded-circle" style="
                              width: 60px !important;
                              height: 60px !important;
                              margin-top: -53px;
                          " [src]="userProfileData.ProfilePicture">
                        <img *ngIf="userProfileData.ProfilePicture == undefined" class="img-thumbnail rounded-circle" style="
                              width: 60px !important;
                              height: 60px !important;
                              margin-top: -53px;
                          " [src]="userProfileData.ProfilePicture">
                    </div>
                        <div class="w-100 col-12 d-flex ">
                            <div class="col-9 text-start">
                                <h5 *ngIf="userProfileData.DisplayName == undefined" class="m-1 text-start text-black profile-name"
                                    (click)="getSnapShotListByUserID()">{{userData.profileName}}</h5>
                                <h5 *ngIf="userProfileData.DisplayName != undefined" class="m-1 text-black profile-name" (click)="getSnapShotListByUserID()">
                                    {{userProfileData.DisplayName}}
                                </h5>
                            </div>
                            <div class="col-3 "><button type="button" style="font-size: 10px;" class="btn btn-outline-info"  (click)="router.navigate(['yologram-social/yologram-public-view-file',{'id':userProfileData.UserID}])">My Files</button>
                            </div>
                        </div>
                        <div class="text-start" style="height: 25px; width: 100%;">
                            <h6 *ngIf="this.avatarID != undefined && this.userId != undefined" class="avatr  text-start ">🧍🏽{{avatarID}}
                            </h6>
                        </div>
                    <div>
                        <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                            <div class="brandbtn">
                                <h6 class="m-1" (click)="selectSummeryView('Story')"
                                    [ngClass]="secteTab == 'Story'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                    <a>
                                        <img  *ngIf="secteTab  == 'Story'"  style="width:15px !important" src="  ./assets/storystyle/story-white.png">
                                        <img  *ngIf="secteTab  != 'Story'" style="width:15px !important" src=" ./assets/storystyle/storyblack.png">
                                    
                                        
                                        </a>
                                </h6>
                                <h6 *ngIf="secteTab  == 'Story'"  class="m-1 font-class">Story</h6>
                            </div>
                            <div class="brandbtn" (click)="selectTab('Brands')">
                                <h6 class="m-1"
                                    [ngClass]="secteTab == 'Brands'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                    <a>
                                        <img  *ngIf="secteTab  == 'Brands'" style="width:15px !important" src="./assets/brand_white.png">
                                        <img  *ngIf="secteTab  != 'Brands'" style="width:15px !important" src="./assets/brand_black.png">
        
                                    </a>
                                </h6>
                                <h6 *ngIf="secteTab  == 'Brands'" class="m-1 font-class">Brands </h6>
                            </div>
                            <div class="brandbtn" (click)="selectTab('Style')">
                                <h6 class="m-1 "
                                    [ngClass]="secteTab == 'Style'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                    <a>
                                        <img *ngIf="secteTab  == 'Style'" style="width:15px !important" src="./assets/t_shirt_white.png">
                                        <img  *ngIf="secteTab  != 'Style'" style="width:15px !important" src="./assets/t_shirt_black.png">
                                                                                </a>
                                </h6>
                                <h6 *ngIf="secteTab  == 'Style'" class="m-1 font-class">Style</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>

    </div>




      




        <div class="col-lg-12 m-3 brand-profile-color">
          <div class="w-100 col-12 d-block justify-content-center band">
             <div class="static-band" *ngIf=" pageScroll == undefined ||  pageScroll < 30    ">
                <div class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal">
                    <img *ngIf="userProfileData.ProfilePicture != undefined" class="img-thumbnail rounded-circle" style="
                      width: 60px !important;
                      height: 60px !important;
                      margin-top: -53px;
                  " src={{userProfileData.ProfilePicture}}>
                    <img *ngIf="userProfileData.ProfilePicture == undefined" class="img-thumbnail rounded-circle" style="
                      width: 60px !important;
                      height: 60px !important;
                      margin-top: -53px;
                  " src={{userProfileData.photoURL}}>
                </div>
                <div class="w-100 col-12 d-flex">
                    <div class="col-9 text-start">
                        <h5 *ngIf="userProfileData.DisplayName == undefined" class="m-1 text-start text-black profile-name"
                            (click)="getSnapShotListByUserID()">{{userData.profileName}}</h5>
                        <h5 *ngIf="userProfileData.DisplayName != undefined" class="m-1 text-black                                                                                        " (click)="getSnapShotListByUserID()">
                            {{userProfileData.DisplayName}}
                        </h5>
                    </div>
                    <div class="col-3 "><button type="button" style="font-size: 10px;" class="btn btn-outline-info"  (click)="router.navigate(['yologram-social/yologram-public-view-file',{'id':userProfileData.UserID}])">My Files</button>
                    </div>
                </div>
                <div class="text-start" style="height: 25px; width: 100%;">
                    <h6 *ngIf="this.avatarID != undefined && this.userId != undefined" class="avatr  text-start ">🧍🏽{{avatarID}}
                    </h6>
                </div>
             

                <div>
                    <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                        <div class="brandbtn">
                            <h6 class="m-1   " (click)="selectSummeryView('Story')"
                                [ngClass]="secteTab == 'Story'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                <a>
                                       
                                    <img  *ngIf="secteTab  == 'Story'"  style="width:15px !important" src="  ./assets/storystyle/story-white.png">
                                    <img  *ngIf="secteTab  != 'Story'" style="width:15px !important" src=" ./assets/storystyle/storyblack.png">
                                
                                    
                                    </a>
                            </h6>
                            <h6 class="m-1 font-class"  *ngIf="secteTab  == 'Story'">Story</h6>
                        </div>
                        <div class="brandbtn" (click)="selectTab('Brands')">
                            <h6 class="m-1 "
                                [ngClass]="secteTab == 'Brands'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                <a>
                                    
                                    <img  *ngIf="secteTab  == 'Brands'" style="width:15px !important" src="./assets/brand_white.png">
                                    <img  *ngIf="secteTab  != 'Brands'" style="width:15px !important" src="./assets/brand_black.png">
    
                                
                                </a>
                            </h6>
                            <h6 class="m-1 font-class" *ngIf="secteTab  == 'Brands'">Brands </h6>
                        </div>
                        <div class="brandbtn">
                            <h6 class="m-1  " (click)="selectTab('Style')"
                                [ngClass]="secteTab == 'Style'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                <a>
                                    <img *ngIf="secteTab  == 'Style'" style="width:15px !important" src="./assets/t_shirt_white.png">
                                    <img  *ngIf="secteTab  != 'Style'" style="width:15px !important" src="./assets/t_shirt_black.png">
                                                                               
                                </a>
                            </h6>
                            <h6 *ngIf="secteTab  == 'Style'"  class="m-1 font-class ">Style</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="justify-content-center story-center d-flex">
                <h6 class="m-2 text-primary  color-class">
                    <div *ngIf="!snaploader2D" class="row d-flex twoimages">
                        <div *ngIf="userFiledetails.length == 0 "
                            class="">
                            <app-slider-two-d [images]="images"   [rootFlag]="userPage"></app-slider-two-d>
                        </div>
                        <div *ngIf="userFiledetails.length != 0 "
                            class="">
                            <app-slider-two-d [deleteGarmentUpload]="deleteflag" [images]="userFiledetails" [UserId]="userId" [rootFlag]="userPage"></app-slider-two-d>
                        </div>
                      
                    </div>
                    <img style="    width: 20px;
                    height: 20px;
                    margin-left: 269px;
                    margin-top: -31px;
                " class="w-80 shadow-1-strong rounded" src="./assets/plus-8-512.png" (click)="userFileUpload()">


                    <input type="file" id="file" [value]="uploadFileName" (change)="start2DFileUpload($event)"
                        accept="image/png, image/gif, image/jpeg">


                </h6>
            </div>

            <i  style="width:15px !important;" (click)="setuserDetails(userProfileData)" class="bi bi-arrow-clockwise  text-white m-2"></i>

        </div>
        </div>
        <div *ngIf="selectedTab  == 'user-story'" class="story-slass brand-profile-color">
            <app-user-story [userSnapShotsInput]="filterSnap" [UserId]="userId" [AvatrId]="avatarID"
                [StoryIds]="selectedStoryId" [PageCount]="pagecount" [pageNumber]="pageNumber" [rootFlag]="userPage"
                (outPageNumber)="setPageNumber($event)"
                [brandPageFlag]="brandFlag" [routLogInFlag]="routLogInFlag"
                
                >
            </app-user-story>
        </div>
        <div *ngIf="selectedTab  == 'story'"
            class="row m-2 height-avatr-id  brand-profile-color justify-content-center story mb-5 my-story ">
            <div class="column d-block" *ngFor="let snapshot of filterSnap ; let i=index;">
                <img class="w-90 shadow-1-strong rounded m-2 " (click)="scrollToStory(snapshot)"
                    [src]="snapshot.SnapshotFileUrl">
            </div>
            <div *ngIf="filterSnap.length >= 12">
                <div class="col-12 text-center justify-content-center morebtn"
                    [ngClass]="{'hide': this.avatarID != undefined }">
                    <h6 (click)="nextButton(counter)"><b>More</b></h6>
                </div>
                <div class="col-12 text-center justify-content-center morebtn"
                    [ngClass]="{'hide': this.avatarID == undefined }">
                    <h6 (click)="nextButtonForAvatar(counter)"><b>More</b></h6>
                </div>
            </div>
        </div>
    </div>




    <div class="modal fade imp-bg" id="upload2D" tabindex="-1" aria-labelledby="upload2D" aria-hidden="true"
        data-bs-backdrop="false">
        <div class="modal-dialog justify-content-center ">
            <div class="modal-content imp-block justify-content-center">
                <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                <div class="justify-content-center text-center">
                    <h5>Upload File</h5>
                </div>
                <span>
                    <input type="text" class="form-control m-2 p-2 input-wid" placeholder="Enter file name" />
                    <input type="file" class="form-control m-2 p-2 input-wid">
                </span>
                <!-- <div class="p-2"  *ngIf="percentage | async as pct">
                <progress [value]="pct" max="100"></progress>
                {{ pct | number }}%
                <div *ngIf="pct == 100" class="alert alert-success">Uploaded Successfully</div>
              </div>
            </div> -->
            </div>
        </div>
    </div>
