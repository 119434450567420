<div *ngIf="show_Panele" class="text-center">
    <div class="spinner-border m-5" role="status">
        <span class="sr-only"></span>
    </div>
</div>
<div class="modal-content imp-block" *ngIf="!show_Panele">
    <button type="button" *ngIf="closebuttonFlag" class="btn-close main-close-button" aria-label="Close"
        (click)="toggleChild()" style="color: white;"></button>
    <div class="col-12 brand-tag">
        <h6 style="margin: 5px;" class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <img (click)="getUserSnapShotByAvatar('story', this.AvatarID,'')" style="width: 25px; height: 25px;"
                    src="./assets/search/avatar.png" alt="Image">
                <span (click)="getUserSnapShotByAvatar('story', this.AvatarID,'')"
                    class="m-1 text-black">{{ userAvatarData?.AvatarName || selectedAvatarName?.slice(0, 10) }}
                    <span *ngIf="userAvatarData?.Gender && userAvatarData?.Gender =='MALE'">(M)</span>
                    <span *ngIf="userAvatarData?.Gender && userAvatarData?.Gender =='FEMALE'">(F)</span>
                </span>
            </div>
            <img *ngIf="showDetailes" (click)="colapsAvatarPanel($event)" style="width: 25px; height: 25px;"
                src="./assets/up-down-button/up.png" alt="Image">
            <img *ngIf="!showDetailes" (click)="colapsAvatarPanel($event)" style="width: 25px; height: 25px;"
                src="./assets/up-down-button/down.png" alt="Image">
        </h6>

        <div class="text-center" *ngIf="showDetailes && this.userAvatarData">
            <img class="m-1 rounded profileImg-brand" style="width:140px; color:black;"
                [src]="this.userAvatarData?.AvatarPreviewPortraitUrl" (click)="changeAvatar()" />
        </div>

        <div class="static-band-five-icon col-12 text-center d-flex justify-content-center mt-3">
            <div class="brandbtn">
                <h6 class="m-1" (click)="selectSummeryView('Story')"
                    [ngClass]="secteTab == 'Story' ? 'story-menu-card' : 'story-menu-card-not-selected'">
                    <a>
                        <img (click)="getUserSnapShotByAvatar('story', this.AvatarID,'')" *ngIf="secteTab  == 'Story'"
                            class="story-icon" src="  ./assets/storystyle/story-white.png">
                        <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                            src=" ./assets/storystyle/storyblack.png">
                    </a>
                </h6>
                <h6 *ngIf="secteTab  == 'Story'" class="ms-2 font-class">Story</h6>
            </div>
            <div class="brandbtn">
                <h6 class="m-1 p-0"
                    [ngClass]="secteTab == 'Brands' ? 'story-menu-card' : 'story-menu-card-not-selected'">
                    <a (click)="selectTab('Brands')">
                        <img *ngIf="secteTab  == 'Brands'" style="width:15px !important" src="./assets/brand_white.png">
                        <img *ngIf="secteTab  != 'Brands'" style="width:15px !important" src="./assets/brand_black.png">
                    </a>
                </h6>
                <h6 *ngIf="secteTab  == 'Brands'" class="m-1 font-class">Brands</h6>
            </div>

            <div class="brandbtn">
                <h6 class="m-1 p-0" (click)="selectTab('Style')"
                    [ngClass]="secteTab == 'Style' ? 'story-menu-card' : 'story-menu-card-not-selected'">
                    <a>
                        <img *ngIf="secteTab  == 'Style'" style="width:15px !important"
                            src="./assets/t_shirt_white.png">
                        <img *ngIf="secteTab  != 'Style'" style="width:15px !important"
                            src="./assets/t_shirt_black.png">
                    </a>
                </h6>
                <h6 *ngIf="secteTab  == 'Style'" class="m-1 font-class">Style</h6>
            </div>
        </div>
    </div>



    <div *ngIf="!showBrand_avatar_Slider &&  !selectedBrand_AvatarPanel && secteTab  == 'Brands'" class="brand-tag ">
        <h6 style="margin: 5px;" class="d-flex text-center justify-content-center " (click)="handleCrossButton_Brand()">
            <!-- <img style="width: 25px; height: 25px;" [src]="getBrandImageByLogo(selectedBrand?.LogoURL) " alt="Image"> -->
            <img style="width:25px !important" src="./assets/brand_black.png">
            <h6 style="margin: 3px;"> All Brands </h6>
        </h6>
        <button class="brand-remove" (click)="handleCrossButton_Brand()">
            X
        </button>
    </div>

    <div *ngIf="!showBrand_avatar_Slider && selectedBrand_AvatarPanel && secteTab  == 'Brands'" class="brand-tag ">
        <h6 style="margin: 5px;" class="d-flex">
            <img style="width: 25px; height: 25px;" [src]="selectedBrand_AvatarPanel.Brandurl" alt="Image">
            <h6 style="margin: 3px;">{{ selectedBrand_AvatarPanel.BrandName }}</h6>
        </h6>
        <button class="brand-remove" (click)="handleCrossButton_Brand()">
            X
        </button>
    </div>


    <div *ngIf="!showCloth_avatar_Slider && !selectedCloth_AvatarPanel && secteTab == 'Style'" class="brand-tag ">
        <h6 style="margin: 5px;" class="d-flex text-center justify-content-center " (click)="handleAvatrSliderClick()">
            <img style="width:25px !important" src="./assets/t_shirt_black.png">
            <h6 style="margin: 3px;"> All Garment </h6>
        </h6>
        <button class="brand-remove" (click)="handleAvatrSliderClick()">
            X
        </button>
    </div>

    <div *ngIf="!showCloth_avatar_Slider && selectedCloth_AvatarPanel && secteTab  == 'Style'" class="brand-tag ">
        <h6 style="margin: 5px;" class="d-flex">
            <img style="width: 25px; height: 25px;" [src]="getGarmentTypeLogo(selectedCloth_AvatarPanel?.RealType)"
                alt="Image">
            <h6 style="margin: 3px;">{{ selectedCloth_AvatarPanel?.Name?.slice(0, 10) }}</h6>
        </h6>
        <button class="brand-remove" (click)="handleAvatrSliderClick()">
            X
        </button>
    </div>



    <div *ngIf="!showStoryList && !allUserSnapDetails" class="text-center">
        <div class="spinner-border" role="status">
            <span class="sr-only"></span>
        </div>
    </div>


    <div class="row image-margin" *ngIf="showStoryList"
        [ngStyle]="allUserSnapDetails && allUserSnapDetails.length < 12 ? {} : { 'height.px': brandStoryScrollerHeight - 102 }">

        <h6 class="text-center" *ngIf="this.callpageNumer > 1 &&  secteTab  == 'Story'" (click)="previousUserbutton()">
            <b class="text-black">Previous</b>
        </h6>

        <h6 *ngIf="this.avatargarmentPageNumber > 1  &&  secteTab  == 'Style'" class="text-center"
            (click)="prevGarmentPage()">
            <b class="text-black">Previous</b>
        </h6>

        <h6 *ngIf="this.avatarBrandPageNumber > 1  &&  secteTab  == 'Brands'" class="text-center" (click)=" prevBrandPage()">
            <b class="text-black">Previous</b>
        </h6>

        <div class="col-6 p-0" *ngFor="let snapshot of allUserSnapDetails; let i=index;">
            <div class="d-flex">
                <img class="shadow-1-strong rounded m-1 story-image"
                    (click)="getUserSnapShotByAvatar('user-story',snapshot,i)"
                    [src]="snapshot?.SnapshotFileUrlMini || snapshot?.SnapshotFileUrl">
            </div>
        </div>
    </div>

    <!-- <div class="collaps" *ngIf="showDetailes">
        <div *ngIf="secteTab  == 'Story'" style="height: 80px !important; width: 100% !important;">
            <div class="slider-scroll band" *ngIf="this.userAvatarData">
                <img class="m-1" style="width:100px; height:70px !important; color:black;"
                         class="rounded profileImg-brand"
                        [src]="this.userAvatarData?.AvatarPreviewPortraitUrl" 
                        />

                <div *ngIf="newArray.length != 0" class="twoDimages">
                    <app-image-slide [images]="newArray" [UserId]="this.UserID"></app-image-slide>
                </div>

                <div *ngIf="newArray.length == 0" class="d-flex " data-bs-dismiss="modal">
                    <div class="twoDimages">
                        <app-image-slide [images]="images"></app-image-slide>
                    </div>
                </div>
            </div>
        </div>

        <div class="centered-container" *ngIf="userAvatarData">
            <p class="centered-text">Height: {{ userAvatarData?.HeightScale | number:'1.2-2' }} m</p>
            <p class="centered-text">Size: {{ userAvatarData?.FatBlendshapeValue | number:'1.2-2' }}</p>
        </div>

        <div *ngIf="secteTab  == 'Story'" class="large-box">
            <div class="col-12 col-sm-12  d-flex">
                <div class="col-2 col-sm-2 m-1">
                    <img class="m-1" style="width:30px; height:30px !important; color:black;"
                        *ngIf="getGarmentrealtype(userAvatarData?.cHeadgearID) == '0' " class="rounded profileImg-brand"
                        [src]="getBrandImageByGarmetnID(userAvatarData?.cHeadgearID)" />
                </div>
                <div class="col-8 col-sm-8 clothName p-1"
                    *ngIf="userAvatarData?.cHeadgearID != undefined && userAvatarData?.cHeadgearID!= ''">
                    <img class="gar-icon" *ngIf="getGarmentrealtype(userAvatarData?.cHeadgearID) == '0' "
                        style="width:15px !important; color:black ; opacity: 0.4;"
                        src="./assets/newsfeed/headgear-new.png">
                    <span class="caption-values"
                        *ngIf="userAvatarData?.cHeadgearID != undefined && userAvatarData?.cHeadgearID != ''">
                        <span class="garment-name" data-toggle="tooltip" data-placement="top" title="Cloth Details">
                            {{getGarmentName(userAvatarData?.cHeadgearID).substring(0,15)}}
                        </span>&nbsp;
                    </span>
                </div>
            </div>
            <div class="col-12 col-sm-12  d-flex">
                <div class="col-2 col-sm-2 m-1 ">
                    <img class="m-1" style="width:30px; height:30px !important; color:black ; "
                        *ngIf="getGarmentrealtype(userAvatarData?.cUpperwearID) == '1' "
                        class="rounded profileImg-brand"
                        [src]="getBrandImageByGarmetnID(userAvatarData?.cUpperwearID)" />
                    <img class="m-1" style="width:30px; height:30px !important; color:black ;"
                        *ngIf="getGarmentrealtype(userAvatarData?.cFullBodyID) == '5' " class="rounded profileImg-brand"
                        [src]="getBrandImageByGarmetnID(userAvatarData?.cFullBodyID)" />
                </div>
                <div class="col-8 col-sm-8 clothName p-1"
                    *ngIf="userAvatarData?.cUpperwearID != undefined &&userAvatarData?.cUpperwearID != ''">
                    <span>
                        <img class="gar-icon" *ngIf="getGarmentrealtype(userAvatarData?.cUpperwearID) == '1' "
                            style="width:15px !important; color:black; opacity: 0.4;"
                            src="./assets/realtype/upperwear.png">
                        <img class="gar-icon" *ngIf="getGarmentrealtype(userAvatarData?.cUpperwearID) == '5' "
                            style="width:15px !important; color:black ; opacity: 0.4;"
                            src="./assets/realtype/fullbody.png">
                        <span class="caption-values"
                            *ngIf="userAvatarData?.cUpperwearID != undefined && userAvatarData?.cUpperwearID != ''">
                            <span class="garment-name" data-toggle="tooltip" data-placement="top" title="Cloth Details">
                                {{getGarmentName(userAvatarData?.cUpperwearID).substring(0,15)}}
                            </span>&nbsp;
                        </span>
                    </span>

                </div>
                <div class="col-8 col-sm-8 clothName p-1"
                    *ngIf="userAvatarData?.cFullBodyID != undefined &&userAvatarData?.cFullBodyID != ''">
                    <span>
                        <img class="gar-icon" *ngIf="getGarmentrealtype(userAvatarData?.cFullBodyID) == '5' "
                            style="width:15px !important; color:black ; opacity: 0.4;"
                            src="./assets/realtype/fullbody.png">
                        <span class="caption-values"
                            *ngIf="userAvatarData?.cFullBodyID != undefined && userAvatarData?.cFullBodyID != ''">
                            <span class="garment-name" data-toggle="tooltip" data-placement="top" title="Cloth Details">
                                {{getGarmentName(userAvatarData?.cFullBodyID).substring(0,15)}}
                            </span>&nbsp;
                        </span>
                    </span>

                </div>
            </div>
            <div class="col-12 col-sm-12 d-flex ">
                <div class="col-2 col-sm-2  m-1">
                    <img style="width:30px; height:30px !important; color:black ; "
                        *ngIf="getGarmentrealtype(userAvatarData?.cBottomwearID) == '2'"
                        class="rounded profileImg-brand"
                        [src]="getBrandImageByGarmetnID(userAvatarData?.cBottomwearID)" />

                </div>
                <div class="col-8 col-sm-8 clothName p-1"
                    *ngIf="userAvatarData?.cBottomwearID != undefined && userAvatarData?.cBottomwearID != ''">
                    <span>


                        <img class="gar-icon" *ngIf="getGarmentrealtype(userAvatarData?.cBottomwearID) == '2'"
                            style="width:15px !important; color:black ; opacity: 0.4;"
                            src="./assets/newsfeed/bottomwear-new.png">
                        <span class="caption-values"
                            *ngIf="userAvatarData?.cBottomwearID != undefined &&userAvatarData?.cBottomwearID!= ''">
                            <span class="garment-name" data-toggle="popover" data-placement="left"
                                title="Cloth Details">{{getGarmentName(userAvatarData?.cBottomwearID).substring(0,15)}}
                            </span>&nbsp;
                        </span>
                    </span>


                </div>
            </div>
            <div class="col-12 col-sm-12  d-flex">
                <div class="col-2 col-sm-2 ">
                    <img class="m-2" style="width:30px; height:30px !important; color:black ;"
                        *ngIf="getGarmentrealtype(userAvatarData?.cFootwearID) == '3'" class="rounded profileImg-brand"
                        [src]="getBrandImageByGarmetnID(userAvatarData?.cFootwearID)" />
                </div>

                <div class="col-8 col-sm-8 clothName p-1"
                    *ngIf="userAvatarData?.cFootwearID != undefined && userAvatarData?.cFootwearID != ''">
                    <img class="gar-icon" *ngIf="getGarmentrealtype(userAvatarData?.cFootwearID) == '3' "
                        style="width:15px !important; color:black ; opacity: 0.4;" src="./assets/realtype/footwear.png">

                    <span class="caption-values"
                        *ngIf="userAvatarData?.cFootwearID != undefined && userAvatarData?.cFootwearID != ''">
                        <span class="garment-name" data-toggle="popover" data-placement="left" title="Cloth Details">
                            {{getGarmentName(userAvatarData?.cFootwearID).substring(0,15)}}
                        </span>&nbsp;
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-12 band static-band" *ngIf="showDetailes">
        <div style="font-size: 12px; color: black; text-align: start; padding-left: 5px;">Creator</div>
        <div class="static-band d-flex align-items-center">
            <div (click)="getUserSnapShotDashboard('story','','')" 
                 class="d-flex m-1 profile-popup text-white" 
                 data-bs-dismiss="modal">
                <img *ngIf="userDetailsObject.ProfilePicture != undefined"
                    class="img-thumbnail rounded-circle"
                    style="width: 40px !important; height: 40px !important; margin-top: 0px;"
                    [src]="userDetailsObject.ProfilePicture">
                <img *ngIf="userDetailsObject.ProfilePicture == undefined"
                    class="img-thumbnail rounded-circle"
                    style="width: 40px !important; height: 40px !important;"
                    [src]="userDetailsObject.ProfilePicture">
            </div>
    
            <div class="col-5 text-start">
                <h5 *ngIf="selectedUserName" class="m-1 text-start text-black profile-name"
                    (click)="getUserSnapShotDashboard('story','','')">
                    {{selectedUserName}}
                </h5>
            </div>
    
            <div class="col-3 text-end">
                <button type="button" style="font-size: 8px;" class="btn btn-outline-info follow-btn">
                    Follow
                </button>
            </div>
        </div>
    </div> -->

</div>
<!-- <div class="container">
    <button type="button" *ngIf="closebuttonFlag" class="btn-close main-close-button" aria-label="Close"
        (click)="toggleChild()" style="color: white;"></button>
    <div class="row text-center">
        <div class="container setImage">
            <div class="text-center">
                <div class="row text-start p-2">
                    <div *ngIf="userDetailsloading" class="text-center">
                        <div class="spinner-border m-5" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>


                    <div *ngIf="newArray.length != 0" class="text-start capsule-container"
                                    style="height: 35px; width: 100%; ">
                                    <div class="d-flex align-items-center justify-content-center"
                                        (click)="getUserSnapShotByAvatar('story', this.AvatarID,'')"
                                        *ngIf="newArray.length != 0">
                                        <img class="avatar-image" style="width: 25px; height: 25px;"
                                            [src]="userAvatarData?.AvatarPreviewPortraitUrl">
                                        <span class="avatar-name"><span class="avatar-details">{{selectedAvatarName}}
                                                ({{userAvatarData?.Gender =='MALE' ? 'M': 'F'}})</span></span>
                                    </div>
                                </div>
                    <div *ngIf="newArray.length == 0" class="text-start capsule-container"
                                    style="height: 35px; width: 100%; ">
                                    <div class="d-flex align-items-center justify-content-center"
                                        (click)="getUserSnapShotByAvatar('story', this.AvatarID,'')">
                                        <img class="avatar-image" style="width: 25px; height: 25px;"
                                            [src]="getAvatarImageUrl(userAvatarData)">
                                        <span class="avatar-name"><span class="avatar-details">{{selectedAvatarName }}
                                                ({{userAvatarData?.Gender =='MALE' ? 'M': 'F'}})</span></span>
                                    </div>
                                </div>


                    
                                <div class="centered-container" *ngIf="userAvatarData">
                                    <p class="centered-text">Size : {{userAvatarData?.FatBlendshapeValue}}</p>
                                    <p class="centered-text">Height : {{userAvatarData?.HeightScale}}</p>
                                </div>



                </div>
                <div *ngIf="secteTab  == 'Story'" style="height: 80px !important; width: 100% !important;">
                                <div class="slider-scroll band">
                                    <div *ngIf="newArray.length != 0" class="twoDimages">
                                        <app-image-slide [images]="newArray" [UserId]="this.UserID"></app-image-slide>
                                    </div>

                                    <div *ngIf="newArray.length == 0" class="d-flex " data-bs-dismiss="modal">
                                        <div class="twoDimages">
                                            <app-image-slide [images]="images"></app-image-slide>
                                        </div>
                                    </div>
                                </div>
                            </div>


                <div>

                </div>

                <div class="col-12 div-center" [ngClass]="secteTab == 'Story'   ? 'story-margin' : ''">
                    <div class="col-12" *ngIf="this.secteTab == 'Brands'">
                        <div class="dropdown custom-dropdown">
                            <button (click)="selectavatarOprtion()" class="btn btn-secondary dropdown-toggle"
                                type="button" id="dropdownMenuButton"
                                style="background-color: white; color: black; margin-left: -20px !important;"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <span *ngIf="!brandObject">Select Brand</span>
                                <span (click)="selectavatarOprtion()">
                                    <img *ngIf="brandObject?.Brandurl"
                                        style="width: 30px !important; height: 30px !important; margin-right: 0px;"
                                        class="avatar-image" src="{{ brandObject.Brandurl }}" alt="Avatar Image">
                                    {{ brandObject?.BrandName != undefined || brandObject?.BrandName != null ? brandObject?.BrandName
                                                : brandObject?.id}}
                                </span>
                            </button>


                            <div *ngIf="brandObject && dropdownOpen" class="d-flex justify-content-center all-select">
                                <div class="text-center" (click)="selectAllAvatars()">
                                    View All
                                </div>
                            </div>

                            <div class="row image-margin scroll-drop-item" aria-labelledby="dropdownMenuButton"
                                [ngClass]="{'d-none': !dropdownOpen, 'story-inset': dropdownOpen && secteTab !== 'Story'}"
                                [style.height.px]="getHeight(brandList?.length)">

                                <div *ngIf="isBrandLoading" class="text-center">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div>
                                <div class="col-6 p-0" *ngFor="let brand of brandList" (click)="selectBrand(brand)">
                                    <div class="d-flex position-relative" *ngIf="!isBrandLoading">
                                        <img class="shadow-1-strong rounded m-1 story-image avatar-image"
                                            *ngIf="brand.Brandurl" src="{{ brand.Brandurl }}" alt="Avatar Image" />
                                        <div class="image-name-overlay">
                                            {{ brand.BrandName != undefined || brand.BrandName != null ? brand.BrandName : brand.id }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="this.secteTab == 'Style'">
                        <div class="dropdown custom-dropdown">
                            <button (click)="selectavatarOprtion()" class="btn btn-secondary dropdown-toggle"
                                type="button" id="dropdownMenuButton"
                                style="background-color: white; color: black; margin-left: -24px !important;"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <span *ngIf="!cacheObject">Select Garment</span>
                                <span (click)="selectavatarOprtion()">
                                    <img *ngIf="cacheObject?.RealType"
                                        style="width: 30px !important; height: 30px !important; margin: 0px;"
                                        class="avatar-image" src="{{   getGarmentTypeLogo(cacheObject.RealType)  }}"
                                        alt="Avatar Image">

                                    {{( cacheObject?.Name != undefined || cacheObject?.Name != null ? cacheObject.Name.slice(0, 12)
                                            : cacheObject?.id)}}
                                </span>
                            </button>
                            <div [style.height.px]="getHeight(garmentCacheList?.length)"
                                class="row image-margin scroll-drop-item" aria-labelledby="dropdownMenuButton"
                                [ngClass]="{'d-none': !dropdownOpen, 'story-inset': dropdownOpen && secteTab !== 'Story'}">
                                 <div *ngIf="cacheObject" class="d-flex justify-content-center all-select">
                                    <div class="text-center" (click)="selectAllAvatars()">
                                        View All
                                    </div>
                                </div>
                                <div class="col-6 p-0" *ngFor="let chache of garmentCacheList"
                                    (click)="selectCacheImage(chache)">
                                    <div class="d-flex position-relative" *ngIf="chache">
                                        <div class="shadow-1-strong rounded m-1 story-image avatar-image">
                                            <div class="image-set">
                                                <img class="image-set-cache"
                                                    [src]="chache.cacheUrl != undefined || chache.cacheUrl != null ? chache.cacheUrl : cacheReGenrate(chache)"
                                                    alt="Avatar Image" />
                                            </div>
                                        </div>

                                        <div class="image-name-overlay">
                                            {{ chache.Name != undefined || chache.Name != null ? chache.Name : chache.id }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    </div>
</div> -->



<div *ngIf="allUserSnapDetails &&  allUserSnapDetails?.length > 11 ">
    <div class="col-12 text-center text-black justify-content-center p-3">
        <h6 (click)="incrementImgCounter()">
            <b>More</b>
        </h6>
    </div>
</div>


<div *ngIf="(this.avatargarmentPageNumber * this.currentAvatarGarmentIndex) < this.snapshotsGarmentFilter.length && showStoryList &&  secteTab  == 'Style'"
    class="col-12 text-center text-black justify-content-center p-3">
    <h6 (click)="nextGarmentPage()">
        <b>More</b>
    </h6>
</div>

<div *ngIf="(this.avatarBrandPageNumber * this.currentAvatarBrandIndex) < this.snapshotsBrandFilter.length && showStoryList &&  secteTab  == 'Brands'"
class="col-12 text-center text-black justify-content-center p-3">
<h6 (click)="nextBrandPage()">
    <b>More</b>
</h6>
</div>