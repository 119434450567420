<div (click)="closePanel($event)"><app-yologram-social *ngIf="logInFlag"></app-yologram-social>
    <app-public *ngIf="!logInFlag"></app-public>

    <button class="btn btn-primary" style="z-index: 100000;
position: fixed;
top: 215px;
right: 0;" (click)="profilerCheck()">
        <i class="bi bi-arrow-right-circle" *ngIf="isStatsVisible"></i>
        <i class="bi bi-arrow-left-circle" *ngIf="!isStatsVisible"></i>
    </button>
    <span class="d-block" *ngIf="!showStoryCard && this.queryParams && this.queryParams?.storyId != undefined, "
        style="margin-left: 43px;">
        <img (click)=" OpenCard()" class="dummy-card" src="./assets/small-card.png" />
        <img (click)=" OpenCard()" class="short-image-card"
            [src]="findSnap?.SnapshotFileUrlMini || findSnap?.SnapshotFileUrl" />
        <img class="back-card" (click)="OpenCard()" style="width:20px !important; transform: scaleX(-1);"
            src="./assets/share_copy.png">
        <img class="animation-icon" src="./assets/animation-icon-white.png" />
    </span>

    <div>
        <div style="height: 300px;width:260px; background-color: black;">
            <app-news3-d-templete [EnvUrl]="defaultEnvironment" [FunctionFlag]="functionflagValue"
                [storyText]="storyText" [GarmentsGender]="genders" [flag_v2]="flag_v2"
                [upperwearUrl]="UpperWearfinalUrl" [bottomwearUrl]="garmentbottom"
                [AvatarUrl]=" this.saveAvatarData?.avatraUrls  || this.avtarUrl" [headgearUrl]="headgear"
                [footwearUrl]="footwear"
                [EnvUrl]="this.saveAvatarData?.envUrl || defaultEnvironment"
                [FunctionFlag]="this.saveAvatarData?.functionflagValue || functionflagValue"
                [upperwearUrl]="this.saveAvatarData?.garmenturl" [bottomwearUrl]="this.saveAvatarData?.garmentbottom"
                [BlobUrl]="this.saveAvatarData?.dataBlobUrl" [headgearUrl]="this.saveAvatarData?.headgear"
                [footwearUrl]="this.saveAvatarData?.footwear" [StoryID]="this.saveAvatarData?.storyID"
                [BlobUrl]="this.saveAvatarData?.dataBlobUrl"
                [storyText]="storyText" [isShowStats]="isStatsVisible" >
            </app-news3-d-templete>
        </div>
    </div>



    <!-- [GarmentsGender]="genders" 
            [upperwearUrl]="UpperWearfinalUrl" [bottomwearUrl]="garmentbottom"
            [AvatarUrl]=" this.saveAvatarData?.avatraUrls  || this.avtarUrl" [headgearUrl]="headgear"
            [footwearUrl]="footwear" (calledVlueIntilized)="calledVlueIntilized($event)" [flag_v2]="flag_v2"
            [EnvUrl]="this.saveAvatarData?.envUrl || defaultEnvironment"
            [FunctionFlag]="this.saveAvatarData?.functionflagValue || functionflagValue"
            [upperwearUrl]="this.saveAvatarData?.garmenturl" [bottomwearUrl]="this.saveAvatarData?.garmentbottom"
            [BlobUrl]="this.saveAvatarData?.dataBlobUrl" [headgearUrl]="this.saveAvatarData?.headgear"
            [footwearUrl]="this.saveAvatarData?.footwear" [StoryID]="this.saveAvatarData?.storyID"
            [bvhAnimationUrl]="this.selectedAnimation" [BlobUrl]="this.saveAvatarData?.dataBlobUrl"
            [storyText]="storyText" [isShowStats]="isStatsVisible" -->


    <div class="brand-close" id="closePannel">
        <div id="mySidenav-avatar" class="sidenav" [ngClass]="closeDiv  ? 'expand-size' : 'actual-size'">

            <div *ngIf="!closeDiv" class="d-block">
                <div class="menu-arrow text-end tab-box" (click)="toggleAvatarPanel('search')">
                    <img style="width:20px;" (click)="toggleAvatarPanel('search')"
                        src="./assets/brand-space/search-button.png" alt="Open Search">
                </div>
            </div>

            <app-user-panel [ngClass]="closeDiv  ? '' : 'd-none'" *ngIf="showUserPanel" [UserID]="passUserPanenlValue?.UserID || queryParams?.id"
                [userPanelStories]="userPanelStories" [selectedUserName]="userProfileData?.DisplayName"
                (setTabSelection)="handleUserPanelTab($event)"
                (emitTabDataObjectList)="handleUserPanelTabDataObjectList($event)"
                [childUserPanelDataMapping]="userPanelDataMapping"
                [showAvatar_Useravatar_Slider]="currentUserPanelTab === 'Story' ? showAvatar_Useravatar_Slider : null"
                [showBrand_Useravatar_Slider]="currentUserPanelTab === 'Brands' ? showBrand_Useravatar_Slider : null"
                [showCloth_Useravatar_Slider]="currentUserPanelTab === 'Style' ? showCloth_Useravatar_Slider : null"
                (userAvatarTag_cross)="handlecrossUserPanelAvatar($event)"
                (userBrandTag_cross)="handlecrossUserPanelBrand($event)"
                (userStyleTag_cross)="handlecrossUserPanelCloth($event)" (newEmitUser_Data)="handleUserData($event)"
                (setTabSelection)="handleUserTab($event)"
                (sendStoryId)="handleStoryOnPaneluser($event)"
                [change_avatar_flage]="tempChangeAvatarFlag"
                
                ></app-user-panel>

            <app-avatar-panel [ngClass]="closeDiv  ? '' : 'd-none'"  *ngIf="showAvatarPanel" 
                [UserID]="passAvatarPanenlValue?.UserID ||  queryParams?.id" [AvatarID]="passAvatarPanenlValue?.AvatarID || queryParams?.avatarId"
                [childAvatarPanelDataMapping]="avatarPanelDataMapping"
                (emitTabAvatarPanelDataObjectList)="handleAvatarPanelTabDataObjectList($event)"
                (sendStoryId)="handleStoryOnPaneluser($event)"
                
                [showCloth_avatar_Slider]="showCloth_avatar_Slider"
                [showBrand_avatar_Slider]="showBrand_avatar_Slider"

                (avatrTabselection)="handleAvatarTab($event)"
                (show_brandslider_avatarEmit)="handleCrossAvatarBrandTag($event)"
                (show_clothlider_avatarEmit)="handleCrossAvatarGarmentTag($event)"
                (newEmitUserData)="handleNewAvatrUserData($event)" 



                
                ></app-avatar-panel>
        </div>
    </div>

    <div class="drips-container" [ngClass]="showStoryCard  ? '' : 'd-none'" >
        <div (click)="closeCard()" class="text-black text-end class-close-btn">X</div>
        <app-drips (click)="closePanelByCard()" #childrip [mainMenuFlag]="mainMenuFlag" [avatarV2Flage]="avatarV2Flage"
            [userSnapShotsInput]="filterSnap" [hideStoryCard]="hideStoryCard" [StoryIds]="StoryIds"
            [threadsPage_avatar_flag]="threadsPage_avatar_flag"
            
            (showAvatar_User_Slider_emit)="closeAvatarSlider($event)"
            (showBrand_User_Slider_emit)="closeBrandSlider($event)"
            (showCloth_User_Slider_emit)="closeClothSlider($event)"



            (emit_userPanel_Selected_Avatar)="changeUserAvatar($event)"


            (emit_userPanel_Selected_Brand)="changeUserBrand($event)"
            (emit_userPanel_Selected_Cloth)="changeUserCloth($event)" 
            
            
            (emit_userPanel_Selected_Brand)="changeUserBrand($event)"
            (emit_userPanel_Selected_Cloth)="changeUserCloth($event)" 
            
            (newurl_space)="handlenew_even($event)"
            [showSearchPanel]="showSearchPanel"

            [showpanel]="showpanel"

            (visibleIndex)="handleStoryIndex($event)"
        
            (emitSelectedBrand)="handleSelectedBrand($event)"
            (emitSelectedGarment)="handleSelectedGarment($event)"
           
            (showBrand_avatar_Slider_emit)="closeAvatarBrandSlider($event)"
            (showCloth_avatar_Slider_emit)="closeAvatarClothSlider($event)"


            (emitUserpanleOpen)="handleUserStoryCardPanel($event)"
            (emitAvatarPanel)="handleAvatarStoryCardPanel($event)"
            (change_avatar_flageData)="userAavatarChange($event)"
            >
        </app-drips>
    </div>
</div>
<div *ngIf="!closeDiv" class="d-block preview-space">
    <div class="menu-arrow text-end tab-box space-box">
        <!-- Avatar Icon & Image -->
        <div class="image-space" *ngIf="queryParams.PageType === 'Avatar'" (click)="toggleAvatarPanel('avatar')">
            <img class="small-image" src="./assets/search/avatar.png" alt="Open Avatar">
            <!-- <img class="large-image" [src]="showAvatarPotraite" alt="Open Avatar"> -->
        </div>
        <!-- User Icon & Image -->
        <div class="image-space" *ngIf="queryParams.PageType === 'User'" (click)="toggleAvatarPanel('user')">
            <img class="small-image" src="./assets/search/user.png" alt="Open User">
            <img class="large-image" *ngIf="queryParams.PageType === 'User'" src="" alt="Open User">
            <!-- <img class="large-image" *ngIf="UserIamge" [src]="UserIamge" alt="Open User"> -->
        </div>
    </div>
</div>