<div class="modal-content imp-block" data-bs-backdrop="false">
    <div class="container">
        <button type="button" *ngIf="closebuttonFlag"  class="btn-close main-close-button" aria-label="Close" (click)="toggleChild()" style="color: white !important;"></button>
        <div class="row text-center">
            <div class="container setImage">
                <div class="text-center">
                    <div class="row text-start p-2">
                        <div *ngIf="userDetailsloading" class="text-center">
                            <div class="spinner-border m-5" role="status">
                                <span class="sr-only"></span>
                            </div>
                        </div>
                        <div class=" col-lg-12 band">
                            <div class="static-band" style="margin-left: -18px  !important;">
                                <div (click)="getUserSnapShotDashboard('story','','')"
                                    class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal">
                                    <img *ngIf="userDetailsObject.ProfilePicture != undefined"
                                        class="img-thumbnail rounded-circle" style="
                                          width: 40px !important;
                                          height: 40px !important;
                                          margin-top: -36px;
                                      " [src]="userDetailsObject.ProfilePicture">
                                    <img *ngIf="userDetailsObject.ProfilePicture == undefined"
                                        class="img-thumbnail rounded-circle" style="
                                          width: 40px !important;
                                          height: 40px !important;
                                          margin-top: -36px;
                                      " [src]="userDetailsObject.ProfilePicture">
                                </div>
                                <div class="w-100 col-12 d-flex ">
                                    <div class="col-9 text-start">
                                        <h5 *ngIf="selectedUserName"
                                            class="m-1 text-start text-black profile-name"
                                            (click)="getUserSnapShotDashboard('story','','')">{{selectedUserName}}</h5>
                                        <!-- <h5 *ngIf="userDetailsObject.DisplayName != undefined"
                                            class="m-1 text-black profile-name"
                                            (click)="getUserSnapShotDashboard('story','')">
                                            {{userDetailsObject.DisplayName}}
                                        </h5> -->
                                    </div>
                                    <div class="col-3 "><button type="button" style="font-size: 10px;"
                                            class="btn btn-outline-info follow-btn">Follow</button>
                                    </div>
                                </div>
                                <div class="text-start " style="height: 25px; width: 100%;">
                                    <h6 *ngIf="this.AvatarID != undefined && this.UserID != undefined"
                                        class="avatr  text-start text-black"
                                        (click)="getUserSnapShotByAvatarId('story',this.AvatarID)">🧍🏽{{AvatarID}}
                                    </h6>
                                </div>
                                <div>
                                    <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                                        <div class="brandbtn">
                                            <h6 class="m-1" (click)="selectSummeryView('Story')"
                                                [ngClass]="secteTab == 'Story'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                                <a *ngIf="this.AvatarID == undefined">
                                                    <img (click)="getUserSnapShotDashboard('story','','')"
                                                        *ngIf="secteTab  == 'Story'" class="story-icon"
                                                        src="  ./assets/storystyle/story-white.png">
                                                    <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                                        src=" ./assets/storystyle/storyblack.png">
                                                </a>
                                                <a *ngIf="this.AvatarID != undefined">
                                                    <img (click)="getUserSnapShotByAvatarId('story',this.AvatarID)"
                                                        *ngIf="secteTab  == 'Story'" style="width:15px !important"
                                                        src="  ./assets/storystyle/story-white.png">
                                                    <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                                        src=" ./assets/storystyle/storyblack.png">
                                                </a>

                                            </h6>
                                            <h6 *ngIf="secteTab  == 'Story'" class="ms-2 font-class">Story</h6>
                                        </div>
                                        <div class="brandbtn" (click)="selectTab('Brands')">
                                            <h6 class="m-1 p-0"
                                                [ngClass]="secteTab == 'Brands'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                                <a>
                                                    <img *ngIf="secteTab  == 'Brands'" style="width:15px !important"
                                                        src="./assets/brand_white.png">
                                                    <img *ngIf="secteTab  != 'Brands'" style="width:15px !important"
                                                        src="./assets/brand_black.png">

                                                </a>
                                            </h6>
                                            <h6 *ngIf="secteTab  == 'Brands'" class="m-1 font-class">Brands </h6>
                                        </div>
                                        <div class="brandbtn" (click)="selectTab('Style')">
                                            <h6 class="m-1 p-0 "
                                                [ngClass]="secteTab == 'Style'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                                <a>
                                                    <img *ngIf="secteTab  == 'Style'" style="width:15px !important"
                                                        src="./assets/t_shirt_white.png">
                                                    <img *ngIf="secteTab  != 'Style'" style="width:15px !important"
                                                        src="./assets/t_shirt_black.png">
                                                </a>
                                            </h6>
                                            <h6 *ngIf="secteTab  == 'Style'" class="m-1 font-class">Style</h6>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div style="height: 147px !important; width: 100% !important;">
                                <div class="slider-scroll band">
                                    <div *ngIf="this.AvatarID == undefined" class="d-flex "
                                          data-bs-dismiss="modal">
                                        <div *ngIf="userFiledetails.length == 0 " class="twoDimages">
                                            <!-- <app-slider-two-d  [images]="images" [dismmis]="dismiss"></app-slider-two-d> -->
                                            <app-image-slide [images]="images"></app-image-slide>
                                        </div>
                                        <div *ngIf="userFiledetails.length != 0" class="twoDimages">
                                            <!-- <app-slider-two-d [images]="userFiledetails" [UserId]="this.UserID"
                                                [UserProfile]="userProfile" [dismmis]="dismiss"></app-slider-two-d> -->
                                            <app-image-slide [images]="userFiledetails" [UserId]="this.UserID"
                                                [UserProfile]="userProfile" [dismmis]="dismiss"></app-image-slide>
                                        </div>
                                    </div>
                                    <div class="d-flex " *ngIf="this.AvatarID != undefined"
                                       
                                        data-bs-dismiss="modal">
                                        <div *ngIf="userFiledetails.length == 0 " class=" col-12 band">
                                            <!-- <app-slider-two-d [images]="images" [dismmis]="dismiss"></app-slider-two-d> -->
                                            <app-image-slide [images]="images"></app-image-slide>
                                        </div>
                                        <div *ngIf="userFiledetails.length != 0" class=" col-12  band">
                                            <!-- <app-slider-two-d [images]="userFiledetails" [UserId]="this.UserID"
                                                [UserProfile]="userProfile" [dismmis]="dismiss"></app-slider-two-d> -->
                                            <app-image-slide [images]="userFiledetails" [UserId]="this.UserID"
                                                [UserProfile]="userProfile" [dismmis]="dismiss"></app-image-slide>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        <div class="col-12 div-center" style="margin-top: -45px;">
                            <div class="d-block justify-content-center m-1">
                                <div class="dropdown custom-dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                        style="background-color: white; color: black; width: auto; margin-left: -30px !important;" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <span *ngIf="!avatarObject" (click)="selectavatarOprtion()" >Select Avatar</span>
                                        <span (click)="selectavatarOprtion()">
                                            <img *ngIf="avatarObject?.AvatarPreviewPortraitUrl" class="avatar-image"
                                                src="{{ avatarObject.AvatarPreviewPortraitUrl }}" alt="Avatar Image">
                                            {{ avatarObject?.AvatarName != undefined || avatarObject?.AvatarName != null ? avatarObject?.AvatarName
                                            : avatarObject?.AvatarID}}
                                        </span>
                                    </button>
                                    <ul class="dropdown-menu scroll-drop-item" aria-labelledby="dropdownMenuButton"  [ngClass]="{'d-none': !dropdownOpen,'': dropdownOpen}">
                                        <li class="dropdown-item" (click)="selectAllAvatars()">
                                            Select All
                                        </li>
                                       
                                        <li *ngFor="let avatar of newAvatarList" class="dropdown-item " (click)="selectAvatarId(avatar)"    >
                                            <img *ngIf="avatar.AvatarPreviewPortraitUrl" class="avatar-image"
                                                src="{{ avatar.AvatarPreviewPortraitUrl }}" alt="Avatar Image">
                                            {{ avatar.AvatarName != undefined || avatar.AvatarName != null ? avatar.AvatarName : avatar.AvatarID }}
                                            {{ avatar.AvatarName == undefined || avatar.AvatarName == null ? " " : - avatar.AvatarID }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="d-flex align-items-start" *ngIf="!dropdownOpen && selectedavatarId != ''">
                                    <div >
                                        <button class="btn btn-secondary" style="margin: 4px 40px 0px 40px;" type="button" id="dropdownMenuButton"
                                            data-bs-toggle="dropdown" aria-expanded="false" (click)="navigateToAvatarPage()">
                                            Go
                                        </button>
                                    </div>
                                </div>
                            </div>
                                   
                            <div class="row  image-margin"   [ngStyle]="allUserSnapDetails.length < 12 ? {} : { 'height.px': brandStoryScrollerHeight - 100 }" >
                                <!-- Change "col-4" to "col-6" to make two images per row -->
                                <div class="col-6 p-0" *ngFor="let snapshot of allUserSnapDetails; let i=index;">
                                    <div class="d-flex ">
                                        <img class="shadow-1-strong rounded m-1 story-image"
                                            (click)="getUserSnapShotDashboard('user-story',snapshot,i)"
                                            [src]="snapshot.SnapshotFileUrlMini">
                                    </div>
                                </div>
                            </div>

                           

                            <div *ngIf="brandsnaploadingSnap" class="text-center">
                                <div class="spinner-border m-5 p-5" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <div class="col-12 text-center text-black justify-content-center p-3">
        <h6 (click)="incrementImgCounter()">
            <b>More</b>
        </h6>
    </div>
</div>

