import { Component, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DripsComponent } from 'src/app/common/drips/drips.component';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-avatar-page-redesign',
  templateUrl: './avatar-page-redesign.component.html',
  styleUrls: ['./avatar-page-redesign.component.css']
})
export class AvatarPageRedesignComponent {

  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userdetails = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;

  @ViewChild('childrip') child: DripsComponent;
  defaultEnvironment: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/Environments%2FAdobeStock_209580496.jpeg?alt=media&token=34dad92c-ec1f-4b7d-a293-5443ab42fd78';

  flag_v2: boolean = true;

  showBrand_Useravatar_Slider: boolean;
  showAvatar_Useravatar_Slider: boolean;
  showCloth_Useravatar_Slider: boolean;

  showBrand_avatar_Slider: boolean;
  showCloth_avatar_Slider: boolean;

  selectedBrand_UserPanel
  selectedAvatar_UserPanel: any
  
  selectedCloth_UserPanel

  threadsPage_avatar_flag = true
  logInFlag = false
  queryParams
  showUserPanel = false;
  showAvatarPanel = false

  mainMenuFlag: boolean = false
  avatarV2Flage = false
  showStoryCard = true
  storyCardflag = false

  hideStoryCard: boolean = undefined
  userProfile: any[] = []
  garmentsCollection: any = []
  brandCollection: any = []
  filterSnap: any = []
  userProfileData: any;
  userId: string;
  closeDiv = true
  userPanelStories: any;
  findSnap:any
  storyText=''

  activeTab: string = 'Story'; // default
  StoryIds: any = ' ';

  // store tab object data list (stories) in parent Object against each tab 
  userPanelDataMapping: UserPanelDataMapping = {
    userPanelTabDataObjectMap: {},
    selectedTab: '',
  };

  avatarPanelDataMapping: UserPanelDataMapping = {
    userPanelTabDataObjectMap: {},
    selectedTab: '',
  };

  isStatsVisible: boolean = false


  currentUserPanelTab: any;
  functionflagValue:any = 'skybox'
  showSearchPanel: any;
  showpanel: boolean = true;
  storyIndex: any;
  passAvatarPanenlValue: any;
  garmentLink: string;
  GarmentsGender: any;
  saveAvatarID_temp: any;
  AvatarSwichDetails: any;
  userAvatarData: any;
  avatarname: any;
  showAvatarPotraite: any;
  avtarUrl: any;
  garmenturl: string;
  fullBodyurl: string;
  UpperWearfinalUrl: string;
  garmentbottom: string;
  genders: string;
  headgear: string;
  footwear: string;
  flagForShowThreeJs: boolean;
  usrPageAvatarFlag: any;
  avatarID: any;
  newArray: any;
  saveAvatarData: any;
  panelName: string;
  passUserPanenlValue: any;
  tempChangeAvatarFlag: any;

  constructor(private commonService: CommonService,
    private route: ActivatedRoute,
    private firestore: AngularFirestore,
  ) {
    this.logInFlag = this.commonService.isLoggedIn();



  }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      const avatarId = params.get('avatarID');
      const pagetab = params.get('pagetab');
      const storyId = params.get('storyId');
      const index = params.get('index');
      const pageNumber = params.get('pageNumber');
      const PageType = params.get('PageType');
      const TabSelection = params.get('TabSelection');
      const brandPageNumber = params.get('brandPageNumber');
      const avatarPageNumber = params.get('avatarPageNumber');
      const garmentPageNumber = params.get('garmentPageNumber');
      const avatargarmentPageNumber = params.get('avatargarmentPageNumber');
      const avatarBrandPageNumber = params.get('avatarBrandPageNumber');
      const selectedBrand  = params.get('SetBrandId');
      const selectedGarment  = params.get('SetGarmentId');

      this.queryParams = {
        id, pagetab, storyId, index, pageNumber, PageType, garmentPageNumber,
        avatargarmentPageNumber, avatarBrandPageNumber,
        TabSelection, brandPageNumber, avatarPageNumber,avatarId
      };

      // Get the brand details of the brand 
      // [data]
      // [] 

      this.garmentsList.subscribe((garments) => {
        this.garmentsCollection = garments;
      });

     if(TabSelection == 'Brands' && PageType == 'User') {
      this.brandsFilesList.subscribe((brands) => {
        this.brandCollection = brands;
        if(selectedBrand != undefined){
          let brand =  this.brandCollection.find((brand)=>brand.id == selectedBrand )
          this.userPanelDataMapping.selectedTab = TabSelection
          this.userPanelDataMapping.userPanelTabDataObjectMap['Brands'] = {};
          this.changeUserBrand(brand)
          console.log("brand >>>>",brand)
        }
  
      });
     }else if(TabSelection == 'Style' && PageType == 'User') {
      this.garmentsList.subscribe((garments) => {
        this.garmentsCollection = garments;
        if(selectedBrand != undefined){
          let garment =  this.garmentsCollection.find((g)=>g.id == selectedGarment )
          this.userPanelDataMapping.selectedTab = TabSelection
          this.userPanelDataMapping.userPanelTabDataObjectMap['Style'] = {};
          this.changeUserCloth(garment)
          console.log("Style >>>>",garment)
        }
      });
     } else if(TabSelection == 'Story' && PageType == 'User') {
        this.userPanelDataMapping.selectedTab = TabSelection
     }
      
  

     if (TabSelection === 'Brands' && PageType == 'Avatar') {
      this.brandsFilesList.subscribe((brands) => {
        this.brandCollection = brands;
        if(selectedBrand){
          let brand =  this.brandCollection.find((brand)=>brand.id == selectedBrand )
          this.avatarPanelDataMapping.selectedTab = TabSelection
          this.avatarPanelDataMapping.userPanelTabDataObjectMap['Brands'] = {};
          this.handleSelectedBrand(brand)

          console.log("brand >>>>",brand)
        }else{
          this.avatarPanelDataMapping.selectedTab = TabSelection
        }
  
      });
    } else if (TabSelection === 'Style'&& PageType == 'Avatar') {
      this.garmentsList.subscribe((garments) => {
        this.garmentsCollection = garments;
        if(selectedGarment){
          let garment =  this.garmentsCollection.find((g)=>g.id == selectedGarment )
          this.avatarPanelDataMapping.selectedTab = TabSelection
          this.avatarPanelDataMapping.userPanelTabDataObjectMap['Style'] = {};
          this.handleSelectedGarment(garment)
          console.log("Style >>>>",garment)
        }else {
          this.avatarPanelDataMapping.selectedTab = TabSelection;
        }
      });
    } else if (TabSelection === 'Story' && PageType == 'Avatar') {
      this.avatarPanelDataMapping.selectedTab = TabSelection;
    } 


      this.userdetails.subscribe(userdetails => {
        if (userdetails) {
          this.userProfile = userdetails;
          this.setUserProfileData();
        }
      });

     

      this.getDocumentDetails()
      this.setPanelVisibility(PageType);

      this.avatarPanelDataMapping.selectedTab = TabSelection != undefined ? TabSelection :  'Story'

      console.log('Matrix Params:', this.queryParams);
    });
  }

  setPanelVisibility(PageType: string | null) {
    switch (PageType) {
      case 'User':
        this.showUserPanel = true;
        this.showAvatarPanel = false;
        break;
      case 'Avatar':
        this.showUserPanel = false;
        this.showAvatarPanel = true;
        break;
      default:
        this.showUserPanel = false;
        this.showAvatarPanel = false;
        break;
    }
  }

  setUserProfileData() {
    if (this.userProfile && this.queryParams?.id) {
      this.userProfileData = this.userProfile.find(user => user.UserID === this.queryParams.id);
      console.log('userProfileData', this.userProfileData);
    } else {
      this.userProfileData = null;
    }
  }

  toggleAvatarPanel(type: 'user' | 'avatar' | 'search') {
    this.showUserPanel = type === 'user';
    this.showAvatarPanel = type === 'avatar';
    this.showSearchPanel = type === 'search'
    this.showpanel = true
    this.closeDiv = true;
    if( this.showSearchPanel && !this.showStoryCard ){
      this.handleSearchPanelWithTwoPanel()
    }
  }

  handleSearchPanelWithTwoPanel(){

    // this.storyCardflag = true
    // if(!this.showStoryCard){
    //   this.showStoryCard = false
    //   this.showSearchPanel = true
    //   setTimeout(() => {
    //     this.storyCardflag = false
    //     this.showStoryCard = true
    //   }, 0);
    // }
  }
  profilerCheck() {
    this.isStatsVisible = !this.isStatsVisible
  }

  closePanel(event) {
    event.stopPropagation(); // prevent bubbling
    // this.showUserPanel = false;
    // this.showAvatarPanel = false;
    this.closeDiv = false;

  }

  closePanelByCard() {
    this.showpanel = false
  }

  closeCard() {
    event.stopPropagation(); // prevent bubbling
    this.showStoryCard = false;
    if (this.panelName === 'user' || this.panelName === 'avatar') {
      this.toggleAvatarPanel(this.panelName);
    }
  }

  OpenCard(){
    event.stopPropagation(); // prevent bubbling
    this.showStoryCard = true;
  }

  
  handleUserPanelTab(tab) {
    console.log('Tab changed:', tab);
    this.currentUserPanelTab = tab
    this.userPanelDataMapping.selectedTab = tab;
    // this.activeTab = tab; 
  }

  handleAvatarPanelTab(tab) {
    console.log('Tab changed:', tab);
    this.currentUserPanelTab = tab
    this.avatarPanelDataMapping.selectedTab = tab;
    // this.activeTab = tab; 
  }

  handleUserPanelTabDataObjectList(emitObject) {

    console.log('<<< userPanelTabDataObjectMap Change', emitObject);
    this.avatarV2Flage = false

    // Ensure the tab exists in the map, initialize if undefined
    if (!this.userPanelDataMapping.userPanelTabDataObjectMap[emitObject.tab]) {
      this.userPanelDataMapping.userPanelTabDataObjectMap[emitObject.tab] = {
        selectedTagDetails: null,
        data: []
      };
    }

    // Update the properties
    if (emitObject.selectedTagDetails != undefined)
      this.userPanelDataMapping.userPanelTabDataObjectMap[emitObject.tab].selectedTagDetails = emitObject.selectedTagDetails;
    if (emitObject.dataList != undefined)
      this.userPanelDataMapping.userPanelTabDataObjectMap[emitObject.tab].data = emitObject.dataList;
    this.userPanelDataMapping.selectedTab = emitObject.tab;
    console.log('>>> this.userPanelTabDataObjectMap', this.userPanelDataMapping);
    console.log('userPanelTabDataObjectMap Tab', this.userPanelDataMapping.selectedTab);

    setTimeout(async () => {
      if (!this.mainMenuFlag && emitObject.dataList) {
        this.filterSnap = emitObject.dataList
        this.showStoryCard = false
        this.StoryIds = 0
        if(this.mainMenuFlag){ event.stopPropagation();}
           // prevent bubbling
        this.avatarV2Flage = true
        this.mainMenuFlag = true
        setTimeout(() => {
          this.showStoryCard = true
          this.StoryIds = this.queryParams.storyId
        }, 1000);
        console.log("this.queryParams.storyId", this.queryParams.storyId);        console.log("this.queryParams.storyId", this.queryParams.storyId);
      } else if(emitObject.dataList != undefined) {
        this.filterSnap = emitObject.dataList
      }

    }, 0);
    console.log("filterSnap Snap:", this.findSnap);
    console.log("storyIndex Snap:",this.storyIndex);
    

  }

  handlecrossUserPanelAvatar(data) {
    console.log("user Avatar", data)
    this.child.handle_userAvatarTag_cross(data)
    this.showAvatar_Useravatar_Slider = true;
    this.selectedAvatar_UserPanel = null
  }

  handlecrossUserPanelBrand(data) {
    console.log("user Brand", data)
    this.child.handle_userBrandTag_cross(data)
    this.showBrand_Useravatar_Slider = true;
    this.selectedBrand_UserPanel = null
  }

  
  handlecrossUserPanelCloth(data) {
    this.child.handle_userStyleTag_cross(data)
    this.showCloth_Useravatar_Slider = true;
    this.selectedCloth_UserPanel = null
    console.log("user cloh", data)
  }

  closeAvatarSlider(event: boolean) {
    console.log("Avatar slider closed:", event);
    this.userPanelDataMapping.userPanelTabDataObjectMap['Story']['data'] = undefined;
    this.userPanelDataMapping.userPanelTabDataObjectMap['Story']['selectedTagDetails'] = { key: 'All Avatar', object: undefined };
    this.showAvatar_Useravatar_Slider = false;
  }

  closeBrandSlider(event: boolean) {
    console.log("Brand slider closed:", event);
    this.userPanelDataMapping.userPanelTabDataObjectMap['Brands']['data'] = undefined;
    this.userPanelDataMapping.userPanelTabDataObjectMap['Brands']['selectedTagDetails'] = { key: 'All Brands', object: undefined };
    this.showBrand_Useravatar_Slider = false;
    console.log("Brand slider closed userPanelDataMapping:", this.userPanelDataMapping);
  }

  closeClothSlider(event: boolean) {
    console.log("Cloth slider closed:", event);
    this.userPanelDataMapping.userPanelTabDataObjectMap['Style']['data'] = undefined;
    this.userPanelDataMapping.userPanelTabDataObjectMap['Style']['selectedTagDetails'] = { key: 'All Garment', object: undefined };
    this.showCloth_Useravatar_Slider = false;
  }

  handleUserData(data) {
    this.child.handle_userData(data)
    console.log("handle_userData", data)
  }

  changeUserAvatar(data) {
    this.selectedAvatar_UserPanel = data
    console.log("Cloth slider closed  this.userPanelDataMapping:", this.userPanelDataMapping);
    this.showAvatar_Useravatar_Slider = false;
    this.userPanelDataMapping.userPanelTabDataObjectMap['Story']['data'] = undefined;
    this.userPanelDataMapping.userPanelTabDataObjectMap['Story']['selectedTagDetails'] =
      { key: data.id, object: data };
  }

  changeUserBrand(data) {
    if(data){
      console.log("changeUserBrand init Map", this.userPanelDataMapping)
      this.selectedBrand_UserPanel = data
      this.showBrand_Useravatar_Slider = false;
      this.userPanelDataMapping.userPanelTabDataObjectMap['Brands']['data'] = undefined;
      this.userPanelDataMapping.userPanelTabDataObjectMap['Brands']['selectedTagDetails'] =
        { key: data.id, object: data };
      console.log("Data Map", this.userPanelDataMapping)
    }
  }

  changeUserCloth(data) {
    if(data){
    this.selectedCloth_UserPanel = data
    this.showCloth_Useravatar_Slider = false;
    this.userPanelDataMapping.userPanelTabDataObjectMap['Style']['data'] = undefined;
    this.userPanelDataMapping.userPanelTabDataObjectMap['Style']['selectedTagDetails'] =
      { key: data.id, object: data };}
  }

  handleUserTab(data) {
    console.log("handle_UserTab", data)
    this.child.handle_UserTab(data)
  }



  handleStoryIndex(indx) {
    this.storyIndex = indx
    console.log("handle story index:", indx);
    console.log("handle story index:", this.filterSnap);
    if (this.filterSnap) {
        this.findSnap = this.filterSnap[indx];
        console.log("Selected Snap:", this.findSnap);
    } else {
        console.error("Invalid index or empty array");
    }
}


  handleStoryOnPaneluser(data) {




    console.log("handle story id:", data)
    this.StoryIds = 0
    if(this.mainMenuFlag){ event.stopPropagation();}
       // prevent bubbling
    this.avatarV2Flage = true
    this.mainMenuFlag = true
    setTimeout(() => {
      this.showStoryCard = true
      this.StoryIds = data
    }, 10);

    if (this.showUserPanel) {
      this.panelName ='user'
      this.functionflagValue = null
      setTimeout(() => {
        this.functionflagValue = 'hideavatar'
      }, 10)
    }
    if (this.showAvatarPanel) {
      this.panelName ='avatar'

      this.functionflagValue = null
      setTimeout(() => {
        this.functionflagValue = 'showAvatar'
      }, 10)
    }
  }


  handleUserStoryCardPanel(data) {
    if (this.queryParams?.id == data.UserID) { this.toggleAvatarPanel('user') }
    else {
      this.passUserPanenlValue = data.snapshot
      this.toggleAvatarPanel('user')
    }
  }

  handleAvatarStoryCardPanel(data) {
    console.log("Avatar data", data);
  
    const avatarId = data?.snapshot?.[0]?.AvatarID;
  
    if (!avatarId) {
      console.warn("Invalid avatar snapshot:", data);
      return;
    }
  
    if (this.queryParams?.avatarId === avatarId) {
      this.toggleAvatarPanel('avatar');
    } else {
      this.passAvatarPanenlValue = data.snapshot;
      this.toggleAvatarPanel('avatar');
    }
  }
  

  //#region THREE.js Background Setup
  handlenew_even(data) {
    this.defaultEnvironment = data
    this.functionflagValue = null;
    setTimeout(() => {
      this.functionflagValue = 'skybox';
    }, 10);
  }

  async getDocumentDetails() {
    // console.log("id data",this.userId,this.avatarID)
    // Use provided data or fallback to class properties
    if(this.saveAvatarID_temp == this.queryParams?.avatarId){
      return
    }
    const userId =  this.queryParams.id || this.AvatarSwichDetails?.UserID || this.userId;
    const avatarID = this.queryParams.avatarId || this.AvatarSwichDetails?.AtarID || this.avatarID;
    // if (avatarID == this.saveAvatarID_temp) {
    //   return
    // // }
    this.saveAvatarID_temp = avatarID
    await this.commonService
      .getDocumentsFromSubcollection(userId, avatarID)
      .then((documents) => {
        if (documents && documents.length > 0) {
          const firstDocument = documents[0]; // Assuming there is only one document
          this.userAvatarData = firstDocument;
          this.avatarname = this.userAvatarData?.AvatarName;
          this.openNews3DPassValue();
          this.showAvatarPotraite = this.userAvatarData?.AvatarPreviewPortraitUrl
        } else {
          console.warn("No documents found for given UserID and AvatarID.");
        }
      })
      .catch((error) => {
        console.error("Error in component:", error);
        throw error; // Optionally re-throw the error
      });
  }
  
  async openNews3DPassValue() {
    if (this.userAvatarData) {
      if (this.saveAvatarData?.dataBlobUrl) {
        this.saveAvatarData.dataBlobUrl = null
        this.saveAvatarData.envUrl = null
      }
      this.avtarUrl = this.userAvatarData.AvatarURL;
      this.garmenturl = await this.copyTextToClipGarment(this.userAvatarData.cUpperwearID);
      this.fullBodyurl = await this.copyTextToClipGarment(this.userAvatarData.cFullBodyID);

      // Assign the first non-undefined value
      // this.UpperWearfinalUrl = (this.garmenturl !== null || this.garmenturl !== null || this.garmenturl !== ''  ) ? this.garmenturl : this.fullBodyurl;
      this.UpperWearfinalUrl = this.garmenturl == null ? this.fullBodyurl : this.garmenturl
      this.garmentbottom = await this.copyTextToClipGarment(this.userAvatarData.cBottomwearID);;
      this.genders = await this.userAvatarData.Gender == 'MALE' ? 'M' : 'F';
      this.headgear = await this.copyTextToClipGarment(this.userAvatarData.cHeadgearID);
      this.footwear = await this.copyTextToClipGarment(this.userAvatarData.cFootwearID);
      this.flagForShowThreeJs = true;
      console.log('called....')
      this.functionflagValue = 'LoadOnlyAvatar_' + this.avtarUrl;
      // this.downloadprocessPackZip(this.avtarUrl)
      await this.zipExtractAndSaveFunction(this.avtarUrl)
      if(this.showAvatarPanel && this.closeDiv){
        this.usrPageAvatarFlag = this.avatarID
      }
    }
    // this.envUrl = envUrl;
  }
  processAvatarUrls(): { fileurl: string }[] {
    const urls = [];
    if (this.userAvatarData?.AvatarPreviewFullBodyUrl) {
      urls.push({ fileurlMini: this.userAvatarData.AvatarPreviewFullBodyUrl, fileurl: this.userAvatarData.AvatarPreviewFullBodyUrl, });
    }
    if (this.userAvatarData?.AvatarPreviewPortraitUrl) {
      urls.push({ fileurlMini: this.userAvatarData.AvatarPreviewPortraitUrl, fileurl: this.userAvatarData.AvatarPreviewPortraitUrl, });
    }
    return urls;
  }

  zipExtractAndSaveFunction(url) {
    this.newArray = this.processAvatarUrls()
    if (this.userAvatarData.AvatarPreviewFullBodyUrl != undefined && this.userAvatarData.AvatarPreviewPortraitUrl != undefined) {
      return
    }
    let reqInputObject = {
      url: url,
      userId: this.userId,
      avatarId: this.avatarID
    }
    this.commonService.avatarPageExtractZip(reqInputObject).subscribe(async (response: any) => {
      // this.filterSnap = response.object;
    })
  }
  // this.functionflagValue ='LoadAvatar'

  copyTextToClipGarment(text: string) {
    let Garments = this.garmentsCollection.find(
      (garmentList) => garmentList.id === text
    );
    if (Garments) {
      let brandId = Garments.BrandID;
      let gender = Garments.Gender;
      this.GarmentsGender = Garments.Gender;
      let garmentType = Garments.GarmentType;
      let zipfilename = Garments.id;
      // alert(zipfilename)
      let packlocation =
        'AvatarBlenderClothes%2F' +
        brandId +
        '_' +
        gender +
        '%2F' +
        garmentType +
        '%2F' +
        zipfilename;
      this.garmentLink =
        'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
        packlocation +
        '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';

      return this.garmentLink;
    } else {
      // Handle the case when Garments is not found
      // console.error('Garments not found for id: ' + text);
      return null; // or return an appropriate value or throw an error
    }
  }

   //#endregion


   
  //#region Avatar Panel Setup

  handleAvatarPanelTabDataObjectList(emitObject: any): void {
    console.log('<<< userPanelTabDataObjectMap Change', emitObject);
    this.avatarV2Flage = false;
  
    // Ensure the tab exists in the map; initialize if undefined
    if (!this.avatarPanelDataMapping.userPanelTabDataObjectMap[emitObject.tab]) {
      this.avatarPanelDataMapping.userPanelTabDataObjectMap[emitObject.tab] = {
        selectedTagDetails: null,
        data: []
      };
    }
  
    // Update selectedTagDetails if provided
    if (emitObject.selectedTagDetails !== undefined) {
      this.avatarPanelDataMapping.userPanelTabDataObjectMap[emitObject.tab].selectedTagDetails =
        emitObject.selectedTagDetails;
    }
  
    // Update dataList if provided
    if (emitObject.dataList !== undefined) {
      this.avatarPanelDataMapping.userPanelTabDataObjectMap[emitObject.tab].data =
        emitObject.dataList;
    }
  
    // Set the selected tab
    this.avatarPanelDataMapping.selectedTab = emitObject.tab;
  
    // Debug logs
    console.log('>>> Updated userPanelTabDataObjectMap:', this.avatarPanelDataMapping);
    console.log('Selected Tab:', this.avatarPanelDataMapping.selectedTab);
    setTimeout(async () => {
      if (!this.mainMenuFlag && emitObject.dataList) {
        this.filterSnap = emitObject.dataList
        // this.handleStoryOnPaneluser(this.queryParams.storyId)
        console.log("this.filterSnap-----------------> 1",this.filterSnap);

        this.showStoryCard = false
        this.StoryIds = 0
        if(this.mainMenuFlag){ event.stopPropagation();}
           // prevent bubbling
        this.avatarV2Flage = true
        this.mainMenuFlag = true
        setTimeout(() => {
          this.showStoryCard = true
          this.StoryIds = this.queryParams.storyId
        }, 1000);
        console.log("this.queryParams.storyId", this.queryParams.storyId);
      } else if(emitObject.dataList != undefined) {
        this.filterSnap = emitObject.dataList

        console.log("this.filterSnap-----------------> 2",this.filterSnap);
      }
    }, 0);

    console.log("filterSnap Snap:", this.findSnap);
    console.log("storyIndex Snap:",this.storyIndex);
  }

  handleCrossAvatarBrandTag(data){
    this.avatarPanelDataMapping.userPanelTabDataObjectMap['Brands']['data'] = undefined;
    this.avatarPanelDataMapping.userPanelTabDataObjectMap['Brands']['selectedTagDetails'] = { key: 'All Brands', object: undefined };
    this.child.handleCross_BrandTag(true)
    this.showBrand_avatar_Slider = true;
    event.stopPropagation(); 
  }

  handleCrossAvatarGarmentTag(data){
    this.avatarPanelDataMapping.userPanelTabDataObjectMap['Style']['data'] = undefined;
    this.avatarPanelDataMapping.userPanelTabDataObjectMap['Style']['selectedTagDetails'] = { key: 'All Garment', object: undefined };
    this.child.handleCross_GarmentTag(true)
    this.showCloth_avatar_Slider = true
    event.stopPropagation(); 
  }

  closeAvatarBrandSlider(data){
    this.showBrand_avatar_Slider = false
    event.stopPropagation(); 
  }

  closeAvatarClothSlider(data){
    this.showCloth_avatar_Slider = false
    event.stopPropagation(); 
   }
  
  
  handleAvatarTab(data) {
    this.child.handleAvatarTab(data)
    this.avatarPanelDataMapping.selectedTab = data
  }

  handleNewAvatrUserData(data) {
    this.child.handleNewAvatrUserData(data)
  }

  handleSelectedBrand(data) {
    console.log("changeUserBrand init Map", this.userPanelDataMapping)
    // this.selectedBrand_UserPanel = data
    this.showBrand_avatar_Slider = false;
    this.avatarPanelDataMapping.userPanelTabDataObjectMap['Brands']['data'] = undefined;
    this.avatarPanelDataMapping.userPanelTabDataObjectMap['Brands']['selectedTagDetails'] =
      { key: data.id, object: data };
    console.log("Data Map", this.userPanelDataMapping)
    // this.avatarPanelDataMapping.selectedTab = 'Brands'
    }

  handleSelectedGarment(data) {
    this.showCloth_avatar_Slider = false
    console.log("handleSelectedGarment data", data)
    this.avatarPanelDataMapping.userPanelTabDataObjectMap['Style']['data'] = undefined;
    this.avatarPanelDataMapping.userPanelTabDataObjectMap['Style']['selectedTagDetails'] =
      { key: data.id, object: data };
    console.log("Data Map", this.userPanelDataMapping)
    // this.avatarPanelDataMapping.selectedTab = 'Style'
  }

   //#endregion

   userAavatarChange(data) {
    console.log("slider avatar :", data);
    this.tempChangeAvatarFlag = data;
  }
}

export interface UserPanelDataMapping {
  userPanelTabDataObjectMap: { [key: string]: any }; // Replace `any` with the actual type if known
  selectedTab: string;
}


