<div *ngIf="!showpanelView" class="text-center">
    <div class="spinner-border m-5" role="status">
        <span class="sr-only"></span>
    </div>
</div>

<div class="modal-content imp-block" data-bs-backdrop="false" *ngIf="showpanelView">
    <button type="button" *ngIf="closebuttonFlag" class="btn-close main-close-button" aria-label="Close"
    (click)="toggleChild()" style="color: white !important;"></button>
    <div class="col-12 brand-tag">
        <h6 style="margin: 5px;" class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <img (click)="getUserSnapShotDashboard('story','','')" style="width: 25px; height: 25px;"
                    src="./assets/search/user.png" alt="Image">
                <span (click)="getUserSnapShotDashboard('story','','')"
                    class="m-1 text-black">{{ selectedUserName?.slice(0, 10) }}
                </span>
            </div>
            <img *ngIf="showDetailes" (click)="colapsAvatarPanel($event)" style="width: 25px; height: 25px;"
                src="./assets/up-down-button/up.png" alt="Image">
            <img *ngIf="!showDetailes" (click)="colapsAvatarPanel($event)" style="width: 25px; height: 25px;"
                src="./assets/up-down-button/down.png" alt="Image">
        </h6>
        <div class="text-center position-relative" *ngIf="showDetailes"  (click)="changeUser()">
            <img class="m-1 rounded profileImg-brand" style="width:140px; color:black;"
                [src]="userDetailsObject?.ProfilePicture" (error)="onImageError($event)" #profileImg />
                <button type="button" style="font-size: 10px;"
                                            class="btn btn-outline-info follow-btn">Follow</button>
        </div>

        <div class="static-band-five-icon col-12 text-center d-flex justify-content-center mt-3">
            <div class="brandbtn">
                <h6 class="m-1" (click)="selectSummeryView('Story')"
                    [ngClass]="secteTab == 'Story' ? 'story-menu-card' : 'story-menu-card-not-selected'">
                    <a>
                        <img (click)="getUserSnapShotDashboard('story','','')" *ngIf="secteTab  == 'Story'"
                            class="story-icon" src="./assets/search/avatarwhite.png">
                        <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                            src="./assets/search/avatar.png">
                    </a>
                </h6>
                <h6 *ngIf="secteTab  == 'Story'" class="ms-2 font-class">Avatar</h6>
            </div>

            <div class="brandbtn">
                <h6 class="m-1 p-0"
                    [ngClass]="secteTab == 'Brands' ? 'story-menu-card' : 'story-menu-card-not-selected'">
                    <a (click)="selectTab('Brands')">
                        <img *ngIf="secteTab  == 'Brands'" style="width:15px !important" src="./assets/brand_white.png">
                        <img *ngIf="secteTab  != 'Brands'" style="width:15px !important" src="./assets/brand_black.png">
                    </a>
                </h6>
                <h6 *ngIf="secteTab  == 'Brands'" class="m-1 font-class">Brands</h6>
            </div>

            <div class="brandbtn">
                <h6 class="m-1 p-0" (click)="selectTab('Style')"
                    [ngClass]="secteTab == 'Style' ? 'story-menu-card' : 'story-menu-card-not-selected'">
                    <a>
                        <img *ngIf="secteTab  == 'Style'" style="width:15px !important"
                            src="./assets/t_shirt_white.png">
                        <img *ngIf="secteTab  != 'Style'" style="width:15px !important"
                            src="./assets/t_shirt_black.png">
                    </a>
                </h6>
                <h6 *ngIf="secteTab  == 'Style'" class="m-1 font-class">Style</h6>
            </div>
        </div>
    </div>
    <!-- <div class="container">
        <button type="button" *ngIf="closebuttonFlag" class="btn-close main-close-button" aria-label="Close"
            (click)="toggleChild()" style="color: white !important;"></button>
        <div class="row text-center">
            <div class="container setImage">
                <div class="text-center">
                    <div class="row text-start p-2">
                        <div *ngIf="userDetailsloading" class="text-center">
                            <div class="spinner-border m-5" role="status">
                                <span class="sr-only"></span>
                            </div>
                        </div>
                        <div class=" col-lg-12 band">
                            <div class="static-band" style="margin-left: -18px  !important;">
                                <div (click)="getUserSnapShotDashboard('story','','')"
                                    class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal">
                                    <img *ngIf="userDetailsObject.ProfilePicture != undefined"
                                        class="img-thumbnail rounded-circle" style="
                                          width: 40px !important;
                                          height: 40px !important;
                                          margin-top: -36px;
                                      " [src]="userDetailsObject.ProfilePicture">
                                    <img *ngIf="userDetailsObject.ProfilePicture == undefined"
                                        class="img-thumbnail rounded-circle" style="
                                          width: 40px !important;
                                          height: 40px !important;
                                          margin-top: -36px;
                                      " [src]="userDetailsObject.ProfilePicture">
                                </div>
                                <div class="w-100 col-12 d-flex ">
                                    <div class="col-9 text-start">
                                        <h5 *ngIf="selectedUserName" class="m-1 text-start text-black profile-name"
                                            (click)="getUserSnapShotDashboard('story','','')">{{selectedUserName}}</h5>

                                    </div>
                                    <div class="col-3 "><button type="button" style="font-size: 10px;"
                                            class="btn btn-outline-info follow-btn">Follow</button>
                                    </div>
                                </div>
                                <div class="text-start " style="height: 25px; width: 100%;">
                                    <h6 *ngIf="this.AvatarID != undefined && this.UserID != undefined"
                                        class="avatr  text-start text-black"
                                        (click)="getUserSnapShotByAvatarId('story',this.AvatarID)">🧍🏽{{AvatarID}}
                                    </h6>
                                </div>
                                <div>
                                    <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                                        <div class="brandbtn">
                                            <h6 class="m-1" (click)="selectSummeryView('Story')"
                                                [ngClass]="secteTab == 'Story'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                                <a *ngIf="this.AvatarID == undefined">
                                                    <img (click)="getUserSnapShotDashboard('story','','')"
                                                        *ngIf="secteTab  == 'Story'" class="story-icon"
                                                        src="  ./assets/storystyle/story-white.png">
                                                    <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                                        src=" ./assets/storystyle/storyblack.png">
                                                </a>
                                                <a *ngIf="this.AvatarID != undefined">
                                                    <img (click)="getUserSnapShotByAvatarId('story',this.AvatarID)"
                                                        *ngIf="secteTab  == 'Story'" style="width:15px !important"
                                                        src="  ./assets/storystyle/story-white.png">
                                                    <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                                        src=" ./assets/storystyle/storyblack.png">
                                                </a>

                                            </h6>
                                            <h6 *ngIf="secteTab  == 'Story'" class="ms-2 font-class">Story</h6>
                                        </div>
                                        <div class="brandbtn" (click)="selectTab('Brands')">
                                            <h6 class="m-1 p-0"
                                                [ngClass]="secteTab == 'Brands'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                                <a>
                                                    <img *ngIf="secteTab  == 'Brands'" style="width:15px !important"
                                                        src="./assets/brand_white.png">
                                                    <img *ngIf="secteTab  != 'Brands'" style="width:15px !important"
                                                        src="./assets/brand_black.png">

                                                </a>
                                            </h6>
                                            <h6 *ngIf="secteTab  == 'Brands'" class="m-1 font-class">Brands </h6>
                                        </div>
                                        <div class="brandbtn" (click)="selectTab('Style')">
                                            <h6 class="m-1 p-0 "
                                                [ngClass]="secteTab == 'Style'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                                <a>
                                                    <img *ngIf="secteTab  == 'Style'" style="width:15px !important"
                                                        src="./assets/t_shirt_white.png">
                                                    <img *ngIf="secteTab  != 'Style'" style="width:15px !important"
                                                        src="./assets/t_shirt_black.png">
                                                </a>
                                            </h6>
                                            <h6 *ngIf="secteTab  == 'Style'" class="m-1 font-class">Style</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="secteTab  == 'Story'" style="height: 147px !important; width: 100% !important;">
                                <div class="slider-scroll band">
                                    <div *ngIf="this.AvatarID == undefined" class="d-flex " data-bs-dismiss="modal">
                                        <div *ngIf="userFiledetails.length == 0 " class="twoDimages">
                                            <app-image-slide [images]="images"></app-image-slide>
                                        </div>
                                        <div *ngIf="userFiledetails.length != 0" class="twoDimages">
                                          
                                            <app-image-slide [images]="userFiledetails" [UserId]="this.UserID"
                                            [delete]="this.delete" [UserProfile]="userProfile" [dismmis]="dismiss"></app-image-slide>
                                        </div>
                                    </div>
                                    <div class="d-flex " *ngIf="this.AvatarID != undefined" data-bs-dismiss="modal">
                                        <div *ngIf="userFiledetails.length == 0 " class=" col-12 band">
                                            <app-image-slide [images]="images"></app-image-slide>
                                        </div>
                                        <div *ngIf="userFiledetails.length != 0" class=" col-12  band">

                                            <app-image-slide [images]="userFiledetails" [UserId]="this.UserID"
                                                [UserProfile]="userProfile" [delete]="this.delete" [dismmis]="dismiss"></app-image-slide>
                                        </div>
                                    </div>

                                    <img *ngIf="myProfile"  style="width: 20px; height: 20px; margin-left: 162px; margin-top: -100px;"
                                        class="w-80 shadow-1-strong rounded" src="./assets/plus-8-512.png"
                                        (click)="userFileUpload()">
                                     
                                        <div *ngIf="myProfile"  style="width: 20px;
                                        height: 20px;
                                        position: absolute;
                                        top: -40px;
                                        left: 75px;"
                                        class="w-80 shadow-1-strong rounded" src="./assets/plus-8-512.png"
                                        (click)="setuserDetails(userDetailsObject)">

                                        <i *ngIf="myProfile" style="width:15px !important;" (click)="setuserDetails(userDetailsObject)"
                                        class="bi bi-arrow-clockwise  text-white">
                                    </i> 
                                        </div>
                                    <input style="display: none;" type="file" id="file" [value]="uploadFileName"
                                        (change)="start2DFileUpload($event)" accept="image/png, image/gif, image/jpeg">

                                </div>
                            </div>

                           

                        </div>


                        <div class="col-12 div-center" [ngStyle]="{'margin-top': secteTab === 'Story' ? '-45px' : '0'}">
                            <div *ngIf="secteTab  == 'Story'" class="d-block justify-content-center m-1">
                                <div class="dropdown custom-dropdown">
                                    <button (click)="selectavatarOprtion()" class="btn btn-secondary dropdown-toggle"
                                        type="button" id="dropdownMenuButton"
                                        style="background-color: white; color: black;  margin-left: -24px !important;"
                                        data-bs-toggle="dropdown" aria-expanded="false">

                                        <span *ngIf="!avatarObject">Select Avatar</span>
                                        <span>
                                            <img *ngIf="avatarObject?.AvatarPreviewPortraitUrl"
                                                style="width: 30px !important; height: 30px !important;"
                                                class="avatar-image" src="{{ avatarObject.AvatarPreviewPortraitUrl }}"
                                                alt="Avatar Image">
                                            {{ avatarObject?.AvatarName != undefined || avatarObject?.AvatarName != null ? avatarObject?.AvatarName
                                            : avatarObject?.AvatarID}}
                                        </span>

                                    </button>
                                    <div class="row image-margin scroll-drop-item" aria-labelledby="dropdownMenuButton"
                                        [ngClass]="{'d-none': !dropdownOpen, 'c': dropdownOpen}"
                                        [style.height.px]="getHeight(garmentCacheList?.length)">
                                        <div *ngIf="avatarObject" class="d-flex justify-content-center all-select">
                                            <div class="text-center" (click)="selectAllAvatars()">
                                                View All
                                            </div>
                                        </div>
                                        <div class="col-6 p-0" *ngFor="let avatar of newAvatarList"
                                            [ngClass]="{'d-none': avatar.AvatarName == undefined || avatar.AvatarPreviewPortraitUrl == undefined} "
                                            (click)="selectAvatarId(avatar)">
                                            <div class="d-flex position-relative"
                                                *ngIf="avatar.AvatarName != undefined && avatar.AvatarPreviewPortraitUrl != undefined ">
                                                <img class="shadow-1-strong rounded m-1 story-image avatar-image"
                                                    src="{{  avatar.AvatarPreviewPortraitUrl != undefined || avatar.AvatarPreviewPortraitUrl != null ? avatar.AvatarPreviewPortraitUrl : avatar.AvatarPreviewPortraitUrl}}"
                                                    alt="Avatar Image" />
                                                <div class="image-name-overlay">
                                                    {{ avatar.AvatarName != undefined || avatar.AvatarName != null ? avatar.AvatarName : avatar.AvatarID }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-start"
                                    *ngIf="!dropdownOpen && avatarObject?.AvatarID == AvatarID  && avatarObject && selectedavatarId != ''">
                                    <div>
                                        <button class="btn btn-secondary" style="margin: 4px 40px 0px 40px;"
                                            type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                            aria-expanded="false" (click)="navigateToAvatarPage()">
                                            Go
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="secteTab  == 'Brands'" class="d-block justify-content-center m-1">
                                <div class="dropdown custom-dropdown">
                                    <button (click)="selectavatarOprtion()" class="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        style="background-color: white; color: black; margin-left: -24px !important;"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <span *ngIf="!brandObject">Select Brand</span>
                                        <span>
                                            <img *ngIf="brandObject?.Brandurl"
                                                style="width: 30px !important; height: 30px !important;"
                                                class="avatar-image" src="{{ brandObject.Brandurl }}"
                                                alt="Avatar Image">
                                            {{ brandObject?.BrandName != undefined || brandObject?.BrandName != null ? brandObject?.BrandName
                                            : brandObject?.id}}
                                        </span>
                                    </button>



                                    <div class="row image-margin scroll-drop-item" aria-labelledby="dropdownMenuButton"
                                        [style.height.px]="getHeight(garmentCacheList?.length)"
                                        [ngClass]="{'d-none': !dropdownOpen, 'story-inset': dropdownOpen && secteTab !== 'Story'}">

                                        <div *ngIf="brandObject" class="d-flex justify-content-center all-select">
                                            <div class="text-center" (click)="selectAllAvatars()">
                                                View All
                                            </div>
                                        </div>

                                        <div *ngIf="isBrandLoading" class="text-center">
                                            <div class="spinner-border" role="status">
                                                <span class="sr-only"></span>
                                            </div>
                                        </div>
                                        <div class="col-6 p-0" *ngFor="let brand of brandList"
                                            (click)="selectBrand(brand)">
                                            <div class="d-flex position-relative" *ngIf="!isBrandLoading">
                                                <img class="shadow-1-strong rounded m-1 story-image avatar-image"
                                                    *ngIf="brand.Brandurl" src="{{ brand.Brandurl }}"
                                                    alt="Avatar Image" />
                                                <div class="image-name-overlay">
                                                    {{ brand.BrandName != undefined || brand.BrandName != null ? brand.BrandName : brand.id }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="secteTab  == 'Style'" class="d-block justify-content-center m-1">

                                <div class="dropdown custom-dropdown">
                                    <button (click)="selectavatarOprtion()" class="btn btn-secondary dropdown-toggle"
                                        type="button" id="dropdownMenuButton"
                                        style="background-color: white; color: black; margin-left: -24px !important;"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <span *ngIf="!cacheObject">Select Garment</span>
                                        <span (click)="selectavatarOprtion()">
                                            <img *ngIf="cacheObject?.RealType"
                                                style="width: 30px !important; height: 30px !important;"
                                                class="avatar-image"
                                                src="{{getGarmentTypeLogo(cacheObject.RealType)}}"
                                                alt="Avatar Image">
                                            {{( cacheObject?.Name != undefined || cacheObject?.Name != null ? cacheObject.Name.slice(0, 12)
                                            : cacheObject?.id)}}
                                        </span>
                                    </button>
                                    <div class="row image-margin scroll-drop-item" aria-labelledby="dropdownMenuButton"
                                        [ngClass]="{'d-none': !dropdownOpen, 'story-inset': dropdownOpen && secteTab !== 'Story'}"
                                        [style.height.px]="getHeight(garmentCacheList?.length)">
                                        <div *ngIf="cacheObject" class="d-flex justify-content-center all-select">
                                            <div class="text-center" (click)="selectAllAvatars()">
                                                View All
                                            </div>
                                        </div>
                                        <div class="col-6 p-0" *ngFor="let chache of garmentCacheList"
                                            (click)="selectCacheImage(chache)">
                                            <div class="d-flex position-relative" *ngIf="chache">
                                                <div class="shadow-1-strong rounded m-1 story-image avatar-image">
                                                    <div class="image-set">
                                                        <img class="image-set-cache"
                                                            [src]="chache.cacheUrl != undefined || chache.cacheUrl != null ? chache.cacheUrl : getGarmentTypeLogo(chache.RealType)"
                                                            alt="Avatar Image" />
                                                    </div>
                                                </div>
                                                <div class="image-name-overlay">
                                                    {{ chache.Name != undefined || chache.Name != null ? chache.Name : chache.id }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="showStoryList" class="row  image-margin"
                                [ngStyle]="allUserSnapDetails.length < 12 ? {} : { 'height.px': brandStoryScrollerHeight - 100 }">
                                <h6 *ngIf="this.callpageNumer" (click)="previousUserbutton()">
                                    <b class="text-black" >Previous</b>
                                </h6>
    
                                <div class="col-6 p-0" *ngFor="let snapshot of allUserSnapDetails; let i=index;">
                                    <div class="d-flex ">
                                        <img class="shadow-1-strong rounded m-1 story-image"
                                            (click)="getUserSnapShotDashboard('user-story',snapshot,i)"
                                            [src]="snapshot?.SnapshotFileUrlMini">
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="brandsnaploadingSnap" class="text-center">
                                <div class="spinner-border m-5" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
   




    <div *ngIf="!showAvatar_Useravatar_Slider  && !selectedAvatar_UserPanel &&   secteTab  == 'Story'" class="brand-tag ">
        <h6 style="margin: 5px;" class="d-flex text-center justify-content-center " (click)="handleCrossButton_Avatar()">
            <!-- <img style="width: 25px; height: 25px;" [src]="getBrandImageByLogo(selectedBrand?.LogoURL) " alt="Image"> -->
            <img style="width:25px !important" src="./assets/search/avatar.png">
            <h6 style="margin: 3px;"> All Avatars </h6>
        </h6>
        <button class="brand-remove" (click)="handleCrossButton_Avatar()">
            X
        </button>
    </div>

    <div *ngIf="!showBrand_Useravatar_Slider && !selectedBrand_UserPanel &&  secteTab  == 'Brands'" class="brand-tag ">
        <h6 style="margin: 5px;" class="d-flex text-center justify-content-center " (click)="handleCrossButton_Brand()">
            <!-- <img style="width: 25px; height: 25px;" [src]="getBrandImageByLogo(selectedBrand?.LogoURL) " alt="Image"> -->
            <img style="width:25px !important" src="./assets/brand_black.png">
            <h6 style="margin: 3px;"> All Brands </h6>
        </h6>
        <button class="brand-remove" (click)="handleCrossButton_Brand()">
            X
        </button>
    </div>

    <div *ngIf="!showCloth_Useravatar_Slider && !selectedCloth_UserPanel && this.SetGarmentId == undefined && secteTab == 'Style'" class="brand-tag ">
        <h6 style="margin: 5px;" class="d-flex text-center justify-content-center " (click)="handleCrossButton_Garment()">
            <img style="width:25px !important" src="./assets/t_shirt_black.png">
            <h6 style="margin: 3px;"> All Garment </h6>
        </h6>
        <button class="brand-remove" (click)="handleCrossButton_Garment()">
            X
        </button>
    </div>



    <div *ngIf="!showAvatar_Useravatar_Slider && selectedAvatar_UserPanel && secteTab  == 'Story'" class="brand-tag ">
        <h6 style="margin: 5px;" class="d-flex">
            <img style="width: 25px; height: 25px;" [src]="selectedAvatar_UserPanel?.AvatarPreviewPortraitUrl" alt="Image">
            <h6 style="margin: 3px;">   {{ selectedAvatar_UserPanel?.AvatarName?.length > 10 ? (selectedAvatar_UserPanel.AvatarName | slice: 0:10) + '...' : selectedAvatar_UserPanel?.AvatarName }}</h6>
        </h6>
        <button class="brand-remove" (click)="handleCrossButton_Avatar()" >
            X
        </button>
    </div>

    <div *ngIf="!showBrand_Useravatar_Slider && selectedBrand_UserPanel && secteTab  == 'Brands'" class="brand-tag ">
        <h6 style="margin: 5px;" class="d-flex">
            <img style="width: 25px; height: 25px;" [src]="selectedBrand_UserPanel.Brandurl" alt="Image">
            <h6 style="margin: 3px;">{{ selectedBrand_UserPanel.BrandName }}</h6>
        </h6>
        <button class="brand-remove" (click)="handleCrossButton_Brand()">
            X
        </button>
    </div>

    <div *ngIf="!showCloth_Useravatar_Slider && selectedCloth_UserPanel && secteTab  == 'Style'" class="brand-tag ">
        <h6 style="margin: 5px;" class="d-flex">
            <img style="width: 25px; height: 25px;" [src]="getGarmentTypeLogo(selectedCloth_UserPanel?.RealType)" alt="Image">
            <h6 style="margin: 3px;">{{ selectedCloth_UserPanel?.Name?.slice(0, 10) }}</h6>
        </h6>
        <button class="brand-remove" (click)="handleCrossButton_Garment()">
            X
        </button>
    </div>


    <div *ngIf="!showStoryList" class="text-center">
        <div class="spinner-border m-5" role="status">
            <span class="sr-only"></span>
        </div>
    </div>

    <div *ngIf="showStoryList" class="row  image-margin"
        [ngStyle]="allUserSnapDetails?.length < 12 ? {} : { 'height.px': brandStoryScrollerHeight - 100 }">
       
        <h6 *ngIf="this.callpageNumer > 0   &&  secteTab  == 'Story'" class="text-center" (click)="previousUserbutton()">
            <b class="text-black">Previous</b>
        </h6>
    
        <h6 *ngIf="this.brandPageNumber > 1  &&  secteTab  == 'Brands'" class="text-center" (click)=" prevBrandPage()">
            <b class="text-black">Previous</b>
        </h6>

        <h6 *ngIf="garmentPageNumber > 1 && secteTab === 'Style' && show_StylePrevisousButton"
            class="text-center" (click)="prevGarmentPage()">
            <b class="text-black">Previous</b>
        </h6>

        <div class="col-6 p-0" *ngFor="let snapshot of allUserSnapDetails; let i=index;">
            <div class="d-flex ">
                <img class="shadow-1-strong rounded m-1 story-image"
                    (click)="getUserSnapShotDashboard('user-story',snapshot,i)" [src]="snapshot?.SnapshotFileUrlMini">
            </div>
        </div>
    </div>

</div>

<div>
    <div *ngIf="showpanelView && !change_avatar_flage &&  showStoryList &&  secteTab  == 'Story'"
        class="col-12 text-center text-black justify-content-center p-3">
        <h6 (click)="incrementImgCounter()">
            <b>More</b>
        </h6>
    </div>

    <div *ngIf="showpanelView && change_avatar_flage &&  secteTab  == 'Story'"
        class="col-12 text-center text-black justify-content-center p-3">
        <h6 (click)="nextAvatarPage()">
            <b>More</b>
        </h6>
    </div>
   
    <div *ngIf="(this.brandPageNumber * this.currentBrandIndex) < this.snapshotsBrandFilter.length && showStoryList &&  secteTab  == 'Brands'"
        class="col-12 text-center text-black justify-content-center p-3">
        <h6 (click)="nextBrandPage()">
            <b>More</b>
        </h6>
    </div>

    <div *ngIf="(this.garmentPageNumber * this.currentGarmentIndex) < this.snapshotsGarmentFilter.length && showStoryList &&  secteTab  == 'Style'"
        class="col-12 text-center text-black justify-content-center p-3">
        <h6 (click)="nextGarmentPage()">
            <b>More</b>
        </h6>
    </div>
</div>