import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';
import { Observable, catchError, finalize, map, throwError } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';
import * as JSZip from 'jszip/dist/jszip';


@Component({
  selector: 'app-drips-garment-panel',
  templateUrl: './drips-garment-panel.component.html',
  styleUrls: ['./drips-garment-panel.component.css']
})
export class DripsGarmentPanelComponent implements OnInit, OnChanges {
  @Input() brandObject: any = {};
  @Input() garmentId: any;
  @Input() typeOfGarment: any;
  @Input() typeWear: any;
  @Input() selectedSnapShot: any;
  @Input() brandUrl: any;
  @Input() garmentData: any;
  @Input() source: any;

  

  

  @Input() RealType: any;
  
  private loadTimes: { [key: number]: number } = {};

  



  brandheartFlag: boolean = false;
  tab: any = 'story';

  // garmentsList: Observable<any[]> = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  // brandsFilesList: Observable<Brand[]> = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  // user: Observable<any[]> = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  // userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  // userGarmentCollection = this.firestore
  // .collection('garment-collection')
  // .valueChanges({ idField: 'id' }) as Observable<any[]>;
  // userGarmentCollectionItems = this.firestore
  //   .collection('garment-collection-items')
  //   .valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentsCollection: any[] = [];
  brandsCollection: any[] = [];
  brandLogourl: string;
  selectedBrandId: any;
  swichImageFlage: boolean = true;
  isloading: boolean;
  clothDetails: any;
  // source: any;
  style: string = 'treads';
  garmentScrollFlag: boolean;
  flipFlag: boolean;
  selectedIcon: string = 'popup';
  iconselctFlag: boolean;
  userUploadedFileList: any  = [];
  userFileList: any = [];
  userFiledetails3d: any = [];
  showMore: boolean = false;
  pageNumberGarment: number = 1;



  images = [
    { image: './assets/garment-upload-dummy/img-1.jpg' },
    { image: './assets/garment-upload-dummy/img-2.jpg' },
    { image: './assets/garment-upload-dummy/img-3.jpg' },
    { image: './assets/garment-upload-dummy/img-4.jpg' },
    { image: './assets/garment-upload-dummy/img-5.jpg' },
    { image: './assets/garment-upload-dummy/img-6.jpg' },
    { image: './assets/garment-upload-dummy/img-7.jpg' },
    { image: './assets/garment-upload-dummy/img-8.jpg' },
    { image: './assets/garment-upload-dummy/img-9.jpg' },
  ];
  dummyText: string;
  collectionData: any[];
  collectionDetails: any;
  brandStoryScrollerHeight: number;
  sideNavHeight: number;
  userSnapShotListGarment: any =[];
  loading: boolean;
  garmentLink: string;
  packJsonObject =[];
  zipList: any ={};
  testVar: string;
  brandlogUrl: any;
  result: string;
  selectedgender: string;
  pageNumberBrand: any;
  selectedImageIndex: number;



  constructor(
    private commonService: CommonService,
    private firestore: AngularFirestore,
    private http: HttpClient,
    public router: Router,private storage: AngularFireStorage,private _httpClient: HttpClient,
  ) { }

  ngOnInit() {
    // this.garmentsList.subscribe((garments) => {
    //   this.garmentsCollection = garments;
    //   // this.loadBrandData()

    // });

    // this.brandsFilesList.subscribe((brands) => {
    //   this.brandsCollection = brands;
    //   // this.loadBrandData()
    // });

    // this.userFilesList.subscribe((userFile) => {
    //   this.userUploadedFileList = userFile;
    // });

    // this.userGarmentCollectionItems.subscribe((collectiondata) => {
    //   this.collectionDetails = collectiondata;
    // });
    // // this.setTab(this.tab,'M')
    // this.userGarmentCollection.subscribe((collectiondata) => {
    //   this.collectionData = collectiondata;
    // });

    const text =
    "Your brand's apparel is digitised in 3D.\n\nOn a gamified experience, consumers style avatars & share\n\nanimated avatar stories (linked to ecommerce)\n\nDefining a new paradigm in immersive storytelling for social media & the metaverse";

  const lines = text.split('\n\n');
  this.dummyText =
    lines[0] + '\n' + lines[1] + '\n' + lines[2] + '\n' + lines;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['garmentId']) {
      this.loadGarmentData();
      console.log('selectedSnapShot',this.selectedSnapShot)
      console.log('brandUrl',this.brandUrl)
    }

    if (changes['garmentData']) {
      console.log('garmentData garmentData garmentData',this.garmentData)
    }

  }



  getLoadTimes() {
    return this.loadTimes;
  }
  // async loadGarmentData() {
  //    if(this.garmentData.length != 0){
  //     this.clothDetails =  this.garmentData.garment;
  //     let filePathNewTemp =  this.garmentData.userFiles.find((file) => file.filename == this.clothDetails.id && file.fileType == 'Garment360Cache');
  //     this.source = filePathNewTemp ? filePathNewTemp.fileurl : null;
  //     this.brandObject =  this.garmentData.brand;
  //     this.brandlogUrl =  this.garmentData.brandLogoUrl;
  //     this.userSnapShotListGarment =  this.garmentData.snapshots.object;
  //     console.log("this.source", this.source);
  //     console.log("this.source",  this.garmentData.userFiles.filepath);
  //     this.tab = this.clothDetails.Gender === 'M' ? 'mens-wear' : 'womens-wear';
  //     let filelist2d =  this.garmentData.userFiles.filter(
  //       (uploadlist) =>
  //         uploadlist.link == this.garmentId && uploadlist.fileType == 'GarmentUpload2D'
  //     );
  //     let filelist3d =  this.garmentData.userFiles.filter(
  //       (uploadlist) =>
  //         uploadlist.link == this.garmentId && uploadlist.fileType == 'GarmentUpload3D'
  //     );
  //     this.userFileList = filelist2d;
  //     this.userFiledetails3d = filelist3d;
  

  //   } else {

  //     this.garmentData = await this.garmentPanelDataFunction(this.garmentId);
  //     this.clothDetails =  this.garmentData.garment;

  //      console.log("this.clothDetails", this.clothDetails);

  //     let filePathNewTemp =  this.garmentData.userFiles.find((file) => file.filename == this.clothDetails.id && file.fileType == 'Garment360Cache');
  //     this.source = filePathNewTemp ? filePathNewTemp.fileurl : null;
  //     this.brandObject =  this.garmentData.brand;
  //     this.brandlogUrl =  this.garmentData.brandLogoUrl;
  //    this.userSnapShotListGarment =  this.garmentData.snapshots.object;
  //    console.log("this.source", this.source);
  //    console.log("this.source",  this.garmentData.userFiles.filepath);
  //    this.tab = this.clothDetails.Gender === 'M' ? 'mens-wear' : 'womens-wear';
  //    let filelist2d =  this.garmentData.userFiles.filter(
  //      (uploadlist) =>
  //        uploadlist.link == this.garmentId && uploadlist.fileType == 'GarmentUpload2D'
  //    );
  //    let filelist3d =  this.garmentData.userFiles.filter(
  //      (uploadlist) =>
  //        uploadlist.link == this.garmentId && uploadlist.fileType == 'GarmentUpload3D'
  //    );
  //    this.userFileList = filelist2d;
  //    this.userFiledetails3d = filelist3d;

  //    // If source URL is null or undefined, update it
  //    // this.checkUrl(this.source)
  //   console.log(filePathNewTemp.filepath)
  //    const storageRef = this.storage.ref(filePathNewTemp.filepath);
  //    storageRef.getDownloadURL().subscribe({
  //        next: (downloadUrl) => {
  //         // this.source = downloadUrl
  //            console.log("Download URL:", downloadUrl);
  //        },
  //        error: async (error) => {
  //            if (error.code === 'storage/object-not-found') {
  //              await this.fileUrlUpadate(this.clothDetails);
  //                console.error("File not found:",filePathNewTemp.filepath);
     
  //            } else {
  //                console.error("Error fetching download URL:", error);
  //            }
  //        }
  //    });
  //   }

   




  //     // If source URL exists and data.userFiles is not null, proceed
  //     if (this.garmentData.userFiles) {
  //       let filePathNewTemps =  this.garmentData.userFiles.find((file) => file.filename == this.clothDetails.filename && file.fileType == 'Garment360Cache');
  //       this.loading = false;
  //       const storageRef = await  this.storage.ref(filePathNewTemps.filepath);
  //       // Get the download URL
  //       storageRef.getDownloadURL()
  //         .pipe(
  //           finalize(() => {
  //             // Perform any finalization tasks here
  //           })
  //         )
  //         .toPromise()
  //         .then(async (url) => {
  //           console.log('Download URL:', url);
  //           let filePathNew = this.userUploadedFileList.find((file) => file.filename == this.clothDetails.id && file.fileType == 'Garment360Cache');
  //           // Update Firestore only if filePathNew exists and fileurl needs updating
  //           if (filePathNew && filePathNew.fileurl !== url) {

  //             try {
  //               console.log('File URL updated in Firestore:', filePathNew.id);
  //               await this.firestore.collection('userfiles').doc(filePathNew.id).update({
  //                 fileurl: url
  //               });
  //               console.log('File URL updated in Firestore:', filePathNew.id);
  //               // Update the local source
  //               this.source = url;
  //             } catch (error) {
  //               console.error('Error updating file URL in Firestore:', error);
  //             }
  //           }
  
  //           // Use this URL to download or display the image
  //           this.source = url;
  //         })
  //         .catch((error) => {
  //           // Handle any errors
  //           console.error('Error getting download URL:', error);
  //         });
      
  //   }
  
 
    
  // }




  async loadGarmentData() {
    if (this.garmentData.length !== 0) {
      await this.processGarmentData(this.garmentData);
    } else {
      this.garmentData = await this.garmentPanelDataFunction(this.garmentId);
      await this.processGarmentData(this.garmentData);
    }
    if (this.garmentData) {
      let filePathNewTemps = this.garmentData.userFiles.find(file => file.filename == this.clothDetails.filename && file.fileType == 'Garment360Cache');
      console.log("filePathNewTemps......................",filePathNewTemps)
      if (filePathNewTemps) {
        this.loading = false;
        const storageRef = this.storage.ref(filePathNewTemps.filepath);
        try {
          const url = await storageRef.getDownloadURL().pipe(finalize(() => {})).toPromise();
          console.log('Download URL:', url);
          console.log('Download this.source:', this.source);
          this.source = url;
          if (url.startsWith('https://storage.googleapis.com/')) {
            let filePathNew = this.userUploadedFileList.find(file => file.filename == this.clothDetails.id && file.fileType == 'Garment360Cache');
            if (filePathNew) {
              try {
                // Query the Firestore collection to find the document
                const querySnapshot = await this.firestore.collection('userfiles', ref => 
                  ref.where('filename', '==', this.clothDetails.id)
                     .where('fileType', '==', 'Garment360Cache')
                ).get().toPromise();
                if (!querySnapshot.empty) {
                  querySnapshot.forEach(async (doc) => {
                    await doc.ref.update({ fileurl: url });
                    console.log('File URL updated in Firestore:', doc.id);
                  });
                  this.source = url;
                } else {
                  console.error('No matching documents found in Firestore');
                }
              } catch (error) {
                console.error('Error updating file URL in Firestore:', error);
              }
            } else {
              this.source = url;
            }
          } else {
            console.error('URL does not start with https://storage.googleapis.com/');
          }
        } catch (error) {
          console.error('Error getting download URL:', error);
        }
      } else {
        console.error('File with specified criteria not found in userFiles');
      }
    }
  }
  
  async processGarmentData(garmentData) {
    this.clothDetails = garmentData.garment;
    let filePathNewTemp = garmentData.userFiles.find(file => file.filename == this.clothDetails.id && file.fileType == 'Garment360Cache');
    
    console.log(filePathNewTemp)
    if(filePathNewTemp == undefined){
      await this.fileUrlUpadate(this.clothDetails);
    }

    this.source = filePathNewTemp ? filePathNewTemp.fileurl : null;
    this.brandObject = garmentData.brand;
    this.brandlogUrl = garmentData.brandLogoUrl;
    this.userSnapShotListGarment = garmentData.snapshots.object;
    this.tab = this.clothDetails.Gender === 'M' ? 'mens-wear' : 'womens-wear';
  
    let filelist2d = garmentData.userFiles.filter(uploadlist => uploadlist.link == this.garmentId && uploadlist.fileType == 'GarmentUpload2D');
    let filelist3d = garmentData.userFiles.filter(uploadlist => uploadlist.link == this.garmentId && uploadlist.fileType == 'GarmentUpload3D');
    this.userFileList = filelist2d;
    this.userFiledetails3d = filelist3d;



    const storageRef = this.storage.ref(filePathNewTemp.filepath);
    const url = await storageRef.getDownloadURL().pipe(finalize(() => {})).toPromise();
    this.source = url

   

    if (filePathNewTemp) {
      const storageRef = this.storage.ref(filePathNewTemp.filepath);
      console.log("storageRef",storageRef)
      storageRef.getDownloadURL().subscribe({
        next: (downloadUrl) => {
          console.log("Download URL:", downloadUrl);
        },
        error: async (error) => {
          if (error.code === 'storage/object-not-found') {
            await this.fileUrlUpadate(this.clothDetails);
            console.error("File not found:", filePathNewTemp.filepath);
          } else {
            console.error("Error fetching download URL:", error);
          }
        }
      });
    } else {
      console.error('File with specified criteria not found in userFiles during processGarmentData');
    }
  }
  




















  fetchGarmentData(fileType: string, garmentId: string): void {
    this.commonService.getGarmentPanelData(fileType, garmentId)
      .subscribe(
        data => {
          this.brandObject = data.brand
          this.clothDetails = data.garment
          this.brandlogUrl =  data.brandLogoUrl
          this.userSnapShotListGarment = data.snapshots.object
          console.log('Data fetched:', data);
        },
        error => {
          console.error('Error fetching data:', error);
        }
      );
  }
 

  // getBrandData(garmentId: any) {
  //   console.log('garmentId', garmentId);
  //   console.log('this.brandsCollection', this.brandsCollection);
  //   let garmentObject = this.garmentsCollection.find(
  //     (b: any) => b.id === garmentId
  //   );
  //   if (garmentObject) {
  //     this.brandObject = this.brandsCollection.find(
  //       (b: any) => b.id == garmentObject.BrandID
  //     );
  //     console.log(this.brandObject);
  //     this.selectedBrandId = this.brandObject.id
  //     this.brandLogourl = this.getBrandImage(this.brandObject.LogoURL);
  //   }
  //   this.clothDetails = garmentObject
  //   let filelist2d = this.userUploadedFileList.filter(
  //     (uploadlist) =>
  //       uploadlist.link == garmentId && uploadlist.fileType == 'GarmentUpload2D'
  //   );
  //   let filelist3d = this.userUploadedFileList.filter(
  //     (uploadlist) =>
  //       uploadlist.link == garmentId && uploadlist.fileType == 'GarmentUpload3D'
  //   );
  //   this.userFileList = filelist2d;
  //   this.userFiledetails3d = filelist3d;

  //   let zipNmae = 'pack0';
  //   let packlocation =
  //     'AvatarBlenderClothes%2F' +
  //     this.brandObject.id +
  //     '_' +
  //     garmentObject.Gender +
  //     '%2F' +
  //     zipNmae;
  //   // this.newZipAndSave(packlocation, garmentObject.id)
  // }



  
  filterCollectionName(id) {
    let garmentCollectionId = (
      this.collectionDetails != undefined && this.collectionDetails
    ).find((colection) => colection.id === id);
    let getCollection = this.collectionData.find(
      (colection) =>
        colection.id === garmentCollectionId.GarmentCollectionID &&
        colection.status == 'A'
    );
    let name =
      getCollection != undefined && getCollection.CollectionName != undefined
        ? getCollection.CollectionName
        : '';
    return name;
  }



  // getGarmentStoryImage(fileType, garmentId: any) {
  //   this.userSnapShotListGarment = [];
  //   let reqInputObject = { fileType: fileType, id: garmentId, pageNumber: 1 };
  //   this.commonService
  //     .yologramSnapshotDetailsByType(reqInputObject)
  //     .subscribe(async (response: any) => {
  //       // this.userSnapShotListGarment.push(...response.object);
  //       this.userSnapShotListGarment = response.object;
  //     });
  // }

  async garmentPanelDataFunction(garmentId){
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getGarmentPanelData_v2";
    return this.http.post<any>(functionUrl, { garmentId: garmentId,fileType: this.typeWear }).toPromise();
  }



  // async newZipAndSave(packlocation, id: any) {
  //   this.source = ""
  //   console.log('File path found:', packlocation,);
  //   console.log('File path found:', id);
  //   this.isloading = false
  //   let garmentLink =
  //     'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
  //     packlocation +
  //     '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';

  //   console.log("packetObject", this.packJsonObject)
  //   let filePath = this.userUploadedFileList.find((file) => file.filename == id && file.fileType == 'Garment360Cache');
  //   if (filePath) {
  //     this.loading = false;
  //     const storageRef = this.storage.ref(filePath.filepath);

  //     // Get the download URL
  //     storageRef.getDownloadURL()
  //       .pipe(
  //         finalize(() => {
  //           // Perform any finalization tasks here
  //         })
  //       )
  //       .toPromise()
  //       .then((url) => {
  //         console.log('Download URL:', url);
  //         this.source = url
  //         // Use this URL to download or display the image
  //       })
  //       .catch((error) => {
  //         // Handle any errors
  //         console.error('Error getting download URL:', error);
  //       });
  //     return
  //   }
  //   const url = garmentLink;
  //   const cloth = id;

  //   await this.commonService.downloadAndProcessPack(url, cloth).subscribe({
  //     next: (response) => console.log('Success:', response),
  //     error: (error) => console.error('Error:', error)
  //   });

  //   let filePathNew = this.userUploadedFileList.find((file) => file.filename == id && file.fileType == 'Garment360Cache');
  //   console.log('File path found:', filePath);
  //   if (filePathNew) {
  //     this.loading = false
  //     const storageRef = this.storage.ref(filePathNew.filepath);
  //     // Get the download URL
  //     storageRef.getDownloadURL()
  //       .pipe(
  //         finalize(() => {
  //           // Perform any finalization tasks here
  //         })
  //       )
  //       .toPromise()
  //       .then((url) => {
  //         console.log('Download URL:', url);
  //         this.source = url
  //         // Use this URL to download or display the image
  //       })
  //       .catch((error) => {
  //         // Handle any errors
  //         console.error('Error getting download URL:', error);
  //       });
  //     return;
  //   }

  //   if (!filePathNew) {
  //     this.clothUzip(packlocation)
  //     return;
  //   }
  //   // this.clothUzip(packlocation)
  // }
  // async clothUzip(packlocation) {
  //   this.garmentLink =
  //     'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
  //     packlocation +
  //     '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
  //   // let data =  await this.downloadZipService.newZipSErvices(this.garmentLink)
  //   // this.showImage = data.Normal
  //   // console.log("data",this.showImage)
  //   this.downloadPack0(this.garmentLink);
  //   let data = this.packJsonObject;
  //   console.log(data);
  // }
  // async downloadPack0(url) {
  //   console.log('pack0 loading intilze...');
  //   // Check if a download is already in progress
  //   // Set the download flag to true to indicate a download is in progress

  //   // let url = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=46df48b9-1f7a-4b61-98c6-bff029734da4';
  //   try {
  //     const res = await this._httpClient
  //       .get(url, { observe: 'response', responseType: 'blob' })
  //       .toPromise();
  //     await this.processZipFile(res.body, this.processResponse.bind(this));
  //   } catch (error) {
  //     this.handleErrors(error);
  //   } finally {
  //   }
  //   console.log('pack0 loaded........');
  // }
  // async processResponse(res: any[]) {
  //   this.packJsonObject = res;
  // }
  // processZipFile(data, callBackSetJsonObject) {
  //   // this.processZipFileCopy(data, callBackSetJsonObject)
  //   this.testVar = 'Change Update';
  //   JSZip.loadAsync(data, callBackSetJsonObject, this.testVar)
  //     .then(function (zip) {
  //       /* Parse json file */
  //       var jsonExtn = /(.json)$/;
  //       Object.keys(zip.files)
  //         .filter((fileName) => {
  //           return jsonExtn.test(fileName.toLowerCase());
  //         })
  //         .map((jsonFileName) => {
  //           var jsonFile = zip.files[jsonFileName];
  //           return jsonFile.async('string').then((jsonString) => {
  //             let packJsonObject = JSON.parse(jsonString);

  //             let jsonResponseData = [
  //               {
  //                 title: 'headgear',
  //                 value: {
  //                   files: packJsonObject['hFiles'].filter(
  //                     (data) => data != ''
  //                   ),
  //                   filesIndex: packJsonObject['hFiles'].filter(
  //                     (data) => data == '' || data != undefined
  //                   ),
  //                   name: packJsonObject['hName'].filter(
  //                     (data) => data.indexOf('deleted') < 0
  //                   ),
  //                   count: packJsonObject['hCount'],
  //                 },
  //               },
  //               {
  //                 title: 'upperwear',
  //                 value: {
  //                   files: packJsonObject['uFiles'].filter(
  //                     (data) => data != ''
  //                   ),
  //                   filesIndex: packJsonObject['uFiles'].filter(
  //                     (data) => data == '' || data != undefined
  //                   ),
  //                   name: packJsonObject['uName'].filter(
  //                     (data) => data.indexOf('deleted') < 0
  //                   ),
  //                   count: packJsonObject['uCount'],
  //                 },
  //               },
  //               {
  //                 title: 'bottomwear',
  //                 value: {
  //                   files: packJsonObject['bFiles'].filter(
  //                     (data) => data != ''
  //                   ),
  //                   filesIndex: packJsonObject['bFiles'].filter(
  //                     (data) => data == '' || data != undefined
  //                   ),
  //                   name: packJsonObject['bName'].filter(
  //                     (data) => data.indexOf('deleted') < 0
  //                   ),
  //                   count: packJsonObject['bCount'],
  //                 },
  //               },
  //               {
  //                 title: 'footwear',
  //                 value: {
  //                   files: packJsonObject['fFiles'].filter(
  //                     (data) => data != ''
  //                   ),
  //                   filesIndex: packJsonObject['fFiles'].filter(
  //                     (data) => data == '' || data != undefined
  //                   ),
  //                   name: packJsonObject['fName'].filter(
  //                     (data) => data.indexOf('deleted') < 0
  //                   ),
  //                   count: packJsonObject['fCount'],
  //                 },
  //               },
  //             ];

  //             callBackSetJsonObject(jsonResponseData);
  //           });
  //         });
  //       var re = /(.jpg|.png|.gif|.ps|.jpeg | .json)$/;
  //       var promises = Object.keys(zip.files)
  //         .filter(function (fileName) {
  //           // don't consider non image files
  //           return re.test(fileName.toLowerCase());
  //         })
  //         .map(function (fileName) {
  //           var file = zip.files[fileName];
  //           if (fileName.indexOf('.json') > 0) {
  //           } else {
  //             return file.async('blob').then(function (blob) {
  //               return [
  //                 fileName.substring(0, fileName.lastIndexOf('.')), // keep the link between the file name and the content
  //                 URL.createObjectURL(blob), // create an url. img.src = URL.createObjectURL(...) will work
  //               ];
  //             });
  //           }
  //         });
  //       return Promise.all(promises);
  //     })
  //     .then(function (result) {
  //       return result.reduce(function (acc, val) {
  //         acc[val[0]] = val[1];
  //         return acc;
  //       }, {});
  //     })
  //     .then((result) => {
  //       let newZipList = JSON.parse(JSON.stringify(result));
  //       this.zipList = newZipList;
  //       console.log('newZipList', newZipList);
  //       this.source =
  //         this.zipList[this.garmentId] != undefined
  //           ? this.zipList[this.garmentId]
  //           : ' ';
  //       console.log('this.source', this.source);
  //       this.isloading = false;
  //       return;
  //     })
  //     .catch(function (e) {
  //       console.error(e);
  //     });

  //   // this.reload(data,callBackSetJsonObject)
  // }
  // handleErrors(error) {
  //   console.log('error', error);
  //   this.packJsonObject = [];
  // }

  // getBrandImage(imgURL: string) {
  //   let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
  //   let url =
  //     'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
  //     brandEncodedurl +
  //     '?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c';
  //   return url;
  // }



  clickOnHeart(event: any) {
    event.stopPropagation();
    this.brandheartFlag = !this.brandheartFlag;
  }

  shareBrand() {
    let garmentObjet = this.brandObject.clotObject;
    let brandId = garmentObjet.BrandID;
    let mode = this.tab === 'womens-wear' ? 'W' : this.tab === 'mens-wear' ? 'M' : 'S';
    let link = "https://yolomove-social-live.web.app/threads;brandId=" + brandId + ';M=' + mode + ';Ex=Y';
    // let text = 'Check out '+ this.brandNameSelected +' on Yologram '
    // console.log(text)
    if (navigator.share) {
      navigator.share({
        title: 'Share Brand',
        text: 'Check out ' + this.brandObject.BrandName + ' on Yologram ',
        url: link,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }


  
  callGarmentCloth() {
    let garmentObjet = this.clothDetails;
    let gender = garmentObjet.Gender == 'F' ? 'W' : 'M';
    const dataToSend = {
      garmentId: garmentObjet.id,
      zipSource: this.source,
      typeWear: this.typeWear,
      type: this.typeOfGarment,
         Drap:'Y'
    };
    this.commonService.setSharedObject(dataToSend);
    this.selectedBrandId = garmentObjet.BrandID
    this.router.navigate([
      '/threads',
      {
        brandId: this.selectedBrandId,
        M: gender,
        Ex: 'N',
        garmentId: garmentObjet.id,
        Drape:'Y'
      },
    ]);
  }


  callGarmentClothName() {
    let garmentObjet = this.clothDetails;
    let gender = garmentObjet.Gender == 'F' ? 'W' : 'M';
    const dataToSend = {
      garmentId: garmentObjet.id,
      typeWear: this.typeWear,
      type: this.typeOfGarment,
      Drap:'N'
    };
    this.commonService.setSharedObject(dataToSend);
    this.selectedBrandId = garmentObjet.BrandID
    this.router.navigate([
      '/threads',
      {
        brandId: this.selectedBrandId,
        M: gender,
        Ex: 'N',
        garmentId: garmentObjet.id,
        Drape:'N'
      },
    ]);
  }

  setSelectedGarmetCloth(event) {
    if (event == 'Cloth') {
      this.callGarmentCloth()
    }
  }

  switch3DImage(event: any) {
    event.stopPropagation();
    this.swichImageFlage = !this.swichImageFlage;
  }

  flipClose() {
    this.flipFlag = false;
    this.garmentScrollFlag = false;
  }
  selectIcon(icon) {
    this.selectedIcon = '';
    this.selectedIcon = icon;
    this.iconselctFlag = true;
  }

  setBrandStoryScrollerHeight() {

    this.sideNavHeight = window.innerHeight
    this.brandStoryScrollerHeight = window.innerHeight - 270;

  }

  incrementImgGarmentCard() {

    this.pageNumberGarment++;
    let reqInputObject = {
      fileType: this.typeWear,
      id: this.garmentId,
      pageNumber: this.pageNumberGarment,
    };
    this.commonService
      .yologramSnapshotDetailsByType(reqInputObject)
      .subscribe(async (response: any) => {
        this.userSnapShotListGarment.push(...response.object);
      });
  }

  async fileUrlUpadate(data){
    let garment = data
    let zipNmae = 'pack0';
    let packlocation =
      'AvatarBlenderClothes%2F' +
      this.clothDetails.BrandID +
      '_' +
      garment.Gender +
      '%2F' +
      zipNmae;
      let garmentLink =
      'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
      packlocation +
      '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
    const url = garmentLink;
    const cloth = garment.id;
    
    await this.commonService.downloadAndProcessPack(url, cloth).subscribe({
      next: (response) => console.log('Success:', response),
      error: (error) => console.error('Error:', error)
    });


  }

  
  // checkUrl(url: string): Observable<{ valid: boolean, isImage: boolean }> {
  //   return this.http.get(url, { observe: 'response', responseType: 'blob' }).pipe(
  //     map(response => {
  //       const isImage = response.headers.get('Content-Type')?.startsWith('image/') || false;
  //       return { valid: response.status === 200, isImage: isImage };
  //     }),
  //     catchError(error => {
  //       return throwError(() => new Error('Invalid URL or no data present'));
  //     })
  //   );
  // }

  // checkUrlnew(url) {
  //   this.checkUrl(url).subscribe({
  //     next: (res) => {
  //       if (res.valid) {
  //         if (res.isImage) {
  //           this.result = 'The URL is valid and contains an image.';
  //         } else {
  //           this.result = 'The URL is valid but does not contain an image.';
  //         }
  //       }
  //     },
  //     error: (err) => {
  //       this.result = 'The URL is invalid or the data/image is not present.';
  //     }
  //   });
  // }

  navigateOnThread() {

      this.router.navigate([
        '/threads',
        { brandId: this.brandObject.id, M: 'S', Ex: 'N' },
      ]);
   
  
  
    }


    
  setTab(selectedtab: any, gender) {
    this.tab = selectedtab;
    // this.genderselet = gender
    // if( this.tab=='story'){
    //   this.router.navigate(['/threads', { 'brandId': this.selectedBrandId, 'M': 'S', 'Ex': 'N' }])
    // }
    // let gen = gender == 'F' ? 'W' : gender;
    this.router.navigate([
      '/threads',
      { brandId: this.brandObject.id, M: gender, Ex: 'N' },
    ]);
  }


  selectGarmentImage(index: number, snapObjet) {
    this.selectedImageIndex = index;
    let garmentObjet = this.clothDetails
    this.selectedBrandId = this.brandObject.id
    const dataToSend = {
      garmentId: garmentObjet.id,
      zipSource: this.source,
      typeWear: this.typeWear,
      type: this.typeOfGarment,
      selectImageFunction: true
    };
    this.commonService.setSharedObject(dataToSend);
    this.commonService.setIndex(index);
    let page = this.pageNumberBrand;
    let count = 12
    const currentPage = Math.floor(index / count) + 1;
    this.selectedgender = this.tab === 'mens-wear' ? 'M' : (this.tab === 'womens-wear' ? 'W' : '');
    this.router.navigate([
      '/threads',
      {
        brandId: this.selectedBrandId,
        M: this.selectedgender,
        Ex: 'N',
        storyId: snapObjet.StoryID,
        garmentId: garmentObjet.id,
        Drape:'N',
        'index':index,
        'pageNumber':currentPage
      },
    ]);
  }

}
