import { Component, ElementRef, Input, ViewChild,Renderer2, Output, EventEmitter} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TwoDpopupComponent } from '../templets/twoDimagePopUp/twoDpopup';
import Swiper from 'swiper';
import { NavigationCard } from 'src/app/services/navigation-card-services';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { CaraosalPopupComponent } from '../templets/caraosal-popup/caraosal-popup.component';
@Component({
  selector: 'app-image-slide',
  templateUrl: './image-slide.component.html',
  styleUrls: ['./image-slide.component.css']
})
export class ImageSlideComponent {

  @Input() images: any = [];
  @Input() UserId: any;
  @Input() delete: boolean;
  @Input() deleteGarmentUpload: boolean;
  @Input() clothId: any;
  @Input() UserProfile: any;
  @Input() dismmis: any;
  @Input() modelViewerFlag: boolean;


  
  @Input() threadsPageFlag: boolean ;
  @Output() selectuserUploadImage = new EventEmitter<any>();
  @Output() saveFlage = new EventEmitter<any>();


  @Input() storeFrontFlag: boolean;
  

  
  

  



  
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userUploadedFileList: any = []
  filelist: any = []
  userFiledetails: any = []
  scrollingImages: any = []
  itemWidth = 95;
  totalWidth = 780;
  ele:any;
  loading:boolean = false;
  
  // dummyImages = [
  //   { "image": "./assets/user-upload-dummy/img-6.jpg" },
  //   { "image": "./assets/user-upload-dummy/img-5.jpg" },
  //   { "image": "./assets/user-upload-dummy/img-3.jpg" },
  //   { "image": "./assets/user-upload-dummy/img-2.jpg" },
  // ]

  
  @ViewChild('garmentDummySCroll', { read: ElementRef }) public garmentDummySCroll: ElementRef<any>;
  @ViewChild('swiperContainer') swiperContainer: ElementRef;
  @ViewChild('elRef', { read: ElementRef }) public elRef: ElementRef<any>;


  



  constructor(private modal: NgbModal,
    private renderer:Renderer2,
    private navigate:NavigationCard,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,

    
    ){
      
      this.userFilesList.subscribe((userFile) => {
        this.userUploadedFileList = userFile
        this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads' )
        let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.UserId)
        this.userFiledetails = filelist
        // this.closePopUp()

    });
    // if(this.dismmis){this.closePopUp()
    // }


  }

  ngOnChanges(changes): void {
    this.scrollingImages = this.images;
    console.log('modelViewerFlag',this.modelViewerFlag)
  }

  ngOnInit(): void {
     this.scrollingImages = this.images;
     if(this.threadsPageFlag == undefined){
      this.threadsPageFlag = false
     }
  }
  closePopUp() {
    this.modal.dismissAll(CaraosalPopupComponent)
  }
  ngAfterViewInit(){
    let scrollLeft = this.garmentDummySCroll.nativeElement
    this.inifiniteXScrolls(scrollLeft)
  }

  selectImage(image){
       this.navigate.scrollToElementById(image.id);
  }

  deleteSnapshotFile(data: any) {
    if(this.deleteGarmentUpload){
      var confirmation = confirm('Are you sure to delete this post ?');
      if (!confirmation) return;
      this.firestore.collection("userfiles").doc(data.id).delete().then(() => {
        let filepath = "Uploads/" + data.link +"/"+ data.filename
        this.storage.ref(filepath).delete();
      }).catch((error) => {
      });;
    }else{
      var confirmation = confirm('Are you sure to delete this post ?');
      if (!confirmation) return;
      this.firestore.collection("userfiles").doc(data.id).delete().then(() => {
        let filepath = "Uploads/" + data.userId +"/"+ data.filename
        this.storage.ref(filepath).delete();
      }).catch((error) => {
      });;
  
    }
      this.userfile()
  }


  userfile(){
    if(this.deleteGarmentUpload){
      this.userFilesList.subscribe((userFile) => {
        this.userUploadedFileList = userFile
        this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'GarmentUpload2D' )
        let filelist = this.filelist.filter((uploadlist) => uploadlist.link == this.clothId)
        this.images = filelist
        this.scrollingImages =  this.images
      })
    }else{
      this.userFilesList.subscribe((userFile) => {
        this.userUploadedFileList = userFile
        this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads' )
        let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.UserId)
        this.images = filelist
        this.scrollingImages =  this.images
      })
    }
  }




  scrollinf(){
   new Swiper(".carousel", {
      loopedSlides: 8,
      loop: true,
      slidesPerView: "auto",
      freeMode: true,
      mousewheel: {
        releaseOnEdges: true,
      },
    });
    const thumbElements = this.elRef.nativeElement.querySelectorAll(".item");
    thumbElements.forEach((thumbElement: HTMLElement, index) => {
      const delay = index * 90;
      this.renderer.addClass(thumbElement, "fadeInSlide");
      this.renderer.setStyle(thumbElement, "animation-delay", delay + "ms");
    });
  }



  scrollSlider() {
    let scrollLeft = this.garmentDummySCroll.nativeElement.scrollLeft
    // console.log(scrollLeft +"<"+ this.itemWidth*(this.scrollingImages.length/2))
    if(scrollLeft  <  10) {
      this.loading = true;
      this.garmentDummySCroll.nativeElement.scrollLeft = this.itemWidth*(this.scrollingImages.length-1)

        setTimeout(() => {
            this.loading = true;
         }, 1000);
       }
     
      // console.log("Moving left ",Object.values(this.images));
      // this.scrollingImages = [...Object.values(this.images), ...this.scrollingImages]; 
      // console.log("scrollingImages",this.scrollingImages);
//      console.log("Moving Left ");
      // this.scrollingImages.push(...newArray)
    // console.log(scrollLeft +">"+ this.itemWidth*this.scrollingImages.length)
    if(scrollLeft  >  this.itemWidth*this.scrollingImages.length) {      
      // this.scrollingImages = [...this.scrollingImages,...Object.values(this.images)];
      // console.log( "scrollingImages left",this.scrollingImages)
            this.scrollingImages.push(...this.images) 
            setTimeout(() => {
              this.loading = true;
           }, 1000);

    }
}


// scroll()
// { 
//   let scrollLeft = this.garmentDummySCroll.nativeElement.scrollLeft
//   if(scrollLeft  <  10) {
//     this.loading = true;
//     this.garmentDummySCroll.nativeElement.scrollLeft = this.itemWidth*(this.scrollingImages.length-1)
//       setTimeout(() => {
//           this.loading = true;
//        }, 500);
//      }
   
//   if(scrollLeft >  this.scrollingImages.length*97 ){
//     this.scrollingImages.push(...this.images) 
//     console.log( "scrollingImages left",this.scrollingImages)
//   }


// }
  // inifiniteXScrolls(el) {
  //   let lastScrollX = 0;
  //   let overlapCount = 0;
  //   let clones = [];
  //   while(el.children[overlapCount] && ((el.children[overlapCount].offsetLeft - el.offsetLeft)) <= el.getClientRects()[0].width) {
  //     const clone = el.children[overlapCount].cloneNode(true);
  //     clones.push(clone);
  //     el.appendChild(clone);
  //     overlapCount++;
  //   }
  //   el.scrollTo({left: lastScrollX});
  //   overlapCount -= 1;
  //   let lastScroll: number = el.scrollLeft = 1;
  //   function scrollHandler(): void {
  //     const maxScroll: number = el.scrollWidth - el.clientWidth;
  //     // console.log('maxScroll',maxScroll)
  //     const overlapOverflow =  
  //           (el.children[overlapCount].offsetLeft - el.offsetLeft) 
  //           - el.getClientRects()[0].width
  //           + el.children[overlapCount].getClientRects()[0].width;
  //     if (el.scrollLeft >= maxScroll && el.scrollLeft > lastScroll) {
  //       el.scrollLeft = overlapOverflow;
  //     } else if (el.scrollLeft <= 0  && el.scrollLeft < lastScroll) {
  //       el.scrollLeft = maxScroll - overlapOverflow;      
  //     }  
  //     lastScroll = el.scrollLeft;
  //   }
  //   el.addEventListener('scroll', scrollHandler);
  //   return function () {
  //       const scroll = el.scrollLeft;
  //       clones.forEach((el) => el.replaceWith(""));
  //       el.scrollTo({auto: scroll});
  //       el.removeEventListener('scroll', scrollHandler);
  //   }
  // }

  inifiniteXScrolls(el) {
    let lastScrollX = 0;
    let overlapCount = 0;
    let clones = [];
    // Check if getClientRects() returns a non-empty array
    const clientRects = el.getClientRects();
    if (clientRects.length == 0) {
      // console.error('Element has no client rectangles');
      return null;
    }
    const elWidth = clientRects[0].width;
    while (el.children[overlapCount] && ((el.children[overlapCount].offsetLeft - el.offsetLeft)) <= elWidth) {
      const clone = el.children[overlapCount].cloneNode(true);
      clones.push(clone);
      el.appendChild(clone);
      overlapCount++;
    }
    el.scrollTo({ left: lastScrollX });
    overlapCount -= 1;
    let lastScroll = el.scrollLeft = 1;
  
    function scrollHandler() {
      const maxScroll = el.scrollWidth - el.clientWidth;
      const overlapOverflow =
        (el.children[overlapCount].offsetLeft - el.offsetLeft)
        - elWidth
        + el.children[overlapCount].getClientRects()[0].width;
  
      if (el.scrollLeft >= maxScroll && el.scrollLeft > lastScroll) {
        el.scrollLeft = overlapOverflow;
      } else if (el.scrollLeft <= 0 && el.scrollLeft < lastScroll) {
        el.scrollLeft = maxScroll - overlapOverflow;
      }
      lastScroll = el.scrollLeft;
    }
    el.addEventListener('scroll', scrollHandler);
    return function () {
      const scroll = el.scrollLeft;
      clones.forEach((clone) => clone.replaceWith(""));
      el.scrollTo({ auto: scroll });
      el.removeEventListener('scroll', scrollHandler);
    }
  }
  
  
  open2dPoup3(data) {
    const modalRef = this.modal.open(CaraosalPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.deleteGarmentUpload = this.deleteGarmentUpload;
    modalRef.componentInstance.delete = this.delete;
    modalRef.componentInstance.UserProfile = this.UserProfile;
    modalRef.componentInstance.scrollingImages = this.scrollingImages;
    console.log("this.scrollingImages",this.scrollingImages)
    modalRef.componentInstance.imageID = data.id;
    modalRef.componentInstance.storeFrontFlag = this.storeFrontFlag;
    modalRef.componentInstance.imageDocId = data.docId;
    modalRef.componentInstance.modelViewerFlag = this.modelViewerFlag;
    this.selectuserUploadImage.emit(data.docId)
    modalRef.result.then((result) => {
    }).catch((error) => {
       this.handleModalResult(error)
    });    
  }

  handleModalResult(result: any) {
    console.log('Processing result:', result);
    this.selectuserUploadImage.emit(result.data)
    console.log('Processing result.data:', result.data);
    this.saveFlage.emit(result)
    // Add your logic to handle the result here
  }



  


  
  open2dPoup4(data) {
    const modalRef = this.modal.open(CaraosalPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.deleteGarmentUpload = this.deleteGarmentUpload;
    modalRef.componentInstance.delete = this.delete;
    modalRef.componentInstance.UserProfile = this.UserProfile;
    modalRef.componentInstance.scrollingImages = this.scrollingImages;
    console.log("this.scrollingImages",this.scrollingImages)
    modalRef.componentInstance.imageID = data.id;
    modalRef.componentInstance.storeFrontFlag = this.storeFrontFlag;
    modalRef.result.then((result) => {

      this.saveFlage.emit(result)

    }).catch((error) => {
      console.log(error);
    });    
  }



  open2dPoupDummy(data) {
    const modalRef = this.modal.open(CaraosalPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    // modalRef.componentInstance.deleteGarmentUpload = this.deleteGarmentUpload;
    // modalRef.componentInstance.delete = this.delete;
    // modalRef.componentInstance.UserProfile = this.UserProfile;
    modalRef.componentInstance.scrollingImages = this.scrollingImages;
    modalRef.componentInstance.imageID =  data.image;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });    
  }



open2dPoup(boxImage) {
  const modalRef = this.modal.open(TwoDpopupComponent, {
    modalDialogClass: "model-garmentPopup"
  });
  modalRef.componentInstance.boxImage = boxImage;
  modalRef.result.then((result) => {
    console.log(result);
  }).catch((error) => {
    console.log(error);
  });
}


onScrolsl(event: any) {
  const container = event.target;
  const totalWidth = container.scrollWidth;
  const currentScroll = container.scrollLeft + container.offsetWidth;
  // Check if we've reached the end of the scroll
  if (currentScroll >= totalWidth) {
    // Clone first child
    let firstChild = container.firstChild.cloneNode(true);
    container.appendChild(firstChild);
    // Adjust scroll position after appending child
    container.scrollLeft -= firstChild.offsetWidth;
  }
  // Check if we've reached the start of the scroll
  if (container.scrollLeft === 0) {
    // Clone last child
    let lastChild = container.lastChild.cloneNode(true);
    container.insertBefore(lastChild, container.firstChild);
    // Adjust scroll position after prepending child
    container.scrollLeft += lastChild.offsetWidth;
  }
}
}












