import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { lastValueFrom, Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { Brand } from 'src/app/models/brand';
import { UserPanelDataMapping } from 'src/app/public/avatar-page-redesign/avatar-page-redesign.component';
@Component({
  selector: 'app-avatar-panel',
  templateUrl: './avatar-panel.component.html',
  styleUrls: ['./avatar-panel.component.css']
})
export class AvatarPanelComponent {

  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userAvatarList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;

  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;

  @Input() childAvatarPanelDataMapping: UserPanelDataMapping = {} as UserPanelDataMapping;

  @Input() scrollSide: any;
  @Input() UserShapShotObject: any = {};
  @Input() AvatarID: string;
  @Input() UserID: any | undefined;
  @Input() userData: any;
  @Input() tempAvatrData: any;
  @Input() openSpacePanle: boolean;
  @Input() sameUser: boolean;
  @Input() avatarPageBrandSelection: any;


  @Input() avatarPageNumber: number = 1

  
  








  @Input() rootFlag: string | undefined;
  @Input() userProfile: string;
  @Input() brandStoryScrollerHeight: any;
  @Input() closebuttonFlag: boolean;
  @Input() selectedAvatarName: any;
  @Input() selectedUserName: any;
  @Input() AvatarData: any;
  @Input() ResetPanel: boolean;

  @Input() TabSelection: any;
  @Input() SetBrandId: any;
  @Input() SetGarmentId: any;
  @Input() Index: any;
  @Input() updatdeIndex: any;

  @Input() selectedBrand_AvatarPanel: any;
  @Input() selectedCloth_AvatarPanel: any;

  @Input() SetAvatar_page: any;

  @Input() showBrand_avatar_Slider: boolean | undefined;
  @Input() showCloth_avatar_Slider: boolean | undefined;
  @Input() avatar_by_slider: boolean | undefined;
  show_Panele = false


















  @Input() passTabSelection: boolean;
  @Input() passTabSelectionStyle: boolean;







  
  
  @Output() showStoryList_emit = new EventEmitter<any>();

  @Output() closeAvatarEmitter = new EventEmitter<any>();
  @Output() avatrTabselection = new EventEmitter<any>();

  @Output() show_brandslider_avatarEmit = new EventEmitter<any>();
  @Output() show_clothlider_avatarEmit = new EventEmitter<any>();

  @Output() show_brandslider_a_IndicatorForAvatrPage = new EventEmitter<any>();









  @Output() sendStoryId = new EventEmitter<any>();
  @Output() setTabSelection = new EventEmitter<any>();

  @Output() setTabSelectionBrandId = new EventEmitter<any>();
  @Output() setTabSelectionGarment = new EventEmitter<any>();

  @Output() emitOpneChangeAvatar = new EventEmitter<any>();
  @Output() newEmitUserData = new EventEmitter<any>();










  

  @Output() emitTabAvatarPanelDataObjectList = new EventEmitter<any>();


  @Output() emitSlectedSnapList = new EventEmitter<any>();

  @Output() potrateImage = new EventEmitter<any>();

  @Output() closeAvatarPanel = new EventEmitter<any>();
  @Output() emitSlectedUSER_And_Avatar = new EventEmitter<any>();


















  userAvatarData: any = {};
  allUserSnapDetails: any = []
  tempSnapList: any = []
  snapshotsBrandFilter: any = []
  AllDetails: any = []
  userImfo: any = []
  selectedSanp: any = []
  allUserDetails: any = []
  userFiledetails: any = []
  SnapShotFileDetails: any = []
  filelist: any = []
  userDetails: any = []
  userUploadedFileList: any = []
  userDetail: any = []
  userAllDetails: any = []
  userDetailsObject: any = {}
  avatarFlag = 'avatar'
  userFlag = 'user'
  logInFlag = ''
  filterSnapShotFileDetailsall: any = []
  userDetailsloading: boolean = false
  brandsnaploadingSnap: boolean = true
  selectedTab: any
  secteTab: any = 'Story';
  pageNumber: number = 1
  pagecount: number = 1
  newArray: any = [];

  dropdownOpen: boolean;
  showStoryList: boolean = true;
  brandList: any = [];
  garmentCacheList: any = [];
  snapshotsGarmentFilter: any = [];
  cacheObject: any
  showDetailes = false
  pageFlagRout;


  @Input() brandPageNumber: number = 1;
  @Input() avatargarmentPageNumber: number = 0;
  @Input() avatarBrandPageNumber: number = 0;

  
  currentAvatarGarmentIndex = 12
  currentAvatarBrandIndex = 12

  
  

  // nextAvatarStories: any = []
  // snapshotsAvatarFilter: any = []

  images = [
    { "image": "./assets/user-upload-dummy/new-img-1.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-5.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-6.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-7.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-8.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-9.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-10.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-11.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-12.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-13.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-14.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-15.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-16.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-17.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-18.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-19.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-20.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-21.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-22.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-23.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-24.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-25.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-26.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-27.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-28.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-29.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-30.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-31.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-32.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-33.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-34.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-35.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-36.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-37.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-38.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-39.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-40.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-41.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-42.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-43.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-44.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-45.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-46.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-47.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-48.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-49.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-50.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-51.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-52.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-53.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-54.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-55.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-56.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-57.jpg" },
  ]
  page: number = 1;
  dismiss: boolean = true;
  showChild: boolean = true;
  garmentsCollection: any = [];
  brandsCollection: any = [];
  brandObject: any;
  isBrandLoading: any;
  pageiIndex: any;
  callpageNumer: number = 0;
  nextPage: boolean;
  callpageIndexFlag: any;
  indexCache: any;
  avatrObjFlag: boolean;
  newAvatarfilterSnap: any;
  showSliderBrand: boolean;
  templetShowBrandTag: boolean;
  temp_saved_GarmentFlag: boolean;
  temp_saved_BrandFlag: boolean;
  cacheSnap: any = [];
  previousBrandPageStories: any = [];
  nextBrandStories: any = [];
  pageLoadingFlag = true;

  constructor(
    public router: Router,
    private dataSharingService: DataSharingService,
    private commonService: CommonService,
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {


    this.checkCurrentRoute()

    this.garmentsList.subscribe((garments) => {
      this.garmentsCollection = garments;
    });
    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollection = brands;
    })

    // this.user.subscribe(userdetails => {
    //   this.userDetails = userdetails
    //   let userId = this.UserShapShotObject.UserID != undefined ? this.UserShapShotObject.UserID : (this.userData.uid || this.UserID);
    //   this.userDetailsObject = this.userDetails.find((user) => user.UserID == userId)
    // });


    // this.commonService.getAllUser().subscribe(async (allUsers: any) => {
    //   this.allUserDetails = allUsers.users;
    //   this.AllDetails = allUsers.users;
    // })

    // this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
    //   this.SnapShotFileDetails = snapshot;
    //   // this.update()  
    // })
  }

  async ngOnInit(): Promise<void> {

    // await  this.selectAllAvatars()
    this.filterBrandListAndAvatarList()
    await this.getDocumentDetails()

    //   let reqInputObject = { id: this.UserShapShotObject.AvatarID, pageNumber: 1 }
    //   this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
    //     this.filterSnapShotFileDetailsall = response.object
    //     // this.allUserSnapDetails = response.object.slice(0, 3)
    //     this.allUserSnapDetails= this.filterSnapShotFileDetailsall
    //     this.brandsnaploadingSnap = false
    //     this.filterSnapShotFileDetailsall.forEach(async (snap: any, index) => {
    //     });
    //   })

    // this.userFilesList.subscribe((userFile) => {
    //   let userId = this.UserShapShotObject &&
    //   this.UserShapShotObject.UserID != undefined ? this.UserShapShotObject.UserID : (this.userData.uid || this.UserID);
    //   this.userUploadedFileList = userFile
    //   this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads')
    //   let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == userId)
    //   this.userFiledetails = filelist
    // });
    // this.setuserDetails()
    // ✅ Now this will wait properly

  }


  // ngOnChanges(changes) {
  //   new window['bootstrap'].Carousel(document.getElementById('imageCarousel'), {
  //     interval: true // Disable automatic sliding if needed
  //   });    
  // }

  // async getDocumentDetails() {
  //   await this.commonService.getDocumentsFromSubcollection(this.UserShapShotObject.UserID, this.UserShapShotObject.AvatarID)
  //     .then((documents) => {
  //       if (documents && documents.length > 0) {
  //         const firstDocument = documents[0]; // Assuming there is only one document
  //         this.userAvatarData = null
  //         this.userAvatarData = firstDocument ;
  //         this.newArray = this.processAvatarUrls()
  //         console.log("this.userAvatarData", this.userAvatarData)
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error in component:', error);
  //       throw error; 
  //       // You can choose to re-throw the error or handle it here
  //     });
  // }

  async getDocumentDetails() {
    try {
      const documents = await this.commonService.getDocumentsFromSubcollection(this.UserShapShotObject.UserID || this.UserID, this.UserShapShotObject.AvatarID || this.AvatarID);
      let dummydata = { AvatarID: this.AvatarID || this.UserShapShotObject.AvatarID }
      if (documents && documents.length > 0) {
        const firstDocument = documents[0]; // Assuming there is only one document
        this.userAvatarData = null;
        this.userAvatarData = firstDocument;
        this.newArray = this.processAvatarUrls();
      } else {
        this.userAvatarData = { AvatarID: this.UserShapShotObject.AvatarID };
      }
    } catch (error) {
      console.error('Error in component:', error);
      throw error;
    }
  }

  getAvatarDisplayName(userAvatarData: any): string {
    return userAvatarData?.AvatarName !== undefined && userAvatarData?.AvatarName !== ''
      ? userAvatarData.AvatarName
      : userAvatarData.AvatarID;
  }

  getAvatarImageUrl(userAvatarData: any): string {
    return userAvatarData?.AvatarPreviewPortraitUrl !== undefined && userAvatarData?.AvatarPreviewPortraitUrl !== ''
      ? userAvatarData.AvatarPreviewPortraitUrl
      : './assets/peteravatar.png'; // Replace with the actual path to your dummy image
  }


  processAvatarUrls(): { fileurl: string }[] {
    const urls = [];
    if (this.userAvatarData?.AvatarPreviewFullBodyUrl) {
      urls.push({ fileurlMini: this.userAvatarData.AvatarPreviewFullBodyUrl, fileurl: this.userAvatarData.AvatarPreviewFullBodyUr });
    }
    if (this.userAvatarData?.AvatarPreviewPortraitUrl) {
      urls.push({
        fileurlMini: this.userAvatarData.AvatarPreviewPortraitUrl,
        fileurl: this.userAvatarData.AvatarPreviewPortraitUrl
      });
    }
    this.potrateImage.emit(urls)
    return urls;
  }



  update() {
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.AllDetails = allUsers.users;
      this.AllDetails.forEach(object => {
        let filterSnapShots = this.SnapShotFileDetails.filter((snapShot) => snapShot.UserID == object.uid);
        this.selectedSanp = filterSnapShots;
        if (filterSnapShots.length > 0) {
          this.userImfo.push(object);
        }
      });
      // Assuming this.userData is an array that holds the UserIDs that should not be added again
      const userIDsInUserData = this.userDetails.map(user => user.UserID);
      this.userImfo.forEach(object => {
        let details = object;
        let userDetail = details.providerData[0];
        let saveUserDetails = {
          "DisplayName": userDetail.displayName != undefined ? userDetail.displayName : '',
          "Email": userDetail.email != undefined ? userDetail.email : '',
          "FirstName": '',
          "Identifier": '',
          "LastName": '',
          "MiddleName": '',
          "ProfilePicture": userDetail.photoURL != undefined ? userDetail.photoURL : '',
          "UserID": details.uid != undefined ? details.uid : '',
          "PageName": 'userpopup'
        };

        // Check if UserID is already in this.userData
        if (!userIDsInUserData.includes(saveUserDetails.UserID)) {
          // If not, then check Firestore
          this.firestore.collection('user-profile', ref =>
            ref.where('UserID', '==', saveUserDetails.UserID))
            .get()
            .subscribe(querySnapshot => {
              if (querySnapshot.empty) {
                this.firestore.collection('user-profile').add(saveUserDetails).then((docRef) => { });
              } else {
                console.log("User profile already exists in Firestore");
              }
            });
        } else {
          console.log(`User profile for UserID ${saveUserDetails.UserID} already exists in userData, not adding to Firestore.`);
        }
      });
    });
  }


  // userSnapShots() {
  //   this.closePopUp();
  //   this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
  //   this.dataSharingService.sendUserData(this.userData)
  // }

  async getUserSnapShotDashboard(page, snapshot, index) {
    let id = snapshot.UserID != undefined ? snapshot.UserID : this.UserID
    let count = 12
    const currentPage = Math.floor(index / count) + 1;
    let pagetab = page
    let storyId = snapshot.StoryID != undefined ? snapshot.StoryID : "BlanckStoryID"
    // Construct the parameters object
    const params = {
      id: snapshot.UserID || this.UserID,
      pagetab: page,
      storyId: storyId,
      index: index,
      pageNumber: currentPage,
      PageType: 'User',

    };
    this.router.navigate(['/avatar', params]);

  }

  getUserSnapShotByAvatarId(page, snap, index) {

    let sToryId = snap.StoryID != undefined ? snap.StoryID : ''
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/user')) {
          window.location.reload();
        }
      }
    });
    this.closePopUp();
    let count = 12
    let flag = true
    const currentPage = Math.floor(index / count) + 1;
    this.router.navigate(['/avatar', { 'avatarID': snap.AvatarID || this.AvatarID, 'pagetab': page, 'id': (this.userDetailsObject.UserID || this.UserID), 'Storyid': this.UserShapShotObject.id, 'PageType': 'User' }])

  }

  getUserSnapShotByAvatar(page, snap, index) {
    this.closePopUp();
    event.stopPropagation();
    let flag = true
    let tempAVatarID
    let tempUserID
    // if(this.pageFlagRout == 'avatar' && !snap.SnapshotFileUrl){
    //   return
    // }
    if (this.SetAvatar_page) {
      tempAVatarID = this.SetAvatar_page.AvatarID
      tempUserID = this.SetAvatar_page.UserID
    } else {
      tempAVatarID = this.allUserSnapDetails[0].AvatarID || snap.AvatarID
      tempUserID = this.allUserSnapDetails[0].UserID || snap.UserID
    }


    // if (this.AvatarData) {
    //   tempAVatarID = this.AvatarData.snapshotsData.object[0].AvatarID
    //   tempUserID = this.AvatarData.snapshotsData.object[0].UserID
    // }

    // Set the number of items per page
    const count = 12;
    const currentPage = Math.floor(index / count) + 1;

    // Extract required values with fallback
    const avatarId = tempAVatarID || this.UserShapShotObject?.AvatarID || this.AvatarID;
    const userId = tempUserID || this.userDetailsObject?.UserID || this.UserID;
    const storyId = snap?.StoryID || 'defaultStoryID';


    // Construct the parameters object
    const params: any = {
      avatarID: avatarId,
      pagetab: page,
      id: userId,
      storyId: storyId,
      index: index,
      pageNumber: currentPage,
      PageType: 'Avatar'
    };

    // Add additional logic if certain tabs are selected
    if (this.secteTab === 'Brands') {
      params['TabSelection'] = this.secteTab;
      params['SetBrandId'] = this.brandObject?.id;
      params['avatarBrandPageNumber'] = index
    }




    if (this.secteTab === 'Style') {
      params['TabSelection'] = this.secteTab;
      params['SetGarmentId'] = this.cacheObject?.id;
      params['avatargarmentPageNumber'] = index
    }

    this.router.navigate(['/avatar-v2', params]);
    this.closeAvatarPanel.emit(snap)
    let miss = this.allUserSnapDetails[0]
    let AvatarSwichDetails
    AvatarSwichDetails = AvatarSwichDetails || {};
    AvatarSwichDetails['UserID'] = miss.UserID;
    AvatarSwichDetails['AtarID'] = miss.AvatarID;

    this.emitSlectedUSER_And_Avatar.emit(AvatarSwichDetails)

    if (snap?.StoryID) {
      this.sendStoryId.emit(snap.StoryID);
    } else {
      console.warn('StoryID is undefined for the provided snap object.');
    }

    // Navigate with the constructed parameters


  }


  grtProfile(page) {
    this.closePopUp()
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/user')) {
          window.location.reload();
        }
      }
    });
    this.router.navigate(['user', { 'id': this.UserID, 'pagetab': page }])

    // if(this.rootFlag != undefined || this.UserID != undefined){
    //   this.router.navigate(['yologram-social/yologram-user-myprofile', { 'id':this.UserID, 'pagetab': page }])
    // }
    // else{
    //   this.router.navigate([ this.rootFlag, { 'id':this.userDetailsObject.UserID, 'pagetab': page }])
    // }
  }


  selectSummeryView(tab) {
    this.selectedTab = 'story'
    this.selectTab(tab)
  }

  async selectTab(tab: any) {
    console.log("tab ____________>>>",tab)
    this.secteTab = tab

    if (!this.pageLoadingFlag && event != undefined) {
      //!this.childAvatarPanelDataMapping.userPanelTabDataObjectMap[tab]['selectedTagDetails'].key.includes('All') ) {
      event.stopPropagation();
    }// prevent bubbling
    this.pageLoadingFlag = false

    this.avatrTabselection.emit(tab)

    // if (tab == 'Story') {
    //   this.showStoryList = true
    //   this.allUserSnapDetails = this.AvatarData.snapshotsData.object

    //   this.selectAllAvatars()
    //   this.show_Panele = false
    // }

    // if (tab == 'Brands') {
    //   this.showStoryList = false
    //   this.switchBrandTab()
    //   if (!this.showBrand_avatar_Slider && this.selectedBrand_AvatarPanel && this.secteTab == 'Brands') {
    //     this.selectBrand(this.selectedBrand_AvatarPanel)
    //   }
    //   if (!this.showBrand_avatar_Slider && !this.selectedBrand_AvatarPanel && this.secteTab == 'Brands') {
    //     this.allUserSnapDetails = this.AvatarData.snapshotsData.object
    //     this.showStoryList = true
    //   }
    //   this.showStoryList_emit.emit(this.showStoryList)

    // }

    // if (tab == 'Style') {
    //   this.showStoryList = false
    //   this.switchStyleTab()
    //   if (!this.showCloth_avatar_Slider && this.selectedCloth_AvatarPanel && this.secteTab == 'Style') {
    //     this.selectCacheImage(this.selectedCloth_AvatarPanel)
    //   }
    //   if (!this.showCloth_avatar_Slider && !this.selectedCloth_AvatarPanel && this.secteTab == 'Style') {
    //     this.allUserSnapDetails = this.AvatarData.snapshotsData.object
    //     this.showStoryList = true
    //   }
    //   this.showStoryList_emit.emit(this.showStoryList)

    // }

    // this.setTabSelection.emit(tab)

    // if (this.pageFlagRout == 'avatar') {
    //   // why this condition because when we use this in othe page then its not happen properly
    //   if (!this.showBrand_avatar_Slider && this.selectedBrand_AvatarPanel && this.secteTab == 'Brands') {
    //     this.setTabSelectionBrandId.emit(this.SetBrandId)
    //   }
    //   if (!this.showCloth_avatar_Slider && this.selectedCloth_AvatarPanel && this.secteTab == 'Style') {
    //     this.setTabSelectionGarment.emit(this.SetGarmentId)
    //   }
    // } else {
    //   this.setTabSelectionBrandId.emit(this.SetBrandId)
    //   this.setTabSelectionGarment.emit(this.SetGarmentId)
    // }


    let avatarChecked
    // code for the different user
    if (this.childAvatarPanelDataMapping.userPanelTabDataObjectMap[tab]?.data?.length) {
      const user = this.childAvatarPanelDataMapping.userPanelTabDataObjectMap[tab].data[0];
      
      if (user && user.AvatarID !== undefined) {
        avatarChecked = user.AvatarID === this.AvatarID
      } else {
        console.warn('User object or UserID is undefined');
      }
    } else {
      console.warn('No data found for tab:', tab);
    }

    console.log("Check blank condition", this.childAvatarPanelDataMapping.userPanelTabDataObjectMap)

    if (this.childAvatarPanelDataMapping.userPanelTabDataObjectMap[tab] != undefined &&
      !this.childAvatarPanelDataMapping.userPanelTabDataObjectMap[tab]['selectedTagDetails'].key.includes('All')) {
      // alert("Jai Ho");
      this.allUserSnapDetails = [];

      if (tab == 'Brands') this.selectedBrand_AvatarPanel = this.childAvatarPanelDataMapping.userPanelTabDataObjectMap['Brands']['selectedTagDetails'].object
      if (tab == 'Style') this.selectedCloth_AvatarPanel = this.childAvatarPanelDataMapping.userPanelTabDataObjectMap['Style']['selectedTagDetails'].object
      console.log("allUserSnapDetails after clearing:", this.allUserSnapDetails);
    }
    // this.userPanelDataMapping.userPanelTabDataObjectMap['Style']['selectedTagDetails'] = 
    // {key:data.id , object:data};

    if (this.childAvatarPanelDataMapping.userPanelTabDataObjectMap[tab] != undefined &&
      this.childAvatarPanelDataMapping.userPanelTabDataObjectMap[tab].data != undefined && 
      this.childAvatarPanelDataMapping.userPanelTabDataObjectMap[tab].data.length > 0 && avatarChecked) {

      console.log("childUserPanelTabDataObjectMap", this.childAvatarPanelDataMapping)

      console.log("tab", tab)

      this.allUserSnapDetails = this.childAvatarPanelDataMapping.userPanelTabDataObjectMap[tab].data;
    
      this.emitTabAvatarPanelDataObjectList.emit({
        tab: tab
      });

    } else if (tab == 'Story') {

      // Call this if ALL avatart
      await this.selectAllAvatars();
      // else -- Get stories of that avartart
        this.allUserSnapDetails = this.AvatarData.snapshotsData.object;
        console.log("this.allUserSnapDetails ", this.allUserSnapDetails)
        this.emitTabAvatarPanelDataObjectList.emit({
          tab: tab,
          dataList: this.AvatarData.snapshotsData.object,
          selectedTagDetails: {
            key:  'All Avatar',
            object:  null
          }
        });
      this.showStoryList = true;
      // this.showStoryList_emit.emit(this.showStoryList)
      this.emitSlectedSnapList.emit(this.allUserSnapDetails);
      
    } else if (tab == 'Brands') {
      console.log("allUserSnapDetails", this.allUserSnapDetails)
      this.showStoryList = false;
      if (this.childAvatarPanelDataMapping.userPanelTabDataObjectMap[tab] == undefined
        || (this.childAvatarPanelDataMapping.userPanelTabDataObjectMap[tab] && this.childAvatarPanelDataMapping.userPanelTabDataObjectMap[tab].selectedTagDetails.key == 'All Brands')
      ) {


        this.allUserSnapDetails = this.AvatarData.snapshotsData.object;
        console.log("this.childAvatarPanelDataMapping",this.childAvatarPanelDataMapping)

       
        this.emitTabAvatarPanelDataObjectList.emit({
          tab: tab,
          dataList: this.AvatarData.snapshotsData.object,
          selectedTagDetails: {
            key: 'All Brands',
            object: null
          }
        });
      }
      
      await this.switchBrandTab();
      // this.showStoryList = true;
      this.showStoryList = true
      // this.showStoryList_emit.emit(this.showStoryList)

    } else if (tab == 'Style') {

      if (this.childAvatarPanelDataMapping.userPanelTabDataObjectMap[tab] == undefined
        || (this.childAvatarPanelDataMapping.userPanelTabDataObjectMap[tab] && this.childAvatarPanelDataMapping.userPanelTabDataObjectMap[tab].selectedTagDetails.key == 'All Garment')
      ) {


        this.allUserSnapDetails = this.AvatarData.snapshotsData.object;
        console.log("this.childAvatarPanelDataMapping",this.childAvatarPanelDataMapping)
        this.emitTabAvatarPanelDataObjectList.emit({
          tab: tab,
          dataList: this.AvatarData.snapshotsData.object,
          selectedTagDetails: {
            key: 'All Garment',
            object: null
          }
        });
      }

      await this.switchStyleTab();
      this.showStoryList = true;
      // this.showStoryList_emit.emit(this.showStoryList)
    }


  }

  getSelectSnapshotFeed(userSnap) {
    this.closePopUp()
    let avatar = this.AvatarID
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.filterSnapShotFileDetailsall.filter((snapshot) => snapshot.UserID == userId);
    userSnapShotList.forEach(async (snap: any, index) => {
      let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
      snap.profileName = userObjet && userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
      snap.firstName = snap.profileName.split(" ", 1);
      snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
    })

    let storyObject = { 'id': userId, 'storyId': storyid, 'pageNamuber': this.page, }
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    if (this.AvatarID != undefined) storyObject['avatarID'] = this.AvatarID
    this.router.navigate(['public/public-userStory-feed', storyObject])
  }


  closePopUp() {
    this.activeModal.close();
  }


  incrementImgCounter() {
    this.pageNumber++
    let avatarId = this.AvatarData.snapshotsData.object[0].AvatarID
    let reqInputObject = { id: avatarId, pageNumber: this.pageNumber }
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
      this.allUserSnapDetails.push(...response.object)
    })
  }

  toggleChild() {
    this.closeAvatarEmitter.emit(false)
  }



  async avatarPanelDataFunction(userId, avatarId) {
    if (!userId || !avatarId) {
      console.warn("userId or avatarId is undefined. Skipping HTTP call.");
      return null; // Or handle this case as required in your application
    }

    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getAvatarPanelDetails";

    try {
      return await this.http.post<any>(functionUrl, { userId: userId, avatarId: avatarId }).toPromise();
    } catch (error) {
      console.error("Error fetching avatar panel data:", error);
      throw error;
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    // Handle UserShapShotObject change


    console.log("showCloth_avatar_Slider------------>", this.showCloth_avatar_Slider,this.showCloth_avatar_Slider)



    console.log("loaded", this.openSpacePanle)
    this.callpageNumer = this.avatarPageNumber
    
    console.log("ngOnChanges>>>>>", this.childAvatarPanelDataMapping)


      console.log("ngOnChanges", this.childAvatarPanelDataMapping)
      this.brandStoryScrollerHeight = window.innerHeight - 270;

 if(this.childAvatarPanelDataMapping) {
  this.filterAvatarDetails()
      if(this.childAvatarPanelDataMapping && this.childAvatarPanelDataMapping.selectedTab == 'Brands'
      ) {
        console.log("Loadng select tag")
        this.selectTab(this.childAvatarPanelDataMapping.selectedTab);
      }else if(this.childAvatarPanelDataMapping && this.childAvatarPanelDataMapping.selectedTab == 'Style'
      ){ 
        this.selectTab(this.childAvatarPanelDataMapping.selectedTab);
      }else if (this.childAvatarPanelDataMapping && this.childAvatarPanelDataMapping.selectedTab == 'Story'
      ){
        this.selectTab(this.childAvatarPanelDataMapping.selectedTab);
      }
    }      // console.log(this.allUserDetails)
    
    if (this.pageFlagRout == 'djrip') { 
    if (changes['avatarPageBrandSelection'] && this.avatarPageBrandSelection) {
      console.log("show panel")
    }

    if (!this.showBrand_avatar_Slider && !this.selectedBrand_AvatarPanel && this.secteTab == 'Brands') {
      this.allUserSnapDetails = this.cacheSnap
      this.showStoryList = true
    }

    if (!this.showCloth_avatar_Slider && !this.selectedCloth_AvatarPanel && this.secteTab == 'Style') {
      this.allUserSnapDetails = this.cacheSnap
      this.showStoryList = true
    }




    if (changes['tempAvatrData'] && this.tempAvatrData) {
      this.show_Panele = true
      this.newArray = []
      this.AvatarData = this.tempAvatrData.AvatarData
      this.selectedUserName = this.AvatarData.userDetails.DisplayName;
      this.selectedAvatarName = this.AvatarData.avatarDetails[0].AvatarName;
      this.userAvatarData = this.AvatarData.avatarDetails[0]
      this.allUserSnapDetails = this.AvatarData.snapshotsData.object
      this.newArray = this.processAvatarUrls()
      let u_id = this.allUserSnapDetails[0].UserID
      let a_id = this.allUserSnapDetails[0].AvatarID
      this.UserID = u_id
      this.AvatarID = a_id
      this.UserShapShotObject = null
      this.show_Panele = false
    }



    if (changes['UserShapShotObject'] && this.UserShapShotObject) {
      // this.changeAvatar()
      if (this.pageFlagRout == 'avatar') {
        return
      }
      this.AvatarData = await this.avatarPanelDataFunction(this.UserShapShotObject?.UserID, this.UserShapShotObject?.AvatarID);
      this.loadUserData();
      if (changes['TabSelection'] && this.TabSelection) {
        this.selectTab(this.TabSelection)
      }
    }

    // Handle userData change
    if (changes['userData'] && this.userData && this.userData.userData && this.userData.userData.userDetails) {

      if (this.pageFlagRout == 'avatar') {
        return
      }
      this.AvatarData = this.userData.userData;
      this.selectedUserName = this.AvatarData.userDetails.DisplayName;
      this.selectedAvatarName = this.AvatarData.userDetails.DisplayName;
      this.loadUserData();
      if (changes['TabSelection'] && this.TabSelection) {
        this.selectTab(this.TabSelection)
      }
    } else {
      console.warn('User data or userDetails is missing');
    }
    if (this.ResetPanel == true) {
      this.AvatarData = null
    }

    // Initial load when UserShapShotObject is undefined
    if ((!this.UserShapShotObject || changes['UserShapShotObject'] && !this.AvatarData) || this.ResetPanel) {
      if (this.ResetPanel) {
        // this.AvatarData = null
        return
      }
      console.log("--------------------------------------------------------------------------")
      this.filterAvatarDetails()

      this.brandObject = null;
      this.TabSelection = 'Story'

      // Determine the current values for UserID and AvatarID
      let currentUserID = this.UserShapShotObject?.UserID || this.UserID;
      let currentAvatarID = this.UserShapShotObject?.AvatarID || this.AvatarID;
      if (this.ResetPanel) {
        this.AvatarData = null
      }


      // Fetch avatar data only if it's undefined or needs an update
      if (!this.AvatarData || changes['UserShapShotObject'] && !this.show_Panele) {
        this.AvatarData = await this.avatarPanelDataFunction(currentUserID, currentAvatarID);
       
        if (this.AvatarData) {
          this.loadUserData(); // Load user data after fetching
        }

        console.log("--------------------------------------------------------------------------")


        if (this.ResetPanel && this.AvatarData) {
          this.selectedUserName = this.AvatarData.userDetails.DisplayName;
          this.selectedAvatarName = this.AvatarData.avatarDetails[0].AvatarName;
          this.userAvatarData = this.AvatarData.avatarDetails[0]
          this.allUserSnapDetails = this.AvatarData.snapshotsData.object
          this.newArray = this.processAvatarUrls()
        }

        if (changes['TabSelection'] && this.TabSelection) {
          this.selectTab(this.TabSelection);
        }

        console.log("--------------------------------------------------------------------------")


      }



      if (changes['passTabSelection'] && this.passTabSelection) {
        console.log("passTabSelection", this.passTabSelection)
        this.selectTab('Brands');
      }

      if (changes['passTabSelectionStyle'] && this.passTabSelectionStyle) {
        this.selectTab('Style');
      }

      if (changes['tempAvatrData'] && this.tempAvatrData) {
        this.show_Panele = true
        setTimeout(() => {
          this.newArray = []
          this.AvatarData = this.tempAvatrData.AvatarData
          this.selectedUserName = this.AvatarData.userDetails.DisplayName;
          this.selectedAvatarName = this.AvatarData.avatarDetails[0].AvatarName;
          this.userAvatarData = this.AvatarData.avatarDetails[0]
          this.allUserSnapDetails = this.AvatarData.snapshotsData.object
          this.newArray = this.processAvatarUrls()
          this.show_Panele = false
        }, 3000);

        // Handle tab selection if necessary
      }
    }




    if (this.Index) {
      if (this.pageFlagRout == 'avatar') {
        return
      }
      this.fetchSnapshotByIndex(this.Index)

    }

    if (changes['updatdeIndex'] && this.updatdeIndex) {
      if (this.pageFlagRout == 'avatar') {
        return
      }
      let numb = this.updatdeIndex
      this.loadStoryByScroll(numb)
    }






    if (changes['selectedBrand_AvatarPanel'] && this.selectedBrand_AvatarPanel) {
      // Current value of selectedCloth_AvatarPanel has changed, and this.selectedCloth_AvatarPanel exists
      this.temp_saved_BrandFlag = true
      this.selectBrand(this.selectedBrand_AvatarPanel)
      console.log("selectedBrand_AvatarPanel------------------->", this.selectedBrand_AvatarPanel)
      this.showStoryList = true;
      // Add your logic here for handling the change
    }

    if (changes['selectedCloth_AvatarPanel'] && this.selectedCloth_AvatarPanel) {
      // Current value of selectedCloth_AvatarPanel has changed, and this.selectedCloth_AvatarPanel exists
      this.temp_saved_GarmentFlag = true
      this.selectCacheImage(this.selectedCloth_AvatarPanel)
      this.showStoryList = true;
      // Add your logic here for handling the change
    }


    if (changes['showBrand_avatar_Slider'] &&
      changes['showBrand_avatar_Slider'].previousValue !== changes['showBrand_avatar_Slider'].currentValue) {
      if (!this.temp_saved_BrandFlag && !this.showBrand_avatar_Slider && this.secteTab == 'Brands') {
        this.showBrand_avatar_Slider = false;
        this.selectedBrand_AvatarPanel = null;
        this.secteTab = 'Brands';  // Fixed the `==` to `=`
        this.allUserSnapDetails = this.cacheSnap;
        this.showStoryList = true;
      }

    }


    // if (this.pageFlagRout == 'avatar') {
    //   if (!this.showBrand_avatar_Slider && this.selectedBrand_AvatarPanel && this.secteTab == 'Brands') {
    //     this.temp_saved_BrandFlag = true
    //     this.selectBrand(this.selectedBrand_AvatarPanel)
    //     this.showStoryList = true;
    //   }

    //   if (!this.showCloth_avatar_Slider && this.selectedCloth_AvatarPanel && this.secteTab == 'Style') {
    //     this.temp_saved_GarmentFlag = true
    //     this.selectCacheImage(this.selectedCloth_AvatarPanel)
    //     this.showStoryList = true;
    //   }
    // }

    if (changes['showCloth_avatar_Slider'] &&
      changes['showCloth_avatar_Slider'].previousValue !== changes['showCloth_avatar_Slider'].currentValue) {

      if (!this.temp_saved_GarmentFlag && !this.showCloth_avatar_Slider && this.secteTab == 'Style') {
        this.showCloth_avatar_Slider = false;
        this.selectedCloth_AvatarPanel = null;
        this.secteTab = 'Style';  // Corrected `secteTab ==` to assignment `=`
        this.allUserSnapDetails = this.cacheSnap;
        this.showStoryList = true;
      }
    }

    if (changes['SetAvatar_page'] && this.SetAvatar_page && changes['SetAvatar_page'].previousValue !== changes['SetAvatar_page'].currentValue) {
      // Current value of selectedCloth_AvatarPanel has changed, and this.selectedCloth_AvatarPanel exists
      this.getUserSnapShotByAvatar('story', this.SetAvatar_page.AvatarID, '')
      // Add your logic here for handling the change

    }


    // if(this.avatarPageBrandSelection){
    //   this.allUserSnapDetails = []
    //   console.log("avatarPageBrandSelection", this.avatarPageBrandSelection)
    //   this.selectBrand(this.avatarPageBrandSelection)
    //   // this.avatarPageBrandSelection = null
    // }

  }

  }

  // async loadUserData() {
  //   let data = await this.avatarPanelDataFunction(this.UserShapShotObject.UserID,this.UserShapShotObject.AvatarID)
  //   this.userAvatarData = data.avatarDetails[0]
  //   this.allUserSnapDetails = data.snapshotsData
  //   console.log("data",data)

  // }
  loadStoryByScroll(number) {
    // Only trigger the logic for multiples of 6
    if (number % 5 === 0) {
      this.indexCache = number;
      if (this.indexCache === 5 && this.scrollSide === 'up') {
        this.previousUserbutton();
      } else if (this.indexCache === 5 && this.scrollSide === 'down') {
        this.incrementImgCounter();
      }
    }
  }
  async loadUserData() {
    try {
      this.showStoryList = false
      if (this.AvatarData.length != 0) {
        this.brandsnaploadingSnap = false;
        if (Array.isArray(this.AvatarData.avatarDetails) && this.AvatarData.avatarDetails.length > 0) {
          this.userAvatarData = this.AvatarData.avatarDetails[0]; // Assuming you only need the first avatar detail
          this.newArray = this.processAvatarUrls()
        } else {
          this.userAvatarData = null; // Handle case where no avatar details are found
        }
        if (Array.isArray(this.AvatarData.snapshotsData.object)) {
          if (this.Index == undefined || this.Index < 12 && !this.avatrObjFlag) {
            this.allUserSnapDetails = this.AvatarData.snapshotsData.object
            this.avatrObjFlag = true
          }

          if (this.Index) {
            this.fetchSnapshotByIndex(this.Index)
          }
          // this.allUserSnapDetails = this.AvatarData.snapshotsData.object; // Assuming snapshotsData.object is the array you want to assign
        } else {
          this.allUserSnapDetails = []; // Handle case where no snapshots data is found
        }
        this.userDetailsObject = this.AvatarData.userDetails; // Assuming user
      }
      // Assuming avatarPanelDataFunction is a method that retrieves data asynchronously
      this.AvatarData = await this.avatarPanelDataFunction(this.UserShapShotObject.UserID, this.UserShapShotObject.AvatarID);


      // Check and assign avatar details
      if (Array.isArray(this.AvatarData.avatarDetails) && this.AvatarData.avatarDetails.length > 0) {
        this.userAvatarData = this.AvatarData.avatarDetails[0]; // Assuming you only need the first avatar detail
        console.log("userAvatarData ....",this.userAvatarData)
        this.newArray = this.processAvatarUrls()

      } else {
        this.userAvatarData = null; // Handle case where no avatar details are found
      }

      // Assign snapshots data
      if (Array.isArray(this.AvatarData.snapshotsData.object)) {

        if (this.Index == undefined || this.Index < 12 && !this.avatrObjFlag) {
          this.allUserSnapDetails = this.AvatarData.snapshotsData.object
          this.avatrObjFlag = true
        }

        if (this.Index) {
          this.fetchSnapshotByIndex(this.Index)
        }
        // this.allUserSnapDetails = this.AvatarData.snapshotsData.object; // Assuming snapshotsData.object is the array you want to assign
      } else {
        this.allUserSnapDetails = []; // Handle case where no snapshots data is found
      }

      // Assign user details
      this.userDetailsObject = this.AvatarData.userDetails; // Assuming userDetails is an object directly from the response
      console.log("userAvatarData ....2",this.userAvatarData)
      this.brandsnaploadingSnap = false;
    } catch (error) {
      console.log("not loaded")
    }

    this.tempSnapList = this.allUserSnapDetails
    this.cacheSnap = this.allUserSnapDetails
   
    if (this.cacheSnap != this.allUserSnapDetails) {
      this.emitSlectedSnapList.emit(this.allUserSnapDetails)
    }

    this.filterAvatarDetails()
    this.showStoryList = true
    this.showStoryList_emit.emit(this.showStoryList)
    this.brandsnaploadingSnap = false;

  }

  getGarmentrealtype(garmentId: string): string {
    return this.garmentsCollection.find((b: any) => b.id === garmentId)?.RealType ?? '';
  }

  //showing Brandlogo With Garment Id
  getBrandImageByGarmetnID(garmentId: string): string {
    const imgURL = encodeURI(this.getBrandLogoURL(garmentId)).replace('/', '%2F');
    return `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${imgURL}?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c`;
  }

  //For Finding Brand Logo BrandLogoUrl 
  getBrandLogoURL(garmentId: string): string {
    const garment = this.garmentsCollection.find((b: any) => b.id === garmentId);
    const brandId = garment?.BrandID;
    const brand = this.brandsCollection.find((b: any) => b.id === brandId);
    return brand?.LogoURL ?? '';
  }

  getGarmentName(garmentId: string): string {
    const garment = this.garmentsCollection.find((b: any) => b.id === garmentId);
    return garment?.Name ?? '';
  }


  selectavatarOprtion() {
    this.dropdownOpen = !this.dropdownOpen
  }

  async selectAllAvatars() {
    this.AvatarData = await this.avatarPanelDataFunction(this.UserID, this.AvatarID);
    this.brandObject = null
    this.cacheObject = null
    this.allUserSnapDetails = this.AvatarData?.snapshotsData.object    // this.cacheSnap = this.allUserSnapDetails
    this.dropdownOpen = false
    this.emitSlectedSnapList.emit(this.allUserSnapDetails)
  }
  async selectBrand(brand) {
    this.SetBrandId = null
    let tempAVatarID
    // console.log('this.selectedBrand_AvatarPanel', this.selectedBrand_AvatarPanel)
    console.log('brandObject', brand)
  
    if (this.AvatarData) {
      tempAVatarID = this.AvatarData.snapshotsData.object[0].AvatarID
    }

    let type = 'b';
    const url = await this.commonService.compressImage(brand.LogoURL, type);
    brand.Brandurl = url.downloadUrl; // Save the compressed image URL back into the brand object
    this.brandObject = brand
    const avatarId = this.UserShapShotObject?.AvatarID || this.AvatarID || tempAVatarID || this.allUserSnapDetails[0]?.AvatarID;
    this.isBrandLoading = true; // Show loader

    this.commonService.getSnapshotsByAvatarAndBrand(avatarId, brand.id).subscribe({
      next: (response) => {
       
        this.snapshotsBrandFilter = response.snapshots;
        this.avatarBrandPageNumber = this.avatarBrandPageNumber || 1; // Ensure it's at least 1
        this.snapshotsBrandFilter = response.snapshots;
        const startIndex = (this.avatarBrandPageNumber - 1) * this.currentAvatarBrandIndex;
        const endIndex = startIndex + this.currentAvatarBrandIndex;

        if (Array.isArray(this.snapshotsBrandFilter) && this.snapshotsBrandFilter.length > 0) {
          this.allUserSnapDetails = this.snapshotsBrandFilter.slice(startIndex, endIndex);
          // this.save_first_BrandNavigate_Data =   this.allUserSnapDetails
        } else {
          console.warn("snapshotsBrandFilter is empty or not an array");
        }
        this.showStoryList = true
        this.dropdownOpen = false
        this.emitSlectedSnapList.emit(this.allUserSnapDetails)
        this.show_Panele = false

      },
      error: (error) => {
        console.error('Error fetching snapshots:', error);
      },
      complete: () => {
        this.isBrandLoading = false; // Hide loader
        this.allUserSnapDetails = this.snapshotsBrandFilter
        this.showStoryList = true
      }
    });
  }

  getHeight(length: number): number {
    if (length === 2) {
      return 150;
    } else if (length === 3 || length === 4) {
      return 225;
    } else if (length >= 5) {
      return 300; // Set the "regular height" here
    }
    return 350; // Default height if needed
  }

  async switchBrandTab() {
    let tempAVatarID
    if (this.AvatarData) {
      tempAVatarID = this.AvatarData ? this.AvatarData?.snapshotsData.object[0].AvatarID : this.allUserSnapDetails[0]?.AvatarID;
    }
    this.selectedBrand_AvatarPanel = this.childAvatarPanelDataMapping?.userPanelTabDataObjectMap?.['Brands']?.['selectedTagDetails']?.object || null;
    console.log("this.selectedBrand_AvatarPanel", this.selectedBrand_AvatarPanel)

    const avatarId = this.UserShapShotObject?.AvatarID || this.AvatarID || tempAVatarID || this.allUserSnapDetails[0].AvatarID;
    this.isBrandLoading = true;
    try {
      // Fetch the brand list
      // const response = await this.commonService.getUniqueBrandListByAvatar(avatarId).toPromise();
      // this.brandList = response.brands;
      // // Compress images and update the brand list
      // for (const brand of this.brandList) {
      //   let type = 'b';
      //   const url = await this.commonService.compressImage(brand.LogoURL, type);
      //   brand.Brandurl = url.downloadUrl; // Save the compressed image URL back into the brand object
      // }
      // this.isBrandLoading = false;

      // let data = {
      //   BrandList: this.brandList,
      //   GarmentCacheList: this.garmentCacheList
      // };

      // this.newEmitUserData.emit(data);
      // Cache the current UserID to prevent redundant loading
      // this.cachedUserId = userId;

    } catch (error) {
      console.error('Error fetching brand list:', error);
    } finally {
      // Remove the loader once the process is complete
      if (this.selectedBrand_AvatarPanel) {
        let setbend = this.brandList.find((b) => b.id == this.selectedBrand_AvatarPanel.id)
        // this.selectedBrand_AvatarPanel = setbend
        this.selectBrand(this.selectedBrand_AvatarPanel)
      }
     

    }
  }

  async switchStyleTab() {
    let tempAVatarID
    this.selectedCloth_AvatarPanel = this.childAvatarPanelDataMapping?.userPanelTabDataObjectMap?.['Style']?.['selectedTagDetails']?.object || null;

    if (this.AvatarData) {
      tempAVatarID = this.AvatarData ? this.AvatarData?.snapshotsData.object[0].AvatarID : this.allUserSnapDetails[0].AvatarID;
    } const avatarId = this.UserShapShotObject?.AvatarID || this.AvatarID || tempAVatarID || this.allUserSnapDetails[0].AvatarID;

    console.log("this.selectedCloth_AvatarPanel", this.selectedCloth_AvatarPanel)
    try {
      // Fetch the brand list
      // const response = await this.commonService.getUniqueCacheImageListByAvatar(avatarId).toPromise();
      // this.garmentCacheList = response.garments;
      //  let data = {
      //   BrandList: this.brandList,
      //   GarmentCacheList: this.garmentCacheList
      // };
      // this.newEmitUserData.emit(data);

      // this.cachedUserId = userId;
    } catch (error) {
      console.error('Error fetching brand list:', error);
    } finally {
      if (this.selectedCloth_AvatarPanel) {
        let setbend = this.garmentCacheList.find((b) => b.id == this.selectedCloth_AvatarPanel.id)
        this.selectCacheImage(setbend)
        this.selectedCloth_AvatarPanel = setbend
      }
      this.isBrandLoading = false;
    }
  }

  async selectCacheImage(cache) {
    this.cacheObject = cache
    // this.cacheObject = cache;
    // this.allUserSnapDetails = []
    // this.show_Panele = true

    let tempAVatarID
    if (this.AvatarData) {
      tempAVatarID = this.AvatarData.snapshotsData.object[0].AvatarID

    }

    const avatarId = this.UserShapShotObject?.AvatarID || this.AvatarID || tempAVatarID || this.allUserSnapDetails[0].AvatarID;


    this.commonService.getSnapshotsByAvatarAndGarment(avatarId, cache.id).subscribe({
      next: (response) => {
        // Assign API response to the correct variable
        this.snapshotsGarmentFilter = response.snapshots;
        // Debugging: Log the snapshotsGarmentFilter value  
        // Assign snapshotsGarmentFilter to allUserSnapDetails
       
        // this.allUserSnapDetails = this.snapshotsGarmentFilter;

        this.avatargarmentPageNumber = this.avatargarmentPageNumber || 1
        const startIndex = (this.avatargarmentPageNumber - 1) * this.currentAvatarGarmentIndex;
        const endIndex = startIndex + this.currentAvatarGarmentIndex;
        if (this.snapshotsGarmentFilter) {
          this.allUserSnapDetails = this.snapshotsGarmentFilter.slice(startIndex, endIndex);
        }


        if (this.allUserSnapDetails != this.snapshotsGarmentFilter) {
          this.emitSlectedSnapList.emit(this.allUserSnapDetails);
          this.showStoryList = true;
        }

        // Update UI state
        this.dropdownOpen = false;
        this.show_Panele = false

      },
      error: (error) => {
        console.error('Error fetching snapshots:', error);
      },
      complete: () => {
        // Emit the selected snapshots list
        this.showStoryList = true;
        this.show_Panele = false

      }
    });
  }

  async cacheReGenrate(chache) {
    let tempIamge = 'assets/realtype/upperwear.png'
    let data
    await this.commonService.downloadAndProcessPackUserReference(chache).subscribe({
      next: (response) => {
        data = response
      },
      error: (error) => console.error('Error:', error)
    });
    let image = data.publicUrl
    return image
  }








  /////new previous function
  private loadedPagesNumber = new Set<number>();
  private loadedPagesBackWord = new Set<number>();



  fetchSnapshotByIndex(index: number) {
    // this.previousFlage = true;

    const itemsPerPage = 12; // Number of items per page
    let pageNumber = this.calculatePageNumber(index, itemsPerPage);
    // Check if the page is already loaded
    if (this.loadedPagesNumber.has(pageNumber)) {
      return;
    }


    // Mark the page as loading
    this.loadedPagesNumber.add(pageNumber);

    // Update state variables
    this.pageiIndex = pageNumber;
    this.callpageNumer = pageNumber - 1;
    this.pageNumber = pageNumber;

    // Simulate fetching or call the actual fetching function



    if(this.avatarPageNumber > 1){
      pageNumber =   this.avatarPageNumber  
    }

    this.functNavigate(pageNumber);

  }

  functNavigate(pageNumber) {
    console.log("pageNumber----------->",pageNumber)
    const reqInputObject = { id: this.AvatarID || this.UserShapShotObject.AvatarID, pageNumber: pageNumber };
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(
      async (response: any) => {
        this.pagecount = response.count
        this.allUserSnapDetails = response.object
        this.emitSlectedSnapList.emit(this.allUserSnapDetails)
        if (this.pagecount > this.allUserSnapDetails.length) {
        } else {
          console.log('No new snapshots added. Current pagecount:', this.pagecount);
        }
      },
      (error: any) => {
        console.error('Error occurred while fetching snapshots:', error);
      }
    );
    this.tempSnapList = this.allUserSnapDetails
  }


  calculatePageNumber(index: number, itemsPerPage: number = 12): number {
    const calculatedPageNumber = Math.floor(index / itemsPerPage) + 1;
    return calculatedPageNumber;
  }

  previousUserbutton() {
    const count = 12; // Number of items per page
    // Initialize the page number if necessary
    // if (this.callpageIndexFlag) {
    //   this.pageNumber = this.pageiIndex - 1;
    //   this.callpageIndexFlag = false;
    // }

    if (this.callpageNumer > 0) {

      // Calculate indices for the current page
      const cllpagecount = this.callpageNumer * count;
      const startIndex = cllpagecount - count;

      // Check if the page is already loaded
      if (this.loadedPagesBackWord[cllpagecount]) {
        this.callpageNumer--;
        return;
      }
      if (this.loadedPagesNumber.has(this.callpageNumer)) {
        return;
      }


      // Prepare request object for API call
      let avatarId = this.AvatarData.snapshotsData.object[0].AvatarID

      let reqInputObject = { id: this.AvatarID || avatarId, pageNumber: this.callpageNumer }
      // Get the currently visible item's index
      const currentIndex = this.updatdeIndex; // Placeholder for visible index logic

      this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
        const newItems = response.object; // Data for the current page
        this.nextPage = true;

        // Unshift new items into the array
        this.allUserSnapDetails.unshift(...newItems);

        // Mark this page as loaded
        this.loadedPagesBackWord[cllpagecount] = true;
        this.nextPage = false;


        // Adjust the slider to maintain the same visible item
        const adjustedIndex = currentIndex + newItems.length; // Recalculate the visible item's index
        // this.setSliderToIndex(adjustedIndex); // Programmatically set the slider position

        // Emit the updated snapshot list
        this.emitSlectedSnapList.emit(this.allUserSnapDetails);

        // Decrement the page number
        this.callpageNumer--;
      });
    } else {
      console.log('No more pages to load backward.');
    }
  }

  colapsAvatarPanel(event) {
    event.stopPropagation()
    this.showDetailes = !this.showDetailes
  }

  checkCurrentRoute(): void {
    this.activatedRoute.url.subscribe(url => {
      this.pageFlagRout = url[0].path
      if (this.pageFlagRout == 'avatar') {

      }
      // Check if the URL matches your condition
    });
  }

  async changeAvatar() {
    this.emitOpneChangeAvatar.emit(true);
    let data = {
      user: this.userDetailsObject,
      UserName: this.selectedUserName,
      newArray: this.newArray,
      AvatarData: this.userAvatarData,
      AvatarList: this.newAvatarfilterSnap,
      BrandList: this.brandList,
      GarmentCacheList: this.garmentCacheList
    };
    this.newEmitUserData.emit(data);
  }

  async filterAvatarDetails() {
    let reqInputObject = { userID: this.UserID || this.userDetailsObject.UserID };

    // ✅ Convert observable to Promise using lastValueFrom
    let response: any = await lastValueFrom(this.commonService.yologramPublicByAvatar(reqInputObject));

    this.newAvatarfilterSnap = response.object;

    // ✅ Ensure all avatar data is fetched before proceeding
    await Promise.all(
      this.newAvatarfilterSnap.map(async (entry) => {
        const avatarName = await this.getAvatarData(entry.UserID, entry.AvatarID);
        if (avatarName) {
          entry.AvatarName = avatarName.AvatarName;
          entry.AvatarPreviewPortraitUrl = avatarName.AvatarPreviewPortraitUrl;
        }
      })
    );
  }

  async getAvatarData(id, avatar) {
    try {
      const documents = await this.commonService.getDocumentsFromSubcollection(id, avatar);
      if (documents && documents.length > 0) {
        const firstDocument = documents[0]; // Assuming there is only one document
        return firstDocument;
      }
    } catch (error) {
      console.error('Error getting avatar data:', error);
      return null; // Return null if there's an error fetching the data
    }
  }


  handleCrossButton_Brand() {
    let data = true
    this.showBrand_avatar_Slider = !this.showBrand_avatar_Slider

    if (this.pageFlagRout == 'avatar') {
      let flageData = {
        brand: this.selectedBrand_AvatarPanel?.id,
        flag: true
      }

      this.show_brandslider_a_IndicatorForAvatrPage.emit(flageData)
      this.selectedBrand_AvatarPanel = null
    }
    this.show_brandslider_avatarEmit.emit(this.showBrand_avatar_Slider)
    this.temp_saved_BrandFlag = false
    // this.showStoryList = false

    event.stopPropagation()

  }

  handleAvatrSliderClick() {
    let data = true
    this.showCloth_avatar_Slider =  !this.showCloth_avatar_Slider

    data = this.showCloth_avatar_Slider
    if (this.pageFlagRout == 'avatar') {
      // this.selectedCloth_AvatarPanel = null
    }
    this.show_clothlider_avatarEmit.emit(data)
    this.temp_saved_GarmentFlag = false
    // this.showStoryList = false
    event.stopPropagation()


  }

  getGarmentTypeLogo(option) {
    switch (option) {
      case 0:
        return 'assets/newsfeed/headgear-new.png';
      case 1:
        return 'assets/realtype/upperwear.png'; // Replace with correct image path
      case 2:
        return 'assets/newsfeed/bottomwear-new.png'; // Replace with correct image path
      case 3:
        return 'assets/realtype/footwear.png'; // Replace with correct image path
      case 4:
        return 'assets/realtype/fullbody.png'; // Replace with correct image path
      case 5:
        return './assets/realtype/fullbody.png'; // Replace with correct image path
      default:
        return ''; // Default or placeholder image
    }
  }



  nextBrandPage() {
    if ((this.avatarBrandPageNumber * this.currentAvatarBrandIndex) < this.snapshotsBrandFilter.length) {
      const startIndex = this.avatarBrandPageNumber * this.currentAvatarBrandIndex;
      const endIndex = startIndex + this.currentAvatarBrandIndex;
      const nextBatch = this.snapshotsBrandFilter.slice(startIndex, endIndex);
      this.allUserSnapDetails.push(...nextBatch); // Push new batch at the end
      this.nextBrandStories = this.allUserSnapDetails
      this.avatarBrandPageNumber++;
    }
  }

  prevBrandPage() {
    if (this.avatarBrandPageNumber > 1) {
      const startIndex = (this.avatarBrandPageNumber - 2) * this.currentAvatarBrandIndex;
      const endIndex = startIndex + this.currentAvatarBrandIndex;
      const prevBatch = this.snapshotsBrandFilter.slice(startIndex, endIndex);
      this.allUserSnapDetails.unshift(...prevBatch); // Add previous batch at the start
      this.previousBrandPageStories = this.allUserSnapDetails
      this.avatarBrandPageNumber--;
    }
  }



  nextGarmentPage() {
    if ((this.avatargarmentPageNumber * this.currentAvatarGarmentIndex) < this.snapshotsGarmentFilter.length) {
      const startIndex = this.avatargarmentPageNumber * this.currentAvatarGarmentIndex;
      const endIndex = startIndex + this.currentAvatarGarmentIndex;
      const nextBatch = this.snapshotsGarmentFilter.slice(startIndex, endIndex);
      this.allUserSnapDetails.push(...nextBatch); // Push new batch at the end
      this.avatargarmentPageNumber++;
    }
  }

  prevGarmentPage() {
    if (this.avatargarmentPageNumber > 1) {
      const startIndex = (this.avatargarmentPageNumber - 2) * this.currentAvatarGarmentIndex;
      const endIndex = startIndex + this.currentAvatarGarmentIndex;
      const prevBatch = this.snapshotsGarmentFilter.slice(startIndex, endIndex);
      this.allUserSnapDetails.unshift(...prevBatch); // Add previous batch at the start
      this.avatargarmentPageNumber--;
    }
  }

  async filterBrandListAndAvatarList() {
 const response = await this.commonService.getUniqueCacheImageListByAvatar(this.AvatarID).toPromise();
      this.garmentCacheList = response.garments;
   
    const brandResponse = await this.commonService.getUniqueBrandListByAvatar(this.AvatarID).toPromise();
      this.brandList = brandResponse.brands;
      // Compress images and update the brand list
      for (const brand of this.brandList) {
        let type = 'b';
        const url = await this.commonService.compressImage(brand.LogoURL, type);
        brand.Brandurl = url.downloadUrl; // Save the compressed image URL back into the brand object
      }
      this.isBrandLoading = false;
      let data = {
        BrandList: this.brandList,
        GarmentCacheList: this.garmentCacheList
      };

    this.newEmitUserData.emit(data);
  }

}