<div *ngIf="loading" class="loading">&#8230;</div>
<app-yologram-social *ngIf="logInFlag"></app-yologram-social>

<div *ngIf="!loading">
    <div class="dropzone" dropzone (hovered)="toggleHover($event)" (dropped)="onDropFiles($event)"
        [class.hovering]="isHovering">
        <div class="text-center m-1">
            <h3>File Uploader</h3>
            <p>Drag and Drop a File</p>
        </div>
        <div class="file p-1">
            <label class="file-label ">
                <select class="form-select py-2  m-1" (change)="selectFileType($event)">
                    <option value=""> Select FileType</option>
                    <option value="Environment"> Environment</option>
                    <option value="ClothSource"> ClothSource</option>
                    <option value="GarmentUpload2D"> armentUpload2D</option>
                    <option value="GarmentUpload3D"> armentUpload3D</option>
                </select>
                <input *ngIf="fileType == 'Environment'" class="form-control m-2 p-2" type="text"
                    (change)="setEnvName($event)" placeholder="Enter file name" />
                <input class="form-control m-1 p-2" *ngIf="fileType=='Environment'  ||  fileType == !undefined"
                    [disabled]="!fileType" type="file" (change)="onDrop($event)">
                <span class="file-cta">
                    <span class="file-icon"> <i class="fa fa-upload"></i>
                    </span>
                    <span class="file-label" *ngIf="fileType == 'Environment' || fileType == 'ClothSource' ">
                        or choose a file…
                    </span>
                </span>
                <input class="form-control m-1 p-1" *ngIf="fileType=='ClothSource'" type="file" multiple
                    (change)="onDrop($event)">

            </label>
            <div *ngIf="fileType == 'GarmentUpload2D'">
                <div class="d-flex">
                    <select class="form-select p-1 m-1" (change)="selectbrand($event)">
                        <option value=""> Select Brand</option>
                        <option [value]="brand.id" *ngFor="let brand of brands ;let i=index">{{brand.BrandName}}
                        </option>
                    </select>
                    <select class="form-select p-1 m-1" (change)="selectGender($event)">
                        <option value="">Select Gender</option>
                        <option value="M" >Menwear </option>
                        <option value="F">Womenwear </option>
                        <!-- <option value="B">Both</option> -->

                    </select>
                    <select class="form-select p-1 m-1" (change)="selectRealType($event)">
                        <option value="">Select RealType</option>
                        <option value="0">headgear</option>
                        <option value="1">upperwear </option>
                        <option value="2">bottomwear </option>
                        <option value="3">footwear</option>
                        <option value="4">heeledfootwear </option>
                        <option value="5">fullbody</option>
                    </select>
                    <select class="form-select p-1 m-1" (change)="selectGarment($event)">
                        <option value="">Select Garment</option>
                        <option [value]="cloth.id" *ngFor="let cloth of garmentFilterCloth ;let i=index">{{cloth.Name}}
                        </option>
                    </select>
            </div>
                <input *ngIf="fileType == 'GarmentUpload2D'" class="form-control m-2 p-2" type="text"
                    (change)="setEnvName($event)" placeholder="Enter file name" />
                <input class="form-control m-2 p-2" *ngIf="fileType =='GarmentUpload2D'  ||   fileType == !undefined  "
                    [disabled]="!fileType || brandsDetails ==  ''   || brandRealType  ==  ''   ||  brandGenderDetails ==  ''  || brandGarments ==  '' " type="file" (change)="onDrop($event)">
            </div>
            <div *ngIf="fileType == 'GarmentUpload3D'">
                <div class="d-flex">
                    <select class="form-select p-1 m-1" (change)="selectbrand($event)">
                        <option value=""> Select Brand</option>
                        <option [value]="brand.id" *ngFor="let brand of brands ;let i=index">{{brand.BrandName}}
                        </option>
                    </select>

                    <select class="form-select p-1 m-1" (change)="selectGender($event)">
                        <option value="">Select Gender</option>
                        <option value="M" >Menwear </option>
                        <option value="F">Womenwear </option>
                        <!-- <option value="B">Both</option> -->

                    </select>
                    <select class="form-select p-1 m-1" (change)="selectRealType($event)">
                        <option value="">Select RealType</option>
                        <option value="0">headgear</option>
                        <option value="1">upperwear </option>
                        <option value="2">bottomwear </option>
                        <option value="3">footwear</option>
                        <option value="4">heeledfootwear </option>
                        <option value="5">fullbody</option>
                    </select>

                    <select class="form-select p-1 m-1" (change)="selectGarment($event)">
                        <option value="">Select Garment</option>
                        <option [value]="cloth.id" *ngFor="let cloth of garmentFilterCloth ;let i=index">{{cloth.Name}}
                        </option>
                    </select>

            </div  >
               <span *ngIf=" brandsDetails != '' ||  brandRealType != ''  ||  brandGenderDetails != ''  ||  brandGarments != ''" >
                   <input *ngIf="fileType == 'GarmentUpload3D' " class="form-control m-2 p-2" type="text"
                     (change)="setEnvName($event)" placeholder="Enter file name" />
                   <input class="form-control m-2 p-2" *ngIf="fileType =='GarmentUpload3D'"
                    [disabled]="!fileType " type="file" (change)="onDrop($event)">

                     

                </span>
            </div>
        </div>
    </div>
    <div class="d-flex m-2 col-lg-12" *ngIf="fileType !=''">
        <div class=" col-lg-2">
            <h3>Uploaded files</h3>
        </div>
    </div>
    <div *ngFor="let file of files ">
        <avatar-upload [file]="file" [test]="test" [fileType]="fileType" [envName]="envName" [clothId]="clothId"></avatar-upload>
       

    </div>
    <avatar-index></avatar-index>
</div>