import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.css']
})
export class UserPanelComponent {

  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;

  @Output() closeAvatarEmitter = new EventEmitter<any>();
  @Output() emitSlectedSnapList = new EventEmitter<any>();


  
  @Input() UserShapShotObject: any = {};
  @Input() AvatarID: string;
  @Input() UserID: any ;
  @Input() userData: any;
  @Input() rootFlag: string ;
  @Input() userProfile: string;
  @Input() brandStoryScrollerHeight: any;
  @Input() closebuttonFlag: boolean;
  @Input() selectedUserName: any;
  @Input() brandObject: any

  @Input() UserData: any;







  showChild: boolean = true;
  snapInitCall: boolean;
  dropdownOpen:boolean;


  allUserSnapDetails: any = []
  AllDetails: any = []
  userImfo: any = []
  selectedSanp: any = []
  allUserDetails: any = []
  userFiledetails: any = []
  SnapShotFileDetails: any = []
  filelist: any = []
  userDetails: any = []
  userUploadedFileList: any = []
  userDetail: any = []
  userAllDetails: any = []
  userDetailsObject: any = {}
  avatarFlag = 'avatar'
  userFlag = 'user'
  logInFlag = ''
  filterSnapShotFileDetailsall: any = []
  userDetailsloading: boolean = false
  brandsnaploadingSnap: boolean = true
  selectedTab: any
  secteTab: any = 'Story';
  pageNumber: number = 1
  pagecount: number = 1


  images = [
    { "image": "./assets/user-upload-dummy/new-img-1.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-5.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-6.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-7.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-8.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-9.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-10.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-11.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-12.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-13.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-14.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-15.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-16.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-17.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-18.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-19.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-20.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-21.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-22.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-23.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-24.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-25.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-26.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-27.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-28.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-29.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-30.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-31.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-32.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-33.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-34.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-35.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-36.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-37.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-38.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-39.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-40.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-41.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-42.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-43.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-44.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-45.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-46.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-47.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-48.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-49.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-50.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-51.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-52.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-53.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-54.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-55.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-56.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-57.jpg" },
  ]
  page: number = 1;
  dismiss: boolean = true;
  avatarObject: any;
  newAvatarfilterSnap: any = [];
  newAvatarList: any = [];
  filterSnap: any = [];
  selectedavatarId: any = '';
  avatarMoreFlag: boolean;
  showList: boolean;
  tempSnapList: any = [];
  constructor(
    public router: Router,
    private dataSharingService: DataSharingService,
    private commonService: CommonService,
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore,
    private http: HttpClient,
  ) {


    this.user.subscribe(userdetails => {
      console.log("this.userDetailsObject", this.UserShapShotObject.UserID)
      this.userDetails = userdetails
      let userId = this.UserShapShotObject.UserID != undefined ? this.UserShapShotObject.UserID : (this.userData.uid || this.UserID);
      console.log("userId data", userId)
      // this.userDetailsObject = this.userDetails.find((user) => user.UserID == userId)
      console.log("this.userDetailsObject", this.userDetailsObject)

    });

    // this.commonService.getAllUser().subscribe(async (allUsers: any) => {
    //   this.allUserDetails = allUsers.users;
    //   this.AllDetails = allUsers.users;
    //   console.log("this.AllDetails", allUsers)
    // })

    // this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
    //   this.SnapShotFileDetails = snapshot;
    //   // this.update()  
    // })
  }

  ngOnInit(): void {

    // if (this.AvatarID != undefined) {
    //   let reqInputObject = { id: this.AvatarID, pageNumber: 1 }
    //   this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
    //     this.filterSnapShotFileDetailsall = response.object
    //     // this.allUserSnapDetails = response.object.slice(0, 3)
    //     // this.allUserSnapDetails= this.filterSnapShotFileDetailsall
    //     this.brandsnaploadingSnap = false
    //     this.filterSnapShotFileDetailsall.forEach(async (snap: any, index) => {
    //     });
    //   })
    // } else {
    //   let userId = this.UserShapShotObject && this.UserShapShotObject.UserID != undefined ? this.UserShapShotObject.UserID : this.userData.uid;
    //   let reqInputObject = { id: userId || this.UserID, pageNumber: 1 }
    //   this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
    //     this.filterSnapShotFileDetailsall = response.object
    //     this.pagecount = response.count
    //     // this.allUserSnapDetails = response.object.slice(0, 3)
    //     this.allUserSnapDetails= this.filterSnapShotFileDetailsall
    //     this.brandsnaploadingSnap = false
    //     this.filterSnapShotFileDetailsall.forEach(async (snap: any, index) => {
    //     });
    //   })
    // }
    // this.userFilesList.subscribe((userFile) => {
    //   let userId = this.UserShapShotObject &&
    //   this.UserShapShotObject.UserID != undefined ? this.UserShapShotObject.UserID : (this.userData.uid || this.UserID);
    //   this.userUploadedFileList = userFile
    //   this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads')
    //   let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == userId)
    //   // this.userFiledetails = filelist
    // });
    // this.setuserDetails()


    this.filterAvatarDetails()
  }


  // ngOnChanges(changes) {

  //   // new window['bootstrap'].Carousel(document.getElementById('imageCarousel'), {
  //   //   interval: true // Disable automatic sliding if needed
  //   // });   

  // }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['UserID'] && this.UserID) {
      this.loadUserData();
      console.log("UserID 1", this.UserID)
    }

 

    this.brandStoryScrollerHeight = window.innerHeight - 270;

    console.log("userData", this.userData)

    if (changes['userData'] && this.userData) {
      // Ensure userWholeObject exists
      if (this.userData.userWholeObject) {
        this.UserData = this.userData.userWholeObject;
    
        // Ensure userDetails exists before accessing it
        if (this.UserData.userDetails) {
          this.selectedUserName = this.UserData.userDetails.DisplayName;
        } else {
          console.warn("userDetails is undefined in UserData");
          this.selectedUserName = ''; // Set a default or handle the case
        }
    
        this.loadUserData();
      } else {
        console.warn("userWholeObject is undefined in userData");
      }
    }
    
  }

  async loadUserData() {
    console.log("this.UserID - 2",this.UserID)

    if(this.UserID &&  !this.UserData){
      console.log("this.UserID - 3",this.UserID)
      this.UserData = await this.userPanelDataFunction(this.UserID);
      console.log("this.UserData",this.UserData)
    }

    if (this.UserData.length != 0 && this.UserData) {
      this.userFiledetails = this.UserData.userFiles
      this.userDetailsObject = this.UserData.userDetails
      this.allUserSnapDetails = this.UserData.snapshotsData.object
      this.pagecount = this.UserData.snapshotsData.count
      this.brandsnaploadingSnap = false

    } else {
      this.UserData = await this.userPanelDataFunction(this.UserID);
      if(this.UserData){
        this.userFiledetails = this.UserData.userFiles
        this.userDetailsObject = this.UserData.userDetails
        this.allUserSnapDetails = this.UserData.snapshotsData.object
        this.pagecount = this.UserData.snapshotsData.count
        this.brandsnaploadingSnap = false
      }
    }




     this.tempSnapList = this.allUserSnapDetails
  }


  update() {
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.AllDetails = allUsers.users;
      this.AllDetails.forEach(object => {
        let filterSnapShots = this.SnapShotFileDetails.filter((snapShot) => snapShot.UserID == object.uid);
        this.selectedSanp = filterSnapShots;
        if (filterSnapShots.length > 0) {
          this.userImfo.push(object);
        }
      });

      // Assuming this.userData is an array that holds the UserIDs that should not be added again
      const userIDsInUserData = this.userDetails.map(user => user.UserID);
      this.userImfo.forEach(object => {
        let details = object;
        let userDetail = details.providerData[0];
        let saveUserDetails = {
          "DisplayName": userDetail.displayName != undefined ? userDetail.displayName : '',
          "Email": userDetail.email != undefined ? userDetail.email : '',
          "FirstName": '',
          "Identifier": '',
          "LastName": '',
          "MiddleName": '',
          "ProfilePicture": userDetail.photoURL != undefined ? userDetail.photoURL : '',
          "UserID": details.uid != undefined ? details.uid : '',
          "PageName": 'userpopup'
        };

        // Check if UserID is already in this.userData
        if (!userIDsInUserData.includes(saveUserDetails.UserID)) {
          // If not, then check Firestore
          this.firestore.collection('user-profile', ref =>
            ref.where('UserID', '==', saveUserDetails.UserID))
            .get()
            .subscribe(querySnapshot => {
              if (querySnapshot.empty) {
                this.firestore.collection('user-profile').add(saveUserDetails).then((docRef) => {
                  console.log(`User profile added with ID: ${docRef.id}`);
                  // Optionally, you could add this ID to this.userData to keep the local array updated
                  // this.userData.push({ ...saveUserDetails, UserID: docRef.id });
                });
              } else {
                console.log("User profile already exists in Firestore");
              }
            });
        } else {
          console.log(`User profile for UserID ${saveUserDetails.UserID} already exists in userData, not adding to Firestore.`);
        }
      });
    });
  }


  userSnapShots() {
    this.closePopUp();
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
    this.dataSharingService.sendUserData(this.userData)
  }

  async getUserSnapShotDashboard(page, snapshot, index) {
    let count = 12
    console.log("profile Flag", this.rootFlag)
    const currentPage = Math.floor(index / count) + 1;

    let id = snapshot.UserID != undefined ? snapshot.UserID : this.UserID
    let pagetab = page
    this.pagecount
    let storyId = snapshot.StoryID != undefined ? snapshot.StoryID : "BlanckStoryID"
    this.router.navigate(['/user', { 'id': snapshot.UserID || this.UserID, 'pagetab': page, 'storyId': storyId, 'index': index, 'pageNumber': currentPage }])


    // this.closePopUp();
    // this.router.navigate([ '/user' , { 'id':snapshot.UserID || this.UserID, 'pagetab': page, 'storyId': storyId }])

    // if( this.rootFlag ==  "/edit-user"){
    //   this.router.navigate([this.rootFlag,id,pagetab,storyId])
    // }
    // else if(this.rootFlag ==  "/me" )
    // {
    //   this.router.navigate([ this.rootFlag , { 'id':snapshot.UserID || this.UserID, 'pagetab': page, 'storyId': storyId }])

    // } else if(this.rootFlag ==  '/user'   )
    // {
    //   this.router.navigate([ '/user' , { 'id':snapshot.UserID || this.UserID, 'pagetab': page, 'storyId': storyId }])
    // }
  }

  getUserSnapShotByAvatarId(page, snap) {
    let sToryId = snap.StoryID != undefined ? snap.StoryID : ''
    console.log(this.rootFlag)
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/user')) {
          window.location.reload();
        }
      }
    });
    this.closePopUp();
    this.router.navigate(['/user', { 'avatarID': snap.AvatarID || this.AvatarID, 'pagetab': page, 'id': (this.userDetailsObject.UserID || this.UserID) }])

  }

  grtProfile(page) {
    this.closePopUp()
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/user')) {
          window.location.reload();
        }
      }
    });
    this.router.navigate(['user', { 'id': this.UserID, 'pagetab': page }])

    // if(this.rootFlag != undefined || this.UserID != undefined){
    //   this.router.navigate(['yologram-social/yologram-user-myprofile', { 'id':this.UserID, 'pagetab': page }])
    // }
    // else{
    //   this.router.navigate([ this.rootFlag, { 'id':this.userDetailsObject.UserID, 'pagetab': page }])
    // }
  }


  selectSummeryView(tab) {
    this.selectedTab = 'story'
    this.selectTab(tab)
  }

  selectTab(tab: any) {
    this.secteTab = tab
  }
  getSelectSnapshotFeed(userSnap) {
    this.closePopUp()
    let avatar = this.AvatarID
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.filterSnapShotFileDetailsall.filter((snapshot) => snapshot.UserID == userId);
    userSnapShotList.forEach(async (snap: any, index) => {
      let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
      snap.profileName = userObjet && userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
      snap.firstName = snap.profileName.split(" ", 1);
      snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
    })

    let storyObject = { 'id': userId, 'storyId': storyid, 'pageNamuber': this.page, }
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    if (this.AvatarID != undefined) storyObject['avatarID'] = this.AvatarID
    this.router.navigate(['public/public-userStory-feed', storyObject])
  }


  closePopUp() {
    this.activeModal.close();
  }


  incrementImgCounter() {
    console.log('Incrementing image counter...');

    this.pageNumber++;
    console.log(`Page number incremented to: ${this.pageNumber}`);

    const reqInputObject = { id: this.UserID, pageNumber: this.pageNumber };
    console.log('Request object:', reqInputObject);
    this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(
      async (response: any) => {
        console.log('API response received:', response);
        this.pagecount = response.count
        console.log('API pagecount received:', this.pagecount)
        console.log('this.allUserSnapDetails.length:', this.allUserSnapDetails.length)
        if (this.pagecount > this.allUserSnapDetails.length) {
          console.log('Adding new snapshots to the list...');
          this.allUserSnapDetails.push(...response.object);
          console.log('Updated snapshot details:', this.allUserSnapDetails);
        } else {
          console.log('No new snapshots added. Current pagecount:', this.pagecount);
        }
      },
      (error: any) => {
        console.error('Error occurred while fetching snapshots:', error);
      }
    );
    this.tempSnapList.push(this.allUserSnapDetails)
  }



  toggleChild() {
    this.showChild = !this.showChild;
    this.closeAvatarEmitter.emit(this.showChild)
  }



  async userPanelDataFunction(userId) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getUserPanelDetails";
    return this.http.post<any>(functionUrl, { userId: userId }).toPromise();
  }




async filterAvatarDetails() {
  let reqInputObject = { userID: this.UserID };

  // Wait for the service call to complete
  await this.commonService.yologramPublicByAvatar(reqInputObject).subscribe(async (response: any) => {
    this.newAvatarfilterSnap = response.object;
    console.log("this.newAvatarfilterSnap", this.newAvatarfilterSnap);
    this.newAvatarList = this.newAvatarfilterSnap
    // Use Promise.all with forEach for async operations
    await Promise.all(
      this.newAvatarList.map(async (entry) => {
        const avatarName = await this.getAvatarData(entry.UserID, entry.AvatarID);
        if (avatarName) {
          entry.AvatarName = avatarName.AvatarName;
          entry.AvatarPreviewPortraitUrl = avatarName.AvatarPreviewPortraitUrl;
        }
      })
    );

    console.log("Updated newAvatarList", this.newAvatarList);
  });
}

toggleDropdown() {
  this.dropdownOpen = !this.dropdownOpen;
}

closeDropdown() {
  this.dropdownOpen = false;
}




  async getAvatarData(id, avatar) {
    try {
      const documents = await this.commonService.getDocumentsFromSubcollection(id, avatar);
      if (documents && documents.length > 0) {
        const firstDocument = documents[0]; // Assuming there is only one document
        return firstDocument;
      }
    } catch (error) {
      console.error('Error getting avatar data:', error);
      return null; // Return null if there's an error fetching the data
    }
  }


  selectAvatarId(id) {
    this.avatarMoreFlag = true
    this.avatarObject = id
    this.dropdownOpen = false
    this.selectedavatarId = id.AvatarID
    let reqInputObject = { id: this.selectedavatarId, pageNumber: 1 }
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
      this.filterSnap = response.object;
      this.allUserSnapDetails =  this.filterSnap

      this.emitSlectedSnapList.emit(this.allUserSnapDetails)
    })

  }


  selectAllAvatars(){
    this.allUserSnapDetails  =  this.tempSnapList
    this.dropdownOpen = false

  }
  navigateToAvatarPage() {
    this.router.navigate(['/avatar', { 'id': this.UserID, 'avatarID': this.selectedavatarId, 'pagetab': "story" }])
  }


  selectavatarOprtion(){
    this.dropdownOpen = !this.dropdownOpen
  }
}

