import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, filter } from 'rxjs';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PopupThreeDComponent } from 'src/app/common/popup-three-d/popup-three-d.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { Brand } from 'src/app/models/brand';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-public-evn-profile',
  templateUrl: './public-evn-profile.component.html',
  styleUrls: ['./public-evn-profile.component.css']
})
export class PublicEvnProfileComponent implements OnInit {

  rootFromBrandFlag :any
  evnFileList = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsCollection = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  @Input() rootFlagEven: boolean | undefined;
  @Input() routLogInFlag: boolean | undefined;
  sidenavWidth: number = 20;     
  isExpanded: boolean = false;      // Flag to toggle the sidenav state



  mainMenuFlag: boolean = true
  showpanel: boolean = true
  teamplateFlag : any = 0
  showSearchIcon:boolean;

  userSnapShotList: any = []
  environmentFile: any = []
  evnFile: any
  gltfImgSrc: any = '';
  skyBoXall: any;
  privew: any
  hideFlag: boolean = true;

  // privewImage: any
  profileDetails: any = {}
  selectedAvtar: any
  avatarSnapShotList: any = []
  SnapShotFileDetails: any = []
  garmentsCollection: any = [];
  clothDetails: any = {};
  brandsCollectionall: any = [];
  clocthCollectionIdcollection: any = []
  clothShapShotsList: any = []
  brands: any;
  brandDetails: any
  selectedBrandId: any = "";
  brandboardCollection: any = []
  boards: any;
  userUploadedFileList: any = [];
  userFiledetails: any = []
  clothDetailsList: any = {}
  brandCollectionIdcollection: any = []
  brandhShapShotsListgarmentPopup: any = []
  selectTab: any;
  evnFileImage: any
  storyFeed: any
  counter: number = 1;
  evnName: any
  evnLoading: boolean = true
  logInFlag: boolean;
  evnLoading2: boolean = true
  allUserDetails: any = {}
  evnFileName: any
  selectedStoryId: any;
  storyid: string;
  pagecount: number;
  pageNumber: number = 0;
  showDripPage: boolean;


  //previous-next button variables
  nextPage: boolean = false;
  callpageIndexFlag: boolean = true;
  callpageIndexFlagNextButton: boolean = true;
  callpageNumer: number;
  loadedPages={};
  pageiIndex: any;
  loadedPagesBackWord={};
  index: any;

  windowWidth: number;
  windowHeight: number;
  isMobile: boolean;


  environmentDataCache = [];
  environmentDataCacheOrder = [];
  maxCacheSize: number = 5;
  environmentData: any = [];
  
  newEntry = {
    AvatarID: "",
    BottomwearID: "",
    DateTime: { _seconds: 0, _nanoseconds: 0 },
    DisplayName: "",
    Environment: "",
    FootwearID: "",
    HeadwearID: "",
    SnapshotFileUrl: "",
    SnapshotFileUrlMicro: "",
    SnapshotFileUrlMini: "",
    StoryID: "",
    StoryType: "",
    UpperwearID: "",
    UserID: "",
    avatarName: "",
    home: "",
    id: ""
  }; 
  functionFlag: any = '';
  evntempFile: string;
  urlEvn: any;
  environmentFileName: any;
  evnFileListFileName: any;
evsdiplaynameALL: string;
brandStoryScrollerHeight: any;
evnProfilePageFlag :boolean = true
storyFeedFlage: boolean = false;
  isExpandedCard: boolean;
  envpageFlage:boolean = true
  storyCardflag: boolean;
  storyText: any ='';
  showSearchPanel: boolean;
  showAvatarPanel: boolean = true;

  onResize(event) {
    this.updateWindowDimensions();
  }


  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private apiHttpService: ApiHttpService,
    public commonService: CommonService,
    public router: Router,
    private scrollService: CommonService,
    private http: HttpClient

  ) {
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
    })
 
  


    this.route.paramMap.subscribe(paramMap => {
      this.evnFile = paramMap.get('evnFile')
      this.evnName = paramMap.get('envName')
      this.storyFeed = paramMap.get('storyFeed')
      this.storyid = paramMap.get('storyId')
      this.evnFileName = paramMap.get('evnFileName')
      this.index = paramMap.get('index');
      this.pageiIndex = paramMap.get('pageNumber');
      console.log('evnFile',this.evnFile)
      console.log('evnName',this.evnName)
      console.log('storyFeed',this.storyFeed)
      console.log('storyid',this.storyid)
      console.log('evnFileImage',this.evnFileImage)
      console.log('evnFileName',this.evnFileName)

      let evenListName = "Environments/"
      let nameUrl =  this.evnName 
      let combineUrl = evenListName + nameUrl
      let evnEncodedurl = encodeURI(combineUrl).replace('/', '%2F');

    let evNurl = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + evnEncodedurl + "?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf"
    console.log('evNurl',evNurl)
    this.evnFileImage = evNurl
    })

    // this.dataSharingService.userSnapShotObservable.subscribe(async snapshot => {
    //   this.userSnapShotList = snapshot
    //   this.userSnapShotList.forEach(async (snap: any,index) => {
    //     let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
    //     snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
    //     userObjet.profileName = snap.profileName; 
    //     snap.userData = userObjet
    //     snap.firstName =  snap.profileName.split(" ", 1); 
    //     snap.AvatarLastFive =  snap.AvatarID.substr(snap.AvatarID.length - 5);
    //   });
    // })

    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollectionall = brands;
    });

  
    this.garmentsList.subscribe(
      garments => {
        this.garmentsCollection = garments;
      });
    this.evnFileList.subscribe(envfile => {
      this.environmentFile = envfile
    })
    this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      this.SnapShotFileDetails = snapshot;
    })

    this.brandsCollection.subscribe(
      (brandList) => {
        this.brands = brandList;
        this.brandDetails = this.brands.find((brandList) => brandList.id === this.selectedBrandId)
      });

    this.brandBoardList.subscribe(
      brandboard => {
        this.brandboardCollection = brandboard;
      });

    this.dataSharingService.userclothDetailsListObservable.subscribe(cloth => {
      this.clothDetailsList = cloth
    })

    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      let clothId = this.clothDetailsList
      let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId.id)
      this.userFiledetails = filelist
      this.previewEnvironmentImage()
    })
    this.getstoryFomPOpup()
    setTimeout(() => {
      // this.popupLoadingFor2D = false;
      this.storyid = ''
    }, 0);

    this.logInFlag = this.commonService.isLoggedIn()

  }



  ngOnChanges(changes: SimpleChanges) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      // Here you can fetch the updated query parameters and perform the actions you need.
      // const queryParams = this.route.snapshot.queryParams;
      // console.log("queryParams",queryParams['evnFile'])
      // this.evnFile = queryParams['evnFile']
      // this.evnName =queryParams['envName'] 
      // this.storyFeed = queryParams['storyFeed'] 
      // this.storyid =queryParams['storyId']  
      // this.evnFileImage = queryParams['evnFileImage'] 
      // this.evnFileName = queryParams['evnFileName']  
      // Perform the action you need with the new query parameters.
    });
  }
  ngOnInit() {
    this.updateWindowDimensions()
    this.evnLoading = true;
    setTimeout(() => {
      this.evnLoading = false;
      this.setBrandStoryScrollerHeight()
      this.environmentPanel(this.evnName)
      this.expandSideNav()

    }, 2000);
    this.evnLoading2 = true;
    setTimeout(() => {
      // this.popupLoadingFor2D = false;

      this.evnLoading2 = false;
    }, 2500);


    let title = 'Yologram - spaces'
    this.commonService.changeTitle(title);
    this.selectTab = this.storyFeed
    console.log("Selected Tab",this.storyFeed)

    this.snapInit()

    this.callEvnUrl()

    // this.functionFlag = 'skybox'
  }
  
  scrollToId(id: string) {
   this.scrollService.scrollToElementById(id);
  } 

  snapInit() {
    this.showDripPage = false
    let evn = this.evnFileName
    this.pageNumber = 1;
    let reqInputObject = { id: this.evnFileName, pageNumber: this.pageNumber}
    this.commonService.yologramSnapshotEnvironment(reqInputObject).subscribe(async (response: any) => {
      this.pagecount = response.count
      this.showDripPage = true
      this.route.paramMap.subscribe(paramMap => {
        this.storyid = paramMap.get('storyId')

        if (this.storyid == undefined) {
          setTimeout(async () => {
           this.storyCardflag = true
          }, 2000);
        }
        if (this.storyid != undefined) {
        this.handleStorySelection(this.storyid)  
        
       }
      })
      if (this.index != undefined) {
        this.nextByCall('count')
        return
      }
      this.userSnapShotList.push(...response.object);
      this.userSnapShotList.forEach(async (snap: any, index) => {
        let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID);
      
        // Ensure userObjet is defined
        if (userObjet) {
          snap.profileName = userObjet?.displayName ? userObjet.displayName : snap.UserID;
          userObjet.profileName = snap.profileName;  // Set profileName if userObjet is not undefined
          snap.userData = userObjet;
          snap.firstName = snap.profileName.split(" ", 1);
          snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
        } else {
          // Handle case when no matching user is found
          snap.profileName = snap.UserID;
          snap.userData = null;  // Or handle this as per your logic
          snap.firstName = [snap.UserID];  // Fallback for first name
          snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
        }
      });
      
      console.log('this.userSnapShotList', this.userSnapShotList);
      

    })
    
  }

  // envImage(){
  //   let evnFileAll = this.environmentFile.find((evn) => evn.displayName == this.evnFile)
  //   let evnUrl     = evnFileAll.storageURL
  //   let  evnEncodedurl = encodeURI(evnUrl).replace('/','%2F');
  //   this.privewImage ="https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/"+evnEncodedurl+"?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf"
  // }


  previewEnvironment() {
    let evnFileAll = this.environmentFile.find((evn) => evn.displayName == this.evnFile)
    let evnUrl = evnFileAll.storageURL
    let evnEncodedurl = encodeURI(evnUrl).replace('/', '%2F');
    this.privew = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + evnEncodedurl + "?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf"
    let previews = this.privew

    this.openModel(previews)

  }

  previewEnvironmentImage() {
    let evnFileAll = this.environmentFile.find((evn) => evn.displayName == this.evnFile)
    let evnUrl = evnFileAll.storageURL
    let evnEncodedurl = encodeURI(evnUrl).replace('/', '%2F');
    this.privew = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + evnEncodedurl + "?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf"
    let previews = this.privew

  }



  openModel(skyBoXsrc: any) {
    const dialogRef = this.dialog.open(PopupThreeDComponent);
    this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945'
    this.skyBoXall = skyBoXsrc
    dialogRef.componentInstance.gltfImgSrc = this.privew;
    dialogRef.componentInstance.gltfImgSrc = this.gltfImgSrc;
    dialogRef.componentInstance.skyBoXall = this.skyBoXall;
    dialogRef.afterClosed().subscribe(result => {
    });
  }


  getSnapShotListByUserPopup(snapshot: any) {
    let userId = snapshot.UserID;
    let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
    this.apiHttpService.get(url).subscribe((data: any) => {
      this.profileDetails = data
    })
    this.userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId).slice(0, 9);
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
  }

  getSnapShotListAvatarPopup(snapshot: any) {
    let userId = snapshot.UserID;
    let avatarId = snapshot.AvatarID
    this.selectedAvtar = avatarId
    let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
    this.apiHttpService.get(url).subscribe((data: any) => {
      this.profileDetails = data
    })
    this.avatarSnapShotList = this.SnapShotFileDetails.filter((snapshot) => snapshot.AvatarID == avatarId).slice(0, 9);
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
  }

  getGarmentrealtype(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['RealType'] != undefined) {
        garmentTitle = grandmentObject['RealType'];
      }
    }
    return garmentTitle;
  }





  setClothDetails(garmentId: string) {
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      this.clothDetails = this.garmentsCollection.find((b: any) => b.id === garmentId);
      let brandObject = this.brandsCollectionall.find((b: any) => b.id === this.clothDetails.BrandID);
      this.clothDetails['BrandName'] = brandObject != undefined ? brandObject.BrandName : '';
      this.clothDetails['LogoURL'] = brandObject != undefined ? brandObject.LogoURL : '';
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id);
      this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.clothShapShotsList = this.userSnapShotList.filter((snapshot) => {
        return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      }).slice(0, 9);

      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
        let brandId = grandmentObject["BrandID"];
        this.brandDetails = this.brandsCollectionall.find((b: any) => b.id === brandId);
        this.brandDetails['boardName'] = 'Not Link to Board';
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        if (brandBoardObject != undefined) {
          let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
          let boardDetails = this.boards.find((b: any) => b.id == boardId);
          // this.brandDetails['boardName'] = boardDetails['name'];
          if (boardDetails != undefined && boardDetails['name'] != undefined) this.brandDetails['boardName'] = boardDetails['name'];
        }
      }
      let clothId = this.clothDetails.id
      let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0, 2)
      this.userFiledetails = filelist
    }

  }

  getGarmentName(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        garmentTitle = grandmentObject['Name'];
      }
    }
    return garmentTitle;
  }
  setBrandDetails(garmentId: string) {
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === grandmentObject.BrandID);
      this.brandCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.brandhShapShotsListgarmentPopup = this.SnapShotFileDetails.filter((snapshot) => {
        return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      }).slice(0, 9);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        this.brandDetails = this.brandsCollectionall.find((b: any) => b.id === brandId);
        this.brandDetails['boardName'] = 'Not Link to Board';
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        if (brandBoardObject != undefined) {
          let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
          let boardDetails = this.boards.find((b: any) => b.id == boardId);
          this.brandDetails['boardName'] = boardDetails['name'];
          if (boardDetails != undefined && boardDetails['name'] != undefined) this.brandDetails['boardName'] = boardDetails['name'];
        }
      }
    }
    // if(this.garmentsCollection.length > 0) {
    //   this.brandDetails = this.garmentsCollection.find((b: any) => b.id === garmentId);
    // }}
  }
  getBrandName(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        let brandObject = this.brandsCollectionall.find((b: any) => b.id === brandId);
        brandName = brandObject != undefined && brandObject.BrandName != undefined ? brandObject.BrandName : '';
      }
    }
    return brandName;
  }


  getStoryFeed() {
    this.selectTab = 'story'
  }

  setPageNumber(pageNumber) {
    this.pageNumber = pageNumber
  }

  getstoryFomPOpup() {
    if (this.storyFeed != undefined) {
      this.selectTab = 'storyFeed'
    }
  }
  getStory(StoryID) {   
    this.selectedStoryId = StoryID
    this.selectTab = 'storyFeed'
    this.storyText = ''
    setTimeout(() => {
      // this.selectedStoryId = ''
       this.storyText = ''
    },
      1000);
  }


  nextButtonForEnvironment() {
    this.pageNumber++
    // console.log('counter',counter)
    let reqInputObject = { id: this.evnFileName, pageNumber: this.pageNumber}
    this.commonService.yologramSnapshotEnvironment(reqInputObject).subscribe(async (response: any) => {
      if (this.pagecount > this.userSnapShotList.length) {
        this.userSnapShotList.push(...response.object)
        this.userSnapShotList.forEach(async (snap: any, index) => {
          let userObjet = await this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
          snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
          userObjet.profileName = snap.profileName;
          snap.userData = userObjet
          snap.firstName = snap.profileName.split(" ", 1);
          snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
        })
      }
    })
  }


  handleStoryCall(data) {
    this.nextPage = data
    if (this.nextPage == true) {
      let count = 1
      this.nextButton(count)
    }
  }

  nextByCall(counts) {
    this.storyFeedFlage = false
    const count = 12;
    for (let i = 0; i < this.pagecount; i++) {
      this.userSnapShotList.push(this.newEntry);
    }
    const totalPages = Math.ceil(this.pagecount / count);
    const currentPage = Math.floor(this.index / count) + 1;
    const startIndex = (currentPage - 1) * count;
    console.log("currentPage", currentPage)
    this.counter = count;
    let reqInputObject = { id: this.evnFileName, pageNumber: currentPage }
    this.commonService.yologramSnapshotEnvironment(reqInputObject).subscribe(async (response: any) => {
      const newItems = response.object;
      console.log("this.userSnapShotList",newItems)
      for (let i = 0; i < newItems.length; i++) {
        this.userSnapShotList[startIndex + i] = newItems[i];
      }
    })
    console.log("this.userSnapShotList",  this.userSnapShotList)
    setTimeout(() => {
      this.selectedStoryId = this.storyid;
    }, 10); 
    this.storyFeedFlage = true
    // this.selectedStoryId = this.storyid

  
  }
  nextButton(counts) {
    // Increment the page number

    if (this.callpageIndexFlagNextButton) {
      this.pageNumber = this.pageiIndex;
      console.log('pageiIndex',this.pageiIndex)
      this.callpageIndexFlagNextButton = false
    }
    this.pageNumber++;
  
    const count = 12; // Number of items per page
    const totalPages = Math.ceil(this.pagecount / count);
  
    // Ensure the current page number does not exceed total pages
    if (this.pageNumber > totalPages) {
      this.pageNumber = totalPages;
    }
  
    // Calculate the current page and start index
    const currentPage = this.pageNumber;
    const startIndex = (currentPage - 1) * count;
  
    console.log("pageNumber---------------------------------------->", this.pageNumber);
  
    this.counter = this.pageNumber;
  
    // Check if this page has already been loaded
    if (this.loadedPages[this.pageNumber]) {
      console.log(`Page ${this.pageNumber} is already loaded. Skipping API call.`);
      return;
    }
  
    // Prepare the request object with the updated page number
    let reqInputObject = { id:this.evnFileName, pageNumber: this.counter };
    this.commonService.yologramSnapshotEnvironment(reqInputObject).subscribe(async (response: any) => {
      console.log("this.userSnapShotList before push", this.userSnapShotList);
      const newItems = response.object;
      this.nextPage = true;
  
      // Update userSnapShotList with the new items at the correct start index
      for (let i = 0; i < newItems.length; i++) {
        this.userSnapShotList[startIndex + i] = newItems[i];
      }
  
      // Mark this page as loaded
      this.loadedPages[this.pageNumber] = true;
  
      this.nextPage = false;
      console.log("this.userSnapShotList after push", this.userSnapShotList);
    });
  }
  previousbutton() {
    let count = 12;
  
    if (this.callpageIndexFlag) {
      this.callpageNumer = this.pageiIndex - 1;
      this.callpageIndexFlag = false;
    }
  
    if (this.callpageNumer > 0) {
      let cllpagecount = (this.callpageNumer * count) - 1;
      let startIndex = cllpagecount - (count - 1);
  
      console.log('cllpagecount', cllpagecount);
      console.log(`Alert for index ${cllpagecount} on page ${this.callpageNumer + 1}!`);
  
      // Check if the page has already been loaded
      if (this.loadedPagesBackWord[cllpagecount]) {
        console.log(`Page ${this.callpageNumer + 1} is already loaded. Skipping API call.`);
        this.callpageNumer--;  // Decrease callpage by 1
        return;
      }
  
      // Prepare the request object with the updated page number
      let reqInputObject = { id:this.evnFileName, pageNumber: this.callpageNumer };
      console.log('')
  
      // Make the API call to load the data for the previous page
      this.commonService.yologramSnapshotEnvironment(reqInputObject).subscribe(async (response: any) => {
        console.log("this.userSnapShotList before push", this.userSnapShotList);
        const newItems = response.object;
        this.nextPage = true;
  
        // Update userSnapShotList with the new items at the correct start index
        for (let i = 0; i < newItems.length; i++) {
          this.userSnapShotList[startIndex + i] = newItems[i];
        }
  
        // Mark this page as loaded to prevent future duplicate calls
        this.loadedPagesBackWord[cllpagecount] = true;
  
        this.nextPage = false;
        console.log("this.userSnapShotList after push", this.userSnapShotList);
        console.log("loadedPages", this.loadedPagesBackWord);
  
        // Decrease callpage by 1 after the API call
        this.callpageNumer--;
      });
    }
  }
  async handlePreviousPage(data) {
    console.log("index number :-", data);
    let count = 12;
  
    if (this.callpageIndexFlag) {
      this.callpageNumer = this.pageiIndex - 1;
      this.callpageIndexFlag = false;
    }
  
    while (this.callpageNumer > 0) {
      let cllpagecount = (this.callpageNumer * count) - 1;
      let startIndex = cllpagecount - (count - 1);
      console.log('cllpagecount', cllpagecount);
  
      if (this.loadedPagesBackWord[cllpagecount]) {
        console.log(`Page ${cllpagecount} is already loaded. Skipping API call.`);
        return;
      }
  
      if (cllpagecount === data) {
        console.log(`Alert for index ${cllpagecount} on page ${this.callpageNumer + 1}!`);
  
        try {
          let reqInputObject = { id:  this.evnFileName, pageNumber: this.callpageNumer };
          this.callpageNumer--;  // Decrease callpage by 1
  
          const response = await this.commonService.yologramSnapshotEnvironment(reqInputObject).toPromise();
          const newItems = response['object'];
          this.nextPage = true;
          // Update userSnapShotList with the new items at the correct start index
          newItems.forEach((item, index) => {
            this.userSnapShotList[startIndex + index] = item;
          });
          this.loadedPagesBackWord[cllpagecount] = true;
          console.log("loadedPages", this.loadedPagesBackWord);
        } catch (error) {
          console.error("Error fetching page data:", error);
        }
      } else {
        break;  // Exit loop if no match is found
      }
    }
  }

  
  updateWindowDimensions() {
    this.windowHeight = window.innerHeight;
     this.windowWidth = window.innerWidth;
     this.isMobile = window.innerWidth <= 768; // You can adjust the width threshold for mobile mode

    console.log('Window Height:',   this.windowHeight);
    console.log('Window Width:',  this.windowWidth);

    // You can also use these dimensions to set styles or perform other logic
  }

  callEvnUrl(){
    let evenListName = "Environments/";
    let nameUrl = this.evnName;
    let combineUrl = evenListName + nameUrl;
    let evnEncodedurl = encodeURI(combineUrl).replace('/', '%2F');
    this.functionFlag = null
    let evNurl = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + evnEncodedurl + "?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf";
    console.log('evNurl', evNurl);
  
    // Delay the following code by 2 seconds (2000 milliseconds)
    setTimeout(() => {
      this.evntempFile = this.evnFileImage;
      this.functionFlag = 'skybox';
      console.log('Delayed execution, evntempFile set:', this.evntempFile);
    }, 1000); // Adjust the delay (in milliseconds) as needed
  }

  functionLoaded(data){
    alert(data)
  }

  setSceneFlag(data){
    if(data){
      this.callEvnUrl()
    }
  }
  

  async environmentPanelDataFunction(storageUrl) {
    let functionUrl = " https://us-central1-yolomoves-fb435.cloudfunctions.net/observeEnvironmentsByStorageURL";
    return this.http.post<any>(functionUrl, { storageURL: storageUrl }).toPromise();
  }

  async environmentPanel(environment: any) {
    this.evnFileList.subscribe(async envfile => {
      this.environmentFile = envfile
  
    // event.stopPropagation();
    const evenListName = 'Environments/';
    const environmentKey = evenListName + environment;
    this.environmentData = []
    console.log('environmentKey',environmentKey)
    // Check if the environment data is in the cache
    if (this.environmentDataCache[environmentKey]) {
      // Data is cached
      this.evnFileListFileName = this.environmentFile.find((snap) => snap.storageURL === environmentKey);
      this.urlEvn = environment;
      this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945';
      this.environmentFileName = environment.Environment;
      this.environmentData = this.environmentDataCache[environmentKey];
      
      this.environmentData.snapData['object'] = this.userSnapShotList

      console.log("evenData",this.environmentData)
      console.log("Environment data from cache", this.environmentDataCache[environmentKey]);
    } else {
      // Data is not cached, fetch from server
      console.log("this.environmentFile",this.environmentFile )
      this.evnFileListFileName = this.environmentFile.find((snap) => snap.storageURL === environmentKey);
      console.log("this.evnFileListFileName ",this.evnFileListFileName )

      this.evnFileListFileName['envFileName'] = environment;

      this.environmentData = await this.environmentPanelDataFunction(environment); // Fetch environment data
      console.log("evenData",this.environmentData)
      // this.environmentData['snapData'] = this.userSnapShotList
      this.environmentData.snapData['object'] = this.userSnapShotList

      console.log("evenData",this.environmentData)


      // Cache the fetched data
      if (this.environmentDataCacheOrder.length >= this.maxCacheSize) {
        const oldestCacheKey = this.environmentDataCacheOrder.shift(); // Remove the oldest cached entry
        delete this.environmentDataCache[oldestCacheKey];
      }

      this.environmentDataCache[environmentKey] = this.environmentData;
      this.environmentDataCacheOrder.push(environmentKey); // Add new entry at the end

      this.urlEvn = environment;
      this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945';
      this.environmentFileName = environment.Environment;

      console.log("Environment data fetched and cached", this.environmentDataCache[environmentKey]);
    }

    // Open the model with the environment data
   
  })
  }

  handleStorySelection(StoryID){
     this.storyCardflag = false
    // this.isExpandedCard = true
    this.getStory(StoryID) 
  }


  setBrandStoryScrollerHeight() {
    this.brandStoryScrollerHeight = window.innerHeight - 270;
  }
   // Set the initial width to 20px

  // Function to change the tab and expand the side nav
  expandSideNav(): void {
    if (this.selectTab === 'story') {
      this.sidenavWidth = 181; // Expand the sidenav to 190px for 'story' tab
      this.isExpanded =  true
    } else if (this.selectTab === 'storyFeed') {
      // this.isExpandedCard =  true
      this.sidenavWidth = 40;  // Collapse the sidenav to 15px for 'storyFeed' tab
    }
  }

  toggleSideNav(event): void {
    event.stopPropagation();
    this.isExpanded = false; // Toggle the boolean flag
    if (this.isExpanded) {
      this.sidenavWidth = 181; // Expanded width when the flag is true
    } else {
      this.sidenavWidth = 40;  // Collapsed width when the flag is false
      
    }
    this.showSearchPanel = false
    this.showAvatarPanel = false
  }
  toggleOpen(event): void {
    event.stopPropagation();
    this.isExpanded = true; // Toggle the boolean flag
    this.sidenavWidth = 181;
  }
  
  flipClose(event){
    event.stopPropagation();
    this.isExpandedCard = false
    this.storyCardflag = true
    this.isExpanded = true
    this.sidenavWidth = 181;
    this.showAvatarPanel = true
    // this.toggleSideNav()
    // this.isExpanded = !this.isExpanded
    
  }


  handlepreviousbutton(data){

    if(data){ this.previousbutton()  }
  }

  handleEvenPanel(data){
    if(data){ 
      this.sidenavWidth = 181;
      this.isExpanded = true
      }
  }

  openAvatarPanel(flag,$event) {
    event.stopPropagation();
    // this.wholeDiv = false;
    // this.closeDiv = true;
    this.showSearchPanel = true;
    this.showAvatarPanel = true;
    
    if (flag === 'search') {
      this.showSearchPanel = true;
      this.showAvatarPanel = false;
    } else if (flag === 'avatar') {
      this.showAvatarPanel = true;
      this.showSearchPanel = false;
      this.isExpanded = true; // Toggle the boolean flag
      this.sidenavWidth = 181;
    }
  
    }

    handleClosepanleFlag(data){
      if(data){
        // this.closePanel()
        this.showSearchIcon = false
        // this.snapInIt()
      }
    }
  
    handleOpenpanleFlag(data){
      if(data){
        this.showSearchIcon = true
        this.showAvatarPanel = false
      }
    }

}